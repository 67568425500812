import React, { FC } from 'react';
import VerifyCodeStep from 'components/BorrowerProfile/LoginDetails/BasePopupSteps/VerifyCodeStep';


interface IVerifyEmailStepProps {
  email: string;
  onResendCode: () => Promise<void>;
  onSubmit: (code: string) => Promise<void>;
  isLoading?: boolean;
}

const VerifyEmailStep: FC<IVerifyEmailStepProps> = ({ email, onResendCode, onSubmit, isLoading }) => {
  const message = `Please enter the code sent to ${email}.`;

  return (
    <VerifyCodeStep
      buttonText="Verify Email"
      message={message}
      onResendCode={onResendCode}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};

export default VerifyEmailStep;
