import { TaskStatus } from 'api/digifi/TasksApi';
import { TagColor } from 'product_modules/components/Tag/Tag';

const getTaskTagColor = (status: TaskStatus): TagColor => {
  switch (status) {
    case TaskStatus.NotDone:
      return 'yellow';
    case TaskStatus.InProgress:
      return 'blue';
    case TaskStatus.InReview: {
      return 'violet';
    }
    default:
      return 'gray';
  }
};

export default getTaskTagColor;
