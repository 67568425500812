import styled from 'types/AppTheme';

interface ISvgIconContainerStyledProps {
  disableHover?: boolean;
}

const SvgIconStyled = styled.div<ISvgIconContainerStyledProps>`
  &:hover {
    background-color: ${({ disableHover, theme }) => !disableHover && theme.colors.accentColor};

    svg path, svg polyline {
      stroke:  ${({ disableHover, theme }) => !disableHover && theme.colors.white100Base};
    }
  }

  svg path, svg polyline  {
    stroke: ${({ theme }) => theme.colors.accentColor};
  }
`;

export default SvgIconStyled;
