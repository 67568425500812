import React, { createContext, FC, useMemo } from 'react';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { AnyAction, Store } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface CreateEntitiesLoaderParams<Entity, Field> {
  loadEntities: (fields: Field[]) => AnyAction | ((...args: any[]) => Promise<unknown>);
  isFieldLoading: (field: Field, state: RootState) => boolean;
  isFieldAlreadyLoaded: (field: Field, state: RootState) => boolean;
}

export interface EntitiesLoaderProps {
  debounceTimeout?: number;
}

interface EntitiesLoaderContextValue<Field> {
  (fields: Field[], store: Store<RootState>): Promise<void>;
}

const createEntitiesLoader = <Entity, Field extends string | number>(params: CreateEntitiesLoaderParams<Entity, Field>) => {
  const EntitiesLoaderContext = createContext<EntitiesLoaderContextValue<Field>>(() => {
    throw new Error('No <EntitiesLoader> exists upper in the tree.');
  });

  const EntitiesLoader: FC<EntitiesLoaderProps> = ({
    children,
    debounceTimeout,
  }) => {
    const dispatch = useDispatch();

    const loadEntities = useMemo(() => {
      let fields: Field[] = [];

      const searchEntitiesDebounced = debounce(async (store: Store<RootState>) => {
        const state = store.getState();

        const {
          fields: fieldsToLoad,
        } = fields.reduce((aggregation, field) => {
          if (aggregation.visitedMap[field.toString()]) {
            return aggregation;
          }

          aggregation.visitedMap[field.toString()] = true;

          if (!params.isFieldAlreadyLoaded(field, state) && !params.isFieldLoading(field, state)) {
            aggregation.fields.push(field);
          }

          return aggregation;
        }, {
          fields: [] as Field[],
          visitedMap: {} as Record<string, boolean>,
        });[...fields].filter((field) => {
          return !params.isFieldAlreadyLoaded(field, state) && !params.isFieldLoading(field, state);
        });

        fields = [];

        if (fieldsToLoad.length) {
          dispatch(params.loadEntities(fieldsToLoad));
        }
      }, debounceTimeout);

      return async (requestedFields: Field[], store: Store<RootState>) => {
        fields.push(...requestedFields);

        await searchEntitiesDebounced(store);
      };
    }, [debounceTimeout]);

    return (
      <EntitiesLoaderContext.Provider value={loadEntities}>
        {children}
      </EntitiesLoaderContext.Provider>
    );
  };

  return [
    EntitiesLoaderContext,
    EntitiesLoader,
  ] as [typeof EntitiesLoaderContext, typeof EntitiesLoader];
};

export default createEntitiesLoader;
