import { AddressValue, AddressValueKey } from 'product_modules/api/Types';
import { GeocodeResult } from 'use-places-autocomplete';

enum GoogleAddressComponentType {
  StreetNumber = 'street_number',
  StreetName = 'route',
  Locality = 'locality',
  Sublocality = 'sublocality',
  State = 'administrative_area_level_1',
  Country = 'country',
  PostalCode = 'postal_code',
}

export const getAddressByGeocodeResult = (geocodeResult: GeocodeResult): AddressValue => {
  return geocodeResult.address_components.reduce((address, component) => {
    if (component.types.includes(GoogleAddressComponentType.StreetNumber)) {
      return { ...address, [AddressValueKey.StreetNumber]: component.long_name };
    }

    if (component.types.includes(GoogleAddressComponentType.StreetName)) {
      return { ...address, [AddressValueKey.StreetName]: component.long_name };
    }

    if (component.types.includes(GoogleAddressComponentType.Locality)) {
      return { ...address, [AddressValueKey.City]: component.long_name };
    }

    if (component.types.includes(GoogleAddressComponentType.Sublocality)) {
      return { ...address, [AddressValueKey.Sublocality]: component.long_name };
    }

    if (component.types.includes(GoogleAddressComponentType.State)) {
      return { ...address, [AddressValueKey.StateOrProvince]: component.long_name };
    }

    if (component.types.includes(GoogleAddressComponentType.Country)) {
      return { ...address, [AddressValueKey.Country]: component.long_name };
    }

    if (component.types.includes(GoogleAddressComponentType.PostalCode)) {
      return { ...address, [AddressValueKey.ZipOrPostalCode]: component.long_name };
    }

    return address;
  }, {} as AddressValue);
};
