import BaseApi from 'api/BaseApi';

export interface IEmbeddedSigningData {
  embeddedSigningUrl: string;
  clientId?: string;
  bundleUrl?: string;
  waitingForOthers?: boolean;
}

export interface IGetEmbeddedSigningDataResponse {
  data: IEmbeddedSigningData | null;
}

export interface IDocuSignApi {
  getEmbeddedSigningData(applicationDisplayId: string): Promise<IGetEmbeddedSigningDataResponse>;
}

export default class DocuSignRestApi extends BaseApi implements IDocuSignApi {
  public getEmbeddedSigningData(applicationDisplayId: string): Promise<IGetEmbeddedSigningDataResponse> {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('applicationDisplayId', applicationDisplayId);

    return this.fetch(`/docusign/views?${urlSearchParams}`);
  }
}
