import { useEffect, useState } from 'react';

interface IUseTimeIntervalParams {
  shouldLoadingStart: boolean;
  minLoadingTime: number;
}

const useFakeLoader = ({ minLoadingTime, shouldLoadingStart }: IUseTimeIntervalParams) => {
  const [isLoading, setIsLoading] = useState(false);
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    if (shouldLoadingStart) {
      setIsLoading(true);

      timeout = setTimeout(() => {
        setIsLoading(false);
      }, minLoadingTime);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [minLoadingTime, shouldLoadingStart]);

  return isLoading;
};

export default useFakeLoader;
