import { KeyboardEvent, RefObject } from 'react';
import KeyEventEnum from 'product_modules/common/KeyEventEnum';

type Callback<EventElement> = (e?: KeyboardEvent<EventElement>) => void;

type OnlyFunction<EventElement> = {
  [key in keyof EventElement]: EventElement[key] extends Function ? key : never;
};

type AllowedNames<Base> = OnlyFunction<Base>[keyof Base];

export const HandleKeyDown = <EventElement>(key: KeyEventEnum, callback: Callback<EventElement>) => {
  return (event: KeyboardEvent<EventElement>) => {
    if (event.key === key) {
      callback(event);
    }
  };
};

const TriggerEventOnEnterKeyDown = <EventElement extends {}>(
  ref: RefObject<EventElement>,
  method: AllowedNames<EventElement>,
) => {
  return HandleKeyDown(KeyEventEnum.Enter, () => {
    if (ref.current) {
      ((ref.current[method] as unknown) as () => void)();
    }
  });
};

export default TriggerEventOnEnterKeyDown;
