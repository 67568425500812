import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { VariableValue } from 'product_modules/api/Types';

export interface ISubmitPageParams {
  data?: Record<string, VariableValue>;
  applicationDisplayId?: string;
}

interface IPortalPageInfoApi {
  submitPageData(params: ISubmitPageParams): Promise<void>;
}

export default class PortalPageInfoRestApi extends BaseApi implements IPortalPageInfoApi {
  public submitPageData(params: ISubmitPageParams): Promise<void> {
    return this.fetch('/portal-page-info', {
      method: FetchMethodType.POST,
      body: params,
    });
  }
}
