import ProductList from 'components/ApplicationForm/ProductListModal/ProductList/ProductList';
import ModalOverlay from 'components/common/ModalOverlay';
import React, { FC } from 'react';
import styles from './ProductListModal.module.scss';
import { IProduct } from 'api/digifi/ProductsApi';
import ProductOptions from 'components/ApplicationForm/ProductListModal/ProductOptions';
import Button from 'components/digifi-wrappers/Button';
import { IProductOptions } from 'components/ApplicationForm/ProductListModal/ProductOptions/ProductOptions';

interface IProductListModalProps {
  products: IProduct[];
  selectedProduct: IProduct;
  productOptions: IProductOptions;
  onSelect: (id: string) => void;
  onOptionsChange: (options: IProductOptions) => void;
  onClose: () => void;
  onContinue: () => void;
}

const ProductListModal: FC<IProductListModalProps> = ({
  products,
  selectedProduct,
  productOptions,
  onSelect,
  onClose,
  onOptionsChange,
  onContinue,
}) => {
  return (
    <ModalOverlay
      sectionClassName={styles.productListModal}
      title="Start New Application"
      onClose={onClose}
      contentClassName={styles.container}
      titleClassName={styles.title}
      headerClassName={styles.popupHeader}
    >
      <ProductList
        selectedProductId={selectedProduct.id}
        products={products}
        onSelect={onSelect}
      />
      <ProductOptions
        className={styles.productOptions}
        product={selectedProduct}
        onChange={onOptionsChange}
        options={productOptions}
      />
      <Button
        className={styles.continueButton}
        kind="primary"
        onClick={onContinue}
        size="form"
      >
        Continue
      </Button>
    </ModalOverlay>
  );
};

export default ProductListModal;
