import { FC, useEffect, useState } from 'react';
import { NotFoundSignImage } from 'static/images';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import { getBorrower } from 'handlers/borrowersSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import AuthorizedCenteredLayout from 'layout/AuthorizedCenteredLayout';

const NotFoundPage: FC= () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const fetchData = async () => {
    setIsLoading(true);

    await dispatchWithUnwrap(getBorrower());
  };

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, []);

  const handleClick = () => {
    navigate(AppRoute.Home);
  };

  const renderSubtitle = () => {
    return (
      <>
        We were unable to find the page you requested.<br/>Please check the URL, go back or return to our home page.
      </>
    );
  };

  return (
    <AuthorizedCenteredLayout
      title="Page Not Found!"
      subtitle={renderSubtitle()}
      image={<NotFoundSignImage />}
      showButton
      buttonTitle="Go To Homepage"
      onButtonClick={handleClick}
      isLoading={isLoading}
      applicationsBrowserTitle="Not Found"
    />
  );
};

export default NotFoundPage;
