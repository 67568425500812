import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createLayoutEntitiesByIdsSelector } from 'handlers/layout-configurations/Selectors';
import { useLayoutConfiguration } from './useLayoutConfiguration';

export const useLayoutConfigurationEntities = <EntityType>(
  referenceKey: string,
  storagePath: string,
  parentId: string,
  pathToEntities: 'sortedCardsByGroup' | 'sortedVariableConfigurationsByCard',
) => {
  const layoutConfiguration = useLayoutConfiguration(referenceKey);

  const layoutEntitiesSelector = useMemo(() => {
    return createLayoutEntitiesByIdsSelector<EntityType>(
      storagePath,
      layoutConfiguration?.[pathToEntities][parentId] || [],
    );
  }, [layoutConfiguration?.[pathToEntities], storagePath, parentId]);

  return useSelector(layoutEntitiesSelector) as EntityType[];
};
