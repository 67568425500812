import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import ValueFormattingError from 'product_modules/utils/valueFormatters/base/ValueFormattingError';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';
import { StringVisualDataType } from 'product_modules/enums/VisualDataType';

const isTextValue = getValueVisualTypeCorrespondenceChecker(StringVisualDataType.Text);

const formatTextValue: ValueFormatter = (
  value,
) => {
  if (!isTextValue(value)) {
    throw new ValueFormattingError();
  }

  return value.toString();
}

export default formatTextValue;
