import TestModeNotification from 'components/TestModeNotificationList/TestModeNotification/TestModeNotification';
import { FC, useState } from 'react';
import styles from './TestModeNotificationList.module.scss';
import clsx from 'clsx';
import { ArrowRightBold } from 'static/images';

export interface ITestModeNotificationItems {
  title: string;
  message: string;
}

interface ITestModeNotificationListProps {
  className?: string;
  notificationClassName?: string;
  items: ITestModeNotificationItems[];
}

const TestModeNotificationList: FC<ITestModeNotificationListProps> = ({
  className,
  notificationClassName,
  items,
}) => {
  const [index, setIndex] = useState(0);

  const currentItem = items[index] || null;

  const renderControls = () => {
    if (items.length === 1) {
      return null;
    }

    return (
      <div className={styles.pagination}>
        <div className={styles.index}>
          {index + 1} / {items.length}
        </div>
        <div>
          <ArrowRightBold
            className={clsx(styles.icon, styles.left)}
            onClick={() => setIndex(Math.max(index - 1, 0))}
          />

          <ArrowRightBold
            className={styles.icon}
            onClick={() => setIndex(Math.min(index + 1, items.length - 1))}
          />
        </div>
      </div>
    );
  };

  if (!currentItem) {
    return null;
  }

  return (
    <div className={clsx(styles.container, className)}>
      <TestModeNotification
        className={notificationClassName}
        title={currentItem.title}
        message={currentItem.message}
      />
      {renderControls()}
    </div>
  );
};

export default TestModeNotificationList;
