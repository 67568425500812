import BaseApi from 'api/BaseApi';

export interface IGetMeasurementIdResponse {
  measurementId: string | null;
}

export interface IGoogleAnalyticsApi {
  getMeasurementId(): Promise<IGetMeasurementIdResponse>;
}

export default class GoogleAnalyticsRestApi extends BaseApi implements IGoogleAnalyticsApi {
  public getMeasurementId(): Promise<IGetMeasurementIdResponse> {
    return this.fetch('/google-analytics');
  }
}
