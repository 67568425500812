import { FC } from 'react';
import { batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeLayoutConfigurations } from 'handlers/layoutConfigurationsSlice';
import { removeBorrowerProfileCards } from 'handlers/borrowerProfileCardsSlice';
import { removeBorrowerProfileGroups } from 'handlers/borrowerProfileGroupsSlice';
import { removeBorrowerProfileVariables } from 'handlers/borrowerProfileVariableConfigurationsSlice';
import { useAppDispatch } from 'hooks/reduxHooks';
import { AppRoute } from 'enums/AppRoute';
import DiscardApplicationPopUp from './DiscardApplicationPopUp';

interface IDiscardApplicationPopupConnectorProps {
  onClose: () => void;
}

const DiscardApplicationPopupConnector: FC<IDiscardApplicationPopupConnectorProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    clearApplicationConfigurations();
    onClose();
    navigate(AppRoute.Home);
  };

  const clearApplicationConfigurations = () => {
    batch(() => {
      dispatch(removeLayoutConfigurations());
      dispatch(removeBorrowerProfileCards());
      dispatch(removeBorrowerProfileGroups());
      dispatch(removeBorrowerProfileVariables());
    });
  };

  return (
    <DiscardApplicationPopUp
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default DiscardApplicationPopupConnector;
