import { SortDirection } from '@material-ui/core';
import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { DataViewSortingType, FormLayoutData, VariableValue } from 'product_modules/api/Types';

export enum ApplicationStatusType {
  Custom = 'custom',
  Initial = 'initial',
  Approve = 'approve',
  Reject = 'reject'
}

export interface IApplicationStatus {
  id: string;
  name: string;
  type: ApplicationStatusType;
}

export interface IApplicationViewModel {
  id: string;
  displayId: string;
  status: IApplicationStatus;
  productId: string;
  productName: string;
  createdAt: string;
  incompleteTasksCount: number;
  borrowerId: string;
  variables: FormLayoutData;
  updatedAt: string;
  coborrowerTypes: BorrowerType[];
  borrowerType: BorrowerType;
  statusDate?: string;
  loanAmount?: number;
}

interface IGetBorrowerApplicationsRequest {
  count: number;
  offset: number;
  sortField: ApplicationSortingField;
  sortDirection: SortDirection;
}

interface IGetBorrowerApplicationsResponse {
  applications: IApplicationViewModel[];
  total: number;
}

export interface ICreateBorrowerParams {
  type: BorrowerType;
  variables: Record<string, VariableValue>;
}

export interface ICreateApplicationParams {
  productId: string;
  coborrowers?: ICreateBorrowerParams[];
}

export enum ApplicationSortingField {
  CreatedAt = 'createdAt',
  DisplayId = 'displayId',
  LoanAmount = 'loanAmount',
}

export type ApplicationsSortingType = DataViewSortingType<ApplicationSortingField>;

export interface IApplicationsApi {
  getBorrowerApplications(params: IGetBorrowerApplicationsRequest): Promise<IGetBorrowerApplicationsResponse>;
  getCurrentApplication(): Promise<IApplicationViewModel | null>;
  createApplication(params: ICreateApplicationParams): Promise<IApplicationViewModel>;
}

export default class ApplicationsRestApi extends BaseApi implements IApplicationsApi {
  public getBorrowerApplications(params: IGetBorrowerApplicationsRequest): Promise<IGetBorrowerApplicationsResponse> {
    const urlParams = new URLSearchParams();

    for (const param of Object.keys(params)) {
      urlParams.append(param, params[param as keyof IGetBorrowerApplicationsRequest].toString());
    }

    return this.fetch(`/applications?${urlParams}`);
  }

  public async getCurrentApplication(): Promise<IApplicationViewModel | null> {
    const { application } = await this.fetch<{ application: IApplicationViewModel | null }>('/applications/current');

    return application;
  }

  public createApplication(params: ICreateApplicationParams): Promise<IApplicationViewModel> {
    const { productId } = params;

    return this.fetch('/applications', {
      method: FetchMethodType.POST,
      body: {
        productId,
        variables: {},
        coborrowers: params.coborrowers,
      },
    });
  }

  public findByDisplayId(displayId: string) {
    return this.fetch<IApplicationViewModel>(`/applications/${displayId}`);
  }
}
