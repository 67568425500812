import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateAccountFormParams } from 'types';
import { ISignInFormContextType } from 'components/Auth/forms/SignInForm/SignInForm';
import TokenUtils from 'utils/TokenUtils';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';
import AccountsRestApi, { ICreateAccountParams } from 'api/digifi/AccountsApi';
import SessionsRestApi from 'api/digifi/SessionsApi';
import InvitesRestApi from 'api/digifi/InvitesApi';
import EmailVerificationRestApi from 'api/digifi/EmailVerificationApi';
import PhoneVerificationRestApi from 'api/digifi/PhoneVerificationApi';
import ResetPasswordRestApi from 'api/digifi/ResetPasswordApi';
import { BorrowerType } from 'product_modules/enums/BorrowerType';

export enum AccountsActionType {
  AcceptInvite = 'auth/acceptInvite',
  GetInviteInfo = 'auth/getInviteInfo',
  CreateAccount = 'auth/createAccount',
  SendEmailVerificationCode = 'auth/sendEmailVerificationCode',
  VerifyEmail = 'auth/verifyEmail',
  SendPhoneVerificationCode = 'auth/sendPhoneVerificationCode',
  VerifyPhone = 'auth/verifyPhone',
  SignIn = 'auth/signIn',
  ResetPassword = 'auth/resetPassword',
  CreateNewPassword = 'auth/createNewPassword',
  GetCurrentAccount = 'auth/getCurrentAccount',
  CreatePasswordValidationToken = 'auth/createPasswordValidationToken',
  SendUpdatePhoneNumberCode = 'auth/sendUpdatePhoneNumberCode',
  UpdatePhoneNumber = 'auth/updatePhoneNumber',
  SendUpdateEmailAddressCode = 'auth/sendUpdateEmailAddressCode',
  UpdateEmailAddress = 'auth/updateEmailAddress',
  UpdatePassword = 'auth/updatePassword',
  Logout = 'auth/logout',
  ValidateSessionToken = 'auth/validateSessionToken',
}

interface IAccountParams {
  email: string;
  phone: string;
  isEmailVerified: boolean | null;
  isMfaComplete: boolean | null;
  borrowerId: string;
  isPhoneVerified: boolean | null;
  fullName: string;
  isBlocked: boolean;
}

interface IInviteParams {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  borrowerType: BorrowerType;
}

export interface IAuthState {
  accountData?: IAccountParams;
  inviteData?: IInviteParams;
}

const getAccountInitialState = () => {
  return {
    email: '',
    phone: '',
    isEmailVerified: null,
    isMfaComplete: null,
    borrowerId: '',
    isPhoneVerified: null,
    fullName: '',
    isBlocked: false,
  };
};

const initialState: IAuthState = {
  accountData: undefined,
  inviteData: undefined,
};

const accountsApi = new AccountsRestApi();
const sessionsApi = new SessionsRestApi();
const invitesApi = new InvitesRestApi();
const emailVerificationApi = new EmailVerificationRestApi();
const phoneVerificationApi = new PhoneVerificationRestApi();
const resetPasswordApi = new ResetPasswordRestApi();

export const acceptInvite = createAsyncThunk<void, { params: ICreateAccountFormParams, token: string }>(AccountsActionType.AcceptInvite,
  async ({ params, token }) => {
    const { accessToken, refreshToken } = await invitesApi.acceptInvite({ ...params }, token);

    TokenUtils.setTokens({ accessToken, refreshToken });
  },
);

export const getInviteInfo = createAsyncThunk(
  AccountsActionType.GetInviteInfo,
  async (token: string) => {
    return invitesApi.getInviteInfo(token);
  },
);

export const createAccount = createAsyncThunk(
  AccountsActionType.CreateAccount,
  async (params: ICreateAccountParams, thunkApi) => {
    try {
      const { accessToken, refreshToken } = await accountsApi.createAccount({ ...params });

      TokenUtils.setTokens({ accessToken, refreshToken });
    } catch (error) {
      return rejectWithValueHelper(error, thunkApi);
    }
  },
);

export const sendEmailVerificationCode = createAsyncThunk(
  AccountsActionType.SendEmailVerificationCode,
  async () => {
    await emailVerificationApi.sendEmailVerificationCode();
  },
);

export const verifyEmail = createAsyncThunk(
  AccountsActionType.VerifyEmail,
  async (code: string, thunkApi) => {
    try {
      await emailVerificationApi.verifyEmail(code);
    } catch (error) {
      return rejectWithValueHelper(error, thunkApi);
    }
  },
);

export const sendPhoneVerificationCode = createAsyncThunk(
  AccountsActionType.SendPhoneVerificationCode,
  async () => {
    await phoneVerificationApi.sendPhoneVerificationCode();
  },
);

export const verifyPhone = createAsyncThunk(
  AccountsActionType.VerifyPhone,
  async (code: string, thunkApi) => {
    try {
      await phoneVerificationApi.verifyPhone(code);
    } catch (error) {
      return rejectWithValueHelper(error, thunkApi);
    }
  },
);

export const signIn = createAsyncThunk(
  AccountsActionType.SignIn,
  async (params: ISignInFormContextType) => {
    const { accessToken, refreshToken } = await sessionsApi.signIn(params.email, params.password);

    TokenUtils.setTokens({ accessToken, refreshToken });
  },
);

export const resetPassword = createAsyncThunk(
  AccountsActionType.ResetPassword,
  async (email: string) => {
    await resetPasswordApi.resetPassword(email);
  },
);

export const createNewPassword = createAsyncThunk(
  AccountsActionType.CreateNewPassword,
  async (params: { password: string; resetPasswordToken: string }) => {
    await resetPasswordApi.createNewPassword(params.password, params.resetPasswordToken);
  },
);

export const getCurrentAccount = createAsyncThunk(
  AccountsActionType.GetCurrentAccount,
  async() => {
    return accountsApi.getCurrentAccount();
  },
);

export const createPasswordValidationToken = createAsyncThunk(
  AccountsActionType.CreatePasswordValidationToken,
  async (password: string) => {
    const { passwordValidationToken } = await accountsApi.createPasswordValidationToken(password);
    TokenUtils.setTokens({ passwordValidationToken });
  },
);

export const sendUpdatePhoneNumberCode = createAsyncThunk(
  AccountsActionType.SendUpdatePhoneNumberCode,
  async (phone: string, thunkApi) => {
    try {
      return await accountsApi.sendUpdatePhoneNumberCode(phone);
    } catch (error) {
      return rejectWithValueHelper(error, thunkApi);
    }
  },
);

export const updatePhoneNumber = createAsyncThunk(
  AccountsActionType.UpdatePhoneNumber,
  async (code: string, thunkApi) => {
    try {
      return await accountsApi.updatePhoneNumber(code);
    } catch (error) {
      return rejectWithValueHelper(error, thunkApi);
    }
  },
);

export const sendUpdateEmailAddressCode = createAsyncThunk(
  AccountsActionType.SendUpdateEmailAddressCode,
  async (email: string, thunkApi) => {
    try {
      return await accountsApi.sendUpdateEmailAddressCode(email);
    } catch (error) {
      return rejectWithValueHelper(error, thunkApi);
    }
  },
);

export const updateEmailAddress = createAsyncThunk(
  AccountsActionType.UpdateEmailAddress,
  async (code: string, thunkApi) => {
    try {
      return await accountsApi.updateEmailAddress(code);
    } catch (error) {
      return rejectWithValueHelper(error, thunkApi);
    }
  },
);

export const updatePassword = createAsyncThunk<void, { oldPassword: string, newPassword: string }>(
  AccountsActionType.UpdatePassword,
  async ({ oldPassword, newPassword }) => {
    return accountsApi.updatePassword(oldPassword, newPassword);
  },
);

export const logout = createAsyncThunk(
  AccountsActionType.Logout,
  async () => {
    await sessionsApi.logout();
    TokenUtils.removeTokens();
  },
);

export const validateSessionToken = createAsyncThunk(
  AccountsActionType.ValidateSessionToken,
  async () => {
    return sessionsApi.validateToken();
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccountData(state, { payload }: PayloadAction<Partial<IAccountParams>>) {
      if (payload.email !== undefined) {
        state.accountData!.email = payload.email;
      }

      if (payload.phone !== undefined) {
        state.accountData!.phone = payload.phone;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getInviteInfo.fulfilled, (state, { payload }) => {
        state.inviteData = payload;
      })
      .addCase(getCurrentAccount.fulfilled, (state, { payload }) => {
        state.accountData = payload || getAccountInitialState();
      });
  },
});

export const { setAccountData } = authSlice.actions;

export default authSlice.reducer;
