import React, { ChangeEvent, FC, useRef, useState } from 'react';
import TextInput from 'components/digifi-wrappers/TextInput';
import { validateEmail } from 'product_modules/utils/validation/validation';
import styles from 'components/BorrowerProfile/LoginDetails/ChangeEmailPopup/Steps/Steps.module.scss';
import ButtonWithLoadingState from 'components/digifi-wrappers/ButtonWithLoadingState';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';

interface IProvideEmailStepProps {
  initialEmail: string;
  onContinue: (email: string) => Promise<void>;
  isLoading?: boolean;
}

const ProvideEmailStep: FC<IProvideEmailStepProps> = ({ initialEmail, onContinue, isLoading }) => {
  const [email, setEmail] = useState(initialEmail);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const isEmailInvalid = !!validateEmail(email);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <div className={styles.message}>Please provide your new email address.</div>
      <TextInput
        placeholder="email@domain.com"
        labelTitle="Email Address"
        value={email}
        required
        onChange={handleOnChange}
        disabled={isLoading}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        type="email"
      />
      <ButtonWithLoadingState
        className={styles.button}
        disabled={isEmailInvalid}
        size="form"
        onClick={() => onContinue(email)}
        ref={submitButtonRef}
      >
        Continue
      </ButtonWithLoadingState>
    </div>
  );
};

export default ProvideEmailStep;
