import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createLayoutConfigurationByReferenceKeySelector } from 'handlers/layout-configurations/Selectors';

export const useLayoutConfiguration = (referenceKey: string) => {
  const layoutConfigurationSelector = useMemo(() => {
    return createLayoutConfigurationByReferenceKeySelector(referenceKey);
  }, [referenceKey]);

  return useSelector(layoutConfigurationSelector);
};
