import React, { FC, DetailedHTMLProps, InputHTMLAttributes, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import { removeWhiteSpace } from 'product_modules/utils/validation/validation';
import useRandomIdFallback from 'product_modules/hooks/randomIdFallback';
import InputWithValidation, { InputProps } from 'product_modules/components/InputWithValidation/InputWithValidation';
import LoaderWithState, { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import Label from 'product_modules/components/Label';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import QuestionIcon from 'product_modules/components/QuestionIcon';
import TextareaAutosize, { TextareaAutosizeProps } from 'product_modules/components/TextareaAutosize';
import styles from './TextInput.module.scss';

export type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface TextInputProps {
  labelTitle?: string;
  titleHint?: string;
  errorMessage?: string;
  error?: boolean;
  topRightElement?: ReactNode;
  multiline?: boolean;
  value?: string;
  hasNeighbours?: boolean;
  hasRightNeighbour?: boolean;
  hasLeftNeighbour?: boolean;
  hasRightPadding?: boolean;
  hasLeftPadding?: boolean;
  containerClassName?: string;
  loaderClassName?: string;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tooltip?: string;
  inputIcon?: React.ReactNode;
  inputPrefix?: React.ReactNode;
  questionIconTooltip?: React.ReactNode;
  required?: boolean;
  renderOverlay?: () => ReactNode;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  labelTooltipClassName?: string;
}

export type TextInputPropsMultiline = TextInputProps & TextareaAutosizeProps & {
  inputRef?: Ref<HTMLTextAreaElement>;
  maxRows?: number;
  minRows?: number;
};

export type TextInputPropsSingleLine = TextInputProps &
  HTMLInputProps &
  InputProps & { inputRef?: Ref<HTMLInputElement> };

const isMultiline = (props: TextInputPropsSingleLine | TextInputPropsMultiline): props is TextInputPropsMultiline =>
  !!props.multiline;

const getFieldElement = (props: TextInputPropsSingleLine | TextInputPropsMultiline): JSX.Element => {
  if (isMultiline(props)) {
    const {
      className,
      labelTitle,
      errorMessage,
      multiline,
      containerClassName,
      error,
      inputRef,
      color,
      hasRightNeighbour,
      hasLeftNeighbour,
      useSimplifiedInput,
      ...fieldProps
    } = props;

    const textAreaClassName = clsx(
      styles.textArea,
      (errorMessage || error) && styles.inputError,
      useSimplifiedInput && styles.inputSimplified,
      className,
    );

    return <TextareaAutosize className={textAreaClassName} {...fieldProps} ref={inputRef} />;
  }

  const {
    className,
    labelTitle,
    titleHint,
    errorMessage,
    multiline,
    value: rawValue,
    hasNeighbours = false,
    hasRightNeighbour = false,
    hasLeftNeighbour = false,
    hasRightPadding,
    hasLeftPadding,
    topRightElement,
    inputRef,
    error,
    containerClassName,
    useSimplifiedInput = false,
    ...fieldProps
  } = props as TextInputPropsSingleLine;

  const value = removeWhiteSpace(rawValue);
  const inputClassName = clsx(
    styles.input,
    (errorMessage || error) && styles.inputError,
    hasNeighbours && styles.inputWithNeighbours,
    hasRightNeighbour && styles.inputWithRightNeighbour,
    hasLeftNeighbour && styles.inputWithLeftNeighbour,
    hasRightPadding && styles.inputWithRightPadding,
    hasLeftPadding && styles.inputWithLeftPadding,
    useSimplifiedInput && styles.inputSimplified,
    className,
  );

  return <InputWithValidation className={inputClassName} value={value} {...fieldProps} ref={inputRef} />;
};

const TextInput: FC<TextInputPropsSingleLine | TextInputPropsMultiline> = (props) => {
  const {
    showLoader,
    titleHint,
    onLoaderStateReset,
    loaderState,
    loaderClassName,
    tooltip,
    required,
    questionIconTooltip,
    renderOverlay,
    inputIcon,
    inputPrefix,
    useSimplifiedInput,
    labelTooltipClassName,
    ...restProps
  } = props;
  const { id: providedId, labelTitle, errorMessage, topRightElement, containerClassName } = restProps;

  const id = useRandomIdFallback(providedId);

  const fieldElement = getFieldElement({
    ...restProps,
    useSimplifiedInput,
    id,
  });

  return (
    <div className={clsx(styles.inputContainer, containerClassName, useSimplifiedInput && styles.inputContainerSimplified)}>
      {labelTitle && (
        <div className={styles.header}>
          <div className={styles.labelWithQuestionIcon}>
            <Label htmlFor={id} required={required} tooltip={titleHint} className={styles.label} questionIconClassName={labelTooltipClassName}>
              {labelTitle}
            </Label>
            {questionIconTooltip && (
              <QuestionIcon className={styles.questionIcon} tooltip={questionIconTooltip} />
            )}
          </div>
          {topRightElement}
        </div>
      )}
      <div className={clsx(styles.fieldContainer, styles.fieldContainerSimplified)}>
        {showLoader && (
          <LoaderWithState
            className={clsx(styles.loader, loaderClassName)}
            state={loaderState}
            onStateReset={onLoaderStateReset}
          />
        )}
        <WrapperWithTooltip tooltip={tooltip}>{fieldElement}</WrapperWithTooltip>
        {inputIcon && <div className={styles.inputIconContainer}>{inputIcon}</div>}
        {inputPrefix && <div className={styles.inputPrefixContainer}>{inputPrefix}</div>}
        {renderOverlay?.()}
      </div>
      {errorMessage && !useSimplifiedInput && <p className={styles.errorNotification}>{errorMessage}</p>}
    </div>
  );
};

export default TextInput;
