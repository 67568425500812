import LimitDecimalNumbers from 'product_modules/components/MonetaryInput/utils';
import maskNumberValue from 'product_modules/utils/masks/maskNumberValue';
import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';
import { NumericVisualDataType } from 'product_modules/enums/VisualDataType';
import { getCurrencySymbol } from 'product_modules/components/CurrencySelect/currencies';
import { MonetaryVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import ValueFormattingError from './base/ValueFormattingError';

export interface MonetaryFormattingParams extends MonetaryVisualAttributes {
  alwaysShowFractionalPart?: boolean;
}

const limitDecimalNumbers = LimitDecimalNumbers(2);

const format = (value: number, currencyType: string, alwaysShowFractionalPart: boolean) => {
  const valueAsString = value?.toString() || '';

  const limitedValue = limitDecimalNumbers(valueAsString);
  const maskedValue = maskNumberValue(limitedValue);
  const currencySymbolText = `${currencyType}${currencyType.length > 1 ? ' ' : ''}`;

  const formattedValue = valueAsString.includes('-')
    ? maskedValue.replace('-', `-${currencySymbolText}`)
    : `${currencySymbolText}${maskedValue}`;

  if (!alwaysShowFractionalPart) {
    return formattedValue;
  }

  return `${formattedValue}${!formattedValue.includes('.') ? '.00' : ''}`;
};

const isMonetaryValue = getValueVisualTypeCorrespondenceChecker(NumericVisualDataType.Monetary);

const formatMonetaryValue: ValueFormatter<MonetaryFormattingParams> = (
  value,
  params = { currency: '' },
) => {
  if (!isMonetaryValue(value)) {
    throw new ValueFormattingError();
  }

  const { currency = '', alwaysShowFractionalPart = false } = params;
  const currencySymbol = currency && getCurrencySymbol(currency);

  return format(value, currencySymbol, alwaysShowFractionalPart);
}

export default formatMonetaryValue;
