import React, { FC } from 'react';
import Logo from 'components/common/Logo';
import AccountIcon from 'components/common/AccountIcon';
import LinkButton from 'product_modules/components/LinkButton';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import styles from 'components/PageLayout/Header/Header.module.scss';

interface IHeaderProps {
  hideSignInButton?: boolean;
}

const Header: FC<IHeaderProps> = ({ hideSignInButton }) => {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    navigate(AppRoute.SignIn);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerContent}>
        <Logo className={styles.logo} />
        {!hideSignInButton && <LinkButton className={styles.link} onClick={handleLinkClick}>
          <AccountIcon />
          Sign In
        </LinkButton>}
      </div>
    </div>
  );
};

export default Header;
