import styled from 'types/AppTheme';
import { rgba } from 'polished';

const OptionIconStyledContainer = styled.div<{ isSelected?: boolean }>`
  background: ${({ theme, isSelected }) => 
    isSelected 
      ? rgba(theme.colors.brandColor, 0.16) 
      : '#F1F2F4'};
   svg path {
     fill: ${({ theme, isSelected }) => isSelected
       ? theme.colors.brandColor
       : '#3F4F68'};
   }
}
`;

export default OptionIconStyledContainer;
