import { FC } from 'react';
import { VariableValue } from 'product_modules/api/Types';
import useVariablesBySystemNames from 'product_modules/loaders/Variables/hooks/useVariablesBySystemNames';
import getTemplatePlaceholders from 'utils/getTemplatePlaceholders';
import formatVariableValue from 'utils/formatVariableValue';

interface ITextElementWrapperProps {
  templateString: string;
  context: Record<string, VariableValue>;
  children: (elementContext: Record<string, VariableValue>, showLoader?: boolean) => JSX.Element;
}

const TextElementWrapper: FC<ITextElementWrapperProps> = ({
  templateString,
  context,
  children,
}) => {
  const usedVariableNames = getTemplatePlaceholders(templateString);

  const usedVariables = useVariablesBySystemNames(usedVariableNames);

  const templateSpecificContext = usedVariables ? Object.keys(usedVariables).reduce<Record<string, VariableValue>>((accumulator, variableName) => {
    accumulator[variableName] = formatVariableValue(usedVariables[variableName], context[usedVariables[variableName].systemName]);

    return accumulator;
  }, {}) : {};

  return children(templateSpecificContext, !usedVariables);
};

export default TextElementWrapper;
