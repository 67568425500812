import React, { forwardRef } from 'react';
import ButtonWithLoadingState from 'components/digifi-wrappers/ButtonWithLoadingState';
import styles from 'components/common/SubmitButton/SubmitButton.module.scss';
import clsx from 'clsx';

interface ISubmitButtonProps {
  title: string;
  isButtonEnabled: boolean;
  onSubmit: () => Promise<void>;
  buttonContainerClassName?: string;
  tabIndex?: number;
}
const SubmitButton = forwardRef<HTMLButtonElement, ISubmitButtonProps>(({ title, isButtonEnabled, onSubmit, buttonContainerClassName, tabIndex }, ref) => {
  return (
    <div className={clsx(styles.buttonContainer, buttonContainerClassName)}>
      <ButtonWithLoadingState
        size="form"
        kind="primary"
        disabled={!isButtonEnabled}
        onClick={onSubmit}
        tabIndex={tabIndex}
        ref={ref}
      >
        {title}
      </ButtonWithLoadingState>
    </div>
  );
});

export default SubmitButton;
