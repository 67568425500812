import { FC } from 'react';
import clsx from 'clsx';
import styles from 'components/Notifications/NotificationsLayout/Notifications.module.scss';
import { useAppSelector } from 'hooks/reduxHooks';
import { selectAllNotifications } from 'handlers/notificationsSlice';
import ConnectedNotifications from 'components/Notifications/ConnectedNotifications/ConnectedNotifications';

interface ILayoutNotificationsProps {
  className?: string;
}

const LayoutNotification: FC<ILayoutNotificationsProps> = ({
  className,
}) => {
  const notificationsList = useAppSelector(selectAllNotifications);

  const classNames = clsx(
    styles.mainLayoutNotifications,
    styles.leftNavigationCollapsed,
    notificationsList.length === 0 && styles.empty,
    className,
  );

  return <ConnectedNotifications className={classNames} />;
};

export default LayoutNotification;
