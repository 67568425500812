import { IImageParamsWithId } from 'product_modules/api/Types';
import { IBasePortalPageElement, PortalPageElementType } from './BasePortalPageElement';

export enum ImageElementAlignment {
  Fill = 'fill',
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface IImageElementConfig {
  alignment: ImageElementAlignment;
  imageParams?: IImageParamsWithId;
}

export interface IImagePageElement extends IBasePortalPageElement {
  elementType: PortalPageElementType.Image;
  config: IImageElementConfig;
}

