import React, { FC, FocusEvent, ReactNode, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import NumberInput from 'product_modules/components/NumberInput';
import styles from './MonetaryInput.module.scss';

interface MonetaryInputProps {
  value: string;
  labelTitle: string;
  onChange: (value: string) => void;
  placeholder?: string;
  currencySymbol?: string | null;
  readOnly?: boolean;
  disabled?: boolean;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  inputIcon?: ReactNode;
  raw?: boolean;
  required?: boolean;
  errorMessage?: string;
  tooltip?: string;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  inputRef?: React.Ref<HTMLInputElement>;
  titleHint?: string;
  labelTooltipClassName?: string;
}

const MAX_DECIMAL_PART_LENGTH = 2;
const DEFAULT_SIMPLIFIED_INPUT_PADDING = 12;
const DEFAULT_REGULAR_INPUT_PADDING = 72;

const MonetaryInput: FC<MonetaryInputProps> = ({
  value,
  labelTitle,
  readOnly,
  disabled,
  placeholder,
  currencySymbol = '',
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  inputIcon,
  raw = false,
  required,
  errorMessage,
  tooltip,
  onChange,
  onBlur,
  onFocus,
  useSimplifiedInput = false,
  style,
  inputRef,
  titleHint,
  labelTooltipClassName,
}) => {
  const [prefixWidth, setPrefixWidth] = useState<number>(0);
  const prefixRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setPrefixWidth(
      useSimplifiedInput
        ? ((prefixRef.current?.offsetWidth ?? 0) + DEFAULT_SIMPLIFIED_INPUT_PADDING)
        : DEFAULT_REGULAR_INPUT_PADDING,
    );
  }, [useSimplifiedInput]);

  const renderPrefix = () => {
    return (
      <div
        ref={prefixRef}
        className={clsx(
          styles.basePrefixContainer,
          !useSimplifiedInput && styles.prefixContainer,
          useSimplifiedInput && styles.prefixContainerSimplified,
          errorMessage && styles.errorPrefixContainer,
          errorMessage && useSimplifiedInput && styles.errorPrefixContainerSimplified,
        )}
      >
        <span className={clsx(styles.symbol, useSimplifiedInput && styles.symbolSimplified)}>
          {currencySymbol}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <NumberInput
        value={value}
        raw={raw}
        labelTitle={labelTitle}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        showLoader={showLoader}
        loaderState={loaderState}
        onLoaderStateReset={onLoaderStateReset}
        tabIndex={tabIndex}
        inputIcon={inputIcon}
        required={required}
        errorMessage={errorMessage}
        tooltip={tooltip}
        hasLeftPadding={!useSimplifiedInput}
        decimalScale={MAX_DECIMAL_PART_LENGTH}
        onFocus={onFocus}
        onBlur={(_, event) => onBlur?.(event)}
        inputPrefix={renderPrefix()}
        useSimplifiedInput={useSimplifiedInput}
        style={{
          ...style,
          paddingLeft: `${prefixWidth}px`,
        }}
        inputRef={inputRef}
        titleHint={titleHint}
        labelTooltipClassName={labelTooltipClassName}
      />
    </div>
  );
};

export default MonetaryInput;
