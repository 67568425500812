import { useEffect, useLayoutEffect, useMemo, useState, useRef } from 'react';
import { CountryCode, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';

const getSamplePhoneNumber = (countryCode: CountryCode) => {
  const phoneNumber = getExampleNumber(countryCode, examples);

  return phoneNumber?.formatNational();
};

const TrimPhoneNumberForCountry = (countryCode: string) => (phoneNumber: string): string => {
  switch (countryCode.toLowerCase()) {
    case 'ca':
    case 'us': {
      return phoneNumber.slice(0, 12);
    }
    default:
      return phoneNumber;
  }
};

export const focusInputElement = ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
  currentTarget.querySelector('input')?.focus();

const identityFn = <V>(value: V) => value;

type PhoneNumberWithChangeHandler = [string, (phoneNumber: string | undefined) => void];

/**
 * This is needed for disallowing a user to type more numbers than needed (that breaks the mask).
 * It may seem this can be achieved by just processing the value in an onChange handler.
 * But because react-phone-number-input uses smart cared positioning, it doesn't reflect the value change
 * if the only change is adding a character.
 */
const useTrimmedPhoneNumber = (
  value: string,
  onChange: (phoneNumber: string) => void,
  country: string | null | undefined,
): PhoneNumberWithChangeHandler => {
  const limitInput = useMemo(() => (country ? TrimPhoneNumberForCountry(country) : identityFn), [country]);

  const [tempValue, setTempValue] = useState(value);
  const isInitialRender = useRef(true);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  useLayoutEffect(() => {
    const trimmedValue = limitInput(tempValue);
    setTempValue(trimmedValue);

    if (!isInitialRender.current) {
      // Do not trigger onChange handler on the initial render
      onChange(trimmedValue);
    } else {
      isInitialRender.current = false;
    }
  }, [tempValue]);

  const handleChange = (phoneNumber: string | undefined) => setTempValue(phoneNumber || '');

  return [tempValue, handleChange];
};

export { getSamplePhoneNumber, useTrimmedPhoneNumber, TrimPhoneNumberForCountry };
