import React, { FC } from 'react';
import styles from './ProductList.module.scss';
import { IProduct } from 'api/digifi/ProductsApi';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';
import clsx from 'clsx';
import PRODUCT_IMAGE_BY_TYPE from 'components/ApplicationForm/ProductListModal/ProductList/ProductImageByType';

interface IProductListProps {
  products: IProduct[];
  selectedProductId: string;
  className?: string;
  onSelect: (id: string) => void;
}

const ProductList: FC<IProductListProps> = ({
  products,
  selectedProductId,
  className,
  onSelect,
}) => {

  const renderProductContent = (product: IProduct) => {
    const Icon = PRODUCT_IMAGE_BY_TYPE[product.type];

    return (
      <>
        <div className={styles.productName}>{product.name}</div>
        <Icon className={styles.productIcon} />
      </>
    );
  };

  const renderProduct = (product: IProduct) => {
    if (product.id === selectedProductId) {
      return (
        <ContainerStyled key={product.id} className={clsx(styles.product, styles.active)}>
          {renderProductContent(product)}
        </ContainerStyled>
      );
    }

    return (
      <div className={styles.product} onClick={() => onSelect(product.id)} key={product.id}>
        {renderProductContent(product)}
      </div>
    );
  };

  return (
    <div className={className}>
      <div className={styles.header}>Select Product</div>

      <div>
        {products.map(renderProduct)}
      </div>
    </div>
  );
};

export default ProductList;
