import { FC } from 'react';
import TimerMessage, { ITimerMessageProps } from 'product_modules/components/TimerMessage';

const TimerMessageWrapper: FC<ITimerMessageProps> = (props) => {
  return (
    <TimerMessage {...props} />
  );
};

export default TimerMessageWrapper;
