import { FC } from 'react';
import { TableHead, TableHeadProps } from 'product_modules/components/Table';

const TableHeadWrapper: FC<TableHeadProps> = (props) => {
  return (
    <TableHead {...props} />
  );
};

export default TableHeadWrapper;
