import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useFormik } from 'formik';
import PasswordInput from 'components/digifi-wrappers/PasswordInput/PasswordInput';
import { validatePassword, validatePasswordByRules } from 'utils/validation';
import getMessage, { MessageType } from 'constants/Messages';
import SubmitButton from 'components/common/SubmitButton';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import PasswordInputWithHint from 'product_modules/components/PasswordInputWithHint';

export interface ICreateNewPasswordContextType {
  password: string;
  confirmPassword: string;
}

interface ICreateNewPasswordFormProps {
  handleSubmit: (values: ICreateNewPasswordContextType) => Promise<void>;
  isLoading?: boolean;
}

const CreateNewPasswordForm: FC<ICreateNewPasswordFormProps> = ({ handleSubmit, isLoading }) => {
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { setFieldError, errors, values, handleChange, setFieldValue } = useFormik<ICreateNewPasswordContextType>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleSubmit,
  });

  const [passwordErrors, setPasswordErrors] = useState<MessageType[]>(validatePasswordByRules(values.password));

  const isButtonEnabled = !validatePassword(values.password)
    && !validatePasswordByRules(values.confirmPassword).length
    && values.password === values.confirmPassword;

  const clearFieldErrorOnFocus = (fieldName: string) => {
    setFieldError(fieldName, '');
  };

  const handlePasswordBlur = (currentPassword: string, name: string) => {
    const error = validatePassword(currentPassword);
    setFieldError(name, error === null ? '' : getMessage(error));
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    const error = validatePasswordByRules(passwordValue);
    
    setFieldValue('password', passwordValue);
    setPasswordErrors(error);
  };

  return (
    <div>
      <PasswordInputWithHint
        name="password"
        labelTitle="Password"
        required
        value={values.password}
        onChange={handlePasswordChange}
        disabled={isLoading}
        onKeyDown={TriggerEventOnEnterKeyDown(confirmPasswordInputRef, 'focus')}
        invalidMessageTypes={passwordErrors}
      />
      <PasswordInput
        name="confirmPassword"
        labelTitle="Confirm Password"
        required
        value={values.confirmPassword}
        onChange={handleChange}
        onBlur={() => handlePasswordBlur(values.confirmPassword, 'confirmPassword')}
        onFocus={() => clearFieldErrorOnFocus('confirmPassword')}
        errorMessage={errors.confirmPassword}
        disabled={isLoading}
        ref={confirmPasswordInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
      />
      <SubmitButton
        title="Save New Password"
        isButtonEnabled={isButtonEnabled}
        onSubmit={() => handleSubmit(values)}
        ref={submitButtonRef}
      />
    </div>
  );
};

export default CreateNewPasswordForm;
