import { ElementAlignment, IBasePortalPageElement, PortalPageElementType } from './BasePortalPageElement';

export enum PageDividerElementLength {
  Short = 'short',
  Medium = 'medium',
  Long = 'long',
}

export interface IPageDividerElementConfig {
  alignment: ElementAlignment;
  length: PageDividerElementLength;
}

export interface IPageDividerPageElement extends IBasePortalPageElement {
  elementType: PortalPageElementType.PageDivider;
  config: IPageDividerElementConfig;
}
