import { AddressValue, AddressValueKey } from 'product_modules/api/Types';
import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import ValueFormattingError from 'product_modules/utils/valueFormatters/base/ValueFormattingError';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';
import { AddressVisualDataType } from 'product_modules/enums/VisualDataType';

interface AddressTemplateComponent {
  key: keyof AddressValue;
  dismissComma?: boolean;
  prefix?: string;
}

const addressTemplateComponents: Array<AddressTemplateComponent> = [
  { key: AddressValueKey.StreetNumber },
  { key: AddressValueKey.StreetName, dismissComma: true },
  { key: AddressValueKey.UnitNumber, dismissComma: true, prefix: '#' },
  { key: AddressValueKey.Sublocality },
  { key: AddressValueKey.City },
  { key: AddressValueKey.StateOrProvince },
  { key: AddressValueKey.ZipOrPostalCode },
  { key: AddressValueKey.Country },
];

const templateAddressPart = (
  addressTemplateComponent: AddressTemplateComponent,
  addressPart: string,
  fullAddress: string,
) => {
  return addressTemplateComponent.dismissComma
    ? `${fullAddress} ${addressTemplateComponent.prefix || ''}${addressPart}`
    : `${fullAddress}, ${addressTemplateComponent.prefix || ''}${addressPart}`;
};

const isAddressValue = getValueVisualTypeCorrespondenceChecker(AddressVisualDataType.Address);

const formatAddressValue: ValueFormatter = (
  value,
): string => {
  if (!isAddressValue(value)) {
    throw new ValueFormattingError();
  }

  return addressTemplateComponents.reduce((fullAddress, addressTemplateComponent) => {
    const addressPart = (value as AddressValue)[addressTemplateComponent.key];

    if (!addressPart) {
      return fullAddress;
    }

    return fullAddress
      ? templateAddressPart(addressTemplateComponent, addressPart, fullAddress)
      : `${addressTemplateComponent.prefix || ''}${addressPart}`;
  }, '');
};

export default formatAddressValue;
