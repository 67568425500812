import React, { FC } from 'react';
import clsx from 'clsx';
import { upperFirst } from 'lodash';
import { Option } from 'product_modules/components/SelectInput/SelectInput';
import { CheckedImage } from 'product_modules/static/images';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import ApplicationTag from 'product_modules/components/Tag/ApplicationTag';
import BlankText from 'product_modules/components/BlankText';
import styles from 'product_modules/components/SelectInput/SelectInput.module.scss';

interface DropdownItemProps {
  option: Option;
  handleSelect?: (option: Option) => void;
  selected: boolean;
  highlighted?: boolean;
  onFocus: (id: string) => void;
  isLabelTag?: boolean;
}

const DropdownItem: FC<DropdownItemProps> = ({
  option,
  onFocus,
  handleSelect,
  selected = false,
  highlighted = false,
  isLabelTag,
}) => {
  const optionClassName = clsx(
    styles.option,
    selected && styles.optionChecked,
    highlighted && styles.optionActive,
    option.disabled && styles[`optionDisabled${upperFirst(option.disableType || 'text')}Type`],
  );

  const renderOptionContent = () => {
    if (option.componentOption) {
      return option.componentOption;
    }

    return (
      <div className={styles.optionContent}>
        {option.icon}
        <p className={clsx(styles.optionTextContent, option.icon && styles.hasIcon)}>
          <BlankText tag="span" className={styles.name}>{option.name}</BlankText>
          {option.description && <span className={styles.description}>{option.description}</span>}
        </p>
        {option.iconAfter}
      </div>
    );
  };

  const renderLabelTag = () => (
    <ApplicationTag color={option.color} medium>
      {option.name}
    </ApplicationTag>
  );

  return (
    <WrapperWithTooltip tooltip={option.tooltip}>
      <div
        className={clsx(optionClassName, option.withSeparator && styles.withSeparatorOption)}
        onMouseEnter={() => onFocus(option.id!)}
        onClick={() => handleSelect?.(option)}
      >
        {isLabelTag ? renderLabelTag() : renderOptionContent()}
        <input type="radio" name="option" readOnly checked={option.selected} value={option.value} />
        {selected && <CheckedImage className={styles.optionCheckMark} />}
      </div>
    </WrapperWithTooltip>
  );
};

export default React.memo(DropdownItem) as typeof DropdownItem;
