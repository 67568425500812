import { IItemData } from 'handlers/layoutConfigurationsSlice';

export const groupItemIdsByParentId = <ItemType extends IItemData>(
  items: ItemType[],
  reference = 'id',
) => {
  return items.reduce((accumulator, item) => {
    if (item[reference as keyof IItemData] in accumulator) {
      accumulator[item[reference as keyof IItemData]].push({ id: item.id, position: item.position });
    } else {
      accumulator[item[reference as keyof IItemData]] = [{ id: item.id, position: item.position }];
    }

    return accumulator;
  }, {} as Record<string, Array<{ id: string, position: number }>>);
};
