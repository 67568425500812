import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { applicationsApi } from 'store/api';

enum ActiveBorrowerInformationActionType {
  GetCurrentApplication = 'applications/getCurrentApplication',
}

export const getCurrentApplication = createAsyncThunk(
  ActiveBorrowerInformationActionType.GetCurrentApplication, () => {
    return applicationsApi.getCurrentApplication();
  },
);

export interface IActiveBorrowerInformationState {
  currentApplication?: string | null;
  allowToDiscardNewApplication?: boolean;
}

const initialState: IActiveBorrowerInformationState = {
  currentApplication: undefined,
  allowToDiscardNewApplication: true,
};

export const resetCurrentApplication = createAction('activeBorrowerInformation/resetCurrentApplication');
export const setAllowToDiscardNewApplication = createAction<boolean>('activeBorrowerInformation/allowToDiscardNewApplication');
export const setCurrentApplication = createAction<string>('activeBorrowerInformation/setCurrentApplication');

const activeBorrowerInformationSlice = createSlice({
  name: 'activeBorrowerInformation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCurrentApplication.fulfilled, (state, { payload }) => {
        state.currentApplication = payload?.id || null;
      })
      .addCase(resetCurrentApplication, (state) => {
        state.currentApplication = undefined;
      })
      .addCase(setAllowToDiscardNewApplication, (state, { payload }) => {
        state.allowToDiscardNewApplication = payload;
      })
      .addCase(setCurrentApplication, (state, { payload }) => {
        state.currentApplication = payload;
      });
  },
});

export default activeBorrowerInformationSlice.reducer;
