import React, { FC, useCallback } from 'react';
import clsx from 'clsx';
import InputWithDataType from 'product_modules/components/InputWithDataType/InputWithDataType';
import { BasicVariableValue, VariableValue } from 'product_modules/api/Types';
import { TableColumn } from 'product_modules/api/Core/VariablesApi';
import { TableBodyCell } from 'product_modules/components/Table';
import { IAddressInputClassNames } from 'product_modules/types/InputClassNamesTypes';
import styles from './TableInputEditMode.module.scss';

const DEFAULT_INPUT_HEIGHT = 52;

interface TableInputCellProps {
  rowId: string;
  columnIndex: number;
  column: TableColumn;
  value: BasicVariableValue;
  onCellHover: (id: string) => void;
  onValueUpdate: (rowId: string, updatedCellKey: string, updatedCellValue: BasicVariableValue) => void;
  onErrorMessageUpdate?: (errorInformation: Record<string, boolean>) => void;
  disabledValidation?: boolean;
  disableCapAttributesValidation?: boolean;
  disabled?: boolean;
  validateOnRender?: boolean;
  hasError?: boolean;
  style?: React.CSSProperties;
  dropdownClassName?: string;
  classNames?: {
    address?: IAddressInputClassNames;
  };
}

const TableInputCell: FC<TableInputCellProps> = ({
  columnIndex,
  column,
  value,
  onErrorMessageUpdate,
  onValueUpdate,
  disabledValidation,
  disableCapAttributesValidation,
  disabled,
  validateOnRender,
  rowId,
  hasError = false,
  style,
  dropdownClassName,
  onCellHover,
  classNames,
}) => {
  const handleErrorMessageUpdate = useCallback((errorMessage: string) => {
    if (!!errorMessage === hasError) {
      return;
    }

    onErrorMessageUpdate?.({ [`${rowId}-${column.systemName}`]: !!errorMessage });
  }, [onErrorMessageUpdate, hasError, rowId, column.systemName]);

  const handleValueUpdate = useCallback((updatedValue: VariableValue) => {
    onValueUpdate(rowId, column.systemName, updatedValue as BasicVariableValue);
  }, [onValueUpdate, rowId, column.systemName]);

  const handleMouseEnter = useCallback(() => onCellHover(rowId), []);

  return (
    <div style={style} onMouseEnter={handleMouseEnter}>
      <TableBodyCell
        className={clsx(styles.bodyCell, !columnIndex && styles.bodyCellWithLeftBorder)}
      >
        <InputWithDataType
          visualDataType={column?.visualDataType!}
          visualAttributes={column.visualAttributes}
          labelTitle=""
          value={value}
          onChange={handleValueUpdate}
          onErrorMessageUpdate={handleErrorMessageUpdate}
          required={column.required}
          useSimplifiedInput
          disableCapAttributesValidation={disableCapAttributesValidation}
          disabledValidation={disabledValidation}
          disabled={disabled}
          validateOnRender={validateOnRender && hasError}
          style={{
            height: `${DEFAULT_INPUT_HEIGHT}px`,
          }}
          dropdownClassName={dropdownClassName}
          classNames={classNames}
        />
      </TableBodyCell>
    </div>
  );
};

export default React.memo(TableInputCell);
