import { FC } from 'react';
import { TableRow, TableRowProps } from 'product_modules/components/Table';
import styled from 'types/AppTheme';

interface ITableRowStyledProps {
  disableHover?: boolean;
}

const TableRowStyled = styled(TableRow)<ITableRowStyledProps>`
  &:hover {
    background-color: ${({ theme, disableHover }) => disableHover ? '' : theme.colors.backgroundHoverAccentColor};
  }
`;

const TableRowWrapper: FC<TableRowProps> = (props) => {
  return (
    <TableRowStyled {...props} />
  );
};

export default TableRowWrapper;
