import React, { FC } from 'react';
import clsx from 'clsx';
import StickyContainer from 'product_modules/components/StickyContainer';
import styles from './TableHead.module.scss';

export interface TableHeadProps {
  sticky?: boolean;
  className?: string;
  columnsClassName?: string;
  stickyMarginTop?: number;
  stickyRootElement?: HTMLElement;
  noBottomBorder?: boolean;
  style?: React.CSSProperties;
}

const TableHead: FC<TableHeadProps> = ({
  sticky,
  columnsClassName,
  className,
  stickyMarginTop = 0,
  stickyRootElement = document.body,
  noBottomBorder,
  style,
  children,
}) => {
  return (
    <StickyContainer
      sticky={sticky}
      stickyTopOffset={stickyMarginTop}
      stickyRootElement={stickyRootElement}
      className={clsx(
        styles.tableHead,
        noBottomBorder && styles.noBottomBorder,
        className,
      )}
    >
      {() => (
        <div className={clsx(styles.columns, columnsClassName)} style={style}>{children}</div>
      )}
    </StickyContainer>
  );
};

export default TableHead;
