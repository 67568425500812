import { cloneElement, ReactElement } from 'react';
import clsx from 'clsx';

import styles from './RowActions.module.scss';

interface RowActionsContainerProps<ChildProps> {
  children: ReactElement<ChildProps>;
}

const RowActionsContainer = <ChildProps extends { className: string }>({
  children,
}: RowActionsContainerProps<ChildProps>) => {
  const { className } = children.props;

  return cloneElement<{ className: string }>(children, {
    className: clsx(styles.rowActionsContainer, className),
  });
};

export default RowActionsContainer;
