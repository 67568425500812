import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './ButtonLoader.module.scss';

export interface ButtonLoaderProps {
  className?: string;
  dotClassName?: string;
  dotColor?: string | null;
}

const BaseButtonLoader: FC<ButtonLoaderProps> = ({ className, dotColor, dotClassName }) => {
  const dotStyle = { backgroundColor: dotColor || styles.defaultDotColor };

  return (
    <div className={clsx(styles.container, className)}>
      <div style={dotStyle} className={clsx(styles.dot, styles.left, dotClassName)} />
      <div style={dotStyle} className={clsx(styles.dot, styles.middle, dotClassName)} />
      <div style={dotStyle} className={clsx(styles.dot, styles.right, dotClassName)} />
    </div>
  );
};

export default BaseButtonLoader;
