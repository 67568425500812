import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import { GetStartedBlock, StepsInfoBlock, SignInBlock } from 'components/GuestHome';
import styles from './StartPage.module.scss';

const StartPage = () => {
  return (
    <UnauthorizedLayout>
      <div className={styles.container}>
        <GetStartedBlock />
        <StepsInfoBlock />
        <SignInBlock />
      </div>
    </UnauthorizedLayout>
  );
};

export default StartPage;
