import { IProduct } from 'api/digifi/ProductsApi';
import { useAppSelector } from './reduxHooks';

const useAvailableProducts = () => {
  const products = useAppSelector((state) => state.products.entities);

  return Object.values(products).filter((product) => product) as IProduct[];
};

export default useAvailableProducts;
