export enum PortalPageElementType {
  HeaderText = 'HeaderText',
  SubHeaderText = 'SubHeaderText',
  BodyText = 'BodyText',
  OfferSelection = 'OfferSelection',
  Tasks = 'Tasks',
  DataInput = 'DataInput',
  Button = 'Button',
  Image = 'Image',
  PageDivider = 'PageDivider',
  ProgressBar = 'ProgressBar',
  ESignature = 'ESignature',
}

export enum ElementAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface ITextElementConfig {
  text: string;
  alignment: ElementAlignment;
}

export interface IBasePortalPageElement {
  id: string;
  name: string;
  portalPageId: string;
  position: number;
  elementType: PortalPageElementType;
}
