import React, { FC } from 'react';
import clsx from 'clsx';
import { SkeletonText } from 'product_modules/components/Skeleton';
import SkeletonColorType from 'product_modules/components/Skeleton/SkeletonColorType';
import styles from './SkeletonTableView.module.scss';

const DEFAULT_COLUMNS_COUNT = 1;

export interface SkeletonTableViewProps {
  fields: number;
  columns?: number;
  textColor?: SkeletonColorType;
}

const SkeletonTableView: FC<SkeletonTableViewProps> = ({ columns = DEFAULT_COLUMNS_COUNT, fields, textColor = 'primary6' }) => {
  const fieldsContainerStyle = {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  };

  const renderSkeletonFields = (length: number) => {
    return new Array(length).fill(null).map((value, index) => {
      const isLastFieldInColumn = index + columns >= length;

      return (
        <div className={clsx(styles.field, isLastFieldInColumn && styles.lastFieldInColumn)} key={index}>
          <SkeletonText lineHeight="44px" width="100%" lineWidth="66%" height="14px" color={textColor} />
          <SkeletonText
            className={styles.fieldValue}
            lineHeight="44px"
            width="100%"
            lineWidth="44%"
            height="14px"
            color={textColor}
          />
        </div>
      );
    });
  };

  return (
    <div style={fieldsContainerStyle} className={styles.fieldsContainer}>
      {renderSkeletonFields(fields)}
    </div>
  );
};

export default SkeletonTableView;
