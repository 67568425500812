import React from 'react';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import Onboarding from 'components/Auth/Onboarding';

const OnboardingPage = () => {
  return (
    <UnauthorizedLayout>
      <Onboarding />
    </UnauthorizedLayout>
  );
};

export default OnboardingPage;
