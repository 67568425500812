import {
  CapVisualAttributes,
  IdentificationTypeVisualAttributes,
  MonetaryVisualAttributes,
  VariableVisualAttributes,
} from 'product_modules/api/Core/VariablesApi';
import {
  StringVisualDataType,
  NumericVisualDataType,
  DateVisualDataType,
  VisualDataType,
} from 'product_modules/enums/VisualDataType';
import {
  validateEmail,
  validateIDNumber,
  validateMaxDateCap,
  validateMaxNumberCap,
  validateMinDateCap,
  validateMinNumberCap,
  validatePhoneNumber,
} from 'product_modules/utils/validation/validation';
import maskNumberValue from 'product_modules/utils/masks/maskNumberValue';
import formatPercentageValue from 'product_modules/utils/valueFormatters/formatPercentageValue';
import formatMonetaryValue from 'product_modules/utils/valueFormatters/formatMonetaryValue';
import getValueCompatibilityChecker from 'product_modules/utils/valueConversionAvailabilityCheckers';

interface ValidationOptions {
  disableCapAttributesValidation?: boolean;
}

const VALUE_COMPATIBILITY_ERROR_MESSAGE = 'The value is not compatible with this variable\'s type';

type ValueValidator = (value: string, visualAttributes: VariableVisualAttributes, visualType: VisualDataType) => string;
type ValueValidatorsFactory = (options: ValidationOptions) => ValueValidator[];

const formatCapByVisualType = (
  cap: string,
  visualAttributes: VariableVisualAttributes,
  visualDataType: VisualDataType,
) => {
  if (visualDataType === NumericVisualDataType.Number) {
    return maskNumberValue(cap);
  }

  if (visualDataType === NumericVisualDataType.Percentage) {
    return formatPercentageValue(Number.parseFloat(cap));
  }

  if (visualDataType === NumericVisualDataType.Monetary) {
    const { currency } = visualAttributes as MonetaryVisualAttributes;

    return formatMonetaryValue(Number.parseFloat(cap), { currency });
  }

  return cap;
};

const getPhoneNumberValidators = (): ValueValidator[] => ([
  (value) => validatePhoneNumber(value),
]);

const getIdentificationNumberValidators = (): ValueValidator[] => ([
  (value, visualAttributes) => {
    const { identificationNumberType } = visualAttributes as IdentificationTypeVisualAttributes;

    return validateIDNumber(value, identificationNumberType!);
  },
]);

const getEmailAddressValidators = (): ValueValidator[] => ([
  (value) => validateEmail(value),
]);

const getNumberValidators = (options: ValidationOptions): ValueValidator[] => (!options?.disableCapAttributesValidation ? [
  (value, visualAttributes, visualDataType) => validateMinNumberCap(
    value,
    (visualAttributes as CapVisualAttributes).minAllowedValue,
    (cap) => formatCapByVisualType(cap, visualAttributes, visualDataType),
  ),
  (value, visualAttributes, visualDataType) => validateMaxNumberCap(
    value,
    (visualAttributes as CapVisualAttributes).maxAllowedValue,
    (cap) => formatCapByVisualType(cap, visualAttributes, visualDataType),
  ),
] : []);

const getDateValidators = (): ValueValidator[] => ([
  (value, visualAttributes) =>
    validateMinDateCap(value, (visualAttributes as CapVisualAttributes).minAllowedValue),
  (value, visualAttributes) =>
    validateMaxDateCap(value, (visualAttributes as CapVisualAttributes).maxAllowedValue),
]);

const validatorsByVisualDataType = new Map<VisualDataType, ValueValidatorsFactory>([
  [StringVisualDataType.PhoneNumber, getPhoneNumberValidators],
  [StringVisualDataType.IdentificationNumber, getIdentificationNumberValidators],
  [StringVisualDataType.EmailAddress, getEmailAddressValidators],
  [NumericVisualDataType.Number, getNumberValidators],
  [NumericVisualDataType.Monetary, getNumberValidators],
  [NumericVisualDataType.Percentage, getNumberValidators],
  [DateVisualDataType.Date, getDateValidators],
]);

const getValidationMessage = (
  value: string,
  visualDataType: VisualDataType,
  visualAttributes: VariableVisualAttributes,
  options: ValidationOptions,
): string => {
  const isValueCompatibleWithVisualType = getValueCompatibilityChecker(visualDataType);
  const valueCompatibleWithVisual = isValueCompatibleWithVisualType(value, visualAttributes);

  if (!valueCompatibleWithVisual) {
    return VALUE_COMPATIBILITY_ERROR_MESSAGE;
  }

  const validators = validatorsByVisualDataType.get(visualDataType)?.(options) || [];

  for (const validator of validators) {
    const errorMessage = validator(value, visualAttributes, visualDataType);

    if (errorMessage) {
      return errorMessage;
    }
  }

  return '';
};

export default getValidationMessage;
