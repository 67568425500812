import { FC } from 'react';
import clsx from 'clsx';
import styles from './BaseApplicationHeaderWrapper.module.scss';

interface IBaseApplicationHeaderWrapperProps {
  className?: string;
}

const BaseApplicationHeaderWrapper: FC<IBaseApplicationHeaderWrapperProps> = ({
  className,
  children,
}) => {
  return (
    <div className={clsx(styles.headerContainer, className)}>
      <div className={styles.headerContent}>
        {children}
      </div>
    </div>
  );
};

export default BaseApplicationHeaderWrapper;
