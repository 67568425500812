import { CorrespondenceChecker } from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/Types';
import { VariableValue } from 'product_modules/api/Types';
import DateTimeUtils from 'product_modules/utils/dateUtils';
import { DateTimeFormat } from 'product_modules/utils/dateFormat';

const isCorrespondsDate: CorrespondenceChecker<string> = (value: VariableValue): value is string => {
  if (typeof value !== 'string') {
    return false;
  }

  const date = DateTimeUtils.parse(value, DateTimeFormat.UsShortWithSlashes);

  return date.isValid();
};

export default isCorrespondsDate;
