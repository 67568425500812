import { forwardRef } from 'react';
import TextInput, { TextInputPropsSingleLine } from 'product_modules/components/TextInput';

const TextInputWrapper = forwardRef<HTMLInputElement, TextInputPropsSingleLine>((props, ref) => {
  return (
    <TextInput {...props} inputRef={ref} />
  );
});

export default TextInputWrapper;
