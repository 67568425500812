import React, { FC, useRef } from 'react';
import EmailInput from 'components/digifi-wrappers/EmailInput';
import { useFormik } from 'formik';
import { validateEmail } from 'utils/validation';
import getMessage  from 'constants/Messages';
import SubmitButton from 'components/common/SubmitButton';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';

export interface IResetPasswordContextType {
  email: string;
}

interface ICreateAccountFormProps {
  handleSubmit: (values: IResetPasswordContextType) => Promise<void>;
  isLoading?: boolean;
}

const ResetPasswordForm: FC<ICreateAccountFormProps> = ({ handleSubmit, isLoading }) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { values, errors, setFieldValue, setFieldError } = useFormik<IResetPasswordContextType>({
    initialValues: {
      email: '',
    },
    onSubmit: handleSubmit,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const clearFieldErrorOnFocus = (fieldName: string) => {
    setFieldError(fieldName, '');
  };

  const handleEmailBlur = (currentEmail: string) => {
    const trimmedEmail = currentEmail.trim();

    const error = validateEmail(trimmedEmail);
    setFieldValue('email', trimmedEmail);
    setFieldError('email', error === null ? '' : getMessage(error));
  };

  const isButtonEnabled = !validateEmail(values.email);

  return (
    <div onSubmit={() => handleSubmit(values)}>
      <EmailInput
        labelTitle="Email"
        onChange={(value) => setFieldValue('email', value)}
        onFocus={() => clearFieldErrorOnFocus('email')}
        onBlur={() => handleEmailBlur(values.email)}
        value={values.email || ''}
        errorMessage={errors.email || ''}
        name="email"
        disabled={isLoading}
        required
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
      />
      <SubmitButton
        title="Reset Password"
        isButtonEnabled={isButtonEnabled}
        onSubmit={() => handleSubmit(values)}
        ref={submitButtonRef}
      />
    </div>
  );
};

export default ResetPasswordForm;
