import BaseApi, { FetchMethodType } from 'api/BaseApi';

export interface IPhoneVerificationApi {
  sendPhoneVerificationCode(): void;
  verifyPhone(code: string): void;
}

export default class PhoneVerificationRestApi extends BaseApi implements IPhoneVerificationApi {
  public sendPhoneVerificationCode() {
    return this.fetch('/phone-verification', {
      method: FetchMethodType.POST,
    });
  };

  public verifyPhone(code: string) {
    return this.fetch(`/phone-verification/${code}`, {
      method: FetchMethodType.PUT,
    });
  };
}
