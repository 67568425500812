import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';
import { IBorrowerProfileCard } from 'api/digifi/layout/CardsApi';
import { LayoutConfigurationEntityName } from 'enums/LayoutConfigurationEntityName';
import { createLayoutEntitiesSlice } from './createLayoutEntitiesSlice';

const {
  reducer,
  removeLayoutEntities: removeBorrowerProfileCards,
} = createLayoutEntitiesSlice<
  IBorrowerProfileCard
>({
  referenceType: LayoutConfigurationReferenceType.BorrowerProfile,
  layoutConfigurationEntityName: LayoutConfigurationEntityName.Card,
});

export {
  removeBorrowerProfileCards,
};

export default reducer;
