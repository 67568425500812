import { FC } from 'react';
import styles from './TasksElement.module.scss';

const NoTasks: FC = () => {
  return (
    <div className={styles.noTasksContainer}>
      <p className={styles.taskTitle}>No Outstanding Tasks</p>
      <p className={styles.description}>There are currently no outstanding items that require your attention.</p>
    </div>
  );
};

export default NoTasks;
