const EXPANDED_MODE_PADDING = 217;
const EXPANDED_MODE_PADDING_MOBILE = 157;

const getTableHeight = (
  currentRowsAmount: number,
  rowHeight: number,
  amountOfRowsVisible: number,
  isExpanded?: boolean,
  isMobile?: boolean,
) => {
  const extraHalfRowHeight = currentRowsAmount > amountOfRowsVisible ? (rowHeight / 2) : 0;
  const padding = isMobile ? EXPANDED_MODE_PADDING_MOBILE : EXPANDED_MODE_PADDING;

  return isExpanded
    ? Math.min(window.innerHeight - padding, currentRowsAmount * rowHeight)
    : (Math.min(currentRowsAmount, amountOfRowsVisible) * rowHeight + extraHalfRowHeight);
};


export default getTableHeight;
