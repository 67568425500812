import { FocusEvent, useState } from 'react';
import BorrowerProfileForm from 'components/ApplicationForm/forms/BorrowerProfileForm';
import { VariableValue } from 'product_modules/api/Types';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { IBaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import styles from './CoBorrowerInformationStep.module.scss';
import StepWrapper, { IStepNavigationProps } from 'components/common/StepWrapper';

interface ICoBorrowerInformationStepProps extends IStepNavigationProps {
  index: number;
  availableBorrowerTypes: Array<BorrowerType>;
  coBorrowersFormData: Array<Record<string, VariableValue>>;
  coBorrowersTypes: Array<BorrowerType>;
  onChangeCoBorrowerType: (type: BorrowerType) => void;
  onCoBorrowerFieldChange: (field: IBaseVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onCoBorrowerFieldBlur: (
    field: IBaseVariableConfiguration,
    variable: Variable,
    event?: FocusEvent<HTMLInputElement>,
  ) => void;
  isLoading: boolean;
}

const CoBorrowerInformationStep = ({
  index,
  availableBorrowerTypes,
  coBorrowersFormData,
  coBorrowersTypes,
  onChangeCoBorrowerType,
  onCoBorrowerFieldChange,
  onCoBorrowerFieldBlur,
  ...restProps
}: ICoBorrowerInformationStepProps) => {
  const [isValid, setIsValid] = useState(false);

  return (
    <StepWrapper
      title={`Co-Applicant ${index ? index + 1 : ''} Information `}
      subtitle='Please provide a few details about your co-applicant.'
      disableContinue={!isValid}
      contentClassName={styles.content}
      titleClassName={styles.title}
      subtitleClassName={styles.subtitle}
      {...restProps}
    >
      <BorrowerProfileForm
        borrowerFormData={coBorrowersFormData[index]}
        coBorrowersFormData={coBorrowersFormData}
        onFieldChange={onCoBorrowerFieldChange}
        onFieldBlur={onCoBorrowerFieldBlur}
        selectedBorrowerType={coBorrowersTypes[index]}
        availableBorrowerTypes={availableBorrowerTypes}
        onChangeBorrowerType={onChangeCoBorrowerType}
        onFormValidationChange={setIsValid}
        borrowerTypeToggleClassName={styles.borrowerTypeToggle}
      />
    </StepWrapper>
  );
};

export default CoBorrowerInformationStep;
