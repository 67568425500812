import HomePage from 'pages/applications/HomePage';
import StartPage from 'pages/auth/StartPage';
import useSessionStatus, { SessionStatus } from 'hooks/useSessionStatus';

const HomePageWrapper = () => {
  const sessionStatus = useSessionStatus();

  return sessionStatus === SessionStatus.Authorized ? <HomePage /> : <StartPage />;
};

export default HomePageWrapper;
