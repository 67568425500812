import React, { FunctionComponent, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface IBasePopupProps {
  key: string;
  onClose: () => void;
  openPopup: (name: string) => void;
}

type IPopupsConfig = Record<string, FunctionComponent<IBasePopupProps>>;

type IOpenedKeys<Type extends IPopupsConfig> = Record<keyof Type, {
  value: string;
  key: keyof Type;
}>;

interface IUsePopupResult<Type extends IPopupsConfig> {
  openPopup: (name: keyof Type, value?: string) => void;
  closePopup: (name: keyof Type) => void;
  renderPopups: () => React.ReactElement;
  openedPopupKeys: IOpenedKeys<Type>;
  topPopupKey: (keyof Type) | null;
}

const usePopupsWithRouting = <Type extends IPopupsConfig>(config: Type): IUsePopupResult<Type> => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const openedPopupKeys = Object.keys(config).filter((key) => location.search.includes(key)).reduce((result, key) => {
    result[key] = {
      value: queryParams.get(key),
      key,
    };

    return result;
  }, {} as Record<string, IOpenedKeys<{}>>) as IOpenedKeys<Type>;

  const openPopup = useCallback((key: keyof Type, value?: string) => {
    const params = new URLSearchParams(location.search);

    params.delete(key as string);

    params.append(key as string, value || '');

    navigate(`${location.pathname}?${params}`);
  }, [location, navigate]);

  const handlePopUpClose = useCallback((popupKeyToClose: keyof Type) => {
    const params = new URLSearchParams(location.search);

    if (params.has(popupKeyToClose as string)) {
      params.delete(popupKeyToClose as string);
    }

    navigate(`${location.pathname}?${params}`);
  }, [location, navigate]);

  const openedKeysParams = Object.values(openedPopupKeys);

  return {
    openPopup,
    closePopup: handlePopUpClose,
    openedPopupKeys,
    topPopupKey: openedKeysParams[openedKeysParams.length - 1]?.key || null,
    renderPopups: () => (
      <>
        {openedKeysParams.map(({ key }) => config[key]({ key: key as string, openPopup, onClose: () => handlePopUpClose(key) }))}
      </>
    ),
  };
};

export default usePopupsWithRouting;
