import { FC } from 'react';
import ApplicationDetailsHeader from 'components/ApplicationDetails/ApplicationDetailsHeader';
import ApplicationDetails from 'components/ApplicationDetails/ApplicationDetailsConnector';
import ModalOverlay from 'components/common/ModalOverlay';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import SkeletonApplicationDetailsHeader
  from 'components/ApplicationDetails/ApplicationDetailsHeader/SkeletonApplicationDetailsHeader';
import styles from './ApplicationDetailsPopup.module.scss';

interface IApplicationDetailsPopupProps {
  application: IApplicationViewModel | null;
  onClose: () => void;
}

const ApplicationDetailsPopup: FC<IApplicationDetailsPopupProps> = ({
  application,
  onClose,
}) => {
  const renderModalTitle = () => {
    if (!application) {
      return <SkeletonApplicationDetailsHeader />;
    }

    return (
      <ApplicationDetailsHeader
        applicationStatus={application.status}
        loanAmount={application.loanAmount}
        productName={application.productName}
      />
    );
  };

  return (
    <ModalOverlay
      title={renderModalTitle()}
      onClose={onClose}
      headerClassName={styles.applicationHeader}
      closeIconClassName={styles.closeIcon}
      className={styles.container}
      titleClassName={application ? '' : styles.title}
      sectionClassName={styles.sectionClassName}
    >
      <div className={styles.contentContainer}>
        <ApplicationDetails
          application={application}
        />
      </div>
    </ModalOverlay>
);
};

export default ApplicationDetailsPopup;
