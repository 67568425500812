import DayjsAdapter from '@date-io/dayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';

const useUtilsClass = (dateFormat: string) => {
  const UtilsClass = useMemo(() => {
    return class LocalizedUtils extends DayjsAdapter {
      // @ts-ignore
      date = (date: Date | string | null): Dayjs => {
        // copied from original function
        if (date === null) {
          return (null as unknown) as Dayjs;
        }

        if (date instanceof Date) {
          return dayjs(date);
        }

        return date ? dayjs(date, dateFormat, true) : dayjs();
      };
    };
  }, [dateFormat]);

  return UtilsClass;
};

export default useUtilsClass;
