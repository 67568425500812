import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { DataViewSortingType } from 'product_modules/api/LoanOriginationSystem/Types';

export enum DocumentExtension {
  Pdf = 'pdf',
  Txt = 'txt',
  Doc = 'doc',
  Docx = 'docx',
  Xls = 'xls',
  Xml = 'xml',
  Xlsx = 'xlsx',
  Json = 'json',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Svg = 'svg',
  Jpeg = 'jpeg',
  Png = 'png',
  Jpg = 'jpg',
  Gif = 'gif',
  Csv = 'csv',
  Word = 'word',
  Zip = 'zip',
  Ico = 'ico',
  Heic = 'heic', // iphone image format
  Webp = 'webp', // android image format
}

export interface IApplicationDocument {
  id: string;
  name: string;
  extension: DocumentExtension;
  size: number;
  createdAt: string;
  applicationId: string;
}

interface IGetApplicationDocumentsResponse {
  documents: IApplicationDocument[];
}

export interface IDownloadResponse {
  file: Blob;
  filename: string;
}

export interface IBatchUploadDocumentParams {
  file: File;
  parentId?: string | null;
  anchor?: string | null;
}

interface IGetPreviewLinkResponseParams {
  token: string;
}

export enum ApplicationDocumentsSortingField {
  Name = 'name',
  Extension = 'extension',
  Size = 'size',
  CreatedAt = 'createdAt',
}

export type ApplicationDocumentsSortingType = DataViewSortingType<ApplicationDocumentsSortingField>;

interface IDocumentsApi {
  getApplicationDocuments: (applicationId: string) => Promise<IGetApplicationDocumentsResponse>;
  download: (id: string) => Promise<IDownloadResponse>;
  downloadAll: (applicationId: string) => Promise<IDownloadResponse>;
  batchUpload: (applicationId: string, params: IBatchUploadDocumentParams[]) => Promise<void>;
  getPublicLink: (fileId: string) => Promise<string>;
}

export default class DocumentsRestApi extends BaseApi implements IDocumentsApi {
  public getApplicationDocuments(applicationId: string): Promise<IGetApplicationDocumentsResponse> {
    const urlParams = new URLSearchParams();

    urlParams.append('applicationId', applicationId);

    return this.fetch(`/application-documents?${urlParams}`);
  }

  public download(documentId: string): Promise<IDownloadResponse> {
    return super.download(`/application-documents-downloads/${documentId}`);
  }

  public async downloadAll(applicationId: string) {
    const params = new URLSearchParams();

    params.set('applicationId', applicationId);

    return super.download(`/application-documents-downloads/?${params}`);
  }

  public async batchUpload(applicationId: string, params: IBatchUploadDocumentParams[]) {
    const formData = new FormData();

    params.forEach((batchUploadDocumentParams, index) => {
      formData.append('files', batchUploadDocumentParams.file);

      if (batchUploadDocumentParams.parentId) {
        formData.append(`options[${index}].parentId`, batchUploadDocumentParams.parentId);
      }

      if (batchUploadDocumentParams.anchor) {
        formData.append(`options[${index}].anchor`, batchUploadDocumentParams.anchor);
      }
    });

    formData.append('applicationId', applicationId);

    return this.fetch<void>('/application-documents/batch', {
      method: FetchMethodType.POST,
      body: formData,
      resetDefaultHeaders: true,
    });
  }

  public async getPublicLink(documentId: string): Promise<string> {
    const { token } = await this.fetch<IGetPreviewLinkResponseParams>(`/application-documents-preview/${documentId}`);

    const params = new URLSearchParams();

    params.set('token', token);

    return `${this.absoluteOrigin}/api/document-preview?${params}`;
  }
}
