import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { ITask, TaskStatus } from './TasksApi';

export interface ISubmitTaskParams {
  applicationId: string;
  taskId: string;
  taskStatus: TaskStatus;
  files?: File[];
  comment?: string;
}

export interface IComment {
  id: string;
  message: string;
  createdAt: Date;
}

export interface ITaskDetailsApi {
  submit(params: ISubmitTaskParams): Promise<ITask>;
}

export default class TaskDetailsRestApi extends BaseApi implements ITaskDetailsApi {
  public submit(params: ISubmitTaskParams) {
    const formData = new FormData();

    formData.append('applicationId', params.applicationId);
    formData.append('taskId', params.taskId);
    formData.append('taskStatus', params.taskStatus);

    if (params.comment) {
      formData.append('comment', params.comment);
    }

    if (params.files) {
      params.files.forEach((file) => {
        formData.append('files', file);
      });
    }

    return this.fetch<ITask>('/task-details', {
      method: FetchMethodType.PUT,
      body: formData,
      resetDefaultHeaders: true,
    });
  }
}
