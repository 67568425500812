import { DateTime } from 'luxon';
import { DateTimeFormat } from 'product_modules/utils/dateFormat';

const formatDate = (date: string, format: DateTimeFormat = DateTimeFormat.Long) => {
  const dateObject = new Date(Date.parse(date));

  if (dateObject.toISOString() === date) {
    return DateTime.fromISO(date).toFormat(format);
  }

  return DateTime.fromFormat(date, DateTimeFormat.Short).toFormat(format);
};

export default formatDate;
