import { createEntityAdapter, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { SnackbarComponentTypes } from 'components/Snackbar/Types';

export interface ISnackContent {
  componentType: SnackbarComponentTypes;
  props: Record<string, string>;
}

export interface ISnackProps {
  id: string;
  title: string;
  content: ISnackContent,
}

export const DEFAULT_SNACK_LIFE_DURATION = 3000;

const snacksAdapter = createEntityAdapter<ISnackProps>();

const initialState = snacksAdapter.getInitialState();

export const removeSnackTimer = (id: string, dispatch: Dispatch, lifeTime = DEFAULT_SNACK_LIFE_DURATION) => {
  if (lifeTime) {
    const timeout = setTimeout(() => {
      dispatch(hideSnack(id));
      clearTimeout(timeout);
    }, lifeTime);
  }
};

const snacksSlice = createSlice({
  name: 'snacksSlice',
  initialState,
  reducers: {
    showSnack(state, { payload }: PayloadAction<ISnackProps>) {
      snacksAdapter.addOne(state, payload);
    },
    hideSnack(state, { payload }: PayloadAction<string>) {
      snacksAdapter.removeOne(state, payload);
    },
  },
});

export const { selectAll: selectAllSnacks } = snacksAdapter.getSelectors((state: RootState) => state.snacks);
export const { showSnack, hideSnack } = snacksSlice.actions;

export default snacksSlice.reducer;
