import { FC } from 'react';
import Tooltip, { WrapperWithTooltipProps } from 'product_modules/components/Tooltip';

const TooltipWrapper: FC<WrapperWithTooltipProps> = (props) => {
  return (
    <Tooltip {...props} />
  );
};

export default TooltipWrapper;
