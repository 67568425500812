import { ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import Button from 'components/digifi-wrappers/Button';
import { LeftArrowImage, RightArrowImage } from 'product_modules/static/images';
import styles from './StepWrapper.module.scss';
import { FormContinueButtonDisabledTooltip } from 'constants/FormContinueButtonDisabledTooltip';

export interface IStepNavigationProps {
  onStepForward: () => void;
  onStepBack: () => void;
}

interface IStepWrapperProps extends IStepNavigationProps {
  hideBack?: boolean;
  isLoading?: boolean;
  disableContinue?: boolean;
  title: string;
  subtitle: string;
  titleClassName?: string;
  subtitleClassName?: string;
  contentClassName?: string;
  buttonsContainerClassName?: string;
  children: ReactNode;
}

const StepWrapper = ({
  onStepForward,
  onStepBack,
  hideBack,
  isLoading,
  disableContinue,
  titleClassName,
  subtitleClassName,
  contentClassName,
  buttonsContainerClassName,
  children,
  subtitle,
  title,
}: IStepWrapperProps) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  return (
    <div className={styles.container}>
      <div className={clsx(styles.title, titleClassName)}>{title}</div>
      <p className={clsx(styles.subtitle, subtitleClassName)}>{subtitle}</p>
      <div className={clsx(styles.content, contentClassName)}>{children}</div>
      <div className={clsx(styles.buttonsContainer, buttonsContainerClassName)}>
        {!hideBack && (
          <Button className={styles.button} kind='secondary' size='form' onClick={onStepBack}>
            <LeftArrowImage />
            Back
          </Button>
        )}
        <Button
          className={styles.button}
          kind='primary'
          size='form'
          disabled={disableContinue}
          onClick={onStepForward}
          isLoading={isLoading}
          tooltip={disableContinue && FormContinueButtonDisabledTooltip}
        >
          Continue
          <RightArrowImage />
        </Button>
      </div>
    </div>
  );
};

export default StepWrapper;
