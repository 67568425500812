import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { automationWorkflowApi } from 'store/api';

enum AutomationWorkflowActionType {
  GetAutomationWorkflowById = 'automationWorkflow/getAutomationWorkflowById',
}

export interface IAutomationWorkflowStateModel {
  id: string;
  isDeleted?: true;
}

const automationWorkflowAdapter = createEntityAdapter<IAutomationWorkflowStateModel>();

export interface IAutomationWorkflowState extends EntityState<IAutomationWorkflowStateModel> {}

const initialState: IAutomationWorkflowState = automationWorkflowAdapter.getInitialState();

export const getAutomationWorkflowById = createAsyncThunk(
  AutomationWorkflowActionType.GetAutomationWorkflowById,
  async (id: string) => {
    return automationWorkflowApi.findById(id);
  },
);

const automationWorkflowSlice = createSlice({
  name: 'portalPageElementsSlice',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getAutomationWorkflowById.fulfilled, (state, { payload }) => {
        automationWorkflowAdapter.setOne(state, payload);
      })
      .addCase(getAutomationWorkflowById.rejected, (state, { meta }) => {
        automationWorkflowAdapter.setOne(state, {
          id: meta.arg,
          isDeleted: true,
        });
      });
  },
});

export default automationWorkflowSlice.reducer;
