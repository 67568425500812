import React from 'react';
import LoggerService from 'services/LoggerService';

class ErrorBoundary extends React.Component {
  protected logger = new LoggerService();

  public componentDidCatch(error: Error) {
    this.logger.log(error);
  }

  public render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
