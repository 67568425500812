import { FC } from 'react';
import { PopUpContent, PopUpContentProps } from 'product_modules/components/PopUp';

const PopUpContentWrapper: FC<PopUpContentProps> = (props) => {
  return (
    <PopUpContent {...props} />
  );
};

export default PopUpContentWrapper;
