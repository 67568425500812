import { darken, rgba } from 'polished';

const DARKEN_LEVEL = 0.1;
const OPACITY_LEVEL = 0.1;

export const getBackgroundHoverColor = (color: string) => {
  return rgba(color, OPACITY_LEVEL);
};

export const getHoverColor = (color: string) => {
  return darken(DARKEN_LEVEL, color);
};
