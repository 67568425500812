import { VariableValue } from 'product_modules/api/Types';
import {
  isEmptyField,
  validateEmail,
  validateIDNumber,
  validateMaxDateCap,
  validateMaxNumberCap,
  validateMinDateCap,
  validateMinNumberCap,
  validatePhoneNumber,
} from 'product_modules/utils/validation/validation';
import {
  CapVisualAttributes,
  IdentificationTypeVisualAttributes,
  TableVisualAttributes,
  VariableValidationAndFormattingFields,
} from 'product_modules/api/Core/VariablesApi';
import {
  DateVisualDataType,
  NumericVisualDataType,
  StringVisualDataType,
  TableVisualDataType,
  VisualDataType,
} from 'product_modules/enums/VisualDataType';
import getValueCompatibilityWithVisualTypeChecker from 'product_modules/utils/valueConversionAvailabilityCheckers';
import { NonNullishVariableValue } from 'product_modules/utils/valueFormatters/base/Types';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';

interface VariableValueValidator {
  (variable: VariableValidationAndFormattingFields, value: VariableValue): boolean;
}

const isNumberOrStringVariableValue = (value: VariableValue): value is string | number => {
  return typeof value === 'number' || typeof value === 'string';
};

const isStringVariableValue = (value: VariableValue): value is string => {
  return typeof value === 'string';
}

const isEmptyVariableField = (value: VariableValue) => {
  return value === null || value === undefined || value === '' || isEmptyField(value.toString());
};

const isOptionalStringVariableValue = (value: VariableValue): value is Exclude<VariableValue, number | boolean | object> =>
  typeof value !== 'number' && typeof value !== 'boolean' && (typeof value !== 'object' || value === null);

const validateEmailVariable: VariableValueValidator = (variable, value) => {
  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validateEmail(value || '');
};

const validateIDNumberVariable: VariableValueValidator = (variable, value) => {
  const { identificationNumberType } = variable.visualAttributes as IdentificationTypeVisualAttributes;

  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validateIDNumber(value || '', identificationNumberType!);
};

const validatePhoneNumberVariable: VariableValueValidator = (variable, value) => {
  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validatePhoneNumber(value || '');
};

const validateVariableMinNumberCap: VariableValueValidator = (variable, value) => {
  return isNumberOrStringVariableValue(value)
    && !validateMinNumberCap(value, (variable.visualAttributes as CapVisualAttributes).minAllowedValue);
};

const validateVariableMaxNumberCap: VariableValueValidator = (variable, value) => {
  return isNumberOrStringVariableValue(value)
    && !validateMaxNumberCap(value, (variable.visualAttributes as CapVisualAttributes).maxAllowedValue);
};

const validateVariableMinDateCap: VariableValueValidator = (variable, value) => {
  return isStringVariableValue(value)
    && !validateMaxDateCap(value, (variable.visualAttributes as CapVisualAttributes).maxAllowedValue);
};

const validateVariableMaxDateCap: VariableValueValidator = (variable, value) => {
  return isStringVariableValue(value)
    && !validateMinDateCap(value, (variable.visualAttributes as CapVisualAttributes).minAllowedValue);
};

const validateTableVariable: VariableValueValidator = (variable, value) => {
  if (!Array.isArray(value) || value.some((item) => typeof item !== 'object')) {
    return false;
  }

  const columns = (variable.visualAttributes as TableVisualAttributes).columns;

  return !value.some((tableRow) => {
    return columns.some((column) => !validateVariable(column, tableRow[column.systemName], column.required));
  }, true);
};

export const validatorsByVisualDataTypeMap = new Map<VisualDataType, VariableValueValidator[]>([
  [StringVisualDataType.EmailAddress, [validateEmailVariable]],
  [StringVisualDataType.IdentificationNumber, [validateIDNumberVariable]],
  [StringVisualDataType.PhoneNumber, [validatePhoneNumberVariable]],
  [NumericVisualDataType.Number, [validateVariableMinNumberCap, validateVariableMaxNumberCap]],
  [NumericVisualDataType.Monetary, [validateVariableMinNumberCap, validateVariableMaxNumberCap]],
  [NumericVisualDataType.Percentage, [validateVariableMinNumberCap, validateVariableMaxNumberCap]],
  [DateVisualDataType.Date, [validateVariableMinDateCap, validateVariableMaxDateCap]],
  [TableVisualDataType.Table, [validateTableVariable]],
]);

export const validateVariable = (variable: VariableValidationAndFormattingFields, value: VariableValue, required?: boolean) => {
  // Skip all validation since field is not required and empty
  if (!required && isEmptyVariableField(value)) {
    return true;
  }

  if (required && isEmptyVariableField(value)) {
    return false;
  }

  const isValueCompatibleWithVisualDataType = getValueCompatibilityWithVisualTypeChecker(variable.visualDataType);

  if (!isValueCompatibleWithVisualDataType(value as NonNullishVariableValue, variable.visualAttributes)) {
    return false;
  }

  const validators = validatorsByVisualDataTypeMap.get(variable.visualDataType) || [];

  return validators.every((validator) => validator(variable, value));
};
