import { FC, ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Button from 'components/digifi-wrappers/Button';
import { NotEligibleToApplyIcon } from 'static/images';
import { AppRoute } from 'enums/AppRoute';
import useCurrentApplicationState, { CurrentApplicationState } from './useCurrentApplicationState';
import useCurrentApplication from 'hooks/useCurrentApplication';
import styles from './CurrentApplicationBlock.module.scss';

interface IApplicationStateScenario {
  title: string;
  description: string;
  onClick?: () => void;
  buttonTitle?: string;
  icon?: ReactNode;
  containerClassName?: string;
}

const SELECT_PRODUCT_QUERY_ATTRIBUTE = 'new';

const CurrentApplicationBlock: FC = () => {
  const navigate = useNavigate();

  const currentApplication = useCurrentApplication();

  const applicationStateScenarios: Record<string, IApplicationStateScenario> = useMemo(() => ({
    [CurrentApplicationState.NoActiveApplication]: {
      title: 'Apply Now!',
      description: 'Click to start new application.',
      buttonTitle: 'Start Application',
      containerClassName: styles.containerWithButton,
      onClick: () => {
        const params = new URLSearchParams(location.search);
        params.append(SELECT_PRODUCT_QUERY_ATTRIBUTE, '');

        navigate(`${location.pathname}?${params}`);
      },
    },
    [CurrentApplicationState.ApplicationInProgress]: {
      title: 'Application in Progress',
      description: 'Click the button to continue your application.',
      buttonTitle: 'Continue Application',
      containerClassName: styles.containerWithButton,
      onClick: () => navigate(`${AppRoute.Application}/${currentApplication?.displayId || ''}`),
    },
    [CurrentApplicationState.NotEligible]: {
      title: 'Not Eligible to Apply',
      description: 'You are not eligible to apply at this time.',
      icon: <NotEligibleToApplyIcon />,
    },
  }), []);

  const currentApplicationState = useCurrentApplicationState();

  const scenario = applicationStateScenarios[currentApplicationState];

  return (
    <>
      <div className={clsx(styles.container, scenario.containerClassName)}>
        <div className={styles.textContainer}>
          <p className={styles.blockTitle}>
            {scenario.title}
          </p>
          <p className={styles.description}>
            {scenario.description}
          </p>
        </div>
        {scenario.buttonTitle && (
          <Button
            size="form"
            kind="primary"
            onClick={() => scenario.onClick?.()}
            className={styles.button}
          >
            {scenario.buttonTitle}
          </Button>
        )}
        {scenario.icon}
      </div>
    </>
  );
};

export default CurrentApplicationBlock;
