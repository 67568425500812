import React, { FC } from 'react';
import clsx from 'clsx';
import Spinner from 'components/digifi-wrappers/Spinner';
import styles from './LoaderOverlay.module.scss';

interface ILoaderOverlayProps {
  loaderClassName?: string;
}

const LoaderOverlay: FC<ILoaderOverlayProps> = ({ loaderClassName }) => {
  return (
    <div className={clsx(styles.loaderContainer, loaderClassName)}>
      <Spinner size={52} thickness={4} className={styles.spinner} rootClassName={styles.spinnerRoot} />
    </div>
  );
};

export default LoaderOverlay;
