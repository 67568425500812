import { useEffect, useMemo } from 'react';
import generateObjectHash from 'object-hash';
import { RootState } from 'store';
import { useSelector, useStore } from 'react-redux';
import { Store } from '@reduxjs/toolkit';

interface CreateUseEntitiesByHookParams<Entity, Field extends string | number> {
  mapSelector: (state: RootState) => Partial<Record<string, Entity>>;
  useLoadEntities: () => (fields: Field[], store: Store<RootState>) => Promise<void>;
}

interface IUseEntitiesOptions {
  returnPartialResults?: boolean;
}

const createUseEntitiesByHook = <Entity, Field extends string | number>({
  mapSelector,
  useLoadEntities,
}: CreateUseEntitiesByHookParams<Entity, Field>) => {
  return (fields: Field[] | null | undefined, options?: IUseEntitiesOptions) => {
    const entitiesMap = useSelector(mapSelector);
    const store = useStore();

    const loadEntities = useLoadEntities();

    const { entities, fieldsToLoad, entitiesToLoadHash } = useMemo(() => {
      const data = (fields || []).reduce((previousData, field) => {
        if (!field) {
          return previousData;
        }

        const entity = entitiesMap[field.toString()];

        if (entity) {
          previousData.entities[field.toString()] = entity;

          return previousData;
        }

        if (previousData.visitedEntitiesToLoad[field.toString()]) {
          return previousData;
        }

        previousData.fieldsToLoad.push(field);
        previousData.visitedEntitiesToLoad[field.toString()] = true;

        return previousData;
      }, {
        fieldsToLoad: [] as Field[],
        visitedEntitiesToLoad: {} as Record<string, boolean>,
        entities: {} as Record<string, Entity>,
      });

      return {
        ...data,
        entitiesToLoadHash: generateObjectHash(data.visitedEntitiesToLoad),
      };
    }, [fields, entitiesMap]);

    useEffect(() => {
      if (fieldsToLoad.length) {
        loadEntities(fieldsToLoad, store);
      }
    }, [entitiesToLoadHash, store]);

    if (fieldsToLoad.length) {
      return options?.returnPartialResults ? entities : null;
    }

    return entities;
  }
};

export default createUseEntitiesByHook;
