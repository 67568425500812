import getIdentificationNumberMask from 'product_modules/utils/masks/maskIdentificationNumber';
import ValueFormattingError from 'product_modules/utils/valueFormatters/base/ValueFormattingError';
import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import { IdentificationTypeVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import { StringVisualDataType } from 'product_modules/enums/VisualDataType';
import { IdentificationType } from 'product_modules/components/IdentificationNumberInput/types';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';

const isIdentificationTypeValue = getValueVisualTypeCorrespondenceChecker(StringVisualDataType.IdentificationNumber);

const formatIdentificationNumberValue: ValueFormatter<IdentificationTypeVisualAttributes> = (
  value,
  params = {
    identificationNumberType: IdentificationType.Other,
  },
) => {
  if (!isIdentificationTypeValue(value, params)) {
    throw new ValueFormattingError();
  }

  const identificationNumberType = params.identificationNumberType;

  if (!identificationNumberType) {
    return value;
  }

  const applyMask = getIdentificationNumberMask(identificationNumberType);

  return applyMask(value);
};

export default formatIdentificationNumberValue;
