import { numberToPercentage } from 'product_modules/components/PercentageInput/utils';
import maskNumberValue from 'product_modules/utils/masks/maskNumberValue';
import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import ValueFormattingError from 'product_modules/utils/valueFormatters/base/ValueFormattingError';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';
import { NumericVisualDataType } from 'product_modules/enums/VisualDataType';

const isPercentageValue = getValueVisualTypeCorrespondenceChecker(NumericVisualDataType.Percentage);

const formatPercentageValue: ValueFormatter = (
  value,
) => {
  if (!isPercentageValue(value)) {
    throw new ValueFormattingError();
  }

  const percentageValue = numberToPercentage(value.toString());
  const maskedValue = maskNumberValue(percentageValue.toString());

  return `${maskedValue}%`;
};

export default formatPercentageValue;
