import { FC, useEffect, useState } from 'react';
import { getSettings } from 'handlers/settingsSlice';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getCurrentAccount } from 'handlers/authSlice';
import { getAllAvailableProducts } from 'handlers/productsSlice';

const WorkflowWrapper: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      await Promise.allSettled([
        dispatchWithUnwrap(getSettings()),
        dispatchWithUnwrap(getCurrentAccount()),
        dispatchWithUnwrap(getAllAvailableProducts()),
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <>{isLoading ? <LoaderOverlay /> : children}</>
  );
};

export default WorkflowWrapper;
