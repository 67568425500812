import { FC } from 'react';
import Table, { TableProps } from 'product_modules/components/Table';

const TableWrapper: FC<TableProps> = (props) => {
  return (
    <Table {...props} />
  );
};

export default TableWrapper;
