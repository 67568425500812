import React from 'react';
import { SkeletonRectangle, SkeletonTableView } from 'product_modules/components/Skeleton';
import SkeletonColorType from 'product_modules/components/Skeleton/SkeletonColorType';
import styles from './SkeletonCardsForm.module.scss';

export interface SkeletonCardsFormProps {
  layout: Array<Array<number>>;
  textColor?: SkeletonColorType;
}

const SkeletonCardsForm = ({ layout, textColor = 'primary6' }: SkeletonCardsFormProps) => {
  const renderCard = (fieldsCount: number, index: number) => {
    return (
      <div className={styles.card} key={index}>
        <SkeletonRectangle width="240px" height="20px" color="primary20" marginBottom="24px" />
        <SkeletonTableView key={index} fields={fieldsCount} textColor={textColor} />
      </div>
    );
  }

  const renderRow = (row: Array<number>, rowIndex: number) => (
    <div className={styles.row} key={rowIndex}>
      {row.map((fieldsCount, index) => renderCard(fieldsCount, index))}
    </div>
  )

  return (
    <div className={styles.container}>
      {layout.map((row, index) => renderRow(row, index))}
    </div>
  );
};

export default SkeletonCardsForm;
