import { forwardRef } from 'react';
import styles from './PasswordInputWithHint.module.scss';
import clsx from 'clsx';
import PasswordInput, { PasswordInputProps } from 'product_modules/components/PasswordInput';
import CheckedList from 'product_modules/components/CheckedList';
import getMessage, { MessageType } from 'constants/Messages';

interface InputProps extends Omit<PasswordInputProps, 'ref'> {
  invalidMessageTypes: MessageType[];
}

const hints = [
  MessageType.PasswordRequirementLengthNotMet,
  MessageType.PasswordRequirementContainNumberNotMet,
  MessageType.PasswordRequirementContainLowercaseNotMet,
  MessageType.PasswordRequirementContainUppercaseNotMet,
]

const PasswordInputWithHint = forwardRef<HTMLInputElement, InputProps>(({ invalidMessageTypes, ...inputProps }, ref) => {
  const hintMessages = hints.map(hint => ({ label: getMessage(hint), isChecked: !invalidMessageTypes.includes(hint) }));

  return (
    <div className={clsx(styles.inputContainer)}>
      <PasswordInput {...inputProps} ref={ref} errorMessage={''} labelClassName={styles.inputLabel} />
      <div className={styles.validations}>
        <CheckedList
          listItems={hintMessages}
          listItemClassName={styles.hintItem}
          checkedListItemClassName={styles.hintItem_checked}
        />
      </div>
    </div>
  );
});

export default PasswordInputWithHint;
