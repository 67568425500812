import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'product_modules/hooks/useQueryParam';
import CreateNewApplicationProductSelector from 'components/ApplicationForm/CreateNewApplicationProductSelector';
import useAvailableProducts from 'hooks/useAvailableProducts';
import { AppRoute } from 'enums/AppRoute';
import { hasProductCoborrowerOptions } from 'utils/coborrowersHelper';

const SELECT_PRODUCT_QUERY_ATTRIBUTE = 'new';

const ProductSelector: FC = () => {
  const currentQueryParams = useQueryParams();

  const navigate = useNavigate();

  const availableProducts = useAvailableProducts();

  const handleProductSelectorClose = () => {
    currentQueryParams.delete(SELECT_PRODUCT_QUERY_ATTRIBUTE);

    navigate(AppRoute.Home);
  };

  useEffect(() => {
    if (currentQueryParams.has(SELECT_PRODUCT_QUERY_ATTRIBUTE)
      && availableProducts.length === 1
      && !hasProductCoborrowerOptions(availableProducts[0])
    ) {
      const params = new URLSearchParams();

      params.append('productId', availableProducts[0].id);

      navigate(`${AppRoute.Apply}?${params}`);
    }
  }, [availableProducts]);

  if (!currentQueryParams.has(SELECT_PRODUCT_QUERY_ATTRIBUTE)) {
    return null;
  }

  return (
    <CreateNewApplicationProductSelector onClose={handleProductSelectorClose} />
  );
};

export default ProductSelector;
