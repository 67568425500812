import React, { forwardRef } from 'react';
import { HTMLInputProps } from 'product_modules/components/TextInput/TextInput';

export interface InputProps extends HTMLInputProps {
  valueValidator?: (value: string) => boolean;
  value?: string | number;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ value, onChange, valueValidator, ...props }, ref) => {
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = valueValidator?.(event.target.value);

    if (isValid || !valueValidator) {
      onChange?.(event);
    }
  };

  return <input onChange={handleValueChange} value={value} ref={ref} {...props} />;
});

export default Input;
