import React, { FC } from 'react';
import TableBody from 'components/digifi-wrappers/TableBody';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import TableRow from 'components/digifi-wrappers/TableRow';
import Table from 'components/digifi-wrappers/Table';
import styles from './LoginDetails.module.scss';
import LinkButton from 'components/digifi-wrappers/LinkButton';
import LoginDetailsColumnSizes from './LoginDetailsColumnSizes';
import VerificationStatusTag from 'components/BorrowerProfile/LoginDetails/VerificationStatusTag';
import SkeletonRectangle from 'components/digifi-wrappers/SkeletonRectangle';
import { formatPhoneNumber } from 'product_modules/components/PhoneInput';
import ModalOverlay from 'components/common/ModalOverlay';

interface ILoginDetailsProps {
  onChangeEmail: () => void;
  onChangePhone: () => void;
  onChangePassword: () => void;
  onClose: () => void;
  email?: string;
  phone?: string;
  disabled?: boolean;
}

const SKELETON_ROW_COUNT = 3;

const LoginDetails: FC<ILoginDetailsProps> = ({
  email,
  phone,
  disabled,
  onChangeEmail,
  onChangePhone,
  onChangePassword,
  onClose,
}) => {
  const isLoading = email === undefined;

  const renderValueSkeleton = () => {
    return (
      <div>
        <div className={styles.topLine}>
          <SkeletonRectangle width="212px" height="14px" color="primary6" />
          <SkeletonRectangle marginLeft="12px" width="71px" height="14px" color="primary6" marginBottom="3px" />
        </div>
        <div>
          <SkeletonRectangle width="212px" height="14px" color="primary6"  marginBottom="3px" marginTop="3px" />
        </div>
      </div>
    );
  };

  const renderSkeleton = () => {
    return Array.from({ length: SKELETON_ROW_COUNT }).map((item, index) => (
      <TableRow key={index} hasTopBorder={!index} disableHover>
        <TableBodyCell width={LoginDetailsColumnSizes.Name} className={styles.nameCell}>
          <SkeletonRectangle width="120px" height="14px" color="primary6" />
        </TableBodyCell>
        <TableBodyCell width={LoginDetailsColumnSizes.Value}>
          {renderValueSkeleton()}
        </TableBodyCell>
      </TableRow>
    ));
  };

  return (
    <ModalOverlay title="Login Details" onClose={onClose}>
      <div className={styles.container}>
        <Table>
          <TableBody>
            {isLoading ? renderSkeleton() : (
              <>
                <TableRow disableHover hasTopBorder className={styles.tableRow}>
                  <TableBodyCell className={styles.nameCell}>
                    Email Address
                  </TableBodyCell>
                  <TableBodyCell className={styles.valueCell}>
                    <>
                      <div className={styles.topLine}>
                        <p className={styles.value}>{email}</p>
                        <VerificationStatusTag verified />
                      </div>
                      {!disabled && <LinkButton
                        className={styles.action}
                        onClick={onChangeEmail}
                      >
                        Change Email
                      </LinkButton>}
                    </>
                  </TableBodyCell>
                </TableRow>
                <TableRow disableHover className={styles.tableRow}>
                  <TableBodyCell className={styles.nameCell}>
                    Phone Number
                  </TableBodyCell>
                  <TableBodyCell className={styles.valueCell}>
                    {isLoading && renderValueSkeleton()}
                    <>
                      <div className={styles.topLine}>
                        <p className={styles.value}>{formatPhoneNumber(phone || '') || phone}</p>
                        <VerificationStatusTag className={styles.status} verified />
                      </div>
                      {!disabled && <LinkButton
                        className={styles.action}
                        onClick={onChangePhone}
                      >
                        Change Phone
                      </LinkButton>}
                    </>
                  </TableBodyCell>
                </TableRow>
                <TableRow disableHover className={styles.tableRow}>
                  <TableBodyCell className={styles.nameCell}>
                    Password
                  </TableBodyCell>
                  <TableBodyCell className={styles.valueCell}>
                    {isLoading && renderValueSkeleton()}
                    {!isLoading && (
                      <>
                        <div className={styles.topLine}>
                          <p className={styles.value}>•••••••••••••</p>
                        </div>
                        {!disabled && <LinkButton
                          className={styles.action}
                          onClick={onChangePassword}
                        >
                          Change Password
                        </LinkButton>}
                      </>
                    )}
                  </TableBodyCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </ModalOverlay>
  );
};

export default LoginDetails;
