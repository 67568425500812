/**
 * @param maxDecimalPartLength - decimal part length limit
 * @returns a function that formats a decimal value in a special way:
 * 1. if no decimal part is given, no decimal part is returned
 * 2. if decimal part is present, it always contains [maxDecimalPartLength] characters
 */
const LimitDecimalNumbers = (maxDecimalPartLength: number) => (value: string): string => {
  const dotIndex = value.indexOf('.');
  if (dotIndex === -1 || dotIndex === value.length - 1) {
    return value;
  }
  const result = value.slice(0, dotIndex + maxDecimalPartLength + 1);
  return result + '0'.repeat(dotIndex + maxDecimalPartLength + 1 - result.length);
};

export default LimitDecimalNumbers;
