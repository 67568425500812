import { template, mapValues } from 'lodash';

export enum MessageType {
  FirstNameRequired,
  LastNameRequired,
  CompanyNameRequired,
  EmailRequired,
  EmailInvalid,
  PhoneNumberRequired,
  PhoneNumberInvalid,
  PasswordRequired,
  PasswordRequirementsNotMet,
  PasswordResetEmailSent,
  PasswordRequirementLengthNotMet,
  PasswordRequirementContainNumberNotMet,
  PasswordRequirementContainLowercaseNotMet,
  PasswordRequirementContainUppercaseNotMet,
}

const messages = {
  [MessageType.FirstNameRequired]: 'First name is required.',
  [MessageType.LastNameRequired]: 'Last name is required.',
  [MessageType.CompanyNameRequired]: 'Company name is required',
  [MessageType.EmailRequired]: 'Email is required.',
  [MessageType.EmailInvalid]: 'Invalid email address.',
  [MessageType.PhoneNumberRequired]: 'Phone number is required',
  [MessageType.PhoneNumberInvalid]: 'Invalid phone number.',
  [MessageType.PasswordRequired]: 'Password is required.',
  [MessageType.PasswordRequirementsNotMet]:
    'Password does not meet requirements (8+ characters in length, at least 1 uppercase letter, at least 1 lowercase letter, and at least 1 number).',
  [MessageType.PasswordRequirementLengthNotMet]: 'Must be at least 8 characters long',
  [MessageType.PasswordRequirementContainNumberNotMet]: 'Must contain a number',
  [MessageType.PasswordRequirementContainUppercaseNotMet]: 'Must contain an uppercase letter',
  [MessageType.PasswordRequirementContainLowercaseNotMet]: 'Must contain a lowercase letter',
  [MessageType.PasswordResetEmailSent]:
    'If you have an account, a reset email will have been sent to <%= email %>! If you are unable to locate the email, please check your spam or junk folder.',

};

const messageTemplates = mapValues(messages, (templateString) => template(templateString));

const getMessage = (messageType: MessageType, data?: Record<string, string>) => messageTemplates[messageType](data);

export default getMessage;
