import { FC } from 'react';
import SkeletonRectangle from 'components/digifi-wrappers/SkeletonRectangle';
import TableRow from 'components/digifi-wrappers/TableRow';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import {
  DocumentsTableColumnSizes,
  DocumentsTableColumnSizesMobile,
} from 'components/ApplicationDetails/DocumentsTableRow/DocumentsTableColumnSizes';
import { FileSkeletonIcon } from 'static/images';
import styles from './DocumentsTable.module.scss';

const MAX_ROW_COUNT_DESKTOP = 10;
const MAX_ROW_COUNT_MOBILE = 5;

const SkeletonDocumentsTable: FC = () => {
  return (
    <>
      {Array.from({ length: MAX_ROW_COUNT_DESKTOP }).map(
        (value, index) => (
          <TableRow key={index} className={styles.tableRowDesktop} disableHover hasTopBorder={!index}>
            <TableBodyCell width={DocumentsTableColumnSizes.Name} noPadding >
              <div className={styles.extensionIcon}>
                <FileSkeletonIcon />
              </div>
              <SkeletonRectangle width="38%" color="primary10" height="16px" />
            </TableBodyCell>
            <TableBodyCell width={DocumentsTableColumnSizes.Type}>
              <SkeletonRectangle width="80%" color="primary6" height="16px" />
            </TableBodyCell>
            <TableBodyCell width={DocumentsTableColumnSizes.Size}>
              <SkeletonRectangle width="80%" color="primary6" height="16px" />
            </TableBodyCell>
            <TableBodyCell width={DocumentsTableColumnSizes.Uploaded} noPadding>
              <div className={styles.uploadedCell}>
                <SkeletonRectangle width="64%" color="primary6" height="16px" />
                <SkeletonRectangle width="24px" color="primary6" height="24px" />
              </div>
            </TableBodyCell>
          </TableRow>
        ),
      )}
      {Array.from({ length: MAX_ROW_COUNT_MOBILE }).map(
        (value, index) => (
          <TableRow key={index} className={styles.tableRowMobile} disableHover>
            <TableBodyCell width={DocumentsTableColumnSizesMobile.Name} noPadding>
              <div className={styles.extensionIcon}>
                <FileSkeletonIcon />
              </div>
              <SkeletonRectangle width="70%" color="primary10" height="16px" />
            </TableBodyCell>
            <TableBodyCell width={DocumentsTableColumnSizesMobile.Size}>
              <SkeletonRectangle width="69%" color="primary6" height="16px" />
            </TableBodyCell>
            <TableBodyCell width={DocumentsTableColumnSizesMobile.Uploaded}>
              <div className={styles.uploadedCell}>
                <SkeletonRectangle width="24px" color="primary6" height="24px" />
              </div>
            </TableBodyCell>
          </TableRow>
        ),
      )}
    </>
  );
};

export default SkeletonDocumentsTable;

