import React, { FocusEvent, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import { Collapse } from '@material-ui/core';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import {
  IBaseCard,
  IBaseVariableConfiguration,
} from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { LoaderState } from 'product_modules/components/LoaderWithState';
import ConfigurableForm from 'product_modules/components/ConfigurableForm';
import CardTableView from 'product_modules/components/CardsForm/CardTableView';
import {
  useLayoutConfigurationEntities
} from 'product_modules/loaders/LayoutConfiguration/hooks/useLayoutConfigurationEntities';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import QuestionIcon from 'product_modules/components/QuestionIcon';
import { IAddressInputClassNames, ITableInputClassNames } from 'product_modules/types/InputClassNamesTypes';
import styles from './Card.module.scss';

export interface ICardProps<
  CardType extends IBaseCard,
  FieldType extends IBaseVariableConfiguration,
  CalculationType extends BaseCalculation,
> {
  referenceKey: string;
  storageLayoutEntitiesPaths: {
    cards: string;
    variablesConfigurations: string;
  };
  className?: string;
  card: CardType;
  cardIndex: number;
  data: FormLayoutData;
  onFieldChange: (field: FieldType, variable: Variable, value: VariableValue) => void;
  onFieldFocus?: (field: FieldType, variable: Variable, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (field: FieldType, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  formatVariableConfigurationDisplayTitle?: (title: string) => string;
  loaderStateById?: Record<string, LoaderState | null | undefined>;
  onLoaderStateReset?: (field: FieldType) => void;
  isEditMode?: boolean;
  calculations?: Map<string, CalculationType> | null;
  displayFieldsAttributes?: Record<string, boolean | undefined>;
  rowIndex: number;
  country?: string;
  fieldClassName?: string;
  fieldTitleClassName?: string;
  fieldValueClassName?: string;
  hiddenFieldClassName?: string;
  inputClassNames?: {
    address?: IAddressInputClassNames;
    table?: ITableInputClassNames;
  };
  lastRowCardClassName?: string;
  descriptionIconClassName?: string;
  hideCardHeader?: boolean;
}

const DEFAULT_FORM_INDEX_OFFSET = 4;

const CardComponent = <
  CardType extends IBaseCard,
  FieldType extends IBaseVariableConfiguration,
  CalculationType extends BaseCalculation,
>({
  referenceKey,
  storageLayoutEntitiesPaths,
  className,
  data,
  card,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  loaderStateById,
  onLoaderStateReset,
  isEditMode,
  formatVariableConfigurationDisplayTitle,
  displayFieldsAttributes,
  rowIndex,
  cardIndex,
  calculations,
  fieldClassName,
  fieldTitleClassName,
  fieldValueClassName,
  hiddenFieldClassName,
  country,
  inputClassNames,
  lastRowCardClassName,
  descriptionIconClassName,
  hideCardHeader,
}: ICardProps<CardType, FieldType, CalculationType>) => {
  const wasInViewRef = useRef(false);

  const { ref, inView } = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView) {
      wasInViewRef.current = true;
    }
  }, [inView]);

  const fields = useLayoutConfigurationEntities<FieldType>(
    referenceKey,
    storageLayoutEntitiesPaths.variablesConfigurations,
    card.id,
    'sortedVariableConfigurationsByCard',
  );

  const isAllFieldsInvisible = useMemo(() => {
    if (!displayFieldsAttributes) {
      return false;
    }

    return fields.every((field) => displayFieldsAttributes[field.id] === false);
  }, [displayFieldsAttributes]);

  const renderBody = () => {
    if (isEditMode) {
      return (
        <ConfigurableForm
          showLoader
          className={styles.configurableForm}
          data={data}
          loaderStateById={loaderStateById}
          fields={fields || null}
          onFieldChange={onFieldChange}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
          onLoaderStateReset={onLoaderStateReset}
          formatDisplayTitle={formatVariableConfigurationDisplayTitle}
          formIndex={cardIndex + rowIndex * DEFAULT_FORM_INDEX_OFFSET}
          displayFieldsAttributes={displayFieldsAttributes}
          calculations={calculations}
          inputClassNames={inputClassNames}
          country={country}
          inView={inView || wasInViewRef.current}
          renderFieldsOnlyInView
          labelTooltipClassName={descriptionIconClassName}
        />
      );
    }

    return (
      <CardTableView
        data={data}
        fields={fields || []}
        formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
        displayFieldsAttributes={displayFieldsAttributes}
        fieldClassName={fieldClassName}
        fieldTitleClassName={fieldTitleClassName}
        fieldValueClassName={fieldValueClassName}
        hiddenFieldClassName={hiddenFieldClassName}
        inputClassNames={inputClassNames}
        inView={inView || wasInViewRef.current}
        renderFieldsOnlyInView
      />
    );
  }

  return (
    <Collapse
      style={{ gridRow: rowIndex + 1 }}
      className={clsx(styles.collapseContainer, lastRowCardClassName)}
      in={!isAllFieldsInvisible}
      unmountOnExit
    >
      <div ref={ref} key={card.id} className={clsx(styles.card, className)}>
        {!hideCardHeader && (
          <div className={styles.headerContainer}>
            <h5 className={clsx(styles.cardName, isEditMode && styles.editMode)}>{card.name}</h5>
            {card.description && <QuestionIcon size={24} tooltip={card.description} className={descriptionIconClassName} />}
          </div>
        )}
        {renderBody()}
      </div>
    </Collapse>
  );
};

export default CardComponent;
