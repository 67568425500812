import DateTimeUtils from 'product_modules/utils/dateUtils';

export type DateRange = {
  from: Date | null;
  to: Date | null;
};

export enum DateTimeFormat {
  Long = 'MMM dd, yyyy, h:mm a',
  LongMonthDate = 'MMM dd, yyyy',
  Short = 'MM/dd/yyyy',
  UsShortWithSlashes = 'MM/DD/YYYY',
  ISOString = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
}

const formatDate = (date: string | Date | number, format: string = DateTimeFormat.UsShortWithSlashes) => {
  return DateTimeUtils.parseUnsafe(date).format(format);
};

export default formatDate;
