import { FC, useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { Swiper } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import type { Swiper as SwiperClass } from 'swiper/types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { ArrowRightBold } from 'static/images';
import styles from './OfferSelectionElement.module.scss';
import mediaStyles from 'styles/media.module.scss';

interface IReachedBorderState {
  isBeginning: boolean;
  isEnd: boolean;
}

interface ISwiperWrapperProps {
  itemsCount: number;
}

const SwiperWrapper: FC<ISwiperWrapperProps> = ({
  children,
  itemsCount,
}) => {
  const [reachedBorder, setReachedBorder] = useState<IReachedBorderState>({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef<SwiperClass>(null);

  const handleRightClick = useCallback(() => {
    swiperRef.current?.slideNext();

    if (reachedBorder.isBeginning) {
      setReachedBorder((currentReachedBorder) => ({ ...currentReachedBorder, isBeginning: false }));
    }
  }, [reachedBorder.isBeginning]);

  const handleLeftClick = useCallback(() => {
    swiperRef.current?.slidePrev();

    if (reachedBorder.isEnd) {
      setReachedBorder((currentReachedBorder) => ({ ...currentReachedBorder, isEnd: false }));
    }
  }, [reachedBorder.isEnd]);

  const handleDragEnd = useCallback((swiper: SwiperClass) => {
    setReachedBorder({ isBeginning: swiper.isBeginning, isEnd: swiper.isEnd });
  }, []);

  const handleReachedBeginning = useCallback(() => {
    setReachedBorder((prevState) => ({ ...prevState, isBeginning: true }));
  }, []);

  const handleReachedEnd = useCallback(() => {
    setReachedBorder((prevState) => ({ ...prevState, isEnd: true }));
  }, []);

  return (
    <>
      <Swiper
        onBeforeInit={(swiper: SwiperClass) => {
          swiperRef.current = swiper;
        }}
        slidesPerView={1}
        breakpoints={{
          [parseInt(mediaStyles.maxMobile)]: {
            slidesPerView: Math.min(itemsCount, 2),
          },
          [parseInt(mediaStyles.maxTablet)]: {
            slidesPerView: Math.min(itemsCount, 3),
          },
        }}
        spaceBetween={styles.offerCardsGap}
        scrollbar={{
          horizontalClass: styles.scrollbar,
          dragClass: styles.scrollbarDrag,
          draggable: true,
        }}
        onReachBeginning={handleReachedBeginning}
        onReachEnd={handleReachedEnd}
        onScrollbarDragEnd={handleDragEnd}
        modules={[Scrollbar]}
        className={styles.swiperContainer}
        wrapperClass={styles.swiperWrapper}
      >
        {children}
      </Swiper>
      {!reachedBorder.isBeginning && (
        <div
          onClick={handleLeftClick}
          className={clsx(styles.arrowContainerLeft, reachedBorder.isBeginning && styles.disabledArrow)}
        >
          <ArrowRightBold className={styles.arrowLeft}/>
        </div>
      )}
      {!reachedBorder.isEnd && (
        <div
          onClick={handleRightClick}
          className={clsx(styles.arrowContainerRight, reachedBorder.isEnd && styles.disabledArrow)}
        >
          <ArrowRightBold/>
        </div>
      )}
    </>
  );
};

export default SwiperWrapper;
