import { FC } from 'react';
import clsx from 'clsx';
import styles from './OfferSelectionElement.module.scss';

interface IOfferNotAvailableProps {
  containerClassName?: string;
}

const OfferNotAvailable: FC<IOfferNotAvailableProps> = ({ containerClassName }) => {
  return (
    <div className={clsx(styles.offerNotAvailableContainer, containerClassName)}>
      <p className={styles.offerNotAvailableTitle}>No Offers Available</p>
      <p className={styles.description}>You haven't had any offers.</p>
    </div>
  );
};

export default OfferNotAvailable;
