import { forwardRef } from 'react';
import PhoneNumberInput, { PhoneNumberInputProps } from 'product_modules/components/PhoneNumberInput';

const PhoneNumberInputWrapper = forwardRef<HTMLInputElement, PhoneNumberInputProps>((props, ref) => {
  return (
    <PhoneNumberInput {...props} ref={ref} />
  );
});

export default PhoneNumberInputWrapper;
