import { useEffect, useState, useMemo } from 'react';
import { debounce } from 'lodash';
import { getBorrower } from 'handlers/borrowersSlice';
import { getCurrentApplication } from 'handlers/activeBorrowerInformationSlice';
import { getAllAvailableProducts } from 'handlers/productsSlice';
import { getEnvironment } from 'handlers/settingsSlice';
import useDispatchWithUnwrap from './useDispatchWithUnwrap';
import { useAppSelector } from './reduxHooks';
import useCurrentBorrower from './useCurrentBorrower';

const FETCH_DEBOUNCE_TIME = 300;

const useAppInitialization = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { accountData } = useAppSelector((state) => state.auth);
  const borrower = useCurrentBorrower();

  const borrowerId = accountData?.borrowerId!;

  const fetchData = async () => {
    return Promise.all([
      dispatchWithUnwrap(getBorrower()),
      dispatchWithUnwrap(getCurrentApplication()),
      dispatchWithUnwrap(getAllAvailableProducts()),
      dispatchWithUnwrap(getEnvironment()),
    ]).finally(() => {
      setIsLoading(false);
    });
  };

  const debouncedFetch = useMemo(
    () => debounce(fetchData, FETCH_DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (borrowerId) {
      if (!borrower) {
        setIsLoading(true);

        debouncedFetch();
      }
    }
  }, [borrowerId, borrower?.id]);

  return isLoading;
};

export default useAppInitialization;
