import { FC, PropsWithoutRef } from 'react';
import TopRightInputButton, { HTMLButtonProps } from 'product_modules/components/TopRightInputButton';
import styled from 'types/AppTheme';

interface ITopRightInputButtonStyledProps {
  disabled?: boolean;
}

const TopRightInputButtonStyled = styled(TopRightInputButton)<ITopRightInputButtonStyledProps>`
  color: ${({ theme }) => theme.colors.accentColor};

  &:hover, &:focus {
    color: ${({ theme, disabled }) => disabled ? '' : theme.colors.hoverAccentColor};
  }
`;

const TopRightInputButtonWrapper: FC<PropsWithoutRef<HTMLButtonProps>> = (props) => {
  return (
    <div>
      <TopRightInputButtonStyled {...props} />
    </div>
  );
};

export default TopRightInputButtonWrapper;
