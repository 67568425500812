import { DateTimeFormat } from 'product_modules/utils/dateFormat';
import DateTimeUtils from 'product_modules/utils/dateUtils';

const getDefaultDateFormat = (variableValue: string, dateFormat: string) => {
  const UTCFormatDate = DateTimeUtils.parse(variableValue.toString(), dateFormat).toDate();
  const normalizedDate = DateTimeUtils.parse(UTCFormatDate).format(DateTimeFormat.UsShortWithSlashes);
  return normalizedDate;
};

export default getDefaultDateFormat;
