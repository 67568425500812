import { CorrespondenceChecker } from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/Types';
import { VariableValue } from 'product_modules/api/Types';

const PHONE_NUMBER_REGEXP = /^\+?\d+$/;

const isCorrespondsPhoneNumber: CorrespondenceChecker<string> = (value: VariableValue): value is string => {
  return typeof value === 'string' && PHONE_NUMBER_REGEXP.test(value);
};

export default isCorrespondsPhoneNumber;
