import { Variable } from 'product_modules/api/Core/VariablesApi';
import createEntitiesLoader from 'product_modules/providers/createEntitiesLoaderProvider';
import { loadVariablesBySystemNames } from 'handlers/variablesSlice';

const [
  VariablesBySystemNameLoaderContext,
  VariablesBySystemNameLoader,
] = createEntitiesLoader<Variable, string>({
  loadEntities: loadVariablesBySystemNames,
  isFieldAlreadyLoaded: (field, state) => {
    return !!state.variables.variablesBySystemName[field];
  },
  isFieldLoading: (field, state) => {
    return !!state.variables.loadingState[field];
  },
});

export {
  VariablesBySystemNameLoaderContext,
  VariablesBySystemNameLoader,
};
