import { NonNullishVariableValue } from 'product_modules/utils/valueFormatters/base/Types';
import DateTimeUtils from 'product_modules/utils/dateUtils';
import { DateTimeFormat } from 'product_modules/utils/dateFormat';

const isCompatibleWithDateType = (value: NonNullishVariableValue): value is string => {
  if (typeof value !== 'string') {
    return false;
  }

  const date = DateTimeUtils.parse(value, DateTimeFormat.UsShortWithSlashes);

  return date.isValid();
};

export default isCompatibleWithDateType;
