import { CheckboxSelected, CheckboxUnselected } from 'static/images';
import styled from 'types/AppTheme';

const StyledCheckboxContainer = styled.div<ICheckboxProps>`
  svg rect {
    ${({ isSelected, theme }) =>
      isSelected
        ? `fill: ${theme.colors.brandColor};`
        : `stroke: ${theme.colors.brandColor};`}
  }
`;

interface ICheckboxProps {
  isSelected?: boolean;
  className?: string;
}

const Checkbox = ({
  isSelected,
  className,
}: ICheckboxProps) => {
  return (
    <StyledCheckboxContainer className={className} isSelected={isSelected}>
      {isSelected && <CheckboxSelected />}
      {!isSelected && <CheckboxUnselected />}
    </StyledCheckboxContainer>
  );
};

export default Checkbox;
