import { useEffect, useState } from 'react';
import { ModuleThread, spawn, Thread, Worker } from 'threads';

export type ModuleMethods = {
  [methodName: string]: (...args: any) => any;
};

const createWorkerHook = <ModuleMethodsType extends ModuleMethods>(createWorker: () => Worker) => {
  return (disableWorker?: boolean) => {
    const [worker, setWorker] = useState<ModuleThread<ModuleMethodsType> | null>(null);

    useEffect(() => {
      if (!disableWorker) {
        spawn<ModuleMethodsType>(createWorker()).then(async (spawnedWorker) => {
          setWorker(() => {
            return spawnedWorker as ModuleThread<ModuleMethodsType>;
          });
        });
      }
    }, [disableWorker]);

    useEffect(() => {
      return () => {
        if (worker && !disableWorker) {
          setWorker(null);

          Thread.terminate(worker).then();
        }
      };
    }, [worker, disableWorker]);

    return worker;
  };
};

export default createWorkerHook;
