import { useEffect } from 'react';

const POLLING_INTERVALS = [
  { interval: 5000, duration: 10000 }, // Poll every 5 seconds for 10 seconds
  { interval: 10000, duration: 25000 }, // Poll every 10 seconds for 25 seconds
];

interface IUsePollingParams {
  callback: () => void;
  onAbort?: () => void;
  condition?: boolean;
  maxTime?: number;
}

const usePolling = ({
  callback,
  onAbort,
  condition,
}: IUsePollingParams) => {
  useEffect(() => {
    if (typeof condition !== 'undefined' && !condition) {
      return;
    }

    let elapsedTime = 0;
    let currentIndex = 0;
    let pollingTimer: NodeJS.Timeout;

    const startPolling = () => {
      pollingTimer = setInterval(() => {
        callback();
        elapsedTime += POLLING_INTERVALS[currentIndex].interval;

        if (elapsedTime >= POLLING_INTERVALS[currentIndex].duration && currentIndex < POLLING_INTERVALS.length - 1) {
          currentIndex++;
          clearInterval(pollingTimer);
          startPolling();
        }

        if (elapsedTime >= POLLING_INTERVALS[POLLING_INTERVALS.length - 1].duration) {
          onAbort?.();
          clearInterval(pollingTimer);
        }
      }, POLLING_INTERVALS[currentIndex].interval);
    };

    startPolling();

    return () => clearInterval(pollingTimer);
  }, [condition]);
};

export default usePolling;
