import Button from 'components/digifi-wrappers/Button';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import styles from './SignInBlock.module.scss';

const SignInBlock = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.title}>Sign In</div>
        <div className={styles.text}>If you’ve already created an account.</div>
      </div>
      <Button onClick={() => navigate(AppRoute.SignIn)} size='form' kind='secondary' className={styles.button}>Sign In</Button>
    </div>
  );
};

export default SignInBlock;
