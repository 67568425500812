import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useAppSelector } from 'hooks/reduxHooks';
import { ApplicationSortingField, IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import { getBorrowerApplications } from 'handlers/applicationsSlice';
import PriorApplications from 'components/ApplicationsList/PriorApplications/PriorApplications';
import usePaginationState from 'hooks/usePaginationState';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

interface IPriorApplicationsProps {
  onClick: (application: IApplicationViewModel) => void;
  isLoading?: boolean;
  className?: string;
}

const PriorApplicationsConnector: FC<IPriorApplicationsProps> = ({ onClick, className, isLoading }) => {
  const dispatch = useDispatchWithUnwrap();

  const applications = useSelector((state: RootState) => state.applications.entities);

  const {
    items,
    currentSortType,
    sort,
    paginationProps,
  } = usePaginationState({
    itemsPerPage: 10,
    defaultSorting: {
      ascending: false,
      field: ApplicationSortingField.CreatedAt as ApplicationSortingField,
    },
    loadItems: async ({
      offset,
      count,
      sortType,
    }) => {
      return dispatch(getBorrowerApplications({
        offset,
        count,
        sortType,
      }));
    },
  });

  const { currency } = useAppSelector((state) => state.settings.variablesSettings);

  const mappedApplications = useMemo(() => {
    return items?.map((id) => applications[id] || null) || null;
  }, [applications, items]);

  return (
    <PriorApplications
      applications={mappedApplications}
      currency={currency}
      onClick={onClick}
      onSort={(field, ascending) => sort(field, ascending)}
      isLoading={isLoading}
      paginationProps={paginationProps}
      sortingType={currentSortType}
      className={className}
    />
  );
};

export default PriorApplicationsConnector;
