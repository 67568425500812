import { FC } from 'react';
import { linearGradient, rgba } from 'polished';
import styled from 'types/AppTheme';
import OfferCard, { IOfferCardProps } from './OfferCard';

const getSelectedOfferBackgroundColor = (brandColor: string, percentage: number) => {
  return rgba(brandColor, percentage);
};

const getCheckedBackground = (brandColor: string, checked: boolean) => {
  if (!checked) {
    return '#fff';
  }

  return linearGradient({
    colorStops: [
      `${getSelectedOfferBackgroundColor(brandColor, 0.05)} 0%`,
      `${getSelectedOfferBackgroundColor(brandColor, 0)} 100%`,
    ],
    toDirection: '114deg',
    fallback: '#fff',
  });
};

const OfferCardStyled = styled(OfferCard)<IOfferCardProps>`
  box-shadow: ${({ theme, checked }) => checked 
          ? `0 0 0 3px ${theme.colors.brandColor} inset`
          : '0 0 0 1px #e7e9ec inset, 0 0 0 2px #fff inset'};
  background: ${({ theme, checked }) => getCheckedBackground(theme.colors.brandColor, checked)};
`;

const OfferCardWrapper: FC<IOfferCardProps> = (props) => {
  return (
    <OfferCardStyled {...props} />
  );
};

export default OfferCardWrapper;
