import { omit } from 'lodash';
import { TableValue, VariableValue } from 'product_modules/api/Types';
import renderStringTemplate from 'utils/renderStringTemplate';

const TABLE_SYSTEM_COLUMN = '_id';

const formatValueByType = (value: VariableValue): unknown => {
  if (Array.isArray(value)) {
    return (value as TableValue).map((item) => omit(item, TABLE_SYSTEM_COLUMN));
  }

  return value;
};

const formatValues = (context: Record<string, VariableValue>) => {
  return Object.keys(context).reduce((result, key) => {
    result[key] = formatValueByType(context[key]);

    return result;
  }, {} as Record<string, unknown>);
};

const renderStringTemplateByVariableValue = (body: string, context: Record<string, VariableValue>) => {
  return renderStringTemplate(body, formatValues(context));
};

export default renderStringTemplateByVariableValue;
