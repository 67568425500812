import { FC } from 'react';
import { SkeletonText, SkeletonTextProps } from 'product_modules/components/Skeleton';

const SkeletonTextWrapper: FC<SkeletonTextProps> = (props) => {
  return (
    <SkeletonText {...props} />
  );
};

export default SkeletonTextWrapper;
