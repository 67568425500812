import { FC } from 'react';
import Button from 'components/digifi-wrappers/Button';
import { EmptyDocumentImage } from 'static/images';
import styles from './ESignatureElement.module.scss';

interface IFailedToLoadDocumentProps {
  onClick: () => void;
}

const FailedToLoadDocument: FC<IFailedToLoadDocumentProps> = ({ onClick }) => {
  return (
    <div className={styles.errorContainer}>
      <EmptyDocumentImage />
      <div className={styles.textContainer}>
        <p className={styles.title}>
          Failed to Load Document
        </p>
        <p className={styles.errorDescription}>
          An unexpected error occurred loading<br/>
          the document.
        </p>
      </div>
      <Button kind="secondary" onClick={onClick}>
        Retry
      </Button>
    </div>
  );
};

export default FailedToLoadDocument;
