import React, { forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './WrapperWithLink.module.scss';

interface WrapperWithLinkProps {
  children: React.ReactNode;
  link?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
  disabled?: boolean;
  colored?: boolean;
  id?: string;
}

const WrapperWithLink = forwardRef<HTMLAnchorElement, PropsWithChildren<WrapperWithLinkProps>>(
  ({ children, id, link, onClick, disabled, colored, className }, ref) => {
    const onWrappingLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (event.ctrlKey || event.metaKey) {
        return;
      }

      event.preventDefault();

      if (disabled) {
        return;
      }

      onClick?.(event);
    };

    return (
      <a
        href={link}
        id={id}
        onClick={onWrappingLinkClick}
        className={clsx(styles.link, {
          [styles.disabledLink]: disabled,
          [styles.coloredLink]: colored,
        }, className)}
        ref={ref}
      >
        {children}
      </a>
    );
  },
);

export default WrapperWithLink;
