import { rgba } from 'polished';
import { useNavigate } from 'react-router-dom';
import styled from 'types/AppTheme';
import Button from 'components/digifi-wrappers/Button/Button';
import { AppRoute } from 'enums/AppRoute';
import styles from './GetStartedBlock.module.scss';

const GetStartedBlockStyled = styled.div`
  background: ${({ theme }) => `linear-gradient(270deg, ${rgba(theme.colors.brandColor, 0)} 50%, ${rgba(theme.colors.brandColor, 0.12)} 100%)`};
`;

const GetStartedBlock = () => {
  const navigate = useNavigate();

  return (
    <GetStartedBlockStyled className={styles.styledContainer}>
      <div className={styles.container}>
        <div>
          <div className={styles.title}>Let’s Get Started!</div>
          <p className={styles.text}>Click the button to start your new application.</p>
        </div>
        <Button onClick={() => navigate(AppRoute.Start)} className={styles.button} kind='primary' size='form'>Apply
          Now!</Button>
      </div>
    </GetStartedBlockStyled>
  );
};

export default GetStartedBlock;
