import BaseApi, { FetchMethodType } from 'api/BaseApi';
import formatDate, { DateTimeFormat } from 'product_modules/utils/dateFormat';
import {
  Variable,
  VariablesDataFilters,
  VariableSortingType,
  VariableType,
} from 'product_modules/api/Core/VariablesApi';
import { TableViewData } from 'product_modules/api/LoanOriginationSystem/Types';

export interface IVariablesApi {
  search(
    filters: VariablesDataFilters,
    sortingType?: VariableSortingType,
    abortSignal?: AbortSignal,
  ): Promise<TableViewData<Variable>>;
}

export default class VariablesRestApi extends BaseApi implements IVariablesApi {
  protected resourceName = 'variables';

  public async search(
    filters: VariablesDataFilters,
    sortingType?: VariableSortingType,
  ) {
    const params: Record<string, unknown> = {};

    params.sortField = sortingType?.field;

    if (sortingType?.ascending !== undefined) {
      params.sortDirection = sortingType.ascending ? 'asc' : 'desc';
    }

    if (filters) {
      const {
        ids,
        dueCreatedDateRange,
        dueUpdatedDateRange,
        systemNames,
        type,
        dataType,
        visualDataType,
        offset,
        count,
        search,
        teamMembers,
        showArchived,
        excludeDataTypes,
      } = filters;

      params.offset = offset;
      params.count = count;
      params.search = search;
      params.systemNames = systemNames;
      params.teamMembers = teamMembers?.length ? teamMembers : undefined;
      params.onlyCustom = type === VariableType.Custom;
      params.onlyStandard = type === VariableType.Standard;
      params.dataType = dataType;
      params.excludeDataTypes = excludeDataTypes;
      params.visualDataType = visualDataType;

      if (dueCreatedDateRange && dueCreatedDateRange.from) {
        params.dueCreatedDateRangeFrom = formatDate(dueCreatedDateRange.from, DateTimeFormat.ISOString);
      }

      if (dueCreatedDateRange && dueCreatedDateRange.to) {
        params.dueCreatedDateRangeTo = formatDate(dueCreatedDateRange.to, DateTimeFormat.ISOString);
      }

      if (dueUpdatedDateRange && dueUpdatedDateRange.from) {
        params.dueUpdatedDateRangeFrom = formatDate(dueUpdatedDateRange.from, DateTimeFormat.ISOString);
      }

      if (dueUpdatedDateRange && dueUpdatedDateRange.to) {
        params.dueUpdatedDateRangeTo = formatDate(dueUpdatedDateRange.to, DateTimeFormat.ISOString);
      }

      params.includeArchived = showArchived;
      params.ids = ids;
    }

    return this.fetch<TableViewData<Variable>>(`/${this.resourceName}/search`, {
      method: FetchMethodType.PUT,
      body: params,
    });
  }
}
