import BaseApi, { FetchMethodType } from 'api/BaseApi';

export interface IEmailVerificationApi {
  sendEmailVerificationCode(): void;
  verifyEmail(code: string): void;
}

export default class EmailVerificationRestApi extends BaseApi implements IEmailVerificationApi {
  public sendEmailVerificationCode() {
    return this.fetch('/email-verification', {
      method: FetchMethodType.POST,
    });
  };

  public verifyEmail(code: string) {
    return this.fetch(`/email-verification/${code}`, {
      method: FetchMethodType.PUT,
    });
  };
}
