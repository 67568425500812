import { FC, useEffect, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';

interface TooltipPortalProps {
  tagName?: string;
  children: ReactNode;
  onMount?: (el: HTMLElement) => unknown;
}

const noOp = () => {};

const Portal: FC<TooltipPortalProps> = ({ tagName = 'div', children, onMount = noOp }) => {
  const elementRef = useRef(document.createElement(tagName));

  useEffect(() => {
    const el = elementRef.current;
    document.body.appendChild(el);
    onMount(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);

  return createPortal(children, elementRef.current);
};

export default Portal;
