import clsx from 'clsx';
import { FC, useRef, MouseEvent, WheelEvent, TouchEvent, useState, useCallback, useEffect } from 'react';
import Controls from './Controls';

import styles from './ImageViewer.module.scss';

export const INITIAL_ZOOM = 1;
export const MIN_ZOOM = 0.25;
export const MAX_ZOOM = 5;
const WHEEL_ZOOM_STEP = 0.1;

const DEFAULT_MATRIX_DATA: IMatrixData = {
  zoom: INITIAL_ZOOM,
  transformX: 0,
  transformY: 0,
};

const DEFAULT_DRAG_DATA: IDragData = {
  x: 0,
  y: 0,
  dx: 0,
  dy: 0,
};

const MOVE_CURSOR = 'move';
const DEFAULT_CURSOR = 'default';
const WHEEL_EVENT = 'wheel';

const getTransformStyle = (matrixData: IMatrixData) => {
  const matrix = [matrixData.zoom, 0, 0, matrixData.zoom, matrixData.transformX, matrixData.transformY];

  return `matrix(${matrix.toString()})`;
};

interface IMatrixData {
  zoom: number;
  transformX: number;
  transformY: number;
}

interface IDragData {
  x: number;
  y: number;
  dx: number;
  dy: number;
}

interface IImageViewerProps {
  src: string;
  alt: string;
  className?: string;
  onLoad: () => void;
  onError: () => void;
}

const ImageViewer: FC<IImageViewerProps> = ({ src, alt, className, onLoad, onError }) => {
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [matrixData, setMatrixData] = useState<IMatrixData>(DEFAULT_MATRIX_DATA);
  const [dragData, setDragData] = useState<IDragData>(DEFAULT_DRAG_DATA);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleZoom = (value: number) => {
    setMatrixData((prevState) => ({
      ...prevState,
      zoom: value,
    }));
  };

  const handleReset = () => {
    setMatrixData(DEFAULT_MATRIX_DATA);
  };

  const handlePanStart = (pageX: number, pageY: number, event: MouseEvent<EventTarget> | TouchEvent<EventTarget>) => {
    setIsMouseDown(true);

    setDragData({
      dx: matrixData.transformX,
      dy: matrixData.transformY,
      x: pageX,
      y: pageY,
    });

    if (imageContainerRef.current) {
      imageContainerRef.current.style.cursor = MOVE_CURSOR;
    }

    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
  };

  const handlePanEnd = () => {
    setIsMouseDown(false);

    if (imageContainerRef.current) {
      imageContainerRef.current.style.cursor = DEFAULT_CURSOR;
    }
  };

  const preventEventDefault = useCallback((event: Event) => {
    event.preventDefault();
  }, []);

  const handleMouseEnter = () => {
    document.addEventListener(WHEEL_EVENT, preventEventDefault, { passive: false });
  };

  const handleMouseLeave = () => {
    document.removeEventListener(WHEEL_EVENT, preventEventDefault);
  };

  const updateMousePosition = (pageX: number, pageY: number) => {
    if (!isMouseDown) {
      return;
    }

    const deltaX = dragData.x - pageX;
    const deltaY = dragData.y - pageY;

    const newMatrixData: IMatrixData = {
      ...matrixData,
      transformX: dragData.dx - deltaX,
      transformY: dragData.dy - deltaY,
    };

    setMatrixData(newMatrixData);

    if (imageContainerRef.current) {
      imageContainerRef.current.style.transform = getTransformStyle(newMatrixData);
    }
  };

  const handleWheel = (event: WheelEvent<EventTarget>) => {
    const { zoom } = matrixData;
    const isZoomingIn = event.deltaY < 0;
    const isMaxZoomReached = isZoomingIn && zoom >= MAX_ZOOM - WHEEL_ZOOM_STEP;
    const isMinZoomReached = !isZoomingIn && zoom <= MIN_ZOOM;

    if (isMaxZoomReached || isMinZoomReached) {
      return;
    }

    setMatrixData((prevState) => ({
      ...prevState,
      zoom: isZoomingIn ? zoom + WHEEL_ZOOM_STEP : zoom - WHEEL_ZOOM_STEP,
    }));
  };

  useEffect(() => {
    return () => {
      document.removeEventListener(WHEEL_EVENT, preventEventDefault);
    };
  }, []);

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.imageWrapper}>
        <div
          ref={imageContainerRef}
          style={{
            transform: getTransformStyle(matrixData),
          }}
          onMouseDown={(event) => handlePanStart(event.pageX, event.pageY, event)}
          onMouseUp={handlePanEnd}
          onMouseMove={(event) => updateMousePosition(event.pageX, event.pageY)}
          onWheel={handleWheel}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img src={src} alt={alt} onError={onError} onLoad={onLoad} className={styles.image} />
        </div>
      </div>
      <Controls zoom={matrixData.zoom} onZoom={handleZoom} onReset={handleReset} className={styles.controls} />
    </div>
  );
};

export default ImageViewer;
