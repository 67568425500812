const useSessionStorage = () => {
  return {
    set: (key: string, value: unknown) => {
      const item = typeof value === 'string' ? value : JSON.stringify(value);

      window.sessionStorage.setItem(key, item);
    },
    get: (key: string, useParse?: boolean) => {
      const item = window.sessionStorage.getItem(key);

      if (!item) {
        return null;
      }

      return useParse ? JSON.parse(item) : item;
    },
    remove: (key: string) => {
      window.sessionStorage.removeItem(key);
    },
  };
};

export default useSessionStorage;
