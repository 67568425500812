import { useMemo } from 'react';
import getValidationMessage from 'product_modules/components/InputWithDataType/getValidationMessage';
import { VariableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import { VisualDataType } from 'product_modules/enums/VisualDataType';

const useInputValidators = (
  visualDataType: VisualDataType,
  visualAttributes: VariableVisualAttributes,
  options: { disableCapAttributesValidation?: boolean } = {},
) => {
  return useMemo(() => {
    return [(valueToValidate: string) => getValidationMessage(
      valueToValidate,
      visualDataType,
      visualAttributes,
      options,
    )];
  }, [
    visualDataType,
    visualAttributes,
    options,
  ]);
};

export default useInputValidators;
