import EmptyESignatureElement from './EmptyESignatureElement';

const NoDocumentsToSign = () => {
  return (
    <EmptyESignatureElement
      title="No Documents to Sign"
      description="There are currently no documents that require your review and signature."
    />
  );
};

export default NoDocumentsToSign;
