import { createSelector } from 'reselect';
import { selectTaskEntities } from 'handlers/tasksSlice';
import { ITask } from 'api/digifi/TasksApi';

export const createGetTasksSelector = (taskIds: string[] | null) => {
  return createSelector([selectTaskEntities], (tasksById) => {
    if (!taskIds) {
      return null;
    }

    return taskIds
      .map((id) => tasksById[id])
      .filter((task) => task) as ITask[];
  });
};
