import { FC } from 'react';
import styles from './LegalDocumentPopUp.module.scss';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setSelectedDocument } from 'handlers/settingsSlice';
import ModalOverlay from 'components/common/ModalOverlay';
import formatDate from 'utils/formatDate';
import { DateTimeFormat } from 'product_modules/utils/dateFormat';

interface ILegalDocumentPopUp {
  name: string;
  body: string;
  updatedAt: string;
}

const LegalDocumentPopUp: FC<ILegalDocumentPopUp> = ({ name, body, updatedAt }) => {
  const dispatch = useAppDispatch();

  const handlePopUpClose = () => {
    dispatch(setSelectedDocument(null));
  };

  return (
    <ModalOverlay title={name} onClose={handlePopUpClose} usePortal>
      <div className={styles.documentBodyContainer}>
        <div className={styles.documentBody}>{body}</div>
        <div className={styles.lastModifiedDate}>Last Modified: {formatDate(updatedAt, DateTimeFormat.LongMonthDate)}</div>
      </div>
    </ModalOverlay>
  );
};

export default LegalDocumentPopUp;
