import { FC, ReactNode } from 'react';
import ApplicationsHeader from 'components/ApplicationsList/ApplicationsHeader';
import PortalWithLayer, { PortalLayer } from 'components/PortalWithLayer/PortalWithLayer';

interface IAuthorizedHeaderProps {
  onLoginDetailsClick: () => void;
  onLogoutClick: () => void;
  isLoading?: boolean;
  hideBorrowerDetails?: boolean;
  headerClassName?: string;
  renderApplicationHeader?: () => ReactNode;
  showApplicationHeader?: boolean;
  onHomeButtonClick?: () => void;
}

const AuthorizedHeader: FC<IAuthorizedHeaderProps> = ({
  onLoginDetailsClick,
  onLogoutClick,
  isLoading,
  hideBorrowerDetails,
  headerClassName,
  renderApplicationHeader,
  showApplicationHeader,
  onHomeButtonClick,
}) => {
  return (
    <PortalWithLayer layer={PortalLayer.Bottom}>
      {renderApplicationHeader?.()}
      <ApplicationsHeader
        onLoginDetailsClick={onLoginDetailsClick}
        onLogoutClick={onLogoutClick}
        isLoading={isLoading}
        hideBorrowerDetails={hideBorrowerDetails}
        noShadow={showApplicationHeader}
        className={headerClassName}
        onHomeButtonClick={onHomeButtonClick}
      />
    </PortalWithLayer>
  );
};

export default AuthorizedHeader;
