import { FC } from 'react';
import { DownloadImage, EmptyDocumentsImage } from 'product_modules/static/images';
import Button from 'components/digifi-wrappers/Button';

import styles from './NoPreview.module.scss';

interface INoPreviewProps {
  previewLink: string;
  message?: string;
  downloadAvailable?: boolean;
  onDownload: () => void;
}

const DEFAULT_MESSAGE = 'No preview is available.';

const NoPreview: FC<INoPreviewProps> = ({
  previewLink,
  message = DEFAULT_MESSAGE,
  downloadAvailable,
  onDownload,
}) => {
  return (
    <>
      <EmptyDocumentsImage />
      {<p>{message}</p>}
      {downloadAvailable && (
        <Button
          kind="secondary"
          className={styles.downloadButton}
          onClick={onDownload}
          disabled={!previewLink}
        >
          <DownloadImage className={styles.downloadImage} />
          Download
        </Button>
      )}
    </>
  );
};

export default NoPreview;
