import { FC } from 'react';
import SkeletonRectangle from 'components/digifi-wrappers/SkeletonRectangle';
import { IImageParams } from 'product_modules/api/Types';

interface ISkeletonImageProps {
  imageParams: IImageParams;
}

const SkeletonImage: FC<ISkeletonImageProps> = ({ imageParams }) => {
  return (
    <SkeletonRectangle
      width={`${imageParams.cropRect.width}px`}
      height="auto"
      color="primary10"
      aspectRatio={`${imageParams.cropRect.width} / ${imageParams.cropRect.height}`}
    />
  );
};

export default SkeletonImage;
