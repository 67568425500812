import { FC, useEffect, useState, useMemo, useCallback, FocusEvent } from 'react';
import {
  IButtonPageElement,
  PortalPageElementType,
} from 'api/digifi/portal-page-elements';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getPortalPageElements, generateImageUrls } from 'handlers/portalPageElementsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import PageNoAvailable from 'components/ApplicationPage/PageNoAvailable';
import useAsyncActionCallback from 'product_modules/hooks/useAsyncActionCallback';
import { findApplicationTasks } from 'handlers/tasksSlice';
import ApplicationPage from './ApplicationPage';
import { getAutomationWorkflowById } from 'handlers/automationWorkflowSlice';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { TableValue, VariableValue } from 'product_modules/api/Types';
import { IPortalPageVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import { submitPortalPageInfo } from 'handlers/portal-page-info/Thunks';
import { setApplicationData } from 'handlers/applicationDataSlice';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import { createNotification } from 'handlers/notificationsSlice';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import isTrimmableString from 'utils/isTrimmableString';
import useCurrentApplication from 'hooks/useCurrentApplication';

interface IApplicationPageConnectorProps {
  application: IApplicationViewModel;
  pageNotAvailableClassName?: string;
  className?: string;
}

const ApplicationPageConnector: FC<IApplicationPageConnectorProps> = ({
  application,
  pageNotAvailableClassName,
  className,
}) => {
  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const currentApplication = useCurrentApplication();

  const pageElementsIds = useAppSelector((state) => state.portalPageElements.ids);
  const { entities: elements, isLoaded } = useAppSelector((state) => state.portalPageElements);
  const { offersVariable, isSubmitInProgress } = useAppSelector((state) => state.applicationData);

  const [portalPageFormData, setPortalPageFormData] = useState({});
  const [offersVariableValue, setOffersVariableValue] = useState<TableValue | null>(null);

  const handlePortalPageFieldChange = useCallback((
    _variableConfiguration: IPortalPageVariableConfiguration,
    variable: Variable,
    value: VariableValue,
  ) => {
    setPortalPageFormData((previousPortalPageFormData) => ({
      ...previousPortalPageFormData,
      [variable.systemName]: value,
    }));
  }, []);

  const handlePortalPageFieldBlur = useCallback((
    _variableConfiguration: IPortalPageVariableConfiguration,
    variable: Variable,
    event?: FocusEvent<HTMLInputElement>,
  ) => {
    const { visualDataType } = variable;

    setPortalPageFormData((previousPortalPageFormData) => {
      if (!isTrimmableString(visualDataType)) {
        return previousPortalPageFormData;
      }

      return {
        ...previousPortalPageFormData,
        [variable.systemName]: (event?.target.value as string)?.trim(),
      };
    });
  }, []);

  const imageElementIds = useMemo(() => {
    return Object.values(elements)
      .filter((element) => element && element.elementType === PortalPageElementType.Image && !!element.config.imageParams)
      .map((element) => element!.id);
  }, [elements]);

  const tasksElementExists = useMemo(() => {
    return !!Object.values(elements)
      .find((element) => element?.elementType === PortalPageElementType.Tasks);
  }, [elements]);

  const offersSelectionElement = useMemo(() => {
    return Object.values(elements)
      .find((element) => element && element.elementType === PortalPageElementType.OfferSelection);
  }, [elements]);

  const [isLoading, setIsLoading] = useState(true);

  const loadLayout = async () => {
    setIsLoading(true);

    await dispatchWithUnwrap(getPortalPageElements({ statusId: application!.status.id }));

    setIsLoading(false);
  };

  useEffect(() => {
    loadLayout();
  }, [application.status.id]);

  useEffect(() => {
    setOffersVariableValue((prevState) => {
      if (prevState) {
        return prevState;
      }

      return (
        offersSelectionElement && offersVariable
          ? application.variables[offersVariable] as TableValue
          : null
        );
    });
  }, [offersSelectionElement, isSubmitInProgress, offersVariable]);

  const handleOfferSelect = (updatedOffer: TableValue) => {
    setOffersVariableValue(updatedOffer);
  };

  const [isGenerateImageUrlsInProgress, handleGenerateImageUrls] = useAsyncActionCallback(async (ids: string[]) => {
    if (ids.length) {
      await dispatchWithUnwrap(generateImageUrls({ imageElementIds: ids }));
    }
  }, []);

  const [isFindTasksInProgress, handleFindTasks] = useAsyncActionCallback(async (applicationId: string, hasTaskElement: boolean) => {
    if (hasTaskElement && applicationId) {
      await dispatchWithUnwrap(findApplicationTasks({ applicationId }));
    }
  }, []);

  const [, handleSubmitPageData] = useAsyncActionCallback(async () => {
    try {
      dispatch(setApplicationData({
        previousApplicationStatusId: application.status.id,
        isSubmitInProgress: true,
      }));

      await dispatchWithUnwrap(submitPortalPageInfo({
        data: {
          ...portalPageFormData,
          ...(offersVariable ? { [offersVariable]: offersVariableValue } : {}),
        },
        ...(currentApplication?.id === application.id ? {} : { applicationDisplayId: application.displayId }),
      }));
    } catch (error) {
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });
      dispatch(setApplicationData({
        isSubmitInProgress: false,
      }));
    }
  }, [portalPageFormData, application.status.id, offersVariable, offersVariableValue]);

  useEffect(() => {
    handleGenerateImageUrls(imageElementIds);
  }, [imageElementIds]);

  useEffect(() => {
    handleFindTasks(application.id || '', tasksElementExists);
  }, [application.id, tasksElementExists]);

  useEffect(() => {
    const buttonElement = Object.values(elements).find((element) => {
      return element?.elementType === PortalPageElementType.Button;
    }) as IButtonPageElement | undefined;

    if (buttonElement && buttonElement.config.automationWorkflowId) {
      dispatchWithUnwrap(getAutomationWorkflowById(buttonElement.config.automationWorkflowId));
    }
  }, [elements]);

  if (!isLoaded) {
    return <LoaderOverlay />;
  }

  if (!isLoading && application && pageElementsIds.length === 0) {
    return (
      <PageNoAvailable className={pageNotAvailableClassName} />
    );
  }

  return (
    <ApplicationPage
      className={className}
      pageElementsIds={pageElementsIds as string[]}
      application={application}
      isElementLoadingByType={{
        [PortalPageElementType.Image]: isGenerateImageUrlsInProgress,
        [PortalPageElementType.Tasks]: isFindTasksInProgress,
      } as Record<PortalPageElementType, boolean>}
      portalPageFormData={portalPageFormData}
      onPortalPageFieldChange={handlePortalPageFieldChange}
      onPortalPageFieldBlur={handlePortalPageFieldBlur}
      onSubmitPageData={handleSubmitPageData}
      offersVariableValue={offersSelectionElement ? offersVariableValue : null}
      onOfferSelect={handleOfferSelect}
      offersSelectionElementId={offersSelectionElement?.id || null}
    />
  );
};

export default ApplicationPageConnector;
