import CreateApplicationStep from 'enums/CreateApplicationStep';
import useStepsNavigation from './useStepsNavigation';

const CREATE_APPLICATION_STEPS_DATA_STORAGE_KEY = 'createApplicationStepsData';
const CREATE_APPLICATION_CURRENT_STEP_INDEX_STORAGE_KEY = 'createApplicationCurrentStepIndex';

const useCreateApplicationStepsNavigation = () =>
  useStepsNavigation(
    CreateApplicationStep,
    CREATE_APPLICATION_STEPS_DATA_STORAGE_KEY,
    CREATE_APPLICATION_CURRENT_STEP_INDEX_STORAGE_KEY,
  );

export default useCreateApplicationStepsNavigation;
