import React, { FC, useCallback } from 'react';
import { Grid, GridCellProps } from 'react-virtualized';
import { TableValue } from 'product_modules/api/Types';
import RowActions from 'product_modules/components/RowActions';
import getTableRowActions from 'product_modules/components/TableInput/utils/getTableRowActions';
import getTableHeight from 'product_modules/components/TableInput/utils/getTableHeight';
import styles from './TableInputEditMode.module.scss';

const DEFAULT_TABLE_ROW_HEIGHT = 53;
const TABLE_ROW_ACTIONS_WIDTH = 24;
const TABLE_ROW_ACTIONS_COLUMNS_COUNT = 1;

interface TableRowActionsGridProps {
  value: TableValue;
  onRowInsert: (index: number) => void;
  onRowClear: (rowIndex: number, rowId: string) => void;
  onRowDelete: (rowIndex: number, rowId: string) => void;
  onRowDuplicate: (rowIndex: number, rowId: string) => void;
  onRowHover: (id: string) => void;
  scrollTop: number;
  amountOfRowsVisible: number;
  hoveredRowId?: string;
  disabled?: boolean;
  tableIsMaxLength?: boolean;
  tableHasOneRow?: boolean;
  isExpanded?: boolean;
  isMobile?: boolean;
}

const TableRowActionsGrid: FC<TableRowActionsGridProps> = ({
  value,
  onRowInsert,
  onRowClear,
  onRowDelete,
  onRowDuplicate,
  onRowHover,
  scrollTop,
  hoveredRowId,
  disabled,
  tableIsMaxLength,
  tableHasOneRow,
  isExpanded,
  amountOfRowsVisible,
  isMobile,
}) => {
  const actions = useCallback((rowId: string, rowIndex: number) => getTableRowActions({
      index: rowIndex,
      id: rowId,
      onRowInsert,
      onRowClear,
      onRowDelete,
      onRowDuplicate,
      tableIsMaxLength,
      tableHasOneRow,
    },
  ), [onRowInsert, onRowClear, onRowDelete, onRowDuplicate, tableIsMaxLength, tableHasOneRow]);


  const renderRowActionsCell = (props: GridCellProps) => {
    if (value[props.rowIndex]._id === hoveredRowId) {
      return (
        <RowActions
          style={props.style}
          actions={actions(value[props.rowIndex]._id, props.rowIndex)}
          className={styles.rowActions}
          btnClassName={styles.rowActionsButton}
          key={`${value[props.rowIndex]._id}`}
        />
      );
    }

    return (
      <div
        key={props.key}
        className={styles.rowActionsCellStub}
        style={props.style}
        onMouseEnter={() => onRowHover(value[props.rowIndex]._id)}
      />
    );
  };

  return !disabled ? (
    <Grid
      columnWidth={TABLE_ROW_ACTIONS_WIDTH}
      rowCount={value.length}
      height={getTableHeight(value.length, DEFAULT_TABLE_ROW_HEIGHT, amountOfRowsVisible, isExpanded, isMobile)}
      rowHeight={DEFAULT_TABLE_ROW_HEIGHT}
      cellRenderer={renderRowActionsCell}
      columnCount={TABLE_ROW_ACTIONS_COLUMNS_COUNT}
      width={TABLE_ROW_ACTIONS_WIDTH}
      scrollTop={scrollTop}
      className={styles.tableRowActionsGrid}
    />
  ) : null;
};

export default React.memo(TableRowActionsGrid);
