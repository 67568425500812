import React from 'react';
import LoaderWithState, { LoaderState } from 'components/digifi-wrappers/LoaderWithState';
import { ArchiveImage } from 'static/images';
import getAbbreviatedString from 'product_modules/utils/getAbbreviatedString';
import styles from './ApplicationDocumentsDownloadSnackbar.module.scss';
import { useAppSelector } from 'hooks/reduxHooks';
import { DEFAULT_SNACK_LIFE_DURATION } from 'handlers/snacksSlice';

export interface IApplicationDocumentsDownloadSnackbarProps {
  documentName: string;
  documentDownloadingId: string;
}

const MAX_DOCUMENT_NAME_LENGTH = 35;

const ApplicationDocumentsDownloadSnackbar = ({
  documentName,
  documentDownloadingId,
}: IApplicationDocumentsDownloadSnackbarProps) => {
  const documentDownloadingState = useAppSelector(
    (state) => state.applicationDocuments.documentDownloadingState[documentDownloadingId],
  );

  return (
    <div className={styles.documentContainer}>
      <div className={styles.documentIcon}>
        <ArchiveImage />
      </div>
      <div className={styles.documentName}>
        {getAbbreviatedString(`${documentName}.zip`, MAX_DOCUMENT_NAME_LENGTH)}
      </div>
      <LoaderWithState
        state={
          documentDownloadingState === 'loading' ? LoaderState.Updating : (documentDownloadingState as LoaderState)
        }
        className={styles.loader}
        successTimeout={DEFAULT_SNACK_LIFE_DURATION}
        failureTimeout={DEFAULT_SNACK_LIFE_DURATION}
      />
    </div>
  );
};

export default ApplicationDocumentsDownloadSnackbar;
