export enum AppRoute {
  Home = '/',
  SignIn = '/sign-in',
  CreateAccount = '/create-account',
  AcceptInvite = '/accept-invite',
  PhoneVerification = '/phone-verification',
  ResetPassword = '/reset-password',
  ConfirmEmail = '/confirm-email',
  ResumeProcess = '/applications',
  DocumentPreview = '/document-preview',
  Application = '/applications',
  Forbidden = '/forbidden',
  AdminPasswordRequired = '/password-required',
  Apply = '/apply',
  Start = '/start',
}
