import React, { FocusEvent, useMemo } from 'react';
import clsx from 'clsx';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import { IBaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import { FormSkeleton } from 'product_modules/components/Skeleton';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';
import ConfigurableFormVariable from './ConfigurableFormVariable';
import useVariablesBySystemNames from 'product_modules/loaders/Variables/hooks/useVariablesBySystemNames';
import { IAddressInputClassNames, ITableInputClassNames } from 'product_modules/types/InputClassNamesTypes';
import styles from './ConfigurableForm.module.scss';

interface IConfigurableFormProps<
  FieldType extends IBaseVariableConfiguration,
  CalculationType extends BaseCalculation,
> {
  className?: string;
  fields: Array<FieldType> | null;
  data: FormLayoutData;
  formIndex?: number;
  inputContainerClassname?: string;
  onFieldChange: (field: FieldType, variable: Variable, value: VariableValue) => void;
  onFieldFocus?: (field: FieldType, variable: Variable, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (field: FieldType, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderStateById?: Record<string, LoaderState | undefined | null>;
  onLoaderStateReset?: (variableConfiguration: FieldType) => void;
  formatDisplayTitle?: (title: string) => string;
  displayFieldsAttributes?: Record<string, boolean | undefined>;
  calculations?: Map<string, CalculationType> | null;
  country?: string;
  inputClassNames?: {
    address?: IAddressInputClassNames;
    table?: ITableInputClassNames;
  };
  inView?: boolean;
  renderFieldsOnlyInView?: boolean;
  labelTooltipClassName?: string;
}

const DEFAULT_FORM_INDEX = 0;
const VARIABLES_TAB_INDEX_OFFSET = 50;
const DEFAULT_ITEM_HEIGHT = 100;

const ConfigurableForm = <
  FieldType extends IBaseVariableConfiguration,
  CalculationType extends BaseCalculation
>({
  className,
  fields,
  onFieldChange,
  onFieldFocus,
  onFieldBlur,
  data,
  inputContainerClassname,
  formatDisplayTitle,
  showLoader,
  onLoaderStateReset,
  loaderStateById,
  displayFieldsAttributes,
  calculations,
  formIndex = DEFAULT_FORM_INDEX,
  country,
  inputClassNames,
  renderFieldsOnlyInView,
  inView,
  labelTooltipClassName,
}: IConfigurableFormProps<FieldType, CalculationType>) => {
  const usedVariablesSystemNames = useMemo(() => {
    return fields?.map((configuration) => configuration.variable);
  }, [fields]);

  const variables = useVariablesBySystemNames(usedVariablesSystemNames);

  if (!fields || !variables) {
    return <FormSkeleton className={styles.formSkeleton} fieldsCount={fields?.length} />;
  }

  const renderLayoutItem = (variableConfiguration: FieldType, index: number) => {
    const variable = variables[variableConfiguration.variable];

    if (!variable) {
      return null;
    }

    const visible = !displayFieldsAttributes
      || displayFieldsAttributes[variableConfiguration.id]
      || !isEmptyVariableValue(data[variable.systemName]);

    const isCalculatedVariable = calculations?.has(variable.systemName);

    return (
      <ConfigurableFormVariable<FieldType>
        className={inputContainerClassname}
        key={variableConfiguration.id}
        field={variableConfiguration}
        variable={variable}
        value={data[variable.systemName]}
        showLoader={showLoader}
        loaderState={loaderStateById && loaderStateById[variableConfiguration.id]}
        required={variableConfiguration.required}
        tabIndex={(index + 1) + formIndex * VARIABLES_TAB_INDEX_OFFSET}
        formatDisplayTitle={formatDisplayTitle}
        onChange={onFieldChange}
        onBlur={onFieldBlur}
        onFocus={onFieldFocus}
        onLoaderStateReset={onLoaderStateReset}
        visible={visible}
        isCalculated={isCalculatedVariable}
        country={country}
        classNames={inputClassNames}
        labelTooltipClassName={labelTooltipClassName}
      />
    );
  };

  const configurationFormStyle = {
    gridTemplateColumns: `repeat(${fields.length}, minmax(0, 1fr))`,
    height: !inView ? `${fields.length * DEFAULT_ITEM_HEIGHT}px` : undefined,
  };

  return (
    <div style={configurationFormStyle} className={clsx(styles.configurableFormContainer, className)}>
      {(!renderFieldsOnlyInView || inView) && fields.map(renderLayoutItem)}
    </div>
  );
};

export default ConfigurableForm;
