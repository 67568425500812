import { ReactNode } from 'react';
import OptionIconStyledContainer from './OptionIconStyledContainer';
import styles from './OptionIcon.module.scss';

interface IOptionIconProps {
  isSelected?: boolean;
  icon: ReactNode;
}

const OptionIcon = ({
  icon,
  isSelected,
}: IOptionIconProps) => {
  return (
    <OptionIconStyledContainer className={styles.iconContainer} isSelected={isSelected}>
      {icon}
    </OptionIconStyledContainer>
  );
};

export default OptionIcon;
