import { FC, FocusEvent } from 'react';
import clsx from 'clsx';
import CardsForm from 'components/digifi-wrappers/CardsForm';
import { VariableValue } from 'product_modules/api/Types';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { IPortalPageVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import Header from 'product_modules/components/Header';
import { ProductCalculation } from 'product_modules/api/LoanOriginationSystem/ProductCalculationsApi';
import styles from './DataInputElement.module.scss';

interface IDataInputElementProps {
  referenceKey: string;
  portalPageFormData: Record<string, VariableValue>;
  onPortalPageFieldChange: (field: IPortalPageVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onPortalPageFieldBlur: (field: IPortalPageVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  storageLayoutEntitiesPaths: {
    groups: string;
    cards: string;
    variablesConfigurations: string;
  };
  productCalculations: Map<string, ProductCalculation> | null;
  containerClassName?: string;
  disabled?: boolean;
  displayFieldsAttributes: Record<string, boolean>;
}

const DataInputElement: FC<IDataInputElementProps> = ({
  referenceKey,
  portalPageFormData,
  onPortalPageFieldChange,
  onPortalPageFieldBlur,
  storageLayoutEntitiesPaths,
  containerClassName,
  disabled,
  productCalculations,
  displayFieldsAttributes,
}) => {

  const renderEmptyFormComponent = () => {
    return (
      <div className={clsx(styles.container, containerClassName)}>
        <Header type='h5' className={styles.text}>Data Input</Header>
      </div>
    );
  };

  return (
    <div className={clsx(containerClassName, disabled && styles.containerDisabled)}>
      <CardsForm
        referenceKey={referenceKey}
        storageLayoutEntitiesPaths={storageLayoutEntitiesPaths}
        onFieldChange={onPortalPageFieldChange}
        onFieldBlur={onPortalPageFieldBlur}
        data={portalPageFormData}
        isEditMode
        displayHeader={false}
        emptyFormComponent={renderEmptyFormComponent()}
        calculations={productCalculations}
        displayFieldsAttributes={displayFieldsAttributes}
      />
    </div>
  );
};

export default DataInputElement;
