import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { BorrowerVariable, BorrowerVariableType } from 'enums/BorrowerVariable';
import { FormLayoutData } from 'product_modules/api/LoanOriginationSystem/Types';

const DEFAULT_COBORROWER_INDEX = 0;
const BORROWER_VARIABLE_PREFIX = 'borrower';
const COBORROWER_VARIABLE_PREFIX = 'coborrower';

export const convertBorrowerVariableToCoBorrower = (borrowerVariable: string, coBorrowerIndex: number = DEFAULT_COBORROWER_INDEX) => {
  return borrowerVariable.replace(
    BORROWER_VARIABLE_PREFIX,
    coBorrowerIndex === 0 ? COBORROWER_VARIABLE_PREFIX : `${COBORROWER_VARIABLE_PREFIX}_${coBorrowerIndex + 1}`,
  );
};

export const getBorrowerFullName = (variables: FormLayoutData, type: BorrowerType): string => {
  if (!variables || !Object.keys(variables).length) {
    return '';
  }

  return Object.values(getBorrowerName(variables, type)).join(' ');
};

export const getCoBorrowerFullName = (variables: FormLayoutData, type: BorrowerType, coborrowerIndex = 0): string => {
  if (!variables || Object.keys(variables).length === 0) {
    return '';
  }

  const coborrowerVariables = Object.keys(BorrowerVariable as BorrowerVariableType).reduce((result, key) => {
    const keyTyped = key as keyof BorrowerVariableType;

    result[keyTyped] = convertBorrowerVariableToCoBorrower(BorrowerVariable[keyTyped], coborrowerIndex);

    return result;
  }, {} as BorrowerVariableType);

  return Object.values(getBorrowerName(variables, type, coborrowerVariables)).join(' ');
};

const getBorrowerName = (
  variables: FormLayoutData,
  type: BorrowerType,
  borrowerVariables: BorrowerVariableType = BorrowerVariable,
  excludeLastName = false,
) => {
  return type === BorrowerType.Person
    ? {
      firstName: variables[borrowerVariables.FirstName] as string,
      ...(!excludeLastName ? { lastName: variables[borrowerVariables.LastName] as string } : {}),
    }
    : {
      companyName: variables[borrowerVariables.CompanyName] as string,
    };
};

export const getCoborrowerFullNames = (variables: FormLayoutData, types: BorrowerType[]) => {
  return types
    .map((borrowerType, index) => {
      return getCoBorrowerFullName(variables, borrowerType, index);
    })
    .filter((fullName) => fullName)
    .join(', ');
};

export const getBorrowerFirstName = (variables: FormLayoutData, type: BorrowerType) => {
  if (!variables || !Object.keys(variables).length) {
    return '';
  }

  return Object.values(getBorrowerName(variables, type, BorrowerVariable, true)).join(' ');
};
