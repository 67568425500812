import { ApplicationStatusType, IApplicationStatus } from 'api/digifi/ApplicationsApi';
import { TagColor } from 'product_modules/components/Tag/Tag';

const getApplicationStatusTagColor = (status: IApplicationStatus): TagColor => {
  switch (status.type) {
    case ApplicationStatusType.Approve: {
      return 'green';
    }
    case ApplicationStatusType.Reject: {
      return 'red';
    }
    default: {
      return 'blue';
    }
  }
};

export default getApplicationStatusTagColor;
