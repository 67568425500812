import React, { FC, FocusEvent, ReactNode } from 'react';
import TextInput from 'product_modules/components/TextInput/TextInput';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import NumberFormat from 'react-number-format';

interface NumberInputProps {
  value: string;
  labelTitle?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onBlur?: (value: string, event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  containerClassName?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  loaderClassName?: string;
  inputIcon?: React.ReactNode;
  inputPrefix?: React.ReactNode;
  raw?: boolean;
  errorMessage?: string;
  required?: boolean;
  tooltip?: string;
  hasLeftPadding?: boolean;
  decimalScale?: number;
  renderOverlay?: () => ReactNode;
  className?: string;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  inputRef?: React.Ref<HTMLInputElement>;
  titleHint?: string;
  labelTooltipClassName?: string;
}

const isAllowedValue = (value: number | undefined) => {
  if (value === undefined) {
    return true;
  }

  return value >= Number.MIN_SAFE_INTEGER && value <= Number.MAX_SAFE_INTEGER;
};

const NumberInput: FC<NumberInputProps> = ({
  value,
  labelTitle,
  onChange,
  onFocus,
  placeholder,
  onBlur,
  containerClassName,
  readOnly,
  disabled,
  maxLength,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  loaderClassName,
  inputIcon,
  inputPrefix,
  raw = false,
  required,
  tooltip,
  errorMessage,
  hasLeftPadding,
  decimalScale,
  renderOverlay,
  className,
  useSimplifiedInput,
  style,
  inputRef,
  titleHint,
  labelTooltipClassName,
}) => {
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => onBlur?.(event.target.value, event);

  if (raw) {
    return (
      <TextInput
        value={value}
        labelTitle={labelTitle}
        hasRightNeighbour
        hasLeftPadding={hasLeftPadding}
        placeholder={placeholder}
        onBlur={handleBlur}
        onFocus={onFocus}
        containerClassName={containerClassName}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled}
        showLoader={showLoader}
        loaderState={loaderState}
        onLoaderStateReset={onLoaderStateReset}
        tabIndex={tabIndex}
        loaderClassName={loaderClassName}
        inputIcon={inputIcon}
        inputPrefix={inputPrefix}
        required={required}
        errorMessage={errorMessage}
        tooltip={tooltip}
        className={className}
        useSimplifiedInput={useSimplifiedInput}
        style={style}
        inputRef={inputRef}
        titleHint={titleHint}
        labelTooltipClassName={labelTooltipClassName}
      />
    );
  }

  return (
    <NumberFormat
      isAllowed={({ floatValue }) => isAllowedValue(floatValue)}
      value={value}
      isNumericString
      thousandSeparator
      onValueChange={(values) => onChange(values.value)}
      customInput={TextInput}
      onBlur={handleBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
      tabIndex={tabIndex}
      required={required}
      labelTitle={labelTitle}
      hasRightNeighbour
      hasLeftPadding={hasLeftPadding}
      containerClassName={containerClassName}
      maxLength={maxLength}
      showLoader={showLoader}
      loaderState={loaderState}
      onLoaderStateReset={onLoaderStateReset}
      loaderClassName={loaderClassName}
      inputIcon={inputIcon}
      inputPrefix={inputPrefix}
      errorMessage={errorMessage}
      tooltip={tooltip}
      decimalScale={decimalScale}
      renderOverlay={renderOverlay}
      className={className}
      useSimplifiedInput={useSimplifiedInput}
      style={style}
      inputRef={inputRef}
      titleHint={titleHint}
      labelTooltipClassName={labelTooltipClassName}
    />
  );
};

export default NumberInput;
