import { useState, useMemo } from 'react';
import generateObjectHash from 'object-hash';

type SetHashData<Data> = (previousData: Data) => Data;

const useDataByHashKey = <Data>(dataForHashKey: {} | null): [Data, (data: Data | SetHashData<Data>) => void] => {
  const [dataByHashKey, setDataByHashKey] = useState<Record<string, Data>>({});

  const hashKey = useMemo(() => {
    return generateObjectHash(dataForHashKey);
  }, [dataForHashKey]);

  return [
    dataByHashKey[hashKey],
    (dataOrSetData: SetHashData<Data> | Data) => {
      setDataByHashKey((previousDataByHashKey) => {
        const data = typeof dataOrSetData === 'function'
          ? (dataOrSetData as SetHashData<Data>)(previousDataByHashKey[hashKey])
          : dataOrSetData;

        return previousDataByHashKey[hashKey] === data
          ? previousDataByHashKey
          : { ...previousDataByHashKey, [hashKey]: data };
      });
    },
  ];
};

export default useDataByHashKey;
