import { formatPhoneNumber } from 'product_modules/components/PhoneInput';
import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import ValueFormattingError from 'product_modules/utils/valueFormatters/base/ValueFormattingError';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';
import { StringVisualDataType } from 'product_modules/enums/VisualDataType';

const formatValue = (value: string) => {
  try {
    return formatPhoneNumber(value);
  } catch {
    return value;
  }
};

const isPhoneNumberValue = getValueVisualTypeCorrespondenceChecker(StringVisualDataType.PhoneNumber);

const formatPhoneNumberValue: ValueFormatter = (value) => {
  if (!isPhoneNumberValue(value)) {
    throw new ValueFormattingError();
  }

  const formattedValue = formatValue(value);
  return formattedValue || value;
}

export default formatPhoneNumberValue;
