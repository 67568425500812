import ProductCalculationsRestApi from 'api/digifi/ProductCalculationsApi';
import GooglePlacesRestApi from 'api/digifi/GooglePlacesApi';
import VariablesRestApi from 'api/digifi/VariablesApi';
import SettingsRestApi from 'api/digifi/SettingsApi';
import LayoutConfigurationRestApi from 'api/digifi/layout/LayoutConfigurationApi';
import ApplicationsRestApi from 'api/digifi/ApplicationsApi';
import AdminAuthApi from 'api/digifi/AdminAuthApi';
import PortalPageElementsRestApi from 'api/digifi/PortalPageElementsApi';
import TasksRestApi from 'api/digifi/TasksApi';
import AutomationWorkflowApi from 'api/digifi/AutomationWorkflowApi';
import EnvironmentApi from 'api/EnvironmentApi';
import TasksDetailsRestApi from 'api/digifi/TaskDetailsApi';
import PortalPageInfoRestApi from 'api/digifi/PortalPageInfoApi';
import BorrowerTypesApi from 'api/digifi/BorrowerTypesApi';
import DocuSignRestApi from 'api/digifi/DocuSignApi';
import GoogleAnalyticsRestApi from 'api/digifi/GoogleAnalyticsApi';

export const productCalculationsApi = new ProductCalculationsRestApi();
export const googlePlacesApi = new GooglePlacesRestApi();
export const variablesApi = new VariablesRestApi();
export const settingsApi = new SettingsRestApi();
export const layoutConfigurationApi = new LayoutConfigurationRestApi();
export const adminAuthApi = new AdminAuthApi();
export const applicationsApi = new ApplicationsRestApi();
export const portalPageElementsApi = new PortalPageElementsRestApi();
export const tasksApi = new TasksRestApi();
export const automationWorkflowApi = new AutomationWorkflowApi();
export const environmentApi = new EnvironmentApi();
export const taskDetailsApi = new TasksDetailsRestApi();
export const portalPageInfoApi = new PortalPageInfoRestApi();
export const borrowerTypesApi = new BorrowerTypesApi();
export const docuSignApi = new DocuSignRestApi();
export const googleAnalyticsApi = new GoogleAnalyticsRestApi();
