import { FC } from 'react';
import clsx from 'clsx';
import { ITasksElementConfig } from 'api/digifi/portal-page-elements';
import SkeletonTasksElement from './SkeletonTasksElement';
import NoTasks from './NoTasks';
import TasksGrid from './TasksGrid';
import styles from './TasksElement.module.scss';

interface ITasksElementProps {
  taskIds: string[] | null;
  config: ITasksElementConfig;
  containerClassName?: string;
  disabled?: boolean;
}

const TasksElement: FC<ITasksElementProps> = ({
  taskIds,
  containerClassName,
  disabled,
}) => {
  const renderContent = () => {
    if (!taskIds) {
      return <SkeletonTasksElement />;
    }

    if (!taskIds.length) {
      return <NoTasks />;
    }

    return <TasksGrid taskIds={taskIds} />;
  };

  return (
    <div className={clsx(containerClassName, disabled && styles.disabled)}>
      {renderContent()}
    </div>
  );
};

export default TasksElement;
