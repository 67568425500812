enum OnboardingStep {
  BorrowerType = 'borrowerType',
  Product = 'product',
  Coborrowers = 'coborrowers',
  Borrower = 'borrower',
  CoBorrower = 'coBorrower',
  CoBorrower2 = 'coBorrower2',
  CoBorrower3 = 'coBorrower3',
  Submit = 'submit',
}

export default OnboardingStep;
