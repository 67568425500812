import { useAppSelector } from './reduxHooks';

const useCurrentApplication = () => {
  const currentApplicationId = useAppSelector(
    (state) => state.activeBorrowerInformation.currentApplication,
  );

  return useAppSelector(
    (state) => currentApplicationId ? state.applications.entities[currentApplicationId] : null,
  );
};

export default useCurrentApplication;
