import BaseApi from 'api/BaseApi';

export enum TaskStatus {
  NotDone = 'Not Done',
  InProgress = 'In Progress',
  InReview = 'In Review',
  Done = 'Done',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
}

export interface ITask {
  id: string;
  status: TaskStatus;
  title: string;
  applicationId: string;
  instructions?: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface IFindTaskParams {
  applicationId: string;
}

export interface ITasksApi {
  find(params: IFindTaskParams): Promise<ITask[]>;
}

export default class TasksRestApi extends BaseApi implements ITasksApi {
  public find(params: IFindTaskParams): Promise<ITask[]> {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.set('applicationId', params.applicationId);

    return this.fetch(`/tasks?${urlSearchParams}`);
  }
}
