import { createContext, useContext } from 'react';

const CountryProviderContext = createContext<string | null | undefined>(undefined);

export const useCountry = () => {
  return useContext(CountryProviderContext);
}

const CountryProvider = CountryProviderContext.Provider;

export default CountryProvider;
