import { ILegalDocument } from 'api/digifi/SettingsApi';
import FooterContent from 'components/PageLayout/Footer/FooterContent/FooterContent';
import { setSelectedDocument } from 'handlers/settingsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

const FooterContentConnector = () => {
  const currentYear = new Date().getFullYear();
  const { legalDocuments } = useAppSelector((state) => state.settings);
  const { companyName } = useAppSelector(state => state.settings.branding);
  const footerDocuments = legalDocuments.filter(document => document.showInFooter);
  const dispatch = useAppDispatch();

  const handleDocumentClick = (selectedDocument: ILegalDocument) => {
    dispatch(setSelectedDocument(selectedDocument));
    window.scrollTo(0, 0);
  };

  return (
    <FooterContent
      companyName={companyName}
      currentYear={currentYear}
      footerDocuments={footerDocuments}
      onOpenLegalDocument={handleDocumentClick}
    />
  );
};

export default FooterContentConnector;
