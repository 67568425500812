import React, { FC } from 'react';
import { EntitiesLoaderProps } from './createEntitiesLoaderProvider';

export interface LoadersProviderProps {
  loaders: Array<{ debounceTimeout?: number; component: React.ComponentType<EntitiesLoaderProps> }>;
}

const LoadersProvider: FC<LoadersProviderProps> = ({ children, loaders }) => {
  return loaders.reduce((previousProvider, loader) => {
    const { component, debounceTimeout } = loader;
    const LoaderComponent = component;

    return (
      <LoaderComponent debounceTimeout={debounceTimeout}>
        {previousProvider}
      </LoaderComponent>
    );
  }, <>{children}</>);
};

export default LoadersProvider;
