import clsx from 'clsx';
import React, { FC, DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import styles from './TopRightButton.module.scss';

export type HTMLButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const TopRightButton: FC<HTMLButtonProps> = ({ className, ...props }) => {
  return <button type="button" className={clsx(styles.navButton, className)} {...props} />;
};

export default TopRightButton;
