import React, { FC } from 'react';
import Tag from 'components/digifi-wrappers/Tag';

interface IVerificationStatusTagProps {
  verified: boolean;
  className?: string;
}

const VerificationStatusTag: FC<IVerificationStatusTagProps> = ({ verified, className }) => {
  return (
    <Tag className={className} invertColors={verified} color={verified ? 'green' : 'gray'}>
      {verified ? 'verified' : 'not verified'}
    </Tag>
  );
};

export default VerificationStatusTag;
