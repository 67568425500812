const maskUSSocialIdentificationNumber = (value: string): string =>
  `${value.substring(0, 3)}${value.length > 3 ? '-' : ''}${value.substring(3, 5)}${
    value.length > 5 ? '-' : ''
  }${value.substring(5, 9)}`;

const maskUSEmployerIdentificationNumber = (value: string): string =>
  `${value.substring(0, 2)}${value.length > 2 ? '-' : ''}${value.substring(2, 9)}`;

const maskCanadaSocialIdentificationNumber = (value: string): string =>
  `${value.substring(0, 3)}${value.length > 3 ? '-' : ''}${value.substring(3, 6)}${
    value.length > 6 ? '-' : ''
  }${value.substring(6, 9)}`;

const IDENTIFICATION_NUMBERS_MASKS_MAPPING = {
  USSocial: maskUSSocialIdentificationNumber,
  USEmployer: maskUSEmployerIdentificationNumber,
  CanadaSocial: maskCanadaSocialIdentificationNumber,
};

const getIdentificationNumberMask = (INType: string) => {
  // @ts-ignore
  if (IDENTIFICATION_NUMBERS_MASKS_MAPPING[INType]) {
    // @ts-ignore
    return IDENTIFICATION_NUMBERS_MASKS_MAPPING[INType];
  }
  return (value: string) => value;
};

export default getIdentificationNumberMask;
