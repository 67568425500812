import React, { useState, useLayoutEffect, ChangeEvent, useRef, RefObject, KeyboardEvent } from 'react';

const isNonNumericChar = (char: string) => /\D/.test(char);
const removeNonNumericChars = (value: string) => value.replace(/\D/g, '');

const BACKSPACE_KEY_CODE = 8;
const DELETE_KEY_CODE = 46;

const useMask = (
  value: string,
  handleChange: (value: string) => void,
  maskValue: (value: string) => string,
  unmaskValue: (value: string) => string = removeNonNumericChars,
  isPartOfMask: (char: string) => boolean = isNonNumericChar,
): [
  string,
  RefObject<HTMLInputElement>,
  (event: ChangeEvent<HTMLInputElement>) => void,
  (event: KeyboardEvent<HTMLInputElement>) => void,
  (event: KeyboardEvent<HTMLInputElement>) => void,
  (event: React.MouseEvent<HTMLInputElement>) => void,
] => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [caretPosition, setCaretPosition] = useState<[number, number]>([0, 0]);
  const [eraseDirection, setEraseDirection] = useState<number>(0); // -1 Backspace, 1 Del

  useLayoutEffect(() => {
    if (caretPosition !== null && inputRef.current && document.activeElement === inputRef.current) {
      inputRef.current.setSelectionRange(caretPosition[0]!, caretPosition[1]!);
    }
  });

  const maskedValue = maskValue(value);

  const handleKeyDown = ({ keyCode }: KeyboardEvent<HTMLInputElement>) => {
    switch (keyCode) {
      case BACKSPACE_KEY_CODE: {
        setEraseDirection(-1);
        break;
      }
      case DELETE_KEY_CODE: {
        setEraseDirection(1);
        break;
      }
      default: {
        setEraseDirection(0);
      }
    }
  };

  const syncCaretPosition = () => {
    const { selectionStart, selectionEnd } = inputRef.current!;
    setCaretPosition([selectionStart || 0, selectionEnd || 0]);
  };

  const handleKeyUp = ({ keyCode }: KeyboardEvent<HTMLInputElement>) => {
    if (keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE) {
      syncCaretPosition();
    }
  };
  const handleClick = () => syncCaretPosition();

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value === '') {
      setCaretPosition([0, 0]);
      handleChange('');
      return;
    }

    // delete mask symbol 12|,345 -> DEL -> 12,|345
    const nextMaskedValue = maskValue(unmaskValue(target.value));
    if (maskedValue.length === nextMaskedValue.length) {
      const caretPositionToSet = caretPosition ? caretPosition[0] + eraseDirection : 0;
      setCaretPosition([caretPositionToSet, caretPositionToSet]);
      return;
    }

    const erasedLength = maskedValue.length - target.value.length;
    if (erasedLength > 0) {
      const partOfMask = isPartOfMask(maskedValue[target.selectionEnd!]);
      const nextCaretPosition = (target.selectionEnd || 0) + getCaretOffset(maskedValue.length, nextMaskedValue.length);
      const positionToSet = nextCaretPosition && nextCaretPosition >= 0 ? nextCaretPosition : 0;
      if (!partOfMask) {
        handleChange(unmaskValue(nextMaskedValue));
      }
      setCaretPosition([positionToSet, positionToSet]);
    } else {
      const addedCharsCount = nextMaskedValue.length - maskedValue.length;

      let offset = target.selectionEnd! + (addedCharsCount && addedCharsCount - 1);
      while (offset < nextMaskedValue.length && isPartOfMask(nextMaskedValue[offset - 1])) {
        offset++; // eslint-disable-line no-plusplus
      }
      setCaretPosition([offset, offset]);
      handleChange(unmaskValue(nextMaskedValue));
    }
  };

  /**
   * Case when: 1,234,456 -> 123,445 (6 removed + mask symbol)
   * @param beforeErase
   * @param afterErase
   */
  const getCaretOffset = (beforeErase: number, afterErase: number) => {
    const erased = beforeErase - afterErase;
    if (erased > 1 && caretPosition[0] > 0) {
      return -1;
    }

    return 0;
  };

  return [maskedValue, inputRef, handleInputChange, handleKeyDown, handleKeyUp, handleClick];
};

export default useMask;
