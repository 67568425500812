import {
  PhoneNumberVisualAttributes,
  VariableVisualAttributes,
} from 'product_modules/api/Core/VariablesApi';
import { convertStringToBoolean, convertStringToNumber } from 'product_modules/utils/coerceUtils';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js';
import { BooleanVisualDataType, NumericVisualDataType, StringVisualDataType, VisualDataType } from 'product_modules/enums/VisualDataType';

const convertToPercentageValue = (value: string): number => {
  const parsedValue = convertStringToNumber(value);
  return parsedValue === null ? 0 : parsedValue / 100;
};

const getParsedPhoneNumber = (value: string, country: CountryCode) => {
  try {
    return parsePhoneNumber(value, country);
  } catch {
    return null;
  }
}

const convertToPhoneNumberValue = (value: string, { phoneNumberFormat }: PhoneNumberVisualAttributes): string => {
  if (isPossiblePhoneNumber(value)) {
    return value;
  }

  const parsedNumber = getParsedPhoneNumber(value, phoneNumberFormat);
  return parsedNumber?.number || value;
};

const convertInputValueByVisualDataType = <Type extends VisualDataType>(
  value: string,
  visualDataType: Type,
  attributes: VariableVisualAttributes,
) => {
  switch (visualDataType) {
    case NumericVisualDataType.Percentage:
      return convertToPercentageValue(value);
    case StringVisualDataType.PhoneNumber:
      return convertToPhoneNumberValue(value, attributes as PhoneNumberVisualAttributes);
    case NumericVisualDataType.Number:
    case NumericVisualDataType.Monetary:
      return convertStringToNumber(value);
    case BooleanVisualDataType.Boolean:
      return convertStringToBoolean(value);
    default:
      return value;
  }
};

export default convertInputValueByVisualDataType;
