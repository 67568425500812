import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { noop } from 'lodash';
import { HTMLButtonProps } from 'product_modules/components/Button/Button';
import WrapperWithHoverableTooltip from 'product_modules/components/WrapperWithHoverableTooltip';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import styles from './LinkButton.module.scss';

export interface LinkButtonProps extends HTMLButtonProps {
  className?: string;
  tooltip?: React.ReactNode;
  hoverableTooltip?: boolean;
  tag?: 'button' | 'span';
}

const LinkButton = ({
  className,
  children,
  disabled,
  tooltip,
  onClick,
  hoverableTooltip,
  tag = 'button',
  ...restProps
}: PropsWithChildren<LinkButtonProps>) => {
  const wrapWithTooltip = (content: React.ReactElement) => {
    if (hoverableTooltip) {
      return (
        <WrapperWithHoverableTooltip tooltip={tooltip}>
          {content}
        </WrapperWithHoverableTooltip>
      );
    }

    return (
      <WrapperWithTooltip tooltip={tooltip}>
        {content}
      </WrapperWithTooltip>
    );
  }

  const TagComponent = tag;

  return (
    wrapWithTooltip(
      <TagComponent
        type="button"
        className={clsx(styles.linkButton, disabled && styles.disabled, className)}
        onClick={disabled ? noop : onClick}
        {...restProps}
      >
        {children}
      </TagComponent>
    )
  );
};

export default LinkButton;
