import { FC, Fragment } from 'react';
import { HandleKeyDown } from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import KeyEventEnum from 'product_modules/common/KeyEventEnum';
import { ILegalDocument } from 'api/digifi/SettingsApi';
import LinkButton from 'components/digifi-wrappers/LinkButton';
import Checkbox from 'components/digifi-wrappers/Checkbox/Checkbox';

interface ILegalConsentsCheckboxProps {
  legalDocuments: ILegalDocument[];
  onLegalDocumentClick: (legalDocument: ILegalDocument) => void;
  onCheck: () => void;
  isChecked: boolean;
  className?: string;
}

const LegalConsentsCheckbox: FC<ILegalConsentsCheckboxProps> = ({
  legalDocuments,
  onLegalDocumentClick,
  onCheck,
  isChecked,
  className,
}) => {
  const renderCheckboxLabel = () => {
    return (
      <>By checking this box I confirm that I have read, understood, and consent to
        {' '}{renderLegalDocuments()}.
      </>
    );
  };

  const renderLegalDocuments = () => {
    return legalDocuments.map((document, index) => (
      <Fragment key={`document.name_${index}`}>
        <LinkButton onClick={() => onLegalDocumentClick(document)}>
          {document.name}{index <= legalDocuments.length - 2 && ','}
        </LinkButton>
        {index === legalDocuments.length - 1 ? '' : ' '}
      </Fragment>
    ));
  };

  return (
    <div className={className}>
      <Checkbox
        label={renderCheckboxLabel()}
        checked={isChecked}
        onChange={onCheck}
        onKeyDown={HandleKeyDown(KeyEventEnum.Enter, onCheck)}
      />
    </div>
  );
};

export default LegalConsentsCheckbox;
