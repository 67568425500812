import BaseApi from 'api/BaseApi';
import { GeocodeResult, Suggestions } from 'use-places-autocomplete';

export interface IGooglePlacesApi {
  getSuggestions(inputValue: string, country: string): Promise<Suggestions>;
  getGeocode(placeId: string): Promise<GeocodeResult[]>;
}

export default class GooglePlacesRestApi extends BaseApi implements IGooglePlacesApi {
  public getSuggestions(inputValue: string, country: string): Promise<Suggestions> {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('inputValue', inputValue);
    urlSearchParams.append('country', country);

    return this.fetch(`/places?${urlSearchParams}`);
  }

  public getGeocode(placeId: string): Promise<GeocodeResult[]> {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('placeId', placeId);

    return this.fetch(`/places/geocode?${urlSearchParams}`);
  }
}
