export const sortStrings = (fieldA: string, fieldB: string, ascending: boolean) =>
  ascending ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);

export const sortDates = (fieldA: Date, fieldB: Date, ascending: boolean) =>
  ascending
    ? new Date(fieldA).getTime() - new Date(fieldB).getTime()
    : new Date(fieldB).getTime() - new Date(fieldA).getTime();

export const sortNumbers = (fieldA: number, fieldB: number, ascending: boolean) =>
  ascending ? fieldA - fieldB : fieldB - fieldA;
