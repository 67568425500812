import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import { capitalize } from 'lodash';
import ValueFormattingError from 'product_modules/utils/valueFormatters/base/ValueFormattingError';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';
import { BooleanVisualDataType } from 'product_modules/enums/VisualDataType';

export enum BooleanValueDisplayFormat {
  Uppercase = 'uppercase',
  Capitalize = 'capitalize',
}

export interface BooleanFormattingParams {
  booleanValueFormat?: BooleanValueDisplayFormat;
}

const isBooleanValue = getValueVisualTypeCorrespondenceChecker(BooleanVisualDataType.Boolean);

const formatBooleanValue: ValueFormatter<BooleanFormattingParams> = (
  value,
  params,
) => {
  if (!isBooleanValue(value)) {
    throw new ValueFormattingError();
  }

  const displayValue = value.toString();

  switch (params?.booleanValueFormat) {
    case BooleanValueDisplayFormat.Capitalize:
      return capitalize(displayValue);
    case BooleanValueDisplayFormat.Uppercase:
      return displayValue.toUpperCase();
    default:
      return displayValue.toUpperCase();
  }
}

export default formatBooleanValue;
