import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { DocumentExtension, IDownloadResponse } from 'api/digifi/DocumentsApi';
import { downloadFile } from 'product_modules/utils/downloadFile';
import FilePreviewHeader from './FilePreviewHeader';
import MainFrame from './MainFrame';
import NoPreview from './NoPreview';
import FilePreviewLoader, { LoaderType } from 'components/common/FilePreview/FilePreviewLoader/FilePreviewLoader';
import styles from './FilePreview.module.scss';

export interface IFilePreviewProps {
  fileExtension: DocumentExtension;
  filename: string;
  fileId: string;
  isPreviewReady?: boolean;
  isPreviewAvailable?: boolean;
  previewLink?: string;
  dataExtractionId?: string | null;
  downloadFileContent: (id: string) => Promise<IDownloadResponse>;
  loaderMessage?: string;
  noPreviewMessage?: string;
  loaderType?: LoaderType;
  mainBodyContainerClassName?: string | null;
  previewOverlay?: React.ReactNode;
}

export const GOOGLE_DOCS_EXTENSIONS = [
  DocumentExtension.Ppt,
  DocumentExtension.Pptx,
  DocumentExtension.Txt,
  DocumentExtension.Word,
  DocumentExtension.Doc,
  DocumentExtension.Docx,
  DocumentExtension.Xml,
  DocumentExtension.Xls,
  DocumentExtension.Xlsx,
  DocumentExtension.Csv,
  DocumentExtension.Json,
  DocumentExtension.Zip,
];

export const IMAGE_EXTENSIONS = [
  DocumentExtension.Gif,
  DocumentExtension.Jpeg,
  DocumentExtension.Jpg,
  DocumentExtension.Png,
  DocumentExtension.Svg,
  DocumentExtension.Heic,
  DocumentExtension.Webp,
];

const isSupportedDocumentExtension = (extension: DocumentExtension) => {
  return GOOGLE_DOCS_EXTENSIONS.includes(extension)
    || IMAGE_EXTENSIONS.includes(extension)
    || extension === DocumentExtension.Pdf;
};

const FilePreview: FC<IFilePreviewProps> = ({
  fileExtension,
  previewLink,
  filename,
  fileId,
  isPreviewReady = true,
  isPreviewAvailable = true,
  downloadFileContent,
  loaderMessage,
  noPreviewMessage,
  loaderType = LoaderType.Standard,
  mainBodyContainerClassName,
  previewOverlay,
}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const isPreviewSupported = useMemo(() => {
    return isSupportedDocumentExtension(fileExtension);
  }, [fileExtension]);
  const [
    pdfObjectUrl,
    setPdfObjectUrl,
  ] = useState<string | null>(null);

  const decodedFileName = decodeURIComponent(filename);

  const isDownloadPdfAvailable = fileExtension === DocumentExtension.Pdf
    && isPreviewReady
    && isPreviewAvailable;

  useEffect(() => {
    const downloadPdf = async () => {
      try {
        const fileData = await downloadFileContent(fileId);

        setPdfObjectUrl(window.URL.createObjectURL(fileData.file));
      } catch (error) {
        setIsLoading(false);
      }
    };

    if (isDownloadPdfAvailable) {
      downloadPdf();
    }
  }, [fileId, isDownloadPdfAvailable]);

  useEffect(() => {
    setIsLoading(isPreviewSupported && isPreviewAvailable && (!isPreviewReady || !previewLink));
  }, [previewLink, isPreviewAvailable, isPreviewSupported]);

  const handleLoadSuccess = () => {
    setIsLoading(false);
  };

  const handleLoadFailure = () => {
    setIsLoading(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleDownloadFile = () => {
    downloadFile(previewLink!, filename);
  };

  const renderFilePreview = () => {
    if (isPreviewAvailable) {
      return (
        <MainFrame
          fileExtension={fileExtension}
          fileName={decodedFileName}
          previewLink={previewLink!}
          isLoading={isLoading}
          pdfObjectUrl={pdfObjectUrl}
          onLoad={handleLoadSuccess}
          onError={handleLoadFailure}
        />
      );
    }

    return (
      <NoPreview
        previewLink={previewLink!}
        downloadAvailable={isPreviewAvailable}
        message={noPreviewMessage}
        onDownload={handleDownloadFile}
      />
    );
  };

  const renderLoader = () => {
    return (
      <FilePreviewLoader
        type={loaderType}
        message={loaderMessage}
        downloadAvailable={!!previewLink && isPreviewReady}
        onDownload={handleDownloadFile}
      />
    );
  };

  const isDownloadDisabled = !previewLink || !isPreviewReady || !isPreviewReady || !isPreviewAvailable;

  return (
    <div className={styles.container}>
      <FilePreviewHeader
        fileName={decodedFileName}
        isDownloadDisabled={isDownloadDisabled}
        isLoading={isLoading}
        onGoBack={handleGoBack}
        onDownload={handleDownloadFile}
      />
      <div className={styles.body}>
        <div className={clsx(
          styles.mainBodyContainer,
          mainBodyContainerClassName,
        )}>
          {isLoading && renderLoader()}
          {!isLoading && previewOverlay}
          {previewLink && renderFilePreview()}
        </div>
      </div>
    </div>
  );
};

export default FilePreview;
