import { FC } from 'react';
import clsx from 'clsx';
import { DocumentExtension } from 'api/digifi/DocumentsApi';
import { GOOGLE_DOCS_EXTENSIONS, IMAGE_EXTENSIONS } from 'components/common/FilePreview/FilePreview';
import ImagePreview from './ImagePreview';
import styles from './MainFrame.module.scss';


const getGoogleDocPreviewUrl = (previewLink: string) => `https://docs.google.com/gview?url=${previewLink}&embedded=true`;

interface IMainFrameProps {
  fileExtension: DocumentExtension;
  fileName: string;
  previewLink: string;
  isLoading: boolean;
  pdfObjectUrl: string | null;
  onLoad: () => void;
  onError: () => void;
}

const MainFrame: FC<IMainFrameProps> = ({
  fileExtension,
  previewLink,
  fileName,
  isLoading,
  pdfObjectUrl,
  onLoad,
  onError,
}) => {
  const renderMainFrame = () => {
    if (GOOGLE_DOCS_EXTENSIONS.includes(fileExtension)) {
      return (
        <iframe
          title={fileName}
          src={getGoogleDocPreviewUrl(previewLink)}
          className={styles.frame}
          onLoad={onLoad}
          onError={onError}
        />
      );
    }

    if (IMAGE_EXTENSIONS.includes(fileExtension)) {
      return (
        <ImagePreview
          previewLink={previewLink}
          filename={fileName}
          fileExtension={fileExtension}
          onLoad={onLoad}
          onError={onError}
        />
      );
    }

    if (pdfObjectUrl) {
      return (
        <iframe
          title={fileName}
          src={pdfObjectUrl}
          className={styles.frame}
          onLoad={onLoad}
          onError={onError}
        />
      );
    }

    return null;
  };

  return (
    <div className={clsx(styles.container, isLoading && styles.hidden)}>
      {renderMainFrame()}
    </div>
  );
};

export default MainFrame;
