import React, { ComponentType } from 'react';
import styles from './Snackbar.module.scss';
import Snack from 'components/Snackbar/Snack';
import { SnackbarComponentTypes } from 'components/Snackbar/Types';
import { ISnackContent, ISnackProps } from 'handlers/snacksSlice';


export interface ISnackbarComponentProps<
  CustomSnackbarComponents extends Record<SnackbarComponentTypes, ComponentType<any>>
  > {
  snackbarList: ISnackProps[];
  customSnackbarComponents: CustomSnackbarComponents;
}

const Snackbar = <
  CustomSnackbarComponents extends Record<SnackbarComponentTypes, ComponentType<any>>
  >({
      snackbarList,
      customSnackbarComponents,
    }: ISnackbarComponentProps<CustomSnackbarComponents>) => {
  const renderSnack = (snack: ISnackContent) => {
    const SnackComponent = customSnackbarComponents[snack.componentType as SnackbarComponentTypes];

    return <SnackComponent {...snack.props} />;
  };

  return (
    <div className={styles.snackbarsContainer}>
      {snackbarList.map((snack, index) => (
        <Snack key={index} snackId={snack.id} title={snack.title}>
          {renderSnack(snack.content)}
        </Snack>
      ))}
    </div>
  );
};

export default Snackbar;
