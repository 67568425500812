import React, { FC, useEffect, useState } from 'react';
import FullScreenPopUp from 'product_modules/components/FullScreenPopUp';
import { TableColumn } from 'product_modules/api/Core/VariablesApi';
import { TableValue } from 'product_modules/api/Types';
import TableInput from './TableInput';
import LinkButton from 'product_modules/components/LinkButton';
import { IAddressInputClassNames } from 'product_modules/types/InputClassNamesTypes';
import styles from './TableInput.module.scss';

interface TableInputWrappedProps {
  isExpanded?: boolean;
  onViewModeChange?: () => void;
  columns: TableColumn[];
  value: TableValue | null;
  label: string;
  viewMode?: boolean;
  onChange?: (value: TableValue | null) => void;
  hideTableContent?: boolean;
  disabledValidation?: boolean;
  disableCapAttributesValidation?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  onErrorMessageUpdate?: (info: Record<string, boolean>) => void;
  isViewModeChanged?: boolean;
  errors?: Record<string, boolean>;
  classNames?: {
    tableHeaderViewMode?: string;
    headerContainerExpanded?: string;
    tableContainerExpanded?: string;
    labelExpanded?: string;
    collapseButton?: string;
    labelTooltip?: string;
    address?: IAddressInputClassNames;
  };
  required?: boolean;
  titleHint?: string;
}

const TableInputWrapped: FC<TableInputWrappedProps> = ({
  isExpanded,
  onViewModeChange,
  viewMode = false,
  value,
  hideTableContent = false,
  ...inputProps
}) => {
  const [isFullScreen, setIsFullScreen] = useState(isExpanded !== undefined ? isExpanded : false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => onViewModeChange?.(), []);

  const renderContent = () => {
    if (hideTableContent && !isFullScreen) {
      return (
        <LinkButton onClick={toggleFullScreen} className={styles.showTableLinkButton}>Show Table</LinkButton>
      );
    }

    return (
      <TableInput
        {...inputProps}
        toggleFullScreen={toggleFullScreen}
        isFullScreen={isFullScreen}
        value={value}
        viewMode={viewMode}
      />
    );
  };

  return isFullScreen ? (
    <FullScreenPopUp usePortal>
      {renderContent()}
    </FullScreenPopUp>
  ) : renderContent();
};

export default TableInputWrapped;
