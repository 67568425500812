import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import ValueFormattingError from 'product_modules/utils/valueFormatters/base/ValueFormattingError';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';
import { TableVisualDataType } from 'product_modules/enums/VisualDataType';
import { TableColumn, TableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import formatValueByVariable from 'product_modules/utils/formatValueByVariable';
import { TableRowValue } from 'product_modules/api/Types';

const isTableValue = getValueVisualTypeCorrespondenceChecker(TableVisualDataType.Table);

const formatTableRow = (row: TableRowValue, columns: TableColumn[], rowIndex: number) => {
  return columns.reduce<string[]>((accumulator, column) => {
    accumulator.push(`${column.name}.${rowIndex}: ${formatValueByVariable(row[column.systemName], column) || '-'}`);

    return accumulator;
  }, []).join(', ');
};

const formatTableValue: ValueFormatter<TableVisualAttributes> = (
  value,
  params = {
    columns: [],
  },
): string => {
  if (!isTableValue(value)) {
    throw new ValueFormattingError();
  }

  return value.map((row, index) => formatTableRow(row, params.columns, index)).join(', ');
};

export default formatTableValue;
