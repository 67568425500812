import ButtonWithLoadingState from 'components/digifi-wrappers/ButtonWithLoadingState/ButtonWithLoadingState';
import PasswordInput from 'components/digifi-wrappers/PasswordInput';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import React, { FC, useRef, useState } from 'react';
import { validatePassword } from 'product_modules/utils/validation/validation';
import styles from 'components/BorrowerProfile/LoginDetails/ChangePasswordPopup/ChangePasswordPopup.module.scss';
import { createNotification } from 'handlers/notificationsSlice';
import { useAppDispatch } from 'hooks/reduxHooks';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import PasswordInputWithHint from 'product_modules/components/PasswordInputWithHint';
import { validatePasswordByRules } from 'utils/validation';
import { MessageType } from 'constants/Messages';

interface IChangePasswordPopupProps {
  onPasswordChange: (oldPassword: string, newPassword: string) => Promise<void>;
  onClose: () => void;
}

const ChangePasswordPopup: FC<IChangePasswordPopupProps> = ({ onPasswordChange, onClose }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState<MessageType[]>(validatePasswordByRules(newPassword));
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const newPasswordInputRef = useRef<HTMLInputElement>(null);
  const confirmNewPasswordInputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleOnSave = async () => {
    try {
      await onPasswordChange(oldPassword, newPassword);

      createNotification({
        notification: 'Your password has been updated.',
        type: 'success',
        dispatch,
      });

      onClose();
    } catch (error) {
      createNotification({
        notification: 'Your current password does not match our records. Please try again.',
        type: 'error',
        dispatch,
      });
    }
  };


  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    const error = validatePasswordByRules(passwordValue);

    setNewPassword(passwordValue);
    setNewPasswordError(error);
  };

  const handleConfirmNewPasswordBlur = () => {
    const error = newPassword !== confirmNewPassword;

    setConfirmNewPasswordError(!error ? null : 'Entered value does not match new password');
  };

  const handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(event.target.value);
    setConfirmNewPasswordError(null);
  };

  const isSaveButtonDisabled = oldPassword.length === 0
    || !!validatePassword(newPassword)
    || newPassword !== confirmNewPassword;

  return (
    <PopUp
      title="Change Password"
      closePopUp={onClose}
      classNames={{
        sectionClassName: styles.popUpContainer,
        header: styles.popUpHeader,
      }}
    >
      <PopUpContent className={styles.popUpContent}>
        <PasswordInput
          labelTitle="Old Password"
          required
          value={oldPassword}
          onChange={(event) => setOldPassword(event.target.value)}
          onKeyDown={TriggerEventOnEnterKeyDown(newPasswordInputRef, 'focus')}
        />
        <PasswordInputWithHint
          labelTitle="New Password"
          required
          value={newPassword}
          onChange={handleNewPasswordChange}
          ref={newPasswordInputRef}
          onKeyDown={TriggerEventOnEnterKeyDown(confirmNewPasswordInputRef, 'focus')}
          invalidMessageTypes={newPasswordError}
        />
        <PasswordInput
          labelTitle="Confirm New Password"
          required
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
          onBlur={handleConfirmNewPasswordBlur}
          errorMessage={confirmNewPasswordError || undefined}
          onKeyDown={TriggerEventOnEnterKeyDown(buttonRef, 'click')}
          ref={confirmNewPasswordInputRef}
        />
        <ButtonWithLoadingState
          size="form"
          kind="primary"
          onClick={handleOnSave}
          className={styles.saveButton}
          disabled={isSaveButtonDisabled}
          ref={buttonRef}
        >
          Save New Password
        </ButtonWithLoadingState>
      </PopUpContent>
    </PopUp>
  );
};

export default ChangePasswordPopup;
