import { nanoid } from 'nanoid';
import { useRef } from 'react';

const useRandomIdFallback = (id?: string) => {
  const idRef = useRef<string | undefined>(id);

  if (!idRef.current) {
    idRef.current = nanoid();
  }

  return idRef.current;
};

export default useRandomIdFallback;
