import { FC } from 'react';
import CustomCheckbox, { CustomCheckboxProps } from 'product_modules/components/CustomCheckbox';
import CustomCheckboxStyles from 'product_modules/components/CustomCheckbox/CustomCheckbox.module.scss';
import styled from 'types/AppTheme';
import clsx from 'clsx';

const CHECKBOX_CHECKED_CLASS_NAME = 'CheckboxWrapper_checked';

interface ICheckboxWrapperStyledProps {
  checked?: boolean;
}

const CheckboxWrapperStyled = styled(CustomCheckbox)<ICheckboxWrapperStyledProps>`
  &:hover {
    rect {
      fill: ${({ theme }) => theme.colors.backgroundHoverBrandColor};
      stroke: ${({ theme, checked }) => checked ? 'none' : theme.colors.brandColor};
    }

    &.${CHECKBOX_CHECKED_CLASS_NAME} rect {
      fill: ${({ theme }) => theme.colors.hoverBrandColor};
    }
  }

  &.Mui-disabled svg {
    border: 1px solid ${CustomCheckboxStyles.disabledBorderColor};
    border-radius: 4px;
  }

  rect {
    fill: ${({ theme, checked }) => checked ? theme.colors.brandColor : 'none'};
    stroke: ${({ theme, checked }) => checked ? 'none' : theme.colors.brandColor};
  }

  &:hover rect, &:active rect {
    fill: ${({ theme }) => theme.colors.backgroundHoverBrandColor};
    stroke: ${({ theme, checked }) => checked ? 'none' : theme.colors.brandColor};
  }

  &.${CHECKBOX_CHECKED_CLASS_NAME}:hover rect {
    fill: ${({ theme }) => theme.colors.hoverBrandColor};
  }
`;

const CheckboxWrapper: FC<CustomCheckboxProps> = (props) => {
  return (
    <CheckboxWrapperStyled {...props} classes={{
      ...props.classes,
      checked: clsx(CHECKBOX_CHECKED_CLASS_NAME, props.classes?.checked),
    }} />
  );
};

export default CheckboxWrapper;
