import { forwardRef, ReactNode } from 'react';
import PopUpCore from 'product_modules/components/PopUp/PopUpCore';
import Portal from 'product_modules/components/Portal';
import styles from './FullScreenPopUp.module.scss';
import clsx from 'clsx';

interface FullScreenPopUpProps {
  usePortal?: boolean;
  children: ReactNode;
  sectionClassName?: string;
  className?: string;
}

const FullScreenPopUp = forwardRef<HTMLDivElement, FullScreenPopUpProps>(({ usePortal, children, sectionClassName, className }, ref) => {
  const renderPopUp = () => (
    <div className={styles.popup}>
      <PopUpCore
        ref={ref}
        focusLockClassName={styles.focusLock}
        sectionClassName={clsx(styles.section, sectionClassName)}
      >
        {children}
      </PopUpCore>
    </div>
  );

  return usePortal ? <Portal>{renderPopUp()}</Portal> : renderPopUp();
});

export default FullScreenPopUp;
