import { IPaginationProps } from 'components/Pagination';
import { useEffect, useState } from 'react';
import { ISortType } from 'types/SortType';

export interface IBasePaginationItem {
  id: string;
}

export interface IPaginationResult<IItem extends IBasePaginationItem> {
  items: IItem[];
  total: number;
}
export interface ILoadItemsParams<SortField extends string> {
  offset: number;
  count: number;
  sortType: ISortType<SortField>;
}

export interface IUsePaginationStateParams<IItem extends IBasePaginationItem, SortField extends string> {
  itemsPerPage: number;
  defaultSorting: ISortType<SortField>;
  loadItems: (params: ILoadItemsParams<SortField>) => Promise<IPaginationResult<IItem>>;
}

interface IUsePaginationStateResult<SortField extends string> {
  paginationProps: IPaginationProps;
  items: string[] | null;
  currentSortType: ISortType<SortField>;
  sort: (field: SortField, ascending: boolean) => void;
}

interface IHandleLoadParams<SortField extends string> {
  offset: number;
  sortType: ISortType<SortField>;
}

const usePaginationState = <IItem extends IBasePaginationItem, SortField extends string>({
  itemsPerPage,
  loadItems,
  defaultSorting,
}: IUsePaginationStateParams<IItem, SortField>): IUsePaginationStateResult<SortField> => {
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [items, setItems] = useState<string[] | null>(null);
  const [sortType, setSortType] = useState<ISortType<SortField>>(defaultSorting);

  const handleLoadItems = async (params: IHandleLoadParams<SortField>) => {
    const response = await loadItems({
      offset: params.offset,
      count: itemsPerPage,
      sortType: params.sortType,
    });

    setOffset(params.offset);
    setTotal(response.total);
    setItems(response.items.map((item) => item.id));
  };

  const handleSorting = (sortField: SortField, ascending: boolean) => {
    setSortType({
      field: sortField,
      ascending,
    });

    handleLoadItems({
      offset: 0,
      sortType: {
        field: sortField,
        ascending,
      },
    });
  };

  useEffect(() => {
    handleLoadItems({
      offset: 0,
      sortType,
    });
  }, []);

  const currentPage = Math.floor((offset / itemsPerPage) + 1);
  const pagesCount = Math.ceil(total / itemsPerPage);

  return {
    items,
    currentSortType: sortType,
    sort: handleSorting,
    paginationProps: {
      itemsPerPage,
      itemsTotal: total,
      page: currentPage,
      pagesCount,
      prevPage: () => {
        if (currentPage === 1) {
          return;
        }

        handleLoadItems({
          offset: offset - itemsPerPage,
          sortType,
        });
      },
      nextPage: () => {
        if (currentPage === pagesCount) {
          return;
        }

        handleLoadItems({
          offset: offset + itemsPerPage,
          sortType,
        });
      },
      setPage: (page) => handleLoadItems({
        offset: (page - 1) * itemsPerPage,
        sortType,
      }),
    },
  };
};

export default usePaginationState;
