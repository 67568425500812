import {
  CapVisualAttributes,
  DateVisualAttributes,
  IdentificationTypeVisualAttributes,
  ListVisualDataTypeAttributes,
  MonetaryVisualAttributes,
  PhoneNumberVisualAttributes,
  TableVisualAttributes,
  VariableVisualAttributes,
} from 'product_modules/api/Core/VariablesApi';
import {
  BasicVisualDataType,
} from 'product_modules/enums/VisualDataType';
import { NonNullishVariableValue } from 'product_modules/utils/valueFormatters/base/Types';
import { convertVariableValueToString } from 'product_modules/utils/coerceUtils';
import getValueFormatter, { FormattingParamsByVisualType } from 'product_modules/utils/valueFormatters';
import { MonetaryFormattingParams } from 'product_modules/utils/valueFormatters/formatMonetaryValue';
import { BooleanFormattingParams } from 'product_modules/utils/valueFormatters/formatBooleanValue';
import { VariableValue } from 'product_modules/api/Types';

export type FormattingParams = DateVisualAttributes
  | IdentificationTypeVisualAttributes
  | PhoneNumberVisualAttributes
  | ListVisualDataTypeAttributes
  | MonetaryVisualAttributes
  | CapVisualAttributes
  | MonetaryFormattingParams
  | BooleanFormattingParams
  | TableVisualAttributes;

export const getFormattingParamsFromVisualAttributes = (attributes: VariableVisualAttributes) => {
  if ('currency' in attributes) {
    return attributes as MonetaryVisualAttributes;
  }

  if ('dateFormat' in attributes) {
    return attributes as DateVisualAttributes;
  }

  if ('identificationNumberType' in attributes) {
    return attributes as IdentificationTypeVisualAttributes;
  }

  if ('columns' in attributes) {
    return attributes as TableVisualAttributes;
  }

  return undefined;
}

const formatByType = <VisualType extends BasicVisualDataType>(
  value: NonNullishVariableValue,
  visualDataType: VisualType,
  params?: FormattingParamsByVisualType<typeof visualDataType>,
) => {
  const formatValue = getValueFormatter(visualDataType);

  try {
    return formatValue(value, params);
  } catch {
    return convertVariableValueToString(value);
  }
};

const formatVariableValue = <VisualType extends BasicVisualDataType>(
  value: VariableValue,
  formatType: VisualType,
  params?: FormattingParamsByVisualType<VisualType>,
): string => {
  if (value === undefined || value === null) {
    return '';
  }

  return formatByType<VisualType>(value, formatType, params);
}

export default formatVariableValue;
