import { FC } from 'react';
import Notification, { NotificationProps } from 'product_modules/components/Notification';

const NotificationWrapper: FC<NotificationProps> = ({ children, ...props }) => {
  return (
    <Notification {...props}>
      {children}
    </Notification>
  );
};

export default NotificationWrapper;
