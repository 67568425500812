import React, { FC, FocusEvent, useState } from 'react';
import TextInput from 'product_modules/components/TextInput';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import useMask from 'product_modules/hooks/useMask';
import { removeNonDigits } from 'product_modules/utils/masks/utils';
import { IdentificationType } from 'product_modules/components/IdentificationNumberInput/types';
import getIdentificationNumberMask from 'product_modules/utils/masks/maskIdentificationNumber';

interface IdentificationNumberInputProps {
  value: string;
  onChange: (value: string) => void;
  identificationNumberType: IdentificationType;
  identificationNumberDescription: string | null | undefined;
  labelTitle: string;
  titleHint?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  errorMessage: string;
  tabIndex?: number;
  inputIcon?: React.ReactNode;
  required?: boolean;
  autoFocus?: boolean;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  labelTooltipClassName?: string;
}

const MAX_OTHER_TYPE_VALUE_LENGTH = 100;
const IDENTIFICATION_MASK_DELIMITER_SYMBOL = '-';

const otherTypeMask = (value: string) => {
  return value.length > MAX_OTHER_TYPE_VALUE_LENGTH ? value.slice(0, MAX_OTHER_TYPE_VALUE_LENGTH) : value;
};

export const getPlaceholder = (
  identificationNumberType: IdentificationType | null | undefined,
  identificationNumberDescription: string | null | undefined,
) => {
  switch (identificationNumberType) {
    case IdentificationType.UsSocial:
      return 'U.S. Social Security Number (###-##-####)';
    case IdentificationType.UsEmployer:
      return 'U.S. Employer Identification Number (##-#######)';
    case IdentificationType.CanadaSocial:
      return 'Canada Social Insurance Number (###-###-###)';
    default:
      return identificationNumberDescription || '';
  }
};

const IdentificationNumberInput: FC<IdentificationNumberInputProps> = ({
  value,
  onChange,
  onFocus,
  onBlur,
  readOnly,
  disabled,
  identificationNumberType,
  labelTitle,
  identificationNumberDescription,
  errorMessage,
  loaderState,
  showLoader,
  onLoaderStateReset,
  tabIndex,
  inputIcon,
  required,
  titleHint,
  autoFocus,
  useSimplifiedInput,
  style,
  labelTooltipClassName,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setInputValue(value);
    setIsFocused(true);

    onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onChange(inputValue);
    setIsFocused(false);

    onBlur?.(event);
  };

  const handleChange = (valueToSet: string) => {
    setInputValue(valueToSet);
    onChange(valueToSet);
  };

  const visualValue = isFocused ? inputValue : value;
  const isOtherIdentificationNumberType = identificationNumberType === IdentificationType.Other;

  const applyMask = getIdentificationNumberMask(identificationNumberType);
  const unMask = isOtherIdentificationNumberType ? otherTypeMask : removeNonDigits;

  const [maskedValue, inputRef, handleInputChange, handleKeyDown, handleKeyUp, handleClick] = useMask(
    visualValue,
    handleChange,
    applyMask,
    unMask,
    (char: string) => char === IDENTIFICATION_MASK_DELIMITER_SYMBOL,
  );

  return (
    <TextInput
      labelTitle={labelTitle}
      titleHint={titleHint}
      placeholder={getPlaceholder(identificationNumberType, identificationNumberDescription)}
      value={maskedValue}
      onChange={handleInputChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onClick={handleClick}
      inputRef={inputRef}
      readOnly={readOnly}
      disabled={disabled}
      errorMessage={errorMessage}
      required={required}
      showLoader={showLoader}
      loaderState={loaderState}
      onLoaderStateReset={onLoaderStateReset}
      tabIndex={tabIndex}
      inputIcon={inputIcon}
      autoFocus={autoFocus}
      useSimplifiedInput={useSimplifiedInput}
      style={style}
      labelTooltipClassName={labelTooltipClassName}
    />
  );
};

export default IdentificationNumberInput;
