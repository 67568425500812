import { FC } from 'react';
import ActionPopUp, { ActionPopUpProps } from 'product_modules/components/ActionPopUp';

const ActionPopUpWrapper: FC<ActionPopUpProps> = (props) => {
  return (
    <ActionPopUp {...props} />
  );
};

export default ActionPopUpWrapper;
