import { ApplicationFormStep } from 'enums/ApplicationFormStep';

export const getCoBorrowerPageTypeByIndex = (index: number): ApplicationFormStep => {
  return index === 0 ? ApplicationFormStep.CoBorrower : `${ApplicationFormStep.CoBorrower}_${index + 1}` as ApplicationFormStep;
};

export const getCoBorrowerPageIndex = (page: string) => {
  const [, index] = page.split('_');

  return index ? Number(index) - 1 : 0;
};

export const isCoBorrowerApplicationFormPage = (formPage: string) => {
  return formPage.startsWith(ApplicationFormStep.CoBorrower);
};
