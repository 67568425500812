import { FC } from 'react';
import clsx from 'clsx';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import Button from 'components/digifi-wrappers/Button';
import styles from './DiscardApplicationPopUp.module.scss';

interface IDiscardApplicationPopUpProps {
  onClose: () => void;
  onSubmit: () => void;
}

const DiscardApplicationPopUp: FC<IDiscardApplicationPopUpProps> = ({ onClose, onSubmit }) => {
  return (
    <PopUp title="Discard Application" closePopUp={onClose} classNames={{ sectionClassName: styles.popUpContainer }}>
      <PopUpContent hasTopMargin className={clsx(styles.deleteFormContent, styles.popUpContent)} >
        Are you sure you want to discard this application?
        <div className={styles.buttonsContainer}>
          <Button kind="primary" size="form" onClick={onSubmit}>
            Yes, Discard Application
          </Button>
          <Button kind="secondary" size="form" onClick={onClose}>
            No, Go Back
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default DiscardApplicationPopUp;
