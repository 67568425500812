import React, { FunctionComponent, useCallback, useState } from 'react';

export interface IBasePopupProps {
  key: string;
  onClose: () => void;
  openPopup: (name: string) => void;
  context?: any;
}

type IPopupsConfig = Record<string, FunctionComponent<IBasePopupProps>>;

interface IUsePopupResult<Type extends IPopupsConfig> {
  openPopup: (name: keyof Type, context?: any) => void;
  closePopup: (name: keyof Type) => void;
  renderPopups: () => React.ReactElement;
  openedPopupKeys: (keyof Type)[];
  topPopupKey: (keyof Type) | null;
}

const usePopups = <Type extends IPopupsConfig>(config: Type): IUsePopupResult<Type> => {
  const [openedPopupKeys, setOpenedPopupKeys] = useState<(keyof Type)[]>([]);
  const [popupContext, setPopupContext] = useState(null);

  const openPopup = useCallback((key: keyof Type, context?: any) => {
    setOpenedPopupKeys((previousValue) => [...previousValue, key]);

    if (typeof context !== undefined) {
      setPopupContext(context);
    }
  }, []);

  const handlePopUpClose = useCallback((popupKeyToClose: keyof Type) => {
    setOpenedPopupKeys((previousValue) => previousValue.filter((key) => key !== popupKeyToClose));
    setPopupContext(null);
  }, []);

  return {
    openPopup,
    closePopup: handlePopUpClose,
    openedPopupKeys,
    topPopupKey: openedPopupKeys[openedPopupKeys.length - 1] || null,
    renderPopups: () => (
      <>
        {openedPopupKeys.map((key) => React.createElement(
          config[key],
          ({
            key: key as string,
            openPopup,
            onClose: () => handlePopUpClose(key),
            context: popupContext,
          })),
        )}
      </>
    ),
  };
};

export default usePopups;
