import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import { AppRoute } from 'enums/AppRoute';
import CreateNewApplicationProductSelector from './CreateNewApplicationProductSelector';

interface ICreateNewApplicationProductSelectorConnectorProps {
  onClose: () => void;
}

const CreateNewApplicationProductSelectorConnector: FC<ICreateNewApplicationProductSelectorConnectorProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();

  const products = useAppSelector((state) => state.products.entities);

  const handleSubmit = useCallback((productId: string, coborrowersCount: number) => {
    const params = new URLSearchParams();

    params.append('productId', productId);
    params.append('coborrowersCount', coborrowersCount.toString());

    navigate(`${AppRoute.Apply}?${params}`);
  }, []);

  return (
    <CreateNewApplicationProductSelector
      products={products}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default CreateNewApplicationProductSelectorConnector;
