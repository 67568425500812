import React, { ReactNode } from 'react';
import clsx from 'clsx';
import WrapperWithTooltip, { TooltipPosition } from 'product_modules/components/Tooltip';
import styles from './QuestionIcon.module.scss';
import WrapperWithHoverableTooltip from 'product_modules/components/WrapperWithHoverableTooltip/WrapperWithHoverableTooltip';

export type TooltipType = 'default' | 'hoverable';

export interface QuestionIconProps {
  tooltip: ReactNode;
  tooltipPosition?: TooltipPosition;
  className?: string;
  tooltipClassName?: string;
  size?: number;
  tooltipType?: TooltipType;
}

const DEFAULT_SIZE = 16;
const DEFAULT_TOOLTIP_POSITION: TooltipPosition = 'top';

const QuestionIcon = ({
  tooltip,
  tooltipPosition = DEFAULT_TOOLTIP_POSITION,
  tooltipClassName,
  className,
  size = DEFAULT_SIZE,
  tooltipType = 'default',
}: QuestionIconProps) => {
  const Tooltip = tooltipType === 'default' ? WrapperWithTooltip : WrapperWithHoverableTooltip;

  return (
    <Tooltip tooltip={tooltip} position={tooltipPosition} placement="bottom" className={tooltipClassName}>
      <p style={{ width: size, height: size }} className={clsx(styles.icon, className)} />
    </Tooltip>
  );
};

export default QuestionIcon;
