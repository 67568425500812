import CardsForm, { CardsFormProps } from 'product_modules/components/CardsForm';
import styled from 'types/AppTheme';
import clsx from 'clsx';
import { rgba } from 'polished';
import {
  IBaseVariableConfiguration,
} from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';
import { getBrandColorBackgroundWithPatternStyles } from 'components/BrandingHelpers/ContainerWithPatternStyled';
import styles from './CardsForm.module.scss';

const EDIT_BUTTON_CLASS_NAME = 'CardsFormWrapper-edit-button';
const CARDS_TABLE_VIEW_DISABLED_FIELD_CLASS_NAME = 'CardTableView-field-disabled';
const TABLE_INPUT_HEADER_CONTAINER_EXPANDED_CLASS_NAME = 'CardTableView-table-input-header-container-expanded';
const DESCRIPTION_ICON_CLASS_NAME = 'description-icon';

const CardsFormStyled = styled(CardsForm)`
  .${EDIT_BUTTON_CLASS_NAME} {
    color: ${({ theme }) => theme.colors.accentColor};
    opacity: 1;
  }

  .${EDIT_BUTTON_CLASS_NAME}:hover, .${EDIT_BUTTON_CLASS_NAME}:focus {
    background-color: ${({ theme }) => theme.colors.backgroundHoverAccentColor};
    border-radius: 4px;
  }

  .${EDIT_BUTTON_CLASS_NAME} svg path {
    stroke: ${({ theme }) => theme.colors.accentColor};
  }
  
  .${CARDS_TABLE_VIEW_DISABLED_FIELD_CLASS_NAME} {
    max-height: 0;
    min-height: 0;
  }
  
  .${TABLE_INPUT_HEADER_CONTAINER_EXPANDED_CLASS_NAME} {
    background: ${({ theme }) => getBrandColorBackgroundWithPatternStyles(theme.colors.brandColor)};
  }

  .${DESCRIPTION_ICON_CLASS_NAME}:hover:after {
    color: ${({ theme }) => theme.colors.accentColor};
    border-color: ${({ theme }) => rgba(theme.colors.accentColor, 0.4)};
  }
` as <
    FieldType extends IBaseVariableConfiguration,
    CalculationType extends BaseCalculation,
  >(props: CardsFormProps<FieldType, CalculationType>) => JSX.Element;

const CardsFormWrapper = <
  FieldType extends IBaseVariableConfiguration,
  CalculationType extends BaseCalculation,
>({ editButtonClassName, ...props }: CardsFormProps<FieldType, CalculationType>) => {
  return (
    <CardsFormStyled
      {...props}
      editButtonClassName={clsx(EDIT_BUTTON_CLASS_NAME, styles.text, editButtonClassName)}
      fieldClassName={styles.field}
      fieldTitleClassName={styles.fieldTitle}
      fieldValueClassName={styles.fieldValue}
      hiddenFieldClassName={CARDS_TABLE_VIEW_DISABLED_FIELD_CLASS_NAME}
      descriptionIconClassName={DESCRIPTION_ICON_CLASS_NAME}
      inputClassNames={{
        address: {
          sectionClassName: styles.addressPopUpContainer,
          header: styles.addressPopUpHeader,
          popupContent: styles.addressPopUpContent,
          closeIcon: styles.addressPopupCloseIcon,
          title: styles.addressPopupTitle,
          saveButton: styles.addressPopupSaveButton,
        },
        table: {
          headerContainerExpanded: TABLE_INPUT_HEADER_CONTAINER_EXPANDED_CLASS_NAME,
          tableContainerExpanded: styles.tableContainerExpanded,
          labelExpanded: styles.tableLabelExpanded,
          collapseButton: styles.tableCollapseButton,
        },
      }}
    />
  );
};

export default CardsFormWrapper;
