import { ApplicationStatusType, IApplicationStatus } from 'api/digifi/ApplicationsApi';

const maskApplicationStatusName = (status: IApplicationStatus) => {
  switch (status.type) {
    case ApplicationStatusType.Approve:
    case ApplicationStatusType.Reject:
      return status.name;
    default:
      return 'In Progress';
  }
};

export default maskApplicationStatusName;
