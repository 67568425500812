import React, { FC } from 'react';
import { CheckedImage } from 'static/images';
import styles from './CheckedList.module.scss';
import clsx from 'clsx';

interface ListItem {
  label: string;
  isChecked?: boolean
}

interface ICheckListProps {
  listItems: ListItem[];
  listClassName?: string;
  listItemClassName?: string;
  checkedListItemClassName?: string;
}

const CheckedList: FC<ICheckListProps> = ({
  listItems,
  listClassName,
  listItemClassName,
  checkedListItemClassName = '',
}) => {
  const renderListItem = (listItem: ListItem, index: number) => {
    return (
      <li
        key={index}
        className={clsx(styles.listItem, listItemClassName, {
          [checkedListItemClassName]: listItem.isChecked,
        })}
      >
        <CheckedImage className={styles.checkedIcon} />
        {listItem.label}
      </li>
    );
  };

  return <ul className={listClassName}>{listItems.map(renderListItem)}</ul>;
};

export default CheckedList;
