import { FC } from 'react';
import OverflowedText, { OverflowedTextProps } from 'product_modules/components/OverflowedText';

const OverflowedTextWrapper: FC<OverflowedTextProps> = (props) => {
  return (
    <OverflowedText {...props} />
  );
};

export default OverflowedTextWrapper;
