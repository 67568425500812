import EmptyESignatureElement from './EmptyESignatureElement';

const WaitingForOthers = () => {
  return (
    <EmptyESignatureElement
      title="Waiting for Others"
      description="The signing process must be completed by the other signers to proceed."
    />
  );
};

export default WaitingForOthers;
