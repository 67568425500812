import { VisualDataType } from 'product_modules/enums/VisualDataType';
import { useCallback } from 'react';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';

const useValueCorrespondVisualTypeCheck = (visualType: VisualDataType) => {
  const isValueCorrespondToVisualType = getValueVisualTypeCorrespondenceChecker(visualType);

  return useCallback(isValueCorrespondToVisualType, [visualType]);
}

export default useValueCorrespondVisualTypeCheck;
