import { NonNullishVariableValue } from 'product_modules/utils/valueFormatters/base/Types';
import { VariableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import isCorrespondsIdentificationType
  from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsIdentificationType';

const isCompatibleWithIdentificationType = (
  value: NonNullishVariableValue,
  visualAttributes?: VariableVisualAttributes,
): value is string => {
  return isCorrespondsIdentificationType(value, visualAttributes);
};

export default isCompatibleWithIdentificationType;
