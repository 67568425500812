import { forwardRef } from 'react';
import TextInput, { TextInputPropsMultiline } from 'product_modules/components/TextInput';

const TextAreaWrapper = forwardRef<HTMLTextAreaElement, TextInputPropsMultiline>((props, ref) => {
  return (
    <TextInput {...props} inputRef={ref} />
  );
});

export default TextAreaWrapper;
