import React, { FC } from 'react';
import clsx from 'clsx';
import { VariableValue } from 'product_modules/api/Types';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import TextElementWrapper from 'components/ApplicationPage/elements/TextElementWrapper';
import { ITextElementConfig } from 'api/digifi/portal-page-elements';
import renderStringTemplateByVariableValue from 'utils/renderStringTemplateByVariableValue';
import styles from './SubHeaderTextElement.module.scss';

interface ISubHeaderTextElementProps {
  config: ITextElementConfig;
  context: Record<string, VariableValue>;
  containerClassName?: string;
}

const DEFAULT_SUB_HEADER_VALUE = 'Sub-Header Text';

const SubHeaderTextElement: FC<ISubHeaderTextElementProps> = ({
  config,
  context,
  containerClassName,
}) => {
  const renderSkeleton = () => {
    return (
      <div className={containerClassName}>
        <SkeletonText lineHeight="36px" width="280px" height="28px" color="primary20" className={styles.skeletonDesktop} />
        <SkeletonText lineHeight="36px" width="280px" height="28px" color="primary20" className={styles.skeletonTablet} />
        <SkeletonText lineHeight="28px" width="240px" height="22px" color="primary20" className={styles.skeletonMobile} />
      </div>
    );
  };

  return (
    <TextElementWrapper templateString={config.text} context={context}>
      {(elementContext, showLoader) => (
        showLoader ? renderSkeleton() : (
          <div className={clsx(styles.subHeaderText, containerClassName)} style={{ textAlign: config.alignment }}>
            {renderStringTemplateByVariableValue(config.text, elementContext) || DEFAULT_SUB_HEADER_VALUE}
          </div>
        )
      )}
    </TextElementWrapper>
  );
};

export default SubHeaderTextElement;
