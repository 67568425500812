import { FC } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import styles from 'components/common/Logo/Logo.module.scss';
import clsx from 'clsx';

interface ILogoProps {
  className?: string;
}

const Logo: FC<ILogoProps> = ({ className }) => {
  const { logoId } = useAppSelector((state) => state.settings.branding);

  return (
    <>
      <img className={clsx(styles.logo, className)} src={logoId ? `/api/branding/logo/${logoId}` : '/api/branding/logo'} alt='Logo' />
    </>
  );
};

export default Logo;
