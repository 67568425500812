import { FC } from 'react';
import clsx from 'clsx';
import TableRow from 'components/digifi-wrappers/TableRow';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import SkeletonRectangle from 'components/digifi-wrappers/SkeletonRectangle';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import { ApplicationsTableColumnSizes } from 'components/ApplicationsList/ApplicationsTableRow/ApplicationsTableColumnSized';
import styles from './ApplicationsTable.module.scss';

const DESKTOP_TABLE_LENGTH = 10;
const MOBILE_TABLE_LENGTH = 5;

const SkeletonApplicationsTable: FC = () => {
  const renderDesktopTable = () => {
    return Array.from({ length: DESKTOP_TABLE_LENGTH }).map((item, index) => {
      return (
        <TableRow key={index} className={styles.tableRowDesktop} disableHover hasTopBorder={!index}>
          <TableBodyCell width={ApplicationsTableColumnSizes.ProductName}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={ApplicationsTableColumnSizes.Id}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={ApplicationsTableColumnSizes.LoanAmount}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={ApplicationsTableColumnSizes.Status}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={ApplicationsTableColumnSizes.ApplicationDate}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
        </TableRow>
      );
    });
  };

  const renderMobileTable = () => {
    return Array.from({ length: MOBILE_TABLE_LENGTH }).map((row, index) => {
      return (
        <TableRow key={index} className={styles.tableRowMobile} disableHover hasTopBorder={!index}>
          <TableBodyCell width={100} className={clsx(styles.tableCellMobile, styles.mobileSkeletonContainer)}>
            <div className={styles.skeletonRowContainer}>
              <SkeletonText lineHeight="20px" lineWidth="48%" width="100%" color="primary6" height="14px"/>
              <SkeletonText lineHeight="20px" lineWidth="34%" width="100%" color="primary6" height="14px"/>
            </div>
            <div className={styles.skeletonRowContainer}>
              <SkeletonRectangle width="28%" color="primary6" height="24px"/>
              <SkeletonRectangle width="80px" color="primary6" height="24px"/>
            </div>
            <div className={styles.skeletonRowContainer}>
              <SkeletonText lineHeight="20px" lineWidth="54%" width="100%" color="primary6" height="14px"/>
            </div>
          </TableBodyCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      {renderDesktopTable()}
      {renderMobileTable()}
    </>
  );
};

export default SkeletonApplicationsTable;
