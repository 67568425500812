import ProductType from 'enums/ProductType';
import { ComponentType } from 'react';
import {
  AutoLoanRefinancingProductTypeIcon,
  BusinessLoanProductTypeIcon,
  CommercialLoanProductTypeIcon,
  CreditCardProductTypeIcon,
  CustomProductTypeIcon,
  HomeEquityLineOfCreditProductTypeIcon,
  HomeEquityLoanProductTypeIcon,
  HomeImprovementLoanProductTypeIcon,
  MortgageProductTypeIcon,
  PersonalLoanProductTypeIcon,
  PointOfSaleFinancingProductTypeIcon,
  PersonalLoanAlternativeProductTypeIcon,
  PersonalLoanSecondAlternativeProductTypeIcon,
  CreditCardAlternativeProductTypeIcon,
  SolarLoanProductTypeIcon,
} from 'static/images/product-icons';

const PRODUCT_IMAGE_BY_TYPE: Record<ProductType, ComponentType<{ className?: string }>> = {
  [ProductType.PersonalLoan]: PersonalLoanProductTypeIcon,
  [ProductType.AutoLoanRefinancing]: AutoLoanRefinancingProductTypeIcon,
  [ProductType.CommercialLoan]: CommercialLoanProductTypeIcon,
  [ProductType.CreditCard]: CreditCardProductTypeIcon,
  [ProductType.HomeEquityLoan]: HomeEquityLoanProductTypeIcon,
  [ProductType.HomeImprovementLoan]: HomeImprovementLoanProductTypeIcon,
  [ProductType.Mortgage]: MortgageProductTypeIcon,
  [ProductType.HomeEquityLineOfCredit]: HomeEquityLineOfCreditProductTypeIcon,
  [ProductType.PointOfSaleFinancing]: PointOfSaleFinancingProductTypeIcon,
  [ProductType.SmallBusinessLoan]: BusinessLoanProductTypeIcon,
  [ProductType.PersonalLoanAlternative]: PersonalLoanAlternativeProductTypeIcon,
  [ProductType.PersonalLoanSecondAlternative]: PersonalLoanSecondAlternativeProductTypeIcon,
  [ProductType.CreditCardAlternative]: CreditCardAlternativeProductTypeIcon,
  [ProductType.SolarLoan]: SolarLoanProductTypeIcon,
  [ProductType.Custom]: CustomProductTypeIcon,
};

export default PRODUCT_IMAGE_BY_TYPE;
