import {
  AddressVisualDataType,
  BooleanVisualDataType,
  DateVisualDataType,
  NumericVisualDataType,
  StringVisualDataType,
  TableVisualDataType,
} from 'product_modules/enums/VisualDataType';
import PhoneNumberInput from 'product_modules/components/PhoneNumberInput';
import TextInput from 'product_modules/components/TextInput';
import EmailInput from 'product_modules/components/EmailInput';
import AutoCompletion from 'product_modules/components/AutoCompletion';
import NumberInput from 'product_modules/components/NumberInput';
import PercentageInput from 'product_modules/components/PercentageInput';
import AddressInput from 'product_modules/components/AddressInput';
import DatePicker from 'product_modules/components/DatePicker';
import MonetaryInput from 'product_modules/components/MonetaryInput';
import IdentificationNumberInput from 'product_modules/components/IdentificationNumberInput';
import TableInput from 'product_modules/components/TableInput';

export interface InputComponentsByVisualType {
  [StringVisualDataType.PhoneNumber]: typeof PhoneNumberInput;
  [StringVisualDataType.EmailAddress]: typeof EmailInput;
  [StringVisualDataType.List]: typeof AutoCompletion;
  [BooleanVisualDataType.Boolean]: typeof AutoCompletion;
  [NumericVisualDataType.Number]: typeof NumberInput;
  [NumericVisualDataType.Percentage]: typeof PercentageInput;
  [AddressVisualDataType.Address]: typeof AddressInput;
  [DateVisualDataType.Date]: typeof DatePicker;
  [NumericVisualDataType.Monetary]: typeof MonetaryInput;
  [StringVisualDataType.IdentificationNumber]: typeof IdentificationNumberInput;
  [StringVisualDataType.Text]: typeof TextInput;
  [StringVisualDataType.LargeText]: typeof TextInput;
  [TableVisualDataType.Table]: typeof TableInput;
}

const getInputComponentByVisualType = <Type extends keyof InputComponentsByVisualType>(
  visualType: Type,
): InputComponentsByVisualType[Type] => {
  switch (visualType) {
    case StringVisualDataType.PhoneNumber:
      return PhoneNumberInput as InputComponentsByVisualType[Type];
    case StringVisualDataType.EmailAddress:
      return EmailInput as InputComponentsByVisualType[Type];
    case StringVisualDataType.List:
    case BooleanVisualDataType.Boolean:
      return AutoCompletion as InputComponentsByVisualType[Type];
    case NumericVisualDataType.Number:
      return NumberInput as InputComponentsByVisualType[Type];
    case NumericVisualDataType.Percentage:
      return PercentageInput as InputComponentsByVisualType[Type];
    case AddressVisualDataType.Address:
      return AddressInput as InputComponentsByVisualType[Type];
    case DateVisualDataType.Date:
      return DatePicker as InputComponentsByVisualType[Type];
    case NumericVisualDataType.Monetary:
      return MonetaryInput as InputComponentsByVisualType[Type];
    case StringVisualDataType.IdentificationNumber:
      return IdentificationNumberInput as InputComponentsByVisualType[Type];
    case TableVisualDataType.Table:
      return TableInput as InputComponentsByVisualType[Type];
    default:
      return TextInput as InputComponentsByVisualType[Type];
  }
};

export default getInputComponentByVisualType;
