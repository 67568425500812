import { useMemo } from 'react';
import { FormLayoutData } from 'product_modules/api/Types';
import { convertBorrowerVariablesToCoBorrower } from 'product_modules/utils/BorrowerVariableUtils';

interface IFullApplicationDataProps {
  borrowerFormData: FormLayoutData;
  coBorrowersFormData: Array<FormLayoutData>;
}

const useFullApplicationData = ({
  borrowerFormData,
  coBorrowersFormData,
}: IFullApplicationDataProps) => {
  const mergedCoBorrowersData = useMemo(() => {
    return coBorrowersFormData.reduce((previousMergedCoBorrowersData, coBorrowerData, index) => ({
      ...previousMergedCoBorrowersData,
      ...convertBorrowerVariablesToCoBorrower(coBorrowerData, index),
    }), {});
  }, [coBorrowersFormData]);

  return useMemo(() => ({
    ...borrowerFormData,
    ...mergedCoBorrowersData,
  }), [
    borrowerFormData,
    mergedCoBorrowersData,
  ]);
};

export default useFullApplicationData;
