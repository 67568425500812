import { VariableValidationAndFormattingFields, } from 'product_modules/api/Core/VariablesApi';
import { BooleanFormattingParams, } from 'product_modules/utils/valueFormatters/formatBooleanValue';
import formatVariableValue, { getFormattingParamsFromVisualAttributes } from 'product_modules/utils/formatVariableValue';
import { convertVariableValueToString } from 'product_modules/utils/coerceUtils';
import { VariableValue } from 'product_modules/api/Types';
import { BasicVisualDataType } from 'product_modules/enums/VisualDataType';

const formatValueByVariable = (
  variableValue: VariableValue,
  sourceVariable?: VariableValidationAndFormattingFields,
  params?: BooleanFormattingParams,
): string => {
  if (!sourceVariable) {
    return convertVariableValueToString(variableValue);
  }

  return formatVariableValue(variableValue, sourceVariable?.visualDataType as BasicVisualDataType, {
    ...params,
    ...getFormattingParamsFromVisualAttributes(sourceVariable.visualAttributes),
  });
};

export default formatValueByVariable;
