import styled from 'types/AppTheme';
import { rgba } from 'polished';

interface IContainerStyledProps {
  opacity?: number;
}

const ContainerStyled = styled.div<IContainerStyledProps>`
  background: ${({ theme, opacity }) => opacity 
    ? rgba(theme.colors.brandColor, opacity) 
    : theme.colors.brandColor}
`;

export default ContainerStyled;
