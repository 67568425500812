import React, { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import PopUpCore from './PopUpCore';
import PopUpHeaderWithClose from './PopUpHeaderWithClose';
import Spinner from 'product_modules/components/Spinner';
import Portal from 'product_modules/components/Portal';
import styles from './PopUpContent.module.scss';

export interface PopUpProps {
  children: ReactNode;
  closePopUp: () => void;
  title: string | React.ReactNode;
  classNames?: Partial<{
    popup: string;
    popupCore: string;
    sectionClassName?: string;
    header?: string;
    closeIcon?: string;
    title?: string;
  }>;
  isLoading?: boolean;
  warningText?: string;
  titleIcons?: React.ReactNode;
  titleTooltip?: React.ReactNode;
  closable?: boolean;
  usePortal?: boolean;
  subTitle?: React.ReactNode;
}

const PopUp = forwardRef<HTMLElement, PopUpProps>(
  (
    { children, closePopUp, title, subTitle, classNames = {}, isLoading, warningText, titleTooltip, closable, usePortal, titleIcons },
    ref,
  ) => {
    const renderPopup = () => (
      <div className={clsx(styles.popup, classNames.popup)}>
        <PopUpCore
          className={classNames.popupCore}
          sectionClassName={classNames.sectionClassName}
          ref={ref}
          warningText={warningText}
        >
          <PopUpHeaderWithClose
            titleIcons={titleIcons}
            titleTooltip={titleTooltip}
            title={title}
            subTitle={subTitle}
            onClose={closePopUp}
            closable={closable}
            className={classNames?.header}
            titleClassName={classNames?.title}
            closeIconClassName={classNames?.closeIcon}
          />
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Spinner size={24} background="light" />
            </div>
          ) : (
            children
          )}
        </PopUpCore>
      </div>
    );

    if (usePortal) {
      return <Portal tagName="div">{renderPopup()}</Portal>;
    }

    return renderPopup();
  },
);

export default PopUp;
