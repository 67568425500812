import { BorrowerType } from 'product_modules/enums/BorrowerType';
import BaseApi from 'api/BaseApi';
import ProductType from 'enums/ProductType';

export enum ApplicationFormPageType {
  Borrower = 'borrower',
  CoBorrower = 'coBorrower',
  CoBorrower2 = 'coBorrower_2',
  CoBorrower3 = 'coBorrower_3',
  Intermediary = 'intermediary',
  ApplicationDetails = 'applicationDetails',
  DocumentUpload = 'documentUpload',
}

export enum ApplicationFormPageBehavior {
  Required = 'required',
  Optional = 'optional',
}

export type IApplicationFormPages = Partial<Record<ApplicationFormPageType, ApplicationFormPageBehavior>>;

export interface IProduct {
  id: string;
  name: string;
  applicationFormPages: IApplicationFormPages;
  type: ProductType;
  borrowerTypes: BorrowerType[];
}

interface IProductsApi {
  getAllAvailableProducts(): Promise<IProduct[]>;
}

export default class ProductsRestApi extends BaseApi implements IProductsApi {
  public getAllAvailableProducts(): Promise<IProduct[]> {
    return this.fetch('/products');
  }
}
