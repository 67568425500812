import { FC } from 'react';
import ApplicationDocumentDownloadSnackbar from 'components/Snackbar/snackbars/ApplicationDocumentDownloadSnackbar';
import ApplicationDocumentsDownloadSnackbar from 'components/Snackbar/snackbars/ApplicationDocumentsDownloadSnackbar';
import Snackbar from 'components/Snackbar/Snackbar';
import { useAppSelector } from 'hooks/reduxHooks';
import { selectAllSnacks } from 'handlers/snacksSlice';
import { SnackbarComponentTypes } from 'components/Snackbar/Types';

const snackbarComponentsByType = {
  [SnackbarComponentTypes.ApplicationDownloadDocumentComponent]: ApplicationDocumentDownloadSnackbar,
  [SnackbarComponentTypes.ApplicationDownloadDocumentsComponent]: ApplicationDocumentsDownloadSnackbar,
};

const ConnectedSnackbar: FC = () => {
  const snackbarList = useAppSelector((selectAllSnacks));

  return <Snackbar customSnackbarComponents={snackbarComponentsByType} snackbarList={snackbarList} />;
};

export default ConnectedSnackbar;
