import BaseApi from 'api/BaseApi';
import {
  FindProductCalculationsParams,
  ProductCalculation,
} from 'product_modules/api/LoanOriginationSystem/ProductCalculationsApi';

export interface IProductCalculationsApi {
  find(params: FindProductCalculationsParams): Promise<ProductCalculation[]>;
}

export default class ProductCalculationsRestApi extends BaseApi implements IProductCalculationsApi {
  public find(params: FindProductCalculationsParams): Promise<ProductCalculation[]> {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.set('productId', params.productId);

    if (params.search) {
      urlSearchParams.set('search', params.search);
    }

    return this.fetch(`/product-calculations?${urlSearchParams}`);
  }
}
