import { FC } from 'react';
import { ForbiddenImage } from 'static/images';
import AuthorizedCenteredLayout from 'layout/AuthorizedCenteredLayout';

const ForbiddenPage: FC= () => {
  const renderSubtitle = () => {
    return (
      <>
        You do not have access to your account at this time.<br />Please contact us for more details.
      </>
    );
  };

  return (
   <AuthorizedCenteredLayout
     title="Access denied!"
     subtitle={renderSubtitle()}
     image={<ForbiddenImage />}
     applicationsBrowserTitle="Access Denied"
   />
  );
};

export default ForbiddenPage;
