import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';
import { IBorrowerProfileGroup } from 'api/digifi/layout/GroupsApi';
import { LayoutConfigurationEntityName } from 'enums/LayoutConfigurationEntityName';
import { createLayoutEntitiesSlice } from './createLayoutEntitiesSlice';

const {
  reducer,
  removeLayoutEntities: removeBorrowerProfileGroups,
} = createLayoutEntitiesSlice<
  IBorrowerProfileGroup
>({
  referenceType: LayoutConfigurationReferenceType.BorrowerProfile,
  layoutConfigurationEntityName: LayoutConfigurationEntityName.Group,
});

export {
  removeBorrowerProfileGroups,
};

export default reducer;

