import { VariableValue } from 'product_modules/api/Types';

const BORROWER_SYSTEM_NAME_PREFIX = 'borrower';
const COBORROWER_SYSTEM_NAME_PREFIX = 'coborrower';
const DEFAULT_COBORROWER_INDEX = 0;

export const convertBorrowerSystemNameToCoBorrower = (borrowerSystemName: string, coBorrowerIndex: number = DEFAULT_COBORROWER_INDEX) => {
  return borrowerSystemName.replace(
    BORROWER_SYSTEM_NAME_PREFIX,
    coBorrowerIndex === 0 ? COBORROWER_SYSTEM_NAME_PREFIX : `${COBORROWER_SYSTEM_NAME_PREFIX}_${coBorrowerIndex + 1}`,
  );
};

export const convertBorrowerVariablesToCoBorrower = (borrowerVariables: Record<string, VariableValue>, index: number) => {
  return Object.keys(borrowerVariables).reduce((previousCoBorrowerVariables, key) => ({
    ...previousCoBorrowerVariables,
    [convertBorrowerSystemNameToCoBorrower(key, index)]: borrowerVariables[key],
  }), {});
};
