import { useState, useMemo, useRef } from 'react';

function useAsyncActionCallback<CallbackArgs extends unknown[], CallbackReturnType>(
  callback: (...args: CallbackArgs) => Promise<CallbackReturnType>,
  deps: unknown[],
) {
  const [isAsyncActionInProgress, setIsAsyncActionInProgress] = useState(false);
  const actionParamsRef = useRef<CallbackArgs>();

  const makeAsyncAction = async (action: () => Promise<unknown>) => {
    try {
      setIsAsyncActionInProgress(true);

      await action();
    } finally {
      actionParamsRef.current = undefined;
      setIsAsyncActionInProgress(false);
    }
  };

  return [
    isAsyncActionInProgress,
    useMemo(() => {
      return (...args: CallbackArgs) => {
        actionParamsRef.current = args;

        return makeAsyncAction(() => callback(...args));
      };
    }, deps),
    actionParamsRef.current || [],
  ] as [boolean, (...args: CallbackArgs) => Promise<void>, Partial<CallbackArgs>];
}

export default useAsyncActionCallback;
