import { createAction, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import BorrowersRestApi, {
  ISimplifiedBorrowerParams,
} from 'api/digifi/BorrowersApi';
import { RootState } from 'store';
import { FormLayoutData } from 'product_modules/api/Types';

enum BorrowersActionType {
  GetBorrowerData = 'borrowers/getBorrowerData',
  UpdateBorrower = 'borrowers/updateBorrower',
  GetExistingBorrowerData = 'borrowers/getExistingBorrowerData',
}

const borrowersAdapter = createEntityAdapter<ISimplifiedBorrowerParams>();

const initialState = borrowersAdapter.getInitialState();

const borrowersApi = new BorrowersRestApi();

export const getBorrower = createAsyncThunk(
  BorrowersActionType.GetBorrowerData,
  async() => {
    const response = await borrowersApi.getBorrower();

    return [response];
  },
);

export const updateBorrower = createAsyncThunk<ISimplifiedBorrowerParams, { variables: FormLayoutData }>(
  BorrowersActionType.UpdateBorrower,
  async ({ variables }) => {
    return borrowersApi.updateBorrower(
      variables,
    );
  },
);

export const getExistingBorrowerData = createAsyncThunk<ISimplifiedBorrowerParams>(
  BorrowersActionType.GetExistingBorrowerData,
  async () => {
    return borrowersApi.getExistingBorrowerData();
  },
);

export const resetBorrowers = createAction('borrowers/resetBorrower');

const borrowersSlice = createSlice({
  name: 'borrowersSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBorrower.fulfilled, (state, { payload }) => {
        borrowersAdapter.setAll(state, payload);
      })
      .addCase(updateBorrower.fulfilled, (state, { payload }) => {
        borrowersAdapter.setOne(state, payload);
      })
      .addCase(getExistingBorrowerData.fulfilled, (state, { payload }) => {
        borrowersAdapter.setOne(state, payload);
      })
      .addCase(resetBorrowers, (state) => {
        borrowersAdapter.removeAll(state);
      });
  },
});

export const { selectAll: selectAllBorrowers } = borrowersAdapter.getSelectors((state: RootState) => state.borrowers);

export default borrowersSlice.reducer;
