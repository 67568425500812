import { getSamplePhoneNumber } from './utils';
import { CountryCode } from 'libphonenumber-js';

const DEFAULT_PLACEHOLDER = 'Phone Number';

const getPhoneNumberPlaceholder = (country: string | null) => {
  if (!country) {
    return DEFAULT_PLACEHOLDER;
  }

  return getSamplePhoneNumber(country as CountryCode) || DEFAULT_PLACEHOLDER;
};

export default getPhoneNumberPlaceholder;
