import { FC } from 'react';
import { ITasksElementConfig } from 'api/digifi/portal-page-elements';
import { useAppSelector } from 'hooks/reduxHooks';
import TasksElement from './TasksElement';

interface ITasksElementConnectorProps {
  config: ITasksElementConfig;
  containerClassName?: string;
  disabled?: boolean;
}

const TasksElementConnector: FC<ITasksElementConnectorProps> = ({
  config,
  containerClassName,
  disabled,
}) => {
  const taskIds = useAppSelector((state) => state.applicationData.taskIds);

  return (
    <TasksElement
      config={config}
      taskIds={taskIds}
      containerClassName={containerClassName}
      disabled={disabled}
    />
  );
};

export default TasksElementConnector;
