import maskNumberValue from 'product_modules/utils/masks/maskNumberValue';
import ValueFormattingError from 'product_modules/utils/valueFormatters/base/ValueFormattingError';
import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';
import { NumericVisualDataType } from 'product_modules/enums/VisualDataType';

const isNumberValue = getValueVisualTypeCorrespondenceChecker(NumericVisualDataType.Number);

const formatNumberValue: ValueFormatter = (
  value,
) => {
  if (!isNumberValue(value)) {
    throw new ValueFormattingError();
  }

  if (!isFinite(value)) {
    return 'Null';
  }

  return maskNumberValue(value);
}

export default formatNumberValue;
