import React, { FC, DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './Tag.module.scss';
import clsx from 'clsx';

type HTMLDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

interface TagCoreProps extends HTMLDivProps {
  medium?: boolean;
}

const TagCore: FC<TagCoreProps> = ({ className, medium, ...tagProps }) => {
  const mergedClassName = clsx(className, medium && styles.mediumSize, styles.tag);

  return <div className={mergedClassName} {...tagProps} />;
};

export default TagCore;
