import { NonNullishVariableValue } from 'product_modules/utils/valueFormatters/base/Types';

export const NUMBERS_ONLY_REGEXP = /\d(?:\.\d+)?/;

const isCompatibleWithNumberType = (value: NonNullishVariableValue): value is number | string => {
  return typeof value === 'number'
    || (typeof value ==='string' && NUMBERS_ONLY_REGEXP.test(value));
};

export default isCompatibleWithNumberType;
