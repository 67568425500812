import { useState, useEffect, useMemo } from 'react';
import { ProductCalculation } from 'product_modules/api/LoanOriginationSystem/ProductCalculationsApi';
import { useProductCalculationsApi } from 'providers/ApiServiceProvider';

const useProductCalculations = (productId: string | null) => {
  const [productCalculations, setProductCalculations] = useState<ProductCalculation[] | null>(null);
  const productCalculationsApi = useProductCalculationsApi();

  useEffect(() => {
    if (!productId) {
      return;
    }

    productCalculationsApi.find({ productId }).then((calculations) => setProductCalculations(calculations));
  }, [productId]);

  return useMemo(() => {
    if (!productCalculations) {
      return null;
    }

    return productCalculations.reduce((previousCalculationsMap, calculation) => {
      previousCalculationsMap.set(calculation.variable.systemName, calculation);

      return previousCalculationsMap;
    }, new Map<string, ProductCalculation>());
  }, [productCalculations]);
};

export default useProductCalculations;
