import { FC } from 'react';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import styles from './SkeletonTextSection.module.scss';

const SKELETON_LINES_COUNT = 3;

const SkeletonTextSection: FC = () => {
  return (
    <>
      <SkeletonText width="100px" height="12px" lineHeight="16px" color="primary20" className={styles.sectionLabel} />
      {Array.from({ length: SKELETON_LINES_COUNT }).map((_line, index) => (
        <SkeletonText
          key={index}
          width={index === SKELETON_LINES_COUNT - 1 ? '80%' : '100%'}
          height="14px"
          lineHeight="20px"
          color="primary6"
        />
      ))}
    </>
  );
};

export default SkeletonTextSection;
