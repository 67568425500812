import { FC, useRef, useState } from 'react';
import { CountryCode } from 'libphonenumber-js';
import ButtonWithLoadingState from 'components/digifi-wrappers/ButtonWithLoadingState';
import CountryCodeSelect from 'components/digifi-wrappers/CountryCodeSelect';
import { DEFAULT_PHONE_NUMBER_FORMAT } from 'handlers/settingsSlice';
import { validatePhoneNumber } from 'product_modules/utils/validation/validation';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import PhoneNumberInput from 'product_modules/components/PhoneNumberInput';
import styles from './ProvidePhoneNumberStep.module.scss';

interface IProvidePhoneNumberStepProps {
  initialPhone: string;
  onContinue: (phone: string) => Promise<void>;
  phoneNumberFormat?: string;
  isLoading?: boolean;
}

const ProvidePhoneNumberStep: FC<IProvidePhoneNumberStepProps> = ({ phoneNumberFormat = DEFAULT_PHONE_NUMBER_FORMAT, initialPhone, onContinue, isLoading }) => {
  const [phone, setPhone] = useState(initialPhone);
  const [phoneFormat, setPhoneFormat] = useState(phoneNumberFormat);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const isPhoneValid = !!validatePhoneNumber(phone || '');

  return (
    <div>
      <div className={styles.message}>
        Please provide your mobile phone number. This number must be capable of receiving text messages (standard rates apply).
      </div>

      <div className={styles.inputContainer}>
        <CountryCodeSelect
          hideClearIcon
          hasRightNeighbour
          labelTitle="Phone Number"
          className={styles.countryCodeSelectorContainer}
          value={phoneFormat}
          onChange={(option) => {
            setPhoneFormat(option.value);
          }}
          required
          disabled={isLoading}
          selectControlClassName={styles.selectControl}
        />
        <PhoneNumberInput
          required
          labelTitle=""
          hasLeftNeighbour
          containerClassName={styles.phoneNumberInputContainer}
          value={phone}
          country={phoneFormat as CountryCode}
          withFlag={false}
          onChange={(value) => setPhone(value as string)}
          disabled={isLoading}
          onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        />
      </div>

      <ButtonWithLoadingState
        disabled={isPhoneValid}
        size="form"
        onClick={() => onContinue(phone)}
        className={styles.button}
        ref={submitButtonRef}
      >
        Continue
      </ButtonWithLoadingState>
    </div>
  );
};

export default ProvidePhoneNumberStep;
