import { FC, useState } from 'react';
import styles from './BorrowerDetails.module.scss';
import { ArrowDownIcon } from 'static/images';
import ActionPopUp from 'components/digifi-wrappers/ActionPopUp';
import ActionPopUpItem from 'components/digifi-wrappers/ActionPopUpItem';
import { ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import AccountIcon from 'components/common/AccountIcon';

interface IBorrowerDetailsProps {
  borrowerName: string | null;
  onLoginDetailsClick?: () => void;
  onLogoutClick?: () => void;
  isLoading?: boolean;
}

const BorrowerDetails: FC<IBorrowerDetailsProps> = ({
  isLoading,
  borrowerName,
  onLogoutClick,
  onLoginDetailsClick,
}) => {
  const [isActionPopUpOpen, setIsActionPopUpOpen] = useState(false);

  const handleClick = () => {
    setIsActionPopUpOpen(true);
  };

  const handleClose = () => {
    setIsActionPopUpOpen(false);
  };

  const handleLogoutClick = () => {
    onLogoutClick?.();
  };

  const handleLoginDetailsClick = () => {
    onLoginDetailsClick?.();
    setIsActionPopUpOpen(false);
  };

  const renderActionPopUp = () => {
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <div className={styles.actionPopUpContainer}>
          <ActionPopUp onClose={handleClose}>
            <ActionPopUpItem onClick={handleLoginDetailsClick}>Login Details</ActionPopUpItem>
            <ActionPopUpItem danger onClick={handleLogoutClick}>Sign Out</ActionPopUpItem>
          </ActionPopUp>
        </div>
      </ClickAwayListener>
    );
  };

  const renderSkeleton = () => {
    return <SkeletonText width="126px" height="12px" color="primary20" lineHeight="20px" className={styles.skeletonContainer} />;
  };

  const renderBorrowerName = () => {
    if (isLoading) {
      return renderSkeleton();
    }

    if (!borrowerName) {
      return;
    }

    return (
      <>
        <div className={styles.borrowerName}>{borrowerName}</div>
        <ArrowDownIcon className={styles.arrowIcon} />
      </>
    );
  };

  return (
    <div className={clsx(styles.borrowerDetailsContainer, { [styles.containerDisabled]: isLoading })}>
      <div className={styles.borrowerDetailsContainer} onClick={handleClick}>
        <AccountIcon />
        {renderBorrowerName()}
      </div>
      {isActionPopUpOpen && renderActionPopUp()}
    </div>
  );
};

export default BorrowerDetails;
