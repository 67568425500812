import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import {
  ApplicationSortingField,
  ICreateApplicationParams,
  IApplicationViewModel,
} from 'api/digifi/ApplicationsApi';
import { ISortType } from 'types/SortType';
import { applicationsApi } from 'store/api';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';
import { getCurrentApplication } from './activeBorrowerInformationSlice';

enum ApplicationsActionType {
  CreateApplication = 'applications/createApplication',
  GetBorrowerApplications = 'applications/getBorrowerApplications',
  FindByDisplayId = 'applications/findByDisplayId',
}

interface IGetBorrowerApplicationParams {
  offset: number;
  count: number;
  sortType: ISortType<ApplicationSortingField>;
}

const applicationsAdapter = createEntityAdapter<IApplicationViewModel>();

export type IApplicationsState = EntityState<IApplicationViewModel>;

const initialState = applicationsAdapter.getInitialState();

export const createApplication = createAsyncThunk(
  ApplicationsActionType.CreateApplication,
  async (params: ICreateApplicationParams) => {
    return applicationsApi.createApplication(params);
  },
);

export const getBorrowerApplications = createAsyncThunk(
  ApplicationsActionType.GetBorrowerApplications,
  async(params: IGetBorrowerApplicationParams) => {
    const applications = await applicationsApi.getBorrowerApplications({
      count: params.count,
      offset: params.offset,
      sortField: params.sortType.field,
      sortDirection: params.sortType.ascending ? 'asc' : 'desc',
    });

    return {
      items: applications.applications,
      total: applications.total,
    };
  },
);

export const findApplicationByDisplayId = createAsyncThunk(
  ApplicationsActionType.FindByDisplayId,
  async (displayId: string, thunkApi) => {
    try {
      return await applicationsApi.findByDisplayId(displayId);
    } catch (error) {
      return rejectWithValueHelper(error, thunkApi);
    }
  },
);

const applicationsSlice = createSlice({
  name: 'applicationsSlice',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(createApplication.fulfilled, (state, { payload }) => {
        applicationsAdapter.setOne(state, payload);
      })
      .addCase(getCurrentApplication.fulfilled, (state, { payload }) => {
        if (payload) {
          applicationsAdapter.setOne(state, payload);
        }
      })
      .addCase(getBorrowerApplications.fulfilled, (state, { payload }) => {
        applicationsAdapter.setAll(state, payload.items);
      })
      .addCase(findApplicationByDisplayId.fulfilled, (state, { payload }) => {
        applicationsAdapter.setOne(state, payload);
      });
  },
});

export default applicationsSlice.reducer;
