import React, { FocusEvent, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import { VariableValue } from 'product_modules/api/Types';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import InputWithDataType from 'product_modules/components/InputWithDataType';
import { BaseConfigurableFormVariableField } from 'product_modules/components/ConfigurableForm/types';
import { LoaderState } from 'product_modules/components/LoaderWithState';
import CalculationsFunctionIcon from 'product_modules/components/CalculationsFunctionIcon';
import { IAddressInputClassNames, ITableInputClassNames } from 'product_modules/types/InputClassNamesTypes';
import styles from './ConfigurableFormVariable.module.scss';

export interface IConfigurableFormVariableProps<ConfigurableFormVariableFieldType extends BaseConfigurableFormVariableField> {
  className?: string;
  field: ConfigurableFormVariableFieldType;
  variable: Variable;
  value: VariableValue;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  required?: boolean;
  tabIndex: number;
  formatDisplayTitle?: (displayName: string) => string;
  onChange: (field: ConfigurableFormVariableFieldType, variable: Variable, value: VariableValue) => void;
  onFocus?: (field: ConfigurableFormVariableFieldType, variable: Variable, event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (field: ConfigurableFormVariableFieldType, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onLoaderStateReset?: (field: ConfigurableFormVariableFieldType) => void;
  visible?: boolean;
  isCalculated?: boolean;
  country?: string;
  classNames?: {
    address?: IAddressInputClassNames;
    table?: ITableInputClassNames;
  };
  labelTooltipClassName?: string;
}

const formatValue = (value: VariableValue) => {
  return typeof value === 'number' && !Number.isFinite(value) ? null : value;
};

const ConfigurableFormVariable = <ConfigurableFormVariableFieldType extends BaseConfigurableFormVariableField>({
  className,
  field,
  value,
  showLoader,
  loaderState,
  required,
  tabIndex,
  formatDisplayTitle,
  onChange,
  onFocus,
  onBlur,
  onLoaderStateReset,
  visible = true,
  variable,
  isCalculated,
  country,
  classNames,
  labelTooltipClassName,
}: IConfigurableFormVariableProps<ConfigurableFormVariableFieldType>) => {
  const labelTitle = useMemo(() => {
    return formatDisplayTitle ? formatDisplayTitle(variable.name) : variable.name;
  }, [formatDisplayTitle, variable]);

  const handleChange = useCallback((updatedValue: VariableValue) => {
    onChange(field, variable, updatedValue);
  }, [onChange, field, variable]);

  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    onFocus?.(field, variable, event);
  }, [onFocus, field, variable]);

  const handleBlur = useCallback((event?: FocusEvent<HTMLInputElement>) => {
    onBlur?.(field, variable, event);
  }, [onBlur, field, variable]);

  const handleLoaderStateReset = useCallback(() => {
    onLoaderStateReset?.(field);
  }, [onLoaderStateReset, field]);

  const inputIcon = useMemo(() => {
    if (!isCalculated) {
      return null;
    }

    return (
      <CalculationsFunctionIcon />
    );
  }, [isCalculated]);

  return (
    <Collapse in={visible} unmountOnExit>
      <div className={clsx(styles.input, className)}>
        <InputWithDataType
          visualDataType={variable.visualDataType}
          visualAttributes={variable.visualAttributes}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onLoaderStateReset={handleLoaderStateReset}
          value={formatValue(value)}
          labelTitle={labelTitle}
          disabled={isCalculated}
          tabIndex={tabIndex}
          required={!!required}
          showLoader={showLoader}
          loaderState={loaderState}
          inputIcon={inputIcon}
          country={country}
          classNames={classNames}
          titleHint={variable.description}
          labelTooltipClassName={labelTooltipClassName}
          validateOnRender={isCalculated}
        />
      </div>
    </Collapse>
  );
};

export default React.memo(ConfigurableFormVariable) as typeof ConfigurableFormVariable;
