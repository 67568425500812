import { IOnboardingData } from 'hooks/useOnboardingFlow';
import useAsyncActionCallback from 'product_modules/hooks/useAsyncActionCallback';
import { ICreateAccountFormParams } from 'types';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createAccount, getCurrentAccount } from 'handlers/authSlice';
import { updateBorrower } from 'handlers/borrowersSlice';
import { createApplication } from 'handlers/applicationsSlice';
import SubmitStep from 'components/Auth/Onboarding/steps/SubmitStep/SubmitStep';
import { setDisplayIdToRedirect } from 'handlers/applicationDataSlice';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setCurrentApplication } from 'handlers/activeBorrowerInformationSlice';
import { createNotification } from 'handlers/notificationsSlice';

interface ISubmitStepConnectorProps {
  onStepBack: () => void;
  onboardingData: IOnboardingData;
  clearOnboardingData: () => void;
}

const SubmitStepConnector = ({
  onStepBack,
  onboardingData,
  clearOnboardingData,
}: ISubmitStepConnectorProps) => {
  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const [isLoading, handleSubmit] = useAsyncActionCallback(async (createAccountParams: ICreateAccountFormParams) => {
    if (!onboardingData.borrowerType || !onboardingData.product) {
      return;
    }

    try {
      await dispatchWithUnwrap(createAccount({ ...createAccountParams, borrowerType: onboardingData.borrowerType }));

      await dispatchWithUnwrap(getCurrentAccount());

      await dispatchWithUnwrap(updateBorrower({ variables: onboardingData.borrowerFormData }));

      const { id, displayId } = await dispatchWithUnwrap(createApplication({
        productId: onboardingData.product.id,
        coborrowers: onboardingData.coBorrowerFormData.map((variables, index) => ({
          type: onboardingData.coBorrowerTypes[index],
          variables,
        })),
      }));

      dispatch(setCurrentApplication(id));
      dispatch(setDisplayIdToRedirect(displayId));

      clearOnboardingData();
    } catch (error) {
      createNotification({ notification: error.message, type: 'error', dispatch });
    }
  }, [onboardingData]);

  return (
    <SubmitStep
      onStepBack={onStepBack}
      isSubmitInProgress={isLoading}
      onboardingData={onboardingData}
      onSubmit={handleSubmit}
    />
  );
};

export default SubmitStepConnector;
