import { FC } from 'react';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import { ITask } from 'api/digifi/TasksApi';
import { ISubmitTaskParams } from 'api/digifi/TaskDetailsApi';
import TaskPopupTitle from 'components/ApplicationPage/elements/TasksElement/popups/TaskPopupTitle';
import EditTaskForm from './EditTaskForm';
import styles from './EditTaskPopup.module.scss';

interface IEditTaskPopupProps {
  task: ITask;
  onClose: () => void;
  onSubmit: (params: ISubmitTaskParams) => Promise<void>;
  loading?: boolean;
}

const EditTaskPopup: FC<IEditTaskPopupProps> = ({
  task,
  onClose,
  loading,
  onSubmit,
}) => {
  return (
    <PopUp
      title={<TaskPopupTitle task={task} />}
      closePopUp={onClose}
      classNames={{
        sectionClassName: styles.popupContainer,
        header: styles.popupHeader,
      }}
      usePortal
    >
      <PopUpContent hasTopMargin className={styles.popupContent}>
        <EditTaskForm
          task={task}
          loading={loading}
          onSubmit={onSubmit}
        />
      </PopUpContent>
    </PopUp>
  );
};

export default EditTaskPopup;
