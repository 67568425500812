import React, { FC, FocusEvent } from 'react';
import clsx from 'clsx';
import { Decimal } from 'decimal.js';
import { PercentageImage } from 'product_modules/static/images';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import NumberInput from 'product_modules/components/NumberInput';
import isNumber from 'product_modules/utils/isNumber';
import styles from './PercentageInput.module.scss';

interface PercentageInputProps {
  value: string;
  labelTitle: string;
  onChange: (value: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  inputIcon?: React.ReactNode;
  raw?: boolean;
  required?: boolean;
  errorMessage?: string;
  tooltip?: string;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  inputRef?: React.Ref<HTMLInputElement>;
  titleHint?: string;
  labelTooltipClassName?: string;
}

const MAX_DECIMAL_PART_LENGTH = 18;

const PercentageInput: FC<PercentageInputProps> = ({
  value,
  labelTitle,
  onChange,
  placeholder,
  readOnly,
  disabled,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  inputIcon,
  raw = false,
  required,
  tooltip,
  errorMessage,
  onBlur,
  onFocus,
  useSimplifiedInput = false,
  style,
  inputRef,
  titleHint,
  labelTooltipClassName,
}) => {
  const isValidNumber = value.length && isNumber(value);
  const viewValue = isValidNumber
    ? Decimal.mul(value, 100).toString()
    : value;

  const handleOnChange = (newValue: string) => {
    if (newValue.length && isNumber(newValue)) {
      onChange(Decimal.div(newValue, 100).toString());
    } else {
      onChange(newValue);
    }
  };

  const renderInputPrefix = () => {
    return (
      <div
        className={clsx(
          styles.basePrefixContainer,
          !useSimplifiedInput && styles.prefixContainer,
          useSimplifiedInput && styles.prefixContainerSimplified,
          errorMessage && styles.errorPrefixContainer,
          errorMessage && useSimplifiedInput && styles.errorPrefixContainerSimplified,
        )}
      >
        <PercentageImage className={clsx(useSimplifiedInput && styles.percentageImageSimplified)} />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <NumberInput
        value={viewValue}
        raw={raw}
        labelTitle={labelTitle}
        onChange={handleOnChange}
        hasLeftPadding={!useSimplifiedInput}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        loaderState={loaderState}
        onLoaderStateReset={onLoaderStateReset}
        showLoader={showLoader}
        tabIndex={tabIndex}
        inputIcon={inputIcon}
        required={required}
        errorMessage={errorMessage}
        tooltip={tooltip}
        decimalScale={MAX_DECIMAL_PART_LENGTH}
        onFocus={onFocus}
        onBlur={(_, event) => onBlur?.(event)}
        inputPrefix={renderInputPrefix()}
        useSimplifiedInput={useSimplifiedInput}
        inputRef={inputRef}
        className={clsx(useSimplifiedInput && styles.inputSimplified)}
        style={style}
        titleHint={titleHint}
        labelTooltipClassName={labelTooltipClassName}
      />
    </div>
  );
};

export default PercentageInput;
