import { FC } from 'react';
import clsx from 'clsx';
import TableRow from 'components/digifi-wrappers/TableRow';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import styles from './OfferSelectionElement.module.scss';

const SKELETON_OFFER_CARDS_COUNT = 3;
const SKELETON_TABLE_ROWS_COUNT = 4;

const SkeletonOfferSelectionElement: FC = () => {

  const renderTable = () => {
    return Array.from({ length: SKELETON_TABLE_ROWS_COUNT }).map((_, rowIndex) => {
      return (
        <TableRow hasTopBorder={!rowIndex} noBottomBorder={rowIndex === SKELETON_TABLE_ROWS_COUNT - 1} key={rowIndex} disableHover>
          <TableBodyCell width={50}>
            <SkeletonText width="80px" height="12px" color="primary6" lineHeight="16px" />
          </TableBodyCell>
          <TableBodyCell width={50} overflowed={false}>
            <SkeletonText width="80px" height="12px" color="primary10" lineHeight="16px" />
          </TableBodyCell>
        </TableRow>
      );
    });
  };

  return (
    <div className={styles.skeletonContainer}>
      {Array.from({ length: SKELETON_OFFER_CARDS_COUNT }).map((_, index) => {
        return (
          <div key={index} className={clsx(
            styles.offerCard,
            index > 1 && styles.skeletonCardTablet,
            index && styles.skeletonCardMobile,
          )}>
            <SkeletonText width="57%" height="14px" color="primary20" lineHeight="20px" className={styles.titleRow} />
            {renderTable()}
          </div>
        );
      })}
    </div>
  );
};

export default SkeletonOfferSelectionElement;
