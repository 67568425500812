import React, { FC } from 'react';
import clsx from 'clsx';
import Button, { ButtonProps } from 'product_modules/components/Button/Button';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import styles from './WithFieldsValidationButton.module.scss';

export interface WithFieldsValidationButtonProps extends ButtonProps {
  areFieldsInvalid?: boolean;
  buttonClassName?: string;
}

const WithFieldsValidationButton: FC<WithFieldsValidationButtonProps> = ({
  areFieldsInvalid,
  className,
  disabled,
  buttonClassName,
  ...buttonProps
}) => {
  return (
    <WrapperWithTooltip tooltip={areFieldsInvalid && 'Please provide valid inputs for all required fields'}>
      <span className={clsx(styles.buttonWrapper, className)}>
        <Button disabled={areFieldsInvalid || disabled} className={clsx(styles.button, buttonClassName)} {...buttonProps} />
      </span>
    </WrapperWithTooltip>
  );
};

export default WithFieldsValidationButton;
