import hexColorToRgb from 'product_modules/utils/hexColorToRgb';

export type RGBA = [number, number, number, number];

const addAlphaToColor = (color: string, alpha: number): RGBA => {
  const rgb = hexColorToRgb(color);
  return [...rgb, alpha] as RGBA;
};

export default addAlphaToColor;
