import { FC } from 'react';
import { useDispatch } from 'react-redux';
import useAsyncActionCallback from 'product_modules/hooks/useAsyncActionCallback';
import useDispatchWithUnwrap from 'product_modules/hooks/useDispatchWithUnwrap';
import { ITask } from 'api/digifi/TasksApi';
import { ISubmitTaskParams } from 'api/digifi/TaskDetailsApi';
import { submitTask } from 'handlers/tasksSlice';
import { createNotification } from 'handlers/notificationsSlice';
import EditTaskPopup from './EditTaskPopup';

interface IEditTaskPopupConnectorProps {
  task: ITask;
  onClose: () => void;
}

const EditTaskPopupConnector: FC<IEditTaskPopupConnectorProps> = ({ task, onClose }) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();

  const [isSubmitInProgress, handleTaskSubmit] = useAsyncActionCallback(async (params: ISubmitTaskParams) => {
    try {
      await dispatchWithUnwrap(submitTask(params));

      onClose();
    } catch (error) {
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });
    }

  }, [onClose]);

  return (
    <EditTaskPopup
      task={task}
      onClose={onClose}
      loading={isSubmitInProgress}
      onSubmit={handleTaskSubmit}
    />
  );
};

export default EditTaskPopupConnector;
