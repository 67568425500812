import {
  AddressVisualDataType,
  BooleanVisualDataType,
  DateVisualDataType,
  NumericVisualDataType,
  StringVisualDataType,
  TableVisualDataType,
} from 'product_modules/enums/VisualDataType';
import isCompatibleWithDateType from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithDateType';
import isCompatibleWithIdentificationType
  from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithIdentificationType';
import isCompatibleWithPhoneNumberType from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithPhoneNumberType';
import isCompatibleWithNumberType from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithNumberType';
import isCompatibleWithAddressType from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithAddressType';
import isCompatibleWithBooleanType from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithBooleanType';
import isCompatibleWithTextType from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithTextType';
import { NonNullishVariableValue } from 'product_modules/utils/valueFormatters/base/Types';
import isCompatibleWithPercentageType from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithPercentageType';
import { VariableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import isCompatibleWithListType from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithListType';
import { TableValue } from 'product_modules/api/Types';
import isCompatibleWithTableType
  from 'product_modules/utils/valueConversionAvailabilityCheckers/isCompatibleWithTableType';

type CompatibilityChecker<T extends NonNullishVariableValue> = (
  value: NonNullishVariableValue,
  params?: VariableVisualAttributes,
) => value is T;

interface CheckersByVisualType {
  [AddressVisualDataType.Address]: CompatibilityChecker<string | object>,
  [BooleanVisualDataType.Boolean]: CompatibilityChecker<boolean | string>,
  [DateVisualDataType.Date]: CompatibilityChecker<string>,
  [NumericVisualDataType.Number]: CompatibilityChecker<number | string>,
  [NumericVisualDataType.Monetary]: CompatibilityChecker<number | string>,
  [NumericVisualDataType.Percentage]: CompatibilityChecker<string | number>,
  [StringVisualDataType.PhoneNumber]: CompatibilityChecker<string>,
  [StringVisualDataType.List]: CompatibilityChecker<string>,
  [StringVisualDataType.Text]: CompatibilityChecker<string>,
  [StringVisualDataType.LargeText]: CompatibilityChecker<string>,
  [StringVisualDataType.EmailAddress]: CompatibilityChecker<string>,
  [StringVisualDataType.IdentificationNumber]: CompatibilityChecker<string>,
  [TableVisualDataType.Table]: CompatibilityChecker<TableValue>,
}

const COMPATIBILITY_CHECKERS_BY_VISUAL_TYPE: CheckersByVisualType = {
  [AddressVisualDataType.Address]: isCompatibleWithAddressType,
  [BooleanVisualDataType.Boolean]: isCompatibleWithBooleanType,
  [NumericVisualDataType.Number]: isCompatibleWithNumberType,
  [NumericVisualDataType.Monetary]: isCompatibleWithNumberType,
  [NumericVisualDataType.Percentage]: isCompatibleWithPercentageType,
  [StringVisualDataType.PhoneNumber]: isCompatibleWithPhoneNumberType,
  [StringVisualDataType.IdentificationNumber]: isCompatibleWithIdentificationType,
  [StringVisualDataType.List]: isCompatibleWithListType,
  [StringVisualDataType.Text]: isCompatibleWithTextType,
  [StringVisualDataType.LargeText]: isCompatibleWithTextType,
  [StringVisualDataType.EmailAddress]: isCompatibleWithTextType,
  [DateVisualDataType.Date]: isCompatibleWithDateType,
  [TableVisualDataType.Table]: isCompatibleWithTableType,
};

const getValueCompatibilityChecker = <Type extends keyof CheckersByVisualType>(
  visualDataType: Type,
) => COMPATIBILITY_CHECKERS_BY_VISUAL_TYPE[visualDataType];

export default getValueCompatibilityChecker;
