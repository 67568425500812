import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import ValueFormattingError from 'product_modules/utils/valueFormatters/base/ValueFormattingError';
import { DateVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import { DateVisualDataType } from 'product_modules/enums/VisualDataType';
import getValueVisualTypeCorrespondenceChecker from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers';
import DateTimeUtils from 'product_modules/utils/dateUtils';
import { DateTimeFormat } from 'product_modules/utils/dateFormat';

const getFormattedDate = (variableValue: string, dateFormat: string) => {
  const UTCFormatDate = DateTimeUtils.parseUtcUnsafe(variableValue);

  return UTCFormatDate.format(dateFormat);
};

const isDateValue = getValueVisualTypeCorrespondenceChecker(DateVisualDataType.Date);

const formatDateValue: ValueFormatter<DateVisualAttributes> = (
  value,
  params,
) => {
  if (!isDateValue(value)) {
    throw new ValueFormattingError();
  }

  const { dateFormat = DateTimeFormat.UsShortWithSlashes } = params || {};

  return dateFormat ? getFormattedDate(value, dateFormat) : value;
};

export default formatDateValue;
