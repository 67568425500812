import { CSSProperties, ComponentType } from 'react';
import styles from 'components/Notifications/NotificationList/Notifications.module.scss';
import { INotificationProps, NotificationComponents } from 'handlers/notificationsSlice';
import clsx from 'clsx';
import Notification from 'components/Notifications/NotificationList/Notification';

export interface INotificationListProps<CustomNotificationComponents extends Record<string, ComponentType<any>>> {
  notificationList: INotificationProps[];
  hideNotification: (id: string) => void;
  customNotificationsComponents: CustomNotificationComponents;
  className?: string;
  style?: CSSProperties;
}

const DEFAULT_STYLE = {};

const NotificationList = <CustomNotificationComponents extends Record<string, ComponentType<any>>>({
  notificationList,
  hideNotification,
  className,
  style = DEFAULT_STYLE,
  customNotificationsComponents,
}: INotificationListProps<CustomNotificationComponents>) => {
  const containerClassName = clsx(styles.notificationList, className);

  const renderNotification = (notification: string | NotificationComponents) => {
    if (typeof notification === 'string') {
      return notification;
    }

    const NotificationComponent = customNotificationsComponents[notification.componentType];

    return <NotificationComponent {...notification.props} />;
  };

  return (
    <div className={containerClassName} style={style}>
      {notificationList &&
        notificationList.map((item: INotificationProps, index: number) => {
          return (
            <Notification key={index} type={item.type} onClose={() => hideNotification(item.id)}>
              {renderNotification(item.notification)}
            </Notification>
          );
        })}
    </div>
  );
};

export default NotificationList;
