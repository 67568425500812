import OnboardingStep from 'enums/OnboardingStep';
import useStepsNavigation from './useStepsNavigation';

const ONBOARDING_STEPS_DATA_STORAGE_KEY = 'onboardingStepsData';
const ONBOARDING_CURRENT_STEP_INDEX_STORAGE_KEY = 'onboardingCurrentStepIndex';

const useOnboardingStepsNavigation = () =>
  useStepsNavigation(OnboardingStep, ONBOARDING_STEPS_DATA_STORAGE_KEY, ONBOARDING_CURRENT_STEP_INDEX_STORAGE_KEY);

export default useOnboardingStepsNavigation;
