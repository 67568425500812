import { FC } from 'react';
import SkeletonRectangle from 'components/digifi-wrappers/SkeletonRectangle';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import styles from './TasksElement.module.scss';

const SKELETON_TASKS_COUNT = 3;

const SkeletonTasksElement: FC = () => {
  return (
    <div className={styles.skeletonContainer}>
      {Array.from({ length: SKELETON_TASKS_COUNT }).map((_, index) => {
        return (
          <div key={index} className={styles.taskCard}>
            <SkeletonRectangle width="30%" height="16px" color="primary20" className={styles.taskStatus} />
            <SkeletonText width="57%" height="14px" color="primary20" lineHeight="20px" className={styles.taskTitle} />
            <div>
              <SkeletonText width="100%" height="14px" color="primary6" lineHeight="20px" />
              <SkeletonText width="100%" height="14px" color="primary6" lineHeight="20px" />
              <SkeletonText width="57%" height="12px" color="primary20" lineHeight="20px" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SkeletonTasksElement;
