import { FC } from 'react';
import { TableHeadCell, TableHeadCellProps } from 'product_modules/components/Table';
import styled from 'types/AppTheme';
import clsx from 'clsx';

const ARROW_CLASS_NAME = 'TableHeadCellWrapper-arrow';

const TableHeadCellStyled = styled(TableHeadCell)`
  .${ARROW_CLASS_NAME} path {
    fill: ${({ theme }) => theme.colors.accentColor};
  }
`;

const TableHeadCellWrapper: FC<TableHeadCellProps> = (props) => {
  return (
    <TableHeadCellStyled
      {...props}
      arrowClassName={clsx(props.arrowClassName, ARROW_CLASS_NAME)}
    />
  );
};

export default TableHeadCellWrapper;
