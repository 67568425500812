import { FC } from 'react';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import styles from './ApplicationDetailsHeader.module.scss';

const SkeletonApplicationDetailsHeader: FC = () => {
  const renderDesktopSkeleton = () => (
    <div className={styles.desktopSkeleton}>
      <SkeletonText lineHeight="36px" width="340px" height="20px" color="primary20" />
      <SkeletonText lineHeight="36px" width="150px" height="20px" color="primary20" />
    </div>
  );

  const renderMobileHeader = () => (
    <div className={styles.mobileSkeleton}>
      <SkeletonText lineHeight="36px" lineWidth="100%" width="100%" height="20px" color="primary20" />
      <SkeletonText lineHeight="36px" lineWidth="100%" width="100%" height="20px" color="primary20" />
    </div>
  );

  return (
    <div className={styles.skeletonContainer}>
      {renderDesktopSkeleton()}
      {renderMobileHeader()}
    </div>
  );
};

export default SkeletonApplicationDetailsHeader;
