import { VariableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import { NonNullishVariableValue } from 'product_modules/utils/valueFormatters/base/Types';
import isCorrespondsList from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsList';

const isCompatibleWithListType = (
  value: NonNullishVariableValue,
  attributes?: VariableVisualAttributes,
): value is string => {
  return isCorrespondsList(value, attributes);
};

export default isCompatibleWithListType;
