import LimitDecimalNumbers from 'product_modules/components/MonetaryInput/utils';
import maskNumberValue from './masks/maskNumberValue';

const limitDecimalNumbers = LimitDecimalNumbers(2);

const formatMonetaryValue = (value: number, currencyType: string = '', alwaysShowFractionalPart = false) => {
  const valueAsString = value?.toString() || '';

  const limitedValue = limitDecimalNumbers(valueAsString);
  const maskedValue = maskNumberValue(limitedValue);
  const currencySymbolText = `${currencyType}${currencyType.length > 1 ? ' ' : ''}`;

  const formattedValue = valueAsString.includes('-')
    ? maskedValue.replace('-', `-${currencySymbolText}`)
    : `${currencySymbolText}${maskedValue}`;

  return alwaysShowFractionalPart ? `${formattedValue}${!formattedValue.includes('.') ? '.00' : ''}` : formattedValue;
};

export default formatMonetaryValue;
