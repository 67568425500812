import { useAppSelector } from 'hooks/reduxHooks';

export enum SessionStatus {
  Unauthorized = 'Unauthorized',
  AwaitingPhoneVerification = 'AwaitingPhoneVerification',
  AwaitingEmailVerification = 'AwaitingEmailVerification',
  Authorized = 'Authorized',
}

const useSessionStatus = () => {
  const { accountData } = useAppSelector((state) => state.auth);

  if (!accountData || !accountData.borrowerId) {
    return SessionStatus.Unauthorized;
  }

  if (!accountData.isEmailVerified) {
    return SessionStatus.AwaitingEmailVerification;
  }

  if (!accountData?.isMfaComplete) {
    return SessionStatus.AwaitingPhoneVerification;
  }

  return SessionStatus.Authorized;
};

export default useSessionStatus;
