import ContainerWithGradient from 'components/common/ContainerWithGradient/ContainerWithGradient';
import styles from './AccountIcon.module.scss';
import { UserIcon } from 'static/images';
import React from 'react';

const AccountIcon = () => {
  return (
    <ContainerWithGradient className={styles.iconContainer}>
      <UserIcon />
    </ContainerWithGradient>
  );
};

export default AccountIcon;
