import { FC, useCallback } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import Tag from 'components/digifi-wrappers/Tag';
import usePopups from 'hooks/usePopups';
import getTaskTagColor from 'utils/getTaskTagColor';
import EditTaskPopup from './popups/EditTaskPopup';
import styles from './TasksElement.module.scss';

interface ITaskCardProps {
  taskId: string;
}

const TaskCard: FC<ITaskCardProps> = ({ taskId }) => {
  const { openPopup, renderPopups } = usePopups({
    editTask: (props) => (
      <EditTaskPopup {...props} task={task} />
    ),
  });

  const task = useAppSelector((state) => state.tasks.entities[taskId])!;

  const handleTaskClick = useCallback(() => {
    return openPopup('editTask');
  }, [task]);

  const renderTaskDescription = () => {
    return !task.instructions ? (
      <p className={styles.noItemsMessage}>
        No instructions
      </p>
    ) : (
      <p className={styles.description}>
        {task.instructions}
      </p>
    );
  };

  return (
    <>
      <div className={styles.activeTaskCard} onClick={handleTaskClick}>
        <Tag className={styles.taskStatus} color={getTaskTagColor(task.status)}>
          {task.status}
        </Tag>
        <p className={styles.taskTitle}>
          {task.title}
        </p>
        {renderTaskDescription()}
      </div>
      {renderPopups()}
    </>
  );
};

export default TaskCard;
