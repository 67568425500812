import React, { ReactNode, Ref, cloneElement, ReactElement } from 'react';
import clsx from 'clsx';
import useRandomIdFallback from 'product_modules/hooks/randomIdFallback';
import Label from 'product_modules/components/Label';
import styles from './TextInput.module.scss';

export interface TextInputWrapperProps<ChildProps = {}> {
  labelTitle?: string;
  titleHint?: string;
  children: ReactElement<ChildProps>;
  id?: string;
  errorMessage?: string;
  topRightElement?: ReactNode;
  hasRightNeighbour?: boolean;
  hasLeftNeighbour?: boolean;
  hasRightPadding?: boolean;
  hasLeftPadding?: boolean;
  inputRef?: Ref<any>;
  containerClassName?: string;
  disabled?: boolean;
  required?: boolean;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  labelTooltipClassName?: string;
}

const TextInputWrapper = <ChildProps extends { id: string; ref?: Ref<any>; className: string; disabled?: boolean }>(
  props: TextInputWrapperProps<ChildProps>,
) => {
  const {
    id: providedId,
    inputRef,
    labelTitle,
    errorMessage,
    topRightElement,
    children,
    hasRightNeighbour,
    hasLeftNeighbour,
    hasLeftPadding,
    hasRightPadding,
    containerClassName,
    disabled,
    required,
    useSimplifiedInput,
    style,
    titleHint,
    labelTooltipClassName,
  } = props;

  const id = useRandomIdFallback(providedId);

  const inputClassName = clsx(
    styles.input,
    disabled && styles.input__disabled,
    errorMessage && styles.inputError,
    hasRightNeighbour && styles.inputWithRightNeighbour,
    hasLeftNeighbour && styles.inputWithLeftNeighbour,
    hasRightPadding && styles.inputWithRightPadding,
    hasLeftPadding && styles.inputWithLeftPadding,
    useSimplifiedInput && styles.inputSimplified,
  );

  return (
    <div className={clsx(
      styles.inputContainer,
      useSimplifiedInput && styles.inputContainerSimplified,
      containerClassName,
    )}>
      <div className={styles.labelWithLink}>
        {labelTitle && (
          <Label htmlFor={id} required={required} tooltip={titleHint} questionIconClassName={labelTooltipClassName}>
            {labelTitle}
          </Label>
        )}
        {topRightElement}
      </div>
      {cloneElement(children, { id, ref: inputRef, className: inputClassName, style } as unknown as ChildProps)}
      {errorMessage && !useSimplifiedInput && <p className={styles.errorNotification}>{errorMessage}</p>}
    </div>
  );
};

export default TextInputWrapper;
