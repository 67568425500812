import React, { FC, useCallback, useState } from 'react';
import clsx from 'clsx';
import { Grid, GridCellProps, OnScrollParams, SectionRenderedParams } from 'react-virtualized';
import { TableValue } from 'product_modules/api/Types';
import { TableColumn } from 'product_modules/api/Core/VariablesApi';
import { TableBodyCell } from 'product_modules/components/Table';
import OverflowedText from 'product_modules/components/OverflowedText';
import { BooleanValueDisplayFormat } from 'product_modules/utils/valueFormatters/formatBooleanValue';
import formatValueByVariable from 'product_modules/utils/formatValueByVariable';
import getTableCellWidth from 'product_modules/components/TableInput/utils/getTableCellWidth';
import getTableHeight from 'product_modules/components/TableInput/utils/getTableHeight';
import ContainerShadows from 'product_modules/components/TableInput/ContainerShadows';
import styles from './TableInputViewMode.module.scss';

const DEFAULT_TABLE_ROW_HEIGHT = 45;
const AMOUNT_OF_ROWS_VISIBLE = 10;

interface TableInputViewModeGridProps {
  columns: TableColumn[];
  value: TableValue;
  width: number;
  onScroll: (params: OnScrollParams) => void;
  minCellWidth: number;
  isExpanded?: boolean;
  isMobile?: boolean;
}

const TableInputViewModeGrid: FC<TableInputViewModeGridProps> = ({
  columns,
  value,
  width,
  onScroll,
  isExpanded,
  minCellWidth,
  isMobile,
}) => {
  const [extremeRowsVisibility, setExtremeRowsVisibility] = useState({
    isFirstRowVisible: true,
    isLastRowVisible: true,
    isFirstColumnVisible: true,
    isLastColumnVisible: true,
  });

  const handleSectionRender = useCallback(({
    rowStartIndex,
    rowStopIndex,
    columnStartIndex,
    columnStopIndex,
  }: SectionRenderedParams) => {
    setExtremeRowsVisibility({
      isFirstRowVisible: !rowStartIndex,
      isLastRowVisible: rowStopIndex === value.length - 1,
      isFirstColumnVisible: !columnStartIndex,
      isLastColumnVisible: columnStopIndex === columns.length - 1,
    });
  }, [value.length, columns.length]);

  const renderTableInputBodyCell = (props: GridCellProps) => {
    return (
      <TableBodyCell
        width={width / columns.length}
        className={clsx(
          styles.bodyCell,
          props.rowIndex === value.length - 1 && !isExpanded && styles.lastRowBodyCell,
        )}
        key={`${value[props.rowIndex]._id}-${columns[props.columnIndex].systemName}`}
        style={props.style}
        pixels
      >
        <OverflowedText>
          {formatValueByVariable(
            value[props.rowIndex][columns[props.columnIndex].systemName],
            columns[props.columnIndex],
            {
              booleanValueFormat: BooleanValueDisplayFormat.Capitalize,
            },
          )}
        </OverflowedText>
      </TableBodyCell>
    );
  };

  return (
    <>
      <Grid
        columnWidth={getTableCellWidth(width, columns.length, minCellWidth)}
        rowCount={value.length}
        height={getTableHeight(value.length, DEFAULT_TABLE_ROW_HEIGHT, AMOUNT_OF_ROWS_VISIBLE, isExpanded, isMobile)}
        rowHeight={DEFAULT_TABLE_ROW_HEIGHT}
        cellRenderer={renderTableInputBodyCell}
        columnCount={columns.length}
        width={width}
        onScroll={onScroll}
        className={styles.tableInputGrid}
        onSectionRendered={handleSectionRender}
      />
      <ContainerShadows
        width={width}
        height={getTableHeight(value.length, DEFAULT_TABLE_ROW_HEIGHT, AMOUNT_OF_ROWS_VISIBLE, isExpanded, isMobile)}
        extremeRowsVisibility={extremeRowsVisibility}
        defaultRowHeight={DEFAULT_TABLE_ROW_HEIGHT}
      />
    </>
  );
};

export default TableInputViewModeGrid;
