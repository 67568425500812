import { FC } from 'react';
import clsx from 'clsx';
import TaskCard from './TaskCard';
import styles from './TasksElement.module.scss';

interface ITasksGridProps {
  taskIds: string[];
}

const TasksGrid: FC<ITasksGridProps> = ({ taskIds }) => {
  const containerClassName = clsx(
    styles.tasksGrid, {
      [styles.singleTaskGrid]: taskIds.length === 1,
      [styles.twoTasksGrid]: taskIds.length === 2,
    });

  return (
    <div className={containerClassName}>
      {taskIds.map((taskId) => (
        <TaskCard key={taskId} taskId={taskId} />
      ))}
    </div>
  );
};

export default TasksGrid;
