import React from 'react';
import NotificationsList from 'components/Notifications/NotificationList';
import { useDispatch } from 'react-redux';
import { hideNotification, NotificationComponentTypes, selectAllNotifications } from 'handlers/notificationsSlice';
import { INotificationListProps } from 'components/Notifications/NotificationList/NotificationList';
import { useAppSelector } from 'hooks/reduxHooks';
import AlreadyHaveAnAccountErrorNotification
  from 'components/Notifications/NotificationList/customNotifications/AlreadyHaveAnAccountErrorNotification';

const notificationsComponentsByType = {
  [NotificationComponentTypes.CreateAccountComponent]: AlreadyHaveAnAccountErrorNotification,
 };

type ConnectedNotificationsProps = Omit<
  INotificationListProps<typeof notificationsComponentsByType>,
  'notificationList' | 'hideNotification' | 'customNotificationsComponents'
>;

const ConnectedNotifications = (props: ConnectedNotificationsProps) => {
  const dispatch = useDispatch();
  const notificationsList = useAppSelector(selectAllNotifications);

  const handleNotificationClose = (id: string) => {
    dispatch(hideNotification(id));
  };

  return (
    <NotificationsList
      customNotificationsComponents={notificationsComponentsByType}
      notificationList={notificationsList}
      hideNotification={handleNotificationClose}
      {...props}
    />
  );
};

export default ConnectedNotifications;
