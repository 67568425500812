import { FC, useState } from 'react';
import { Dictionary } from '@reduxjs/toolkit';
import { ApplicationFormPageBehavior, ApplicationFormPageType, IProduct } from 'api/digifi/ProductsApi';
import ProductListModal from 'components/ApplicationForm/ProductListModal';
import { IProductOptions } from 'components/ApplicationForm/ProductListModal/ProductOptions/ProductOptions';
import { isCoBorrowerApplicationFormPage } from 'components/ApplicationForm/CreateApplication/utils';
import { ApplicationFormStep } from 'enums/ApplicationFormStep';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';

interface ICreateNewApplicationProductSelectorProps {
  products: Dictionary<IProduct>;
  onClose: () => void;
  onSubmit: (productId: string, coborrowersCount: number) => void;
}

const getPreselectedCoborrowerPages = (selectedProduct: IProduct | null): ApplicationFormStep[] => {
  return Object.keys(selectedProduct?.applicationFormPages || {})
    .filter(isCoBorrowerApplicationFormPage)
    .filter((pageType) => {
      return selectedProduct!.applicationFormPages[pageType as ApplicationFormPageType] === ApplicationFormPageBehavior.Required;
    }) as ApplicationFormStep[];
};

const CreateNewApplicationProductSelector: FC<ICreateNewApplicationProductSelectorProps> = ({
  onClose,
  products,
  onSubmit,
}) => {
  const productList = Object.values(products).filter((product) => product) as IProduct[];

  const [selectedProductId, setSelectedProductId] = useState(productList[0]?.id);
  const selectedProduct = selectedProductId ? products[selectedProductId] || null : null;

  const [productOptions, setProductOptions] = useState<IProductOptions>({
    coborrowers: getPreselectedCoborrowerPages(selectedProduct),
  });

  const handleProductSelect = (id: string) => {
    setSelectedProductId(id);
    setProductOptions({
      coborrowers: getPreselectedCoborrowerPages(products[id] || null),
    });
  };

  const handleSubmit = () => {
    if (!selectedProduct) {
      return;
    }

    onSubmit(selectedProduct.id, productOptions.coborrowers.length);
  };

  if (!selectedProduct && !productList[0]) {
    return <LoaderOverlay />;
  }

  return (
    <ProductListModal
      selectedProduct={selectedProduct || productList[0]}
      products={productList}
      onSelect={handleProductSelect}
      onClose={onClose}
      onOptionsChange={setProductOptions}
      productOptions={productOptions}
      onContinue={handleSubmit}
    />
  );
};

export default CreateNewApplicationProductSelector;
