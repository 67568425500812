import React, { FC, LabelHTMLAttributes } from 'react';
import clsx from 'clsx';
import QuestionIcon from 'product_modules/components/QuestionIcon';
import RequiredSymbol from 'product_modules/components/RequiredSymbol';
import styles from './Label.module.scss';

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  id?: string;
  required?: boolean;
  className?: string;
  tooltip?: React.ReactNode;
  tooltipClassName?: string;
  requiredSymbolClassName?: string;
  questionIconClassName?: string;
}

const Label: FC<LabelProps> = ({
  children,
  required,
  className,
  tooltip,
  tooltipClassName,
  requiredSymbolClassName,
  questionIconClassName,
  ...restProps
}) => (
  <label {...restProps} className={clsx(styles.label, className)}>
    {children}
    {required && <RequiredSymbol className={requiredSymbolClassName} />}
    {tooltip && (
      <QuestionIcon
        className={clsx(styles.questionIcon, questionIconClassName)}
        tooltipClassName={tooltipClassName}
        tooltip={tooltip}
      />
    )}
  </label>
);

export default Label;
