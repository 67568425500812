import { getCountries, getCountryCallingCode } from 'product_modules/components/PhoneInput';
import phoneNumberInputLocale from 'react-phone-number-input/locale/en.json';
import { Option } from 'product_modules/components/SelectInput/SelectInput';

export const PHONE_NUMBER_FORMATS: Option[] = getCountries().map((countryCode: string) => {
  return {
    value: countryCode,
    name: `+${getCountryCallingCode(countryCode)}`,
    description: phoneNumberInputLocale[countryCode as keyof typeof phoneNumberInputLocale],
  };
});
