import styled from 'types/AppTheme';

const OVERLAY_COLOR = 'linear-gradient(201.19deg, rgba(0, 0, 0, 0) 19.02%, rgba(0, 0, 0, 0) 50.97%, rgba(0, 0, 0, 0.2) 50.97%, rgba(0, 0, 0, 0.1) 82.93%)';

export const getBackground = (color: string) => {
  return `${OVERLAY_COLOR}, ${color};`;
};

interface IContainerWithGradientStyledProps {
  useAccentColor?: boolean;
}

const ContainerWithGradientStyled = styled.div<IContainerWithGradientStyledProps>`
  background: ${({ theme, useAccentColor }) => getBackground(useAccentColor ? theme.colors.accentColor: theme.colors.brandColor)};
  background-blend-mode: overlay, normal;
`;

export default ContainerWithGradientStyled;
