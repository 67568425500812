import { FC, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthorizedLayout from 'layout/AuthorizedLayout';
import DigitalLendingPortalEnvironment from 'enums/DigitalLendingPortalEnvironment';
import useTestModeNotifications from 'hooks/useTestModeNotifications';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import ApplicationHeader from 'components/ApplicationPage/ApplicationHeader';
import ActiveApplication from 'components/ApplicationPage/ApplicationPage';
import AuthorizedPageWrapper from 'pages/applications/AuthorizedPageWrapper/AuthorizedPageWrapper';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { findApplicationByDisplayId } from 'handlers/applicationsSlice';
import useSelectedApplication from 'hooks/useSelectedApplication';
import { AppRoute } from 'enums/AppRoute';
import { HttpStatusCode } from 'enums/HttpStatusCode';
import { createNotification } from 'handlers/notificationsSlice';
import { resetBorrowers } from 'handlers/borrowersSlice';

const ApplicationPage: FC = () => {
  const { applicationDisplayId } = useParams<{ applicationDisplayId: string }>();

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const portalEnvironment = useAppSelector((state) => state.settings.environment);
  const application = useSelectedApplication();

  const testModeNotifications = useTestModeNotifications(application?.id);

  const renderApplicationHeader = () => (
    <ApplicationHeader />
  );

  const handleApplicationFetch = useCallback(async (displayId: string) => {
    try {
      await dispatchWithUnwrap(findApplicationByDisplayId(displayId));
    } catch (error) {
      if (error.responseStatus === HttpStatusCode.NotFound) {
        dispatch(resetBorrowers());
        return navigate(AppRoute.Home);
      }

      createNotification({ notification: error.message, type: 'error', dispatch });
    }
  }, []);

  useEffect(() => {
    if (applicationDisplayId) {
      handleApplicationFetch(applicationDisplayId);
    }
  }, [applicationDisplayId, handleApplicationFetch]);

  const renderPageContent = () => {
    if (!application) {
      return (
        <LoaderOverlay />
      );
    }

    return (
      <ActiveApplication application={application} />
    );
  };

  return (
    <AuthorizedLayout
      applicationsBrowserTitle={`Application | ${applicationDisplayId}`}
      testModeNotifications={portalEnvironment === DigitalLendingPortalEnvironment.Testing ? testModeNotifications : undefined}
      renderApplicationHeader={renderApplicationHeader}
      showApplicationHeader
    >
      <AuthorizedPageWrapper>
        {renderPageContent()}
      </AuthorizedPageWrapper>
    </AuthorizedLayout>
  );
};

export default ApplicationPage;
