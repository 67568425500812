import { ApplicationStatusType } from 'api/digifi/ApplicationsApi';
import useCurrentApplication from 'hooks/useCurrentApplication';
import useCurrentBorrower from 'hooks/useCurrentBorrower';
import useAvailableProducts from 'hooks/useAvailableProducts';

export enum CurrentApplicationState {
  NoActiveApplication = 'NoActiveApplication',
  ApplicationInProgress = 'ApplicationInProgress',
  NotEligible = 'NotEligible',
}

const FINAL_STATUS_TYPES = [ApplicationStatusType.Approve, ApplicationStatusType.Reject];

const isCurrentApplicationInFinalStatus = (statusType: ApplicationStatusType) => {
  return FINAL_STATUS_TYPES.includes(statusType);
};

const useCurrentApplicationState = (): CurrentApplicationState => {
  const currentApplication = useCurrentApplication();
  const borrower = useCurrentBorrower();
  const products = useAvailableProducts();

  if (currentApplication && !isCurrentApplicationInFinalStatus(currentApplication.status.type)) {
    return CurrentApplicationState.ApplicationInProgress;
  }

  const borrowerIsNotEligible = borrower?.locked || !products.length;

  return borrowerIsNotEligible
    ? CurrentApplicationState.NotEligible
    : CurrentApplicationState.NoActiveApplication;
};

export default useCurrentApplicationState;
