import React, { FC } from 'react';
import styles from './PageNoAvailable.module.scss';
import { OopsImage } from 'static/images';
import clsx from 'clsx';

interface IPageNoAvailableProps {
  className?: string;
}

const PageNoAvailable: FC<IPageNoAvailableProps> = ({
  className,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <OopsImage className={styles.image} />
      <div className={styles.title}>Page not available</div>
    </div>
  );
};

export default PageNoAvailable;
