import { downloadFile } from 'product_modules/utils/downloadFile';

interface IResponseParams {
  file: Blob;
  filename: string;
}

export const downloadBlobFile = (response: IResponseParams) => {
  const url = window.URL.createObjectURL(response.file);

  downloadFile(url, response.filename);
};
