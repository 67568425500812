import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'product_modules/hooks/useQueryParam';
import CreateNewApplicationHeader from 'components/ApplicationForm/CreateNewApplicationHeader';
import CreateApplication from 'components/ApplicationForm/CreateApplication';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import AuthorizedLayout from 'layout/AuthorizedLayout';
import AuthorizedPageWrapper from 'pages/applications/AuthorizedPageWrapper/AuthorizedPageWrapper';
import { useAppSelector } from 'hooks/reduxHooks';
import { AppRoute } from 'enums/AppRoute';
import usePopups from 'hooks/usePopups';
import DiscardApplicationPopUp
  from 'components/ApplicationForm/DiscardApplicationPopUp/DiscardApplicationPopupConnector';
import useCurrentBorrower from 'hooks/useCurrentBorrower';

const PRODUCT_ID_QUERY_ATTRIBUTE = 'productId';
const COBORROWERS_COUNT_QUERY_ATTRIBUTE = 'coborrowersCount';
const DEFAULT_COBORROWERS_COUNT = 0;

const CreateApplicationPage: FC = () => {
  const navigate = useNavigate();

  const currentQueryParams = useQueryParams();

  const { openPopup, renderPopups } = usePopups({
    discardApplication: ({ onClose }) => (
      <DiscardApplicationPopUp onClose={onClose} />
    ),
  });

  const productId = currentQueryParams.get(PRODUCT_ID_QUERY_ATTRIBUTE);
  const coborrowersCount = currentQueryParams.get(COBORROWERS_COUNT_QUERY_ATTRIBUTE);

  const borrower = useCurrentBorrower();
  const products = useAppSelector((state) => state.products.entities);
  const { allowToDiscardNewApplication } = useAppSelector((state) => state.activeBorrowerInformation);
  const selectedProduct = productId ? products[productId] : null;

  const renderApplicationHeader = () => (
    <CreateNewApplicationHeader productName={selectedProduct?.name || ''} showDiscardButton={allowToDiscardNewApplication} />
  );

  useEffect(() => {
    if (!productId) {
      navigate(AppRoute.Home);
    }
  }, [productId]);

  const renderPageContent = () => {
    if (!(borrower && selectedProduct)) {
      return <LoaderOverlay />;
    }

    return (
      <CreateApplication
        coborrowersCount={coborrowersCount ? parseInt(coborrowersCount) : DEFAULT_COBORROWERS_COUNT}
        product={selectedProduct}
      />
    );
  };

  return (
    <AuthorizedLayout
      applicationsBrowserTitle="Apply"
      renderApplicationHeader={renderApplicationHeader}
      showApplicationHeader
      onHomeButtonClick={() => openPopup('discardApplication')}
    >
      <AuthorizedPageWrapper>
        {renderPageContent()}
      </AuthorizedPageWrapper>
      {renderPopups()}
    </AuthorizedLayout>
  );
};

export default CreateApplicationPage;
