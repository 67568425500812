import { FC, useEffect } from 'react';
import { useQueryParams } from 'product_modules/hooks/useQueryParam';
import { findApplicationByDisplayId } from 'handlers/applicationsSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useSelectedApplication from 'hooks/useSelectedApplication';
import ApplicationDetailsPopup from './ApplicationDetailsPopup';

interface IApplicationDetailsPopupConnectorProps {
  onClose: () => void;
}

const ApplicationDetailsPopupConnector: FC<IApplicationDetailsPopupConnectorProps> = ({ onClose }) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const currentQueryParams = useQueryParams();

  const application = useSelectedApplication();

  const applicationDisplayId = currentQueryParams.get('applicationDetails');

  useEffect(() => {
    if (applicationDisplayId) {
      dispatchWithUnwrap(findApplicationByDisplayId(applicationDisplayId));
    }
  }, [applicationDisplayId]);

  return (
    <ApplicationDetailsPopup
      application={application}
      onClose={onClose}
    />
  );
};

export default ApplicationDetailsPopupConnector;
