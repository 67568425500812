import { FC } from 'react';
import CountryCodeSelect, { CountryCodeSelectProps } from 'product_modules/components/CountryCodeSelect';

const CountryCodeSelectWrapper: FC<CountryCodeSelectProps> = (props) => {
  return (
    <CountryCodeSelect {...props} />
  );
};

export default CountryCodeSelectWrapper;
