import { ActionCreatorWithPayload, createEntityAdapter, createSlice, EntityState, Reducer } from '@reduxjs/toolkit';
import pluralize from 'pluralize';
import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';
import { LayoutConfigurationEntityName } from 'enums/LayoutConfigurationEntityName';
import { getLayoutConfiguration } from './layoutConfigurationsSlice';

export interface ILayoutEntityState<EntityType> extends EntityState<EntityType> {} {}

interface ICreateLayoutEntitiesSliceParams {
  referenceType: LayoutConfigurationReferenceType;
  layoutConfigurationEntityName: LayoutConfigurationEntityName;
}

interface ICreateLayoutEntitiesSliceResult {
  reducer: Reducer;
  removeLayoutEntities: ActionCreatorWithPayload<void>;
}

export const createLayoutEntitiesSlice = <
  EntityType extends { id: string },
>({ referenceType, layoutConfigurationEntityName }: ICreateLayoutEntitiesSliceParams)
  : ICreateLayoutEntitiesSliceResult => {
  const layoutEntitiesAdapter = createEntityAdapter<EntityType>();

  const initialState = layoutEntitiesAdapter.getInitialState();

  const layoutEntitiesSlice = createSlice({
    name: 'layoutEntitiesSlice',
    initialState,
    reducers: {
      removeLayoutEntities(state) {
        layoutEntitiesAdapter.removeAll(state as ILayoutEntityState<EntityType>);
      },
    },
    extraReducers: builder => {
      builder.addCase(getLayoutConfiguration.fulfilled, (state, action) => {
        const [requestReferenceType] = action.meta.referenceKey.split('-');

        if (requestReferenceType !== referenceType) {
          return;
        }

        layoutEntitiesAdapter.addMany(
          state as ILayoutEntityState<EntityType>,
          action.payload[pluralize(layoutConfigurationEntityName) as 'groups' | 'cards' | 'variables'] as unknown as EntityType[],
        );
      });
    },
  });


  return {
    reducer: layoutEntitiesSlice.reducer,
    removeLayoutEntities: layoutEntitiesSlice.actions.removeLayoutEntities,
  };
};
