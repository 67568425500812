import { FC } from 'react';
import { TableBodyCell, TableBodyCellProps } from 'product_modules/components/Table';

const TableBodyCellWrapper: FC<TableBodyCellProps> = (props) => {
  return (
    <TableBodyCell {...props} />
  );
};

export default TableBodyCellWrapper;
