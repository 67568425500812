export const removeAt = <ArrayElement>(source: ArrayElement[], index: number): ArrayElement[] => {
  return [...source.slice(0, index), ...source.slice(index + 1)];
};

export const replaceAt = <ArrayElement>(source: ArrayElement[], index: number, element: ArrayElement): ArrayElement[] => {
  return [...source.slice(0, index), element, ...source.slice(index + 1)];
};

export const insertAt = <ArrayElement>(source: ArrayElement[], index: number, element: ArrayElement): ArrayElement[] => {
  return [...source.slice(0, index), element, ...source.slice(index)];
};

export const generateAndFillEmptyArrayOfLength = <ArrayElement>(length: number, initialValue: ArrayElement): ArrayElement[] => {
  return Array.from({ length }, () => initialValue);
};
