import React, { forwardRef, MouseEvent } from 'react';
import clsx from 'clsx';
import OverflowedText from 'product_modules/components/OverflowedText';
import { getFormattedWidth } from 'product_modules/components/Table/utils';
import styles from './TableBodyCell.module.scss';
import TableBodyCellNoContent from 'product_modules/components/Table/TableBodyCell/TableBodyCellNoContent';

export interface TableBodyCellProps {
  width?: number;
  className?: string;
  noPadding?: boolean;
  lastColumnCell?: boolean;
  overflowed?: boolean;
  pixels?: boolean;
  disableHover?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  asNameCell?: boolean;
  withRightPadding?: boolean;
  error?: boolean;
  overflowContainerClassName?: string;
  dashedTopBorder?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const TableBodyCell = forwardRef<HTMLDivElement | null, TableBodyCellProps>(
  ({
    children,
    className,
    width,
    noPadding = false,
    lastColumnCell = false,
    overflowed = false,
    onClick,
    pixels = false,
    disableHover = false,
    asNameCell,
    error,
    withRightPadding,
    overflowContainerClassName,
    dashedTopBorder,
    disabled,
    style,
}, ref) => {
  const renderCellContext = () => {
    const content = children ? <>{children}</> : <TableBodyCellNoContent />;

    if (overflowed) {
      return <OverflowedText className={overflowContainerClassName}>{content}</OverflowedText>;
    }

    return content;
  };

  return (
    <div
      className={clsx(
        styles.tableBodyCell,
        noPadding && styles.noPaddings,
        lastColumnCell && styles.lastColumnCell,
        disableHover ? styles.tableBodyCell : styles.tableBodyCellHover,
        asNameCell && styles.nameCell,
        error && styles.errorCell,
        withRightPadding && styles.withRightPadding,
        dashedTopBorder && styles.dashedTopBorder,
        disabled && styles.disabledCell,
        className,
      )}
      style={{ ...style, width: getFormattedWidth(pixels, width) }}
      onClick={onClick}
      ref={ref}
    >
      {renderCellContext()}
    </div>
  );
});

export default TableBodyCell;
