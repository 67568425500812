import {
  AddressVisualDataType,
  BooleanVisualDataType,
  DateVisualDataType,
  NumericVisualDataType,
  StringVisualDataType,
  TableVisualDataType,
  VisualDataType,
} from 'product_modules/enums/VisualDataType';
import {
  DateVisualAttributes,
  MonetaryVisualAttributes,
  IdentificationTypeVisualAttributes,
  TableColumn,
  TableVisualAttributes,
  VariableVisualAttributes,
} from 'product_modules/api/Core/VariablesApi';
import { VariableValue } from 'product_modules/api/Types';
import maskNumberValue from 'product_modules/utils/masks/maskNumberValue';
import formatMonetaryValue from 'product_modules/utils/valueFormatters/formatMonetaryValue';
import formatPhoneNumberValue from 'product_modules/utils/valueFormatters/formatPhoneNumberValue';
import formatDateValue from 'product_modules/utils/valueFormatters/formatDateValue';
import formatPercentageValue from 'product_modules/utils/valueFormatters/formatPercentageValue';
import formatIdentificationNumberValue from 'product_modules/utils/valueFormatters/formatIdentificationNumberValue';
import { IdentificationType } from 'product_modules/components/IdentificationNumberInput/types';
import { buildFullAddressString } from './buildFullAddressString';

export type VariableValidationAndFormattingFields = {
  visualDataType: VisualDataType;
  visualAttributes: VariableVisualAttributes;
}

const formatVariableValue = (variable: VariableValidationAndFormattingFields, value: VariableValue) => {
  try {
    return formatByDataType(variable, value);
  } catch (error) {
    return value;
  }
};

const formatByDataType = (variable: VariableValidationAndFormattingFields, value: VariableValue) => {
  if (value === null || value === undefined) {
    return value;
  }

  const visualDataType = variable.visualDataType;
  const identificationNumberType = (variable.visualAttributes as IdentificationTypeVisualAttributes).identificationNumberType;

  if (visualDataType === NumericVisualDataType.Number) {
    return maskNumberValue(value.toString());
  }

  if (visualDataType === NumericVisualDataType.Monetary) {
    const currency = (variable.visualAttributes as MonetaryVisualAttributes).currency;

    return formatMonetaryValue(value as number, { currency });
  }

  if (visualDataType === NumericVisualDataType.Percentage) {
    return formatPercentageValue(value as number);
  }

  if (visualDataType === StringVisualDataType.PhoneNumber) {
    return formatPhoneNumberValue(value as string);
  }

  if (visualDataType === StringVisualDataType.IdentificationNumber && identificationNumberType) {
    return formatIdentificationNumberValue(value.toString(), { identificationNumberType: identificationNumberType as IdentificationType });
  }

  if (visualDataType === DateVisualDataType.Date) {
    const dateFormat = (variable.visualAttributes as DateVisualAttributes).dateFormat;

    return formatDateValue(value, { dateFormat });
  }

  if (visualDataType === AddressVisualDataType.Address) {
    return typeof value === 'object' && !Array.isArray(value) ? buildFullAddressString(value) : '';
  }

  if (visualDataType === BooleanVisualDataType.Boolean) {
    return value.toString();
  }

  if (visualDataType === TableVisualDataType.Table) {
    return formatTableValue(value, (variable.visualAttributes as TableVisualAttributes).columns || []);
  }

  return value;
};

const formatTableValue = (table: VariableValue, columns: TableColumn[]): VariableValue => {
  if (!Array.isArray(table)) {
    return table;
  }

  return table.reduce((result, row, index) => {
    const rowValue = columns.reduce((columnsResult, column) => {
      const formattedValue = formatVariableValue(column, row[column.systemName]);
      const columnValue = (formattedValue !== null && formattedValue !== undefined)
        ? `${column.name}: ${formattedValue}`
        : '';

      if (!columnsResult) {
        return columnValue;
      }

      return columnValue ? `${columnsResult}, ${columnValue}` : columnsResult;
    }, '');

    return result ? `${result}, Row ${index} (${rowValue})` : `Row ${index} (${rowValue})`;
  }, '');
};

export default formatVariableValue;
