import dayjs, { Dayjs, ManipulateType, OpUnitType, QUnitType, UnitType } from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import UTC from 'dayjs/plugin/utc';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(UTC);
dayjs.extend(AdvancedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(CustomParseFormat);

export const getStartOfTheDay = (date: Date) => {
  return dayjs(date).startOf('day').toDate();
};

export const getEndOfTheDay = (date: Date) => {
  return dayjs(date).endOf('day').toDate();
};

export default class DateTimeUtils {
  private constructor(private rawDate: Dayjs) {}

  public static now() {
    const now = dayjs();

    return new DateTimeUtils(now);
  }

  public static parse(date: Date): DateTimeUtils;
  public static parse(date: number): DateTimeUtils;
  public static parse(date: string, formatString: string): DateTimeUtils;
  public static parse(date: string | Date | number, formatString?: string): DateTimeUtils {
    const rawDate = dayjs(date, formatString, !!formatString);

    return new DateTimeUtils(rawDate);
  }

  /**
   * @deprecated The method should not be used in almost all cases. Since we can parse date string w/o format.
   * use .parse instead and pass expected date format.
   */
  public static parseUnsafe(date: string | Date | number, formatString?: string): DateTimeUtils {
    const rawDate = dayjs(date, formatString, !!formatString);

    return new DateTimeUtils(rawDate);
  }

  /**
   * @deprecated The method should not be used in almost all cases. Since we can parse date string w/o format.
   */
  public static parseUtcUnsafe(date: string | Date | number, formatString?: string): DateTimeUtils {
    const rawDate = dayjs.utc(date, formatString, !!formatString).local();

    return new DateTimeUtils(rawDate);
  }

  public static parseUtc(date: Date): DateTimeUtils;
  public static parseUtc(date: number): DateTimeUtils;
  public static parseUtc(date: string, formatString: string): DateTimeUtils;
  public static parseUtc(date: string | Date | number, formatString?: string): DateTimeUtils {
    const rawDate = dayjs.utc(date, formatString, !!formatString).local();

    return new DateTimeUtils(rawDate);
  }

  public format(formatString: string): string {
    return this.rawDate.format(formatString);
  }

  public toDate(): Date {
    return this.rawDate.toDate();
  }

  public toNumber(): number {
    return this.rawDate.valueOf();
  }

  public add(value: number, unit: ManipulateType): DateTimeUtils {
    const newRawDate = this.rawDate.add(value, unit);

    return new DateTimeUtils(newRawDate);
  }

  public subtract(value: number, unit: ManipulateType): DateTimeUtils {
    const newRawDate = this.rawDate.subtract(value, unit);

    return new DateTimeUtils(newRawDate);
  }

  public startOf(unit: OpUnitType): DateTimeUtils {
    const newRawDate = this.rawDate.startOf(unit);

    return new DateTimeUtils(newRawDate);
  }

  public endOf(unit: OpUnitType): DateTimeUtils {
    const newRawDate = this.rawDate.endOf(unit);

    return new DateTimeUtils(newRawDate);
  }

  public toIsoString(): string {
    return this.rawDate.toISOString();
  }

  public isValid(): boolean {
    return this.rawDate.isValid();
  }

  public set(value: number, unit: UnitType): DateTimeUtils {
    const newRawDate = this.rawDate.set(unit, value);

    return new DateTimeUtils(newRawDate);
  }

  public utc(template?: string): string {
    return this.rawDate.utc().format(template);
  }

  /**
   * Returns numbers from 0 (Sunday) to 6 (Saturday).
   */
  public day(): number {
    return this.rawDate.day();
  }

  public fromNow(): string {
    return this.rawDate.fromNow() || '';
  }

  public isSame(date: DateTimeUtils): boolean {
    return this.rawDate.isSame(date.rawDate);
  }

  public isBefore(date: DateTimeUtils): boolean {
    return this.rawDate.isBefore(date.rawDate);
  }

  public difference(date: DateTimeUtils, unit: QUnitType | OpUnitType): number {
    return this.rawDate.diff(date.rawDate, unit);
  }

  public gte(date: DateTimeUtils): boolean {
    return this.difference(date, 'ms') >= 0;
  }

  public lte(date: DateTimeUtils): boolean {
    return this.difference(date, 'ms') <= 0;
  }
}
