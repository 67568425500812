import { FC } from 'react';
import clsx from 'clsx';
import styles from './Ellipses.module.scss';

const DEFAULT_ELLIPSES_COUNT = 3;

export interface IEllipsesProps {
  count?: number;
  ellipseClassName?: string;
  className?: string;
}

const Ellipses: FC<IEllipsesProps> = ({
  count = DEFAULT_ELLIPSES_COUNT,
  className,
  ellipseClassName,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className={clsx(styles.ellipse, ellipseClassName)} />
      ))}
    </div>
  );
};

export default Ellipses;
