import { FC } from 'react';
import LinkButton, { LinkButtonProps } from 'product_modules/components/LinkButton';
import styled from 'types/AppTheme';

interface ILinkButtonStyledProps {
  disabled?: boolean;
}

const LinkButtonStyled = styled(LinkButton)<ILinkButtonStyledProps>`
  color: ${({ theme }) => theme.colors.accentColor};

  &:hover, &:focus {
    color: ${({ theme, disabled }) => disabled ? '' : theme.colors.hoverAccentColor};
  }
`;

const LinkButtonWrapper: FC<LinkButtonProps> = (props) => {
  return (
    <LinkButtonStyled {...props} />
  );
};

export default LinkButtonWrapper;
