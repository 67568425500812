import BaseApi, { FetchMethodType } from 'api/BaseApi';

export interface IResetPasswordApi {
  resetPassword(email: string): void;
  createNewPassword(password: string, resetPasswordToken: string): void;
}

export default class ResetPasswordRestApi extends BaseApi implements IResetPasswordApi {
  public resetPassword(email: string) {
    return this.fetch('/reset-password', {
      method: FetchMethodType.POST,
      body: {
        email,
      },
    });
  };

  public createNewPassword(password: string, resetPasswordToken: string) {
    return this.fetch(`/reset-password/${resetPasswordToken}`, {
      method: FetchMethodType.PUT,
      body: {
        password,
      },
    });
  };
}
