import { FC } from 'react';
import PriorApplications from 'components/ApplicationsList/PriorApplications';
import ApplicationDetailsPopup from 'components/ApplicationDetailsPopup';
import usePopupsWithRouting from 'hooks/usePopupsWithRouting';
import styles from './ApplicationHistory.module.scss';

const ApplicationHistory: FC = () => {
  const { openPopup, renderPopups } = usePopupsWithRouting({
    applicationDetails: ({ onClose }) => (
      <ApplicationDetailsPopup onClose={onClose} />
    ),
  });

  return (
    <>
      <div className={styles.blockContainer}>
        <div className={styles.blockTitle}>
          My Applications
        </div>
        <PriorApplications
          onClick={(clickedApplication) => openPopup('applicationDetails', clickedApplication.displayId)}
        />
      </div>
      {renderPopups()}
    </>
  );
};

export default ApplicationHistory;
