import { useEffect, useMemo } from 'react';
import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';
import useDispatchWithUnwrap from 'product_modules/hooks/useDispatchWithUnwrap';
import { buildReferenceKey } from 'product_modules/utils/layoutConfiguration/buildReferenceKey';
import { getLayoutConfiguration } from 'handlers/layoutConfigurationsSlice';

interface IUseLayoutConfigurationReferenceKeyWithLayoutFetchParams {
  referenceType: LayoutConfigurationReferenceType;
  reference?: string | null;
  referenceExists?: boolean;
}

export const useLayoutConfigurationReferenceKeyWithLayoutFetch = ({
  referenceType,
  reference,
  referenceExists = true,
}: IUseLayoutConfigurationReferenceKeyWithLayoutFetchParams) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const layoutConfigurationReference = useMemo(() => ({
    referenceType,
    reference: reference || '',
  }), [referenceType, reference]);

  useEffect(() => {
    if (referenceExists && !reference) {
      return;
    }

    dispatchWithUnwrap(getLayoutConfiguration({
      ...layoutConfigurationReference,
    }));
  }, [layoutConfigurationReference]);

  return buildReferenceKey(layoutConfigurationReference);
};
