import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { ILegalDocument } from 'api/digifi/SettingsApi';
import { setSelectedDocument } from 'handlers/settingsSlice';
import LegalConsentsCheckbox from './LegalConsentsCheckbox';

interface ILegalConsentsProps {
  className?: string;
  isChecked: boolean;
  onCheck: () => void;
}

const LegalConsents = (props: ILegalConsentsProps) => {
  const dispatch = useAppDispatch();
  const { legalDocuments } = useAppSelector(state => state.settings);
  const createApplicationLegalDocuments = legalDocuments.filter(document => document.showOnSubmitApplication);

  const handleLegalDocumentClick = (legalDocument: ILegalDocument) => {
    dispatch(setSelectedDocument(legalDocument));
  };

  return (
    <LegalConsentsCheckbox
      legalDocuments={createApplicationLegalDocuments}
      onLegalDocumentClick={handleLegalDocumentClick}
      {...props}
    />
  );
};

export default LegalConsents;
