import { useAppSelector } from './reduxHooks';

const useSelectedApplication = () => {
  const selectedApplicationId = useAppSelector(
    (state) => state.applicationData.selectedApplicationId,
  );

  return useAppSelector(
    (state) => selectedApplicationId ? state.applications.entities[selectedApplicationId] : null,
  ) || null;
};

export default useSelectedApplication;
