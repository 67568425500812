import { CorrespondenceChecker } from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/Types';
import { ListVisualDataTypeAttributes } from 'product_modules/api/Core/VariablesApi';

const isCorrespondsList: CorrespondenceChecker<string> = (
  value,
  attributes,
): value is string => {
  if (typeof value !== 'string') {
    return false;
  }

  const options = (attributes as ListVisualDataTypeAttributes)?.optionsList || [];
  return options.includes(value);
};

export default isCorrespondsList;
