import { DataWithLayoutConfigurationReference } from 'product_modules/types/LayoutConfigurationReference';
import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';

export const buildReferenceKey = <ParamsType>(params: DataWithLayoutConfigurationReference<ParamsType> & {
  referenceType: LayoutConfigurationReferenceType;
}) => {
  const referencePart = params.reference ? `-${params.reference}` : '';

  return `${params.referenceType}${referencePart}`;
};
