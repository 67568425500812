import { FC } from 'react';
import styles from './FooterContent.module.scss';
import { ILegalDocument } from 'api/digifi/SettingsApi';

interface IFooterContentProps {
  currentYear: number;
  companyName: string | null;
  footerDocuments: ILegalDocument[];
  onOpenLegalDocument: (legal: ILegalDocument) => void;
}

const FooterContent: FC<IFooterContentProps> = ({
  companyName,
  currentYear,
  footerDocuments,
  onOpenLegalDocument,
}) => {
  return (
    <div className={styles.footerContent}>
      <div className={styles.companyName}>© {currentYear} {companyName}</div>
      <div className={styles.documentsContainer}>
        {footerDocuments.map((document) => (
          <span
            key={document.id}
            className={styles.documentName}
            onClick={() => onOpenLegalDocument(document)}
          >
            {document.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default FooterContent;
