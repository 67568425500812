import { FC } from 'react';
import clsx from 'clsx';
import ApplicationsTable from 'components/ApplicationsList/ApplicationsTable';
import Pagination, { IPaginationProps } from 'components/Pagination';
import { ApplicationSortingField, ApplicationsSortingType, IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import styles from './PriorApplications.module.scss';

interface IPriorApplicationsProps {
  onClick: (application: IApplicationViewModel) => void;
  onSort: (field: ApplicationSortingField, ascending: boolean) => void;
  isLoading?: boolean;
  paginationProps: IPaginationProps;
  sortingType: ApplicationsSortingType;
  currency: string;
  applications: (IApplicationViewModel | null)[] | null;
  className?: string;
}

const PriorApplications: FC<IPriorApplicationsProps> = ({
  onClick,
  isLoading,
  onSort,
  paginationProps,
  currency,
  applications,
  sortingType,
  className,
}) => {
  return (
    <div className={clsx(styles.applicationsContainer, className)}>
      <ApplicationsTable
        applications={applications}
        onClick={onClick}
        currency={currency}
        onSort={onSort}
        sortingType={sortingType}
        isLoading={isLoading}
      />
      {!isLoading && applications && applications.length > 0 && <Pagination {...paginationProps} />}
    </div>
  );
};

export default PriorApplications;
