import { useMemo } from 'react';
import { flatten } from 'lodash';
import { useSelector } from 'react-redux';
import { IBaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { useLayoutConfiguration } from './useLayoutConfiguration';
import { createLayoutEntitiesByIdsSelector } from 'handlers/layout-configurations/Selectors';

export const useLayoutConfigurationVariables = <
  VariableConfigurationType extends IBaseVariableConfiguration
>(layoutReferenceKey: string, storageVariableConfigurationsPath: string) => {
  const layoutConfiguration = useLayoutConfiguration(layoutReferenceKey);

  const layoutEntitiesSelector = useMemo(() => {
    const variableConfigurations = flatten(
      Object.values(layoutConfiguration?.sortedVariableConfigurationsByCard || {},
      )) || [];

    return createLayoutEntitiesByIdsSelector<VariableConfigurationType>(
      storageVariableConfigurationsPath,
      variableConfigurations,
    );
  }, [
    layoutConfiguration?.sortedVariableConfigurationsByCard,
    storageVariableConfigurationsPath,
  ]);

  return useSelector(layoutEntitiesSelector) as  VariableConfigurationType[];
};
