import { useEffect, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';

const SECONDS_IN_MINUTE = 60;
export const DEFAULT_TIMEOUT_SECONDS = 60;

const calculateTotalSeconds = ({ minutes, seconds }: { minutes: number, seconds: number }) =>
  minutes * SECONDS_IN_MINUTE + seconds;

interface TimerOptions {
  autoStart?: boolean;
}

const useTimer = (initialTimeoutSeconds: number = DEFAULT_TIMEOUT_SECONDS, options: TimerOptions = {}) => {
  const [timeoutSeconds, setTimeoutSeconds] = useState(initialTimeoutSeconds);

  const {
    autoStart = true,
  } = options;

  const stopwatch = useStopwatch({
    autoStart,
  });

  useEffect(() => {
    if (stopwatch.isRunning && calculateTotalSeconds(stopwatch) >= timeoutSeconds) {
      stopwatch.pause();
    }
  }, [stopwatch.minutes, stopwatch.seconds]);

  return {
    isRunning: stopwatch.isRunning,
    seconds: timeoutSeconds - calculateTotalSeconds(stopwatch),
    restart: (newTimeoutSeconds?: number) => {
      if (newTimeoutSeconds) {
        setTimeoutSeconds(newTimeoutSeconds);
      }

      stopwatch.reset(undefined, autoStart);

      if (!autoStart) {
        stopwatch.start();
      }
    },
  };
};

export default useTimer;
