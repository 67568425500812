import createWorkerHook, { ModuleMethods } from 'product_modules/hooks/createWorkerHook';
import { Worker } from 'threads';
import { FormulaCondition } from 'product_modules/api/Base/FormulaConditionApi';
// @ts-expect-error it don't want .ts
// eslint-disable-next-line import/no-webpack-loader-syntax
import workerURL from 'threads-plugin/dist/loader?name=conditions-worker!../workers/ConditionalDisplayLogicWorker';
import { VariableValue } from 'product_modules/api/Types';

export interface IWithConditionalDisplayRuleField {
  id: string;
  conditionalDisplayRule?: FormulaCondition | null;
}

export type DisplayFieldsAttributes = Record<string, boolean>;

interface IConditionalDisplayWorkerModule extends ModuleMethods {
  runConditionalDisplayLogic(
    fields: Array<IWithConditionalDisplayRuleField>,
    data: Record<string, VariableValue>,
  ): Promise<DisplayFieldsAttributes>;
}

const useConditionalDisplayLogicWorker = createWorkerHook<IConditionalDisplayWorkerModule>(() => {
  return new Worker(workerURL);
});

export default useConditionalDisplayLogicWorker;
