import BaseApi from 'api/BaseApi';
import {
  IBaseCard,
  IBaseGroup,
  IBaseVariableConfiguration,
} from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';

export interface IBaseLayout<
  GroupType extends IBaseGroup = IBaseGroup,
  CardType extends IBaseCard = IBaseCard,
  VariableType extends IBaseVariableConfiguration = IBaseVariableConfiguration
> {
  groups: GroupType[];
  cards: CardType[];
  variables: VariableType[];
}

export interface IFindLayoutConfigurationParams {
  referenceType: LayoutConfigurationReferenceType;
  reference?: string;
}

export interface ILayoutConfigurationApi<
  GroupType extends IBaseGroup = IBaseGroup,
  CardType extends IBaseCard = IBaseCard,
  VariableType extends IBaseVariableConfiguration = IBaseVariableConfiguration
> {
  find(params: IFindLayoutConfigurationParams): Promise<IBaseLayout<GroupType, CardType, VariableType>>;
}

export default class LayoutConfigurationRestApi<
  GroupType extends IBaseGroup = IBaseGroup,
  CardType extends IBaseCard = IBaseCard,
  VariableType extends IBaseVariableConfiguration = IBaseVariableConfiguration
> extends BaseApi implements ILayoutConfigurationApi<GroupType, CardType, VariableType> {
  protected resourceName = 'layout-configuration';

  public find(params: IFindLayoutConfigurationParams): Promise<IBaseLayout<GroupType, CardType, VariableType>> {
    const searchParams = new URLSearchParams();

    searchParams.set('referenceType', params.referenceType);

    if (params.reference) {
      searchParams.set('reference', params.reference);
    }

    return this.fetch(`/${this.resourceName}?${searchParams}`);
  }
}
