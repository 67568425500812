import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ContainerWithGradient from 'components/common/ContainerWithGradient';
import { HomeIcon } from 'static/images';
import { AppRoute } from 'enums/AppRoute';
import styles from './HomeButton.module.scss';

interface IHomeButtonProps {
  onClick?: () => void;
}

const HomeButton: FC<IHomeButtonProps> = ({ onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(AppRoute.Home);
  };

  return (
    <div className={styles.buttonContainer} onClick={onClick ?? handleClick}>
      <ContainerWithGradient className={styles.iconContainer} useAccentColor>
        <HomeIcon />
      </ContainerWithGradient>
      <div className={styles.title}>
        Home
      </div>
    </div>
  );
};

export default HomeButton;
