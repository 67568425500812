import React, { DetailedHTMLProps, ButtonHTMLAttributes, FC } from 'react';
import clsx from 'clsx';
import styles from './ActionPopUpItem.module.scss';

type HTMLButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export interface ActionPopUpItemProps extends HTMLButtonProps {
  danger?: boolean;
}

const ActionPopUpItem: FC<ActionPopUpItemProps> = ({ danger, className, disabled, ...props }) => {
  const buttonStyle = clsx(styles.popUpItem, danger && styles.dangerButton, disabled && styles.disabled, className);

  return <button disabled={disabled} type="button" className={buttonStyle} {...props} />;
};

export default ActionPopUpItem;
