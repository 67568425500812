import { createContext, useContext } from 'react';
import { IProductCalculationsApi } from 'api/digifi/ProductCalculationsApi';
import { IGooglePlacesApi } from 'api/digifi/GooglePlacesApi';
import { IAdminAuthApi } from 'api/digifi/AdminAuthApi';

export interface IApiProviderValue {
  productCalculationsApi: IProductCalculationsApi;
  googlePlacesApi: IGooglePlacesApi;
  adminAuthApi: IAdminAuthApi;
}

export type Services =
  | IProductCalculationsApi
  | IGooglePlacesApi
  | IAdminAuthApi;

const ApiContext = createContext<IApiProviderValue>({} as IApiProviderValue);

const getService = <ServiceType extends Services>(serviceName: keyof IApiProviderValue) => () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const services = useContext(ApiContext);

  return services[serviceName] as ServiceType;
};

export const useProductCalculationsApi = getService<IProductCalculationsApi>('productCalculationsApi');

export const useGooglePlacesApi = getService<IGooglePlacesApi>('googlePlacesApi');

export const useAdminAuthApi = getService<IAdminAuthApi>('adminAuthApi');

const ApiProvider = ApiContext.Provider;

export default ApiProvider;
