import { FC, useEffect } from 'react';
import useVariableBySystemName from 'product_modules/loaders/Variables/hooks/useVariableBySystemName';
import { IOfferSelectionElementConfig } from 'api/digifi/portal-page-elements';
import { setApplicationData } from 'handlers/applicationDataSlice';
import { useAppDispatch } from 'hooks/reduxHooks';
import { TableValue } from 'product_modules/api/Types';
import OfferSelectionElement from './OfferSelectionElement';

interface IOfferSelectionElementConnectorProps {
  config: IOfferSelectionElementConfig;
  offersVariableValue: TableValue | null;
  onOfferSelect: (updatedOffers: TableValue) => void;
  disabled?: boolean;
  containerClassName?: string;
}

const OfferSelectionElementConnector: FC<IOfferSelectionElementConnectorProps> = ({
  config,
  disabled,
  offersVariableValue,
  onOfferSelect,
  containerClassName,
}) => {
  const dispatch = useAppDispatch();

  const variable = useVariableBySystemName(config.variable);

  useEffect(() => {
    if (config.variable) {
      dispatch(setApplicationData({ offersVariable: config.variable }));
    }
  }, [config.variable]);

  return (
    <OfferSelectionElement
      config={config}
      offersVariableValue={offersVariableValue}
      disabled={disabled}
      onOfferSelect={onOfferSelect}
      containerClassName={containerClassName}
      offerVariable={variable}
    />
  );
};

export default OfferSelectionElementConnector;
