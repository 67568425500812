import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';
import { IPortalPageVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import { LayoutConfigurationEntityName } from 'enums/LayoutConfigurationEntityName';
import { createLayoutEntitiesSlice } from './createLayoutEntitiesSlice';

const {
  reducer,
} = createLayoutEntitiesSlice<
  IPortalPageVariableConfiguration
>({
  referenceType: LayoutConfigurationReferenceType.PortalPage,
  layoutConfigurationEntityName: LayoutConfigurationEntityName.Variable,
});

export default reducer;
