import React, { FocusEvent, useMemo } from 'react';
import clsx from 'clsx';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import { LoaderState } from 'product_modules/components/LoaderWithState';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';
import SkeletonCardsLayout from 'product_modules/components/CardsLayoutConfiguration/SkeletonCardsLayout';
import WithFieldsValidationButton from 'product_modules/components/WithFieldsValidationButton';
import Button from 'product_modules/components/Button';
import ButtonWithImage from 'product_modules/components/ButtonWithImage';
import { useLayoutConfiguration } from 'product_modules/loaders/LayoutConfiguration/hooks/useLayoutConfiguration';
import {
  IBaseCard,
  IBaseGroup,
  IBaseVariableConfiguration
} from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { IAddressInputClassNames, ITableInputClassNames } from 'product_modules/types/InputClassNamesTypes';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import SkeletonCardsForm from './SkeletonCardsForm';
import Group from './Group';
import styles from './CardsForm.module.scss';

export interface CardsFormProps<
  FieldType extends IBaseVariableConfiguration,
  CalculationType extends BaseCalculation,
> {
  referenceKey: string;
  storageLayoutEntitiesPaths: {
    groups: string;
    cards: string;
    variablesConfigurations: string;
  };
  data: FormLayoutData;
  isEditMode?: boolean;
  loaderStateById?: Record<string, LoaderState | null | undefined>;
  onFieldChange: (field: FieldType, variable: Variable, value: VariableValue) => void;
  onFieldFocus?: (field: FieldType, variable: Variable, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (field: FieldType, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onLoaderStateReset?: (variableConfiguration: FieldType) => void;
  formatVariableConfigurationDisplayTitle?: (title: string) => string;
  onSwitchEditMode?: () => void;
  isSwitchEditModeButtonDisabled?: boolean;
  switchEditModeButtonWrapperText?: string;
  title?: string;
  switchEditModeButton?: string;
  className?: string;
  displayHeader?: boolean;
  skeletonCardsLayout?: Array<Array<number>>;
  isSaveChangesButtonDisabled?: boolean;
  isUpdatingInProgress?: boolean;
  areFieldsInvalid?: boolean;
  onSaveChangesClick?: () => void;
  displayFieldsAttributes?: Record<string, boolean | undefined>;
  displaySkeleton?: boolean;
  calculations?: Map<string, CalculationType> | null;
  editButtonClassName?: string;
  cardsFormHeaderClassName?: string;
  fieldClassName?: string;
  fieldTitleClassName?: string;
  fieldValueClassName?: string;
  hiddenFieldClassName?: string;
  descriptionIconClassName?: string;
  country?: string;
  inputClassNames?: {
    address?: IAddressInputClassNames;
    table?: ITableInputClassNames;
  };
  emptyFormComponent?: React.ReactNode;
}

const DEFAULT_SKELETON_CARDS_LAYOUT = [
  [2, 1],
  [2],
  [1, 1],
];

const CardsForm = <
  GroupType extends IBaseGroup,
  CardType extends IBaseCard,
  FieldType extends IBaseVariableConfiguration,
  CalculationType extends BaseCalculation,
>({
  referenceKey,
  storageLayoutEntitiesPaths,
  title,
  data,
  isEditMode,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  onLoaderStateReset,
  loaderStateById,
  formatVariableConfigurationDisplayTitle,
  onSwitchEditMode,
  isSwitchEditModeButtonDisabled = false,
  switchEditModeButton,
  displayHeader = true,
  className,
  skeletonCardsLayout = DEFAULT_SKELETON_CARDS_LAYOUT,
  isSaveChangesButtonDisabled,
  isUpdatingInProgress,
  onSaveChangesClick,
  areFieldsInvalid,
  displayFieldsAttributes,
  displaySkeleton,
  calculations,
  editButtonClassName,
  cardsFormHeaderClassName,
  fieldClassName,
  fieldTitleClassName,
  fieldValueClassName,
  hiddenFieldClassName,
  inputClassNames,
  country,
  emptyFormComponent,
  descriptionIconClassName,
}: CardsFormProps<FieldType, CalculationType>) => {
  const layoutConfiguration = useLayoutConfiguration(referenceKey);

  const isEmptyLayoutConfiguration = useMemo(() => {
    if (!layoutConfiguration) {
      return;
    }

    const groupsLength = layoutConfiguration.sortedGroups.length;
    const cardsLength = Object.values(layoutConfiguration.sortedCardsByGroup).flat().length;
    const variablesLength = Object.values(layoutConfiguration.sortedVariableConfigurationsByCard).flat().length;

    return !groupsLength || !cardsLength || !variablesLength;
  }, [layoutConfiguration]);

  const renderGroup = (group: GroupType, index: number) => {
    return (
      <Group<CardType, FieldType, CalculationType>
        key={group.id}
        groupId={group.id}
        groupIndex={index}
        referenceKey={referenceKey}
        storageLayoutEntitiesPaths={storageLayoutEntitiesPaths}
        data={data}
        onFieldChange={onFieldChange}
        onFieldFocus={onFieldFocus}
        onFieldBlur={onFieldBlur}
        isEditMode={isEditMode}
        onLoaderStateReset={onLoaderStateReset}
        formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
        loaderStateById={loaderStateById}
        displayFieldsAttributes={displayFieldsAttributes}
        calculations={calculations}
        fieldClassName={fieldClassName}
        fieldTitleClassName={fieldTitleClassName}
        fieldValueClassName={fieldValueClassName}
        hiddenFieldClassName={hiddenFieldClassName}
        inputClassNames={inputClassNames}
        country={country}
        lastGroupClassName={index === layoutConfiguration.sortedGroups.length - 1 ? styles.lastGroup : ''}
        descriptionIconClassName={descriptionIconClassName}
      />
    )
  };

  const renderForm = () => {
    if (!layoutConfiguration || displaySkeleton) {
      return isEditMode
        ? <SkeletonCardsLayout cardClassName={styles.skeletonCard} layout={skeletonCardsLayout} />
        : <SkeletonCardsForm layout={skeletonCardsLayout} />;
    }

    if (emptyFormComponent && isEmptyLayoutConfiguration) {
      return emptyFormComponent;
    }

    return (
      <div className={styles.formContainer}>
        {(layoutConfiguration.sortedGroups).map((group, index) => renderGroup(group as GroupType, index))}
      </div>
    );
  };

  const renderHeaderButton = () => {
    if (isEditMode) {
      return (
        <div className={styles.editModeActions}>
          <Button className={styles.closeEditorButton} kind="secondary" onClick={() => onSwitchEditMode?.()}>
            Close Editor
          </Button>
          <WithFieldsValidationButton
            kind="primary"
            className={styles.saveChangesButton}
            disabled={isSaveChangesButtonDisabled}
            onClick={onSaveChangesClick}
            isLoading={isUpdatingInProgress}
            areFieldsInvalid={areFieldsInvalid}
          >
            Save Changes
          </WithFieldsValidationButton>
        </div>
      );
    }

    return (
      <ButtonWithImage
        className={clsx(styles.editDataButton, editButtonClassName)}
        disabled={!layoutConfiguration || isSwitchEditModeButtonDisabled}
        title={switchEditModeButton || 'Edit Data'}
        kind="edit"
        onClick={() => onSwitchEditMode?.()}
      />
    );
  };

  return (
    <div className={className}>
      {displayHeader && <div className={clsx(styles.cardsFormHeader, cardsFormHeaderClassName)}>
        <h4>{title}</h4>
        {renderHeaderButton()}
      </div>}
      {renderForm()}
    </div>
  );
};

export default CardsForm;
