import React, { FocusEvent, ReactElement, useEffect, useMemo, useRef, KeyboardEvent } from 'react';
import { VariableValue } from 'product_modules/api/Types';
import { VariableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import { VisualDataType } from 'product_modules/enums/VisualDataType';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import { InputSize } from 'product_modules/components/DatePicker/Input';
import useInputValidation from 'product_modules/hooks/useInputValidation';
import { convertVariableValueByDataType, convertVariableValueToString } from 'product_modules/utils/coerceUtils';
import useValueCorrespondVisualTypeCheck from 'product_modules/components/InputWithDataType/hooks/useValueCorrespondVisualTypeCheck';
import IncompatibleValueInput from 'product_modules/components/InputWithDataType/IncompatibleValueInput/IncompatibleValueInput';
import useInputValidators from 'product_modules/components/InputWithDataType/hooks/useInputValidators';
import InputComponent from 'product_modules/components/InputWithDataType/InputComponent';
import { getDataTypeByVisualDataType } from 'product_modules/utils/variablesUtils';
import { IAddressInputClassNames, ITableInputClassNames } from 'product_modules/types/InputClassNamesTypes';

export interface BasicInputProps {
  errorMessage?: string;
  labelTitle: string;
  titleHint?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
  onChange: (value: VariableValue) => void;
  required?: boolean;
  showLoader?: boolean;
  tabIndex?: number;
  onLoaderStateReset?: () => void;
  hasLeftNeighbour?: boolean;
  hasRightNeighbour?: boolean;
  containerClassName?: string;
  inputIcon?: React.ReactNode;
  raw?: boolean;
  country?: string;
  autoFocus?: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  classNames?: {
    address?: IAddressInputClassNames;
    table?: ITableInputClassNames;
  };
  useSimplifiedInput?: boolean;
  onErrorMessageUpdate?: (errorMessage: string) => void;
  style?: React.CSSProperties;
  labelTooltipClassName?: string;
}

export interface InputWithDataTypeProps extends BasicInputProps {
  value: VariableValue;
  visualDataType: VisualDataType;
  visualAttributes: VariableVisualAttributes;
  disabledValidation?: boolean;
  loaderState?: LoaderState | null;
  disableCapAttributesValidation?: boolean;
  disableValueConversionByDataType?: boolean;
  inputSize?: InputSize;
  disableValueCorrespondenceValidation?: boolean;
  hidePlaceholder?: boolean;
  validateOnRender?: boolean;
  dropdownClassName?: string;
}

const InputWithDataType = ({
  value,
  onChange,
  onFocus,
  onBlur,
  disabledValidation,
  inputSize,
  disableCapAttributesValidation,
  disableValueConversionByDataType,
  visualDataType,
  visualAttributes,
  disableValueCorrespondenceValidation,
  onErrorMessageUpdate,
  hidePlaceholder,
  validateOnRender,
  ...commonProps
}: InputWithDataTypeProps): ReactElement => {
  const valueAsString = useMemo(() => convertVariableValueToString(value), [value]);

  const inputComponentRef = useRef<HTMLInputElement | null>(null);

  const isValueCorrespondVisualType = useValueCorrespondVisualTypeCheck(visualDataType);

  const valueCorrespondsVisualType = useMemo(() => {
    return disableValueCorrespondenceValidation || value === null || value === undefined || isValueCorrespondVisualType(value, visualAttributes);
  }, [value, visualAttributes, disableValueCorrespondenceValidation]);

  const prevValueCorrespondsVisualTypeRef = useRef<boolean>(valueCorrespondsVisualType);

  useEffect(() => {
    const nextValueState = disableValueCorrespondenceValidation || !value || isValueCorrespondVisualType(value, visualAttributes);

    if (nextValueState && !prevValueCorrespondsVisualTypeRef.current) {
      inputComponentRef.current?.focus();
    }

    prevValueCorrespondsVisualTypeRef.current = valueCorrespondsVisualType;
  }, [valueCorrespondsVisualType]);

  const validators = useInputValidators(
    visualDataType,
    visualAttributes,
    { disableCapAttributesValidation },
  );

  const [
    handleBlur,
    handleFocus,
    errorMessage,
    ,
    showErrorMessage,
    hideErrorMessage,
  ] = useInputValidation({
    validators,
    value: valueAsString,
    onFocus,
    onBlur,
    required: commonProps.required,
    disabledValidation,
    validateOnRender,
  });

  useEffect(() => {
    onErrorMessageUpdate?.(errorMessage);
  }, [errorMessage]);

  const handleValueChange = (updatedValue: VariableValue) => {
    if (disableValueConversionByDataType || !valueCorrespondsVisualType) {
      onChange(updatedValue);

      return;
    }

    onChange(convertVariableValueByDataType(updatedValue, getDataTypeByVisualDataType(visualDataType)));
  };

  const commonInputProps = {
    ...commonProps,
    errorMessage,
    value: valueAsString,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange: handleValueChange,
  };

  if (!valueCorrespondsVisualType && !commonProps.raw) {
    return <IncompatibleValueInput
      {...commonInputProps}
      visualAttributes={visualAttributes}
      visualDataType={visualDataType}
      placeholder={hidePlaceholder ? '' : visualDataType}
      disableValueCorrespondenceValidation={disableValueCorrespondenceValidation}
    />;
  }

  return <InputComponent
    {...commonInputProps}
    showErrorMessage={showErrorMessage}
    hideErrorMessage={hideErrorMessage}
    visualAttributes={visualAttributes}
    visualDataType={visualDataType}
    inputSize={inputSize}
    nonFormattedValue={value}
    inputRef={inputComponentRef}
    hidePlaceholder={hidePlaceholder}
    disableCapAttributesValidation={disableCapAttributesValidation}
    disabledValidation={disabledValidation}
  />;
};

export default React.memo(InputWithDataType) as typeof InputWithDataType;
