import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './Separator.module.scss';

export interface ISeparatorProps {
  className?: string;
}
const Separator: FC<ISeparatorProps> = ({ className }) => {
  return <div className={clsx(styles.separator, className)} />;
};

export default Separator;
