import React, { FC } from 'react';
import clsx from 'clsx';
import Separator from 'product_modules/components/Separator';
import {
  IPageDividerElementConfig,
  PageDividerElementLength,
} from 'api/digifi/portal-page-elements';
import styles from './PageDividerElement.module.scss';

interface IPageDividerElementProps {
  config: IPageDividerElementConfig;
  containerClassName?: string;
}

const PageDividerElement: FC<IPageDividerElementProps> = ({ config, containerClassName }) => {
  const lengthByType = clsx({
    [styles.long]: config.length === PageDividerElementLength.Long,
    [styles.medium]: config.length === PageDividerElementLength.Medium,
    [styles.short]: config.length === PageDividerElementLength.Short,
  });

  return (
    <div className={clsx(styles.container, containerClassName)} style={{ justifyContent: config.alignment }}>
      <Separator className={clsx(styles.divider, lengthByType)} />
    </div>
  );
};

export default PageDividerElement;
