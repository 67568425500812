import React, { FC } from 'react';
import { AddressValue } from 'product_modules/api/Types';
import PopUpContent from 'product_modules/components/PopUp/PopUpContent';
import PopUp from 'product_modules/components/PopUp/PopUp';
import AddressDetails from 'product_modules/components/AddressDetails';
import { IAddressInputClassNames } from 'product_modules/types/InputClassNamesTypes';

export interface AddressDetailsPopupProps {
  addressLabel?: string;
  address: AddressValue | null | undefined;
  onSubmit: (address: AddressValue) => void;
  onClose: () => void;
  usePortal?: boolean;
  classNames?: IAddressInputClassNames;
}

const AddressDetailsPopup: FC<AddressDetailsPopupProps> = ({
  addressLabel,
  address,
  onClose,
  onSubmit,
  usePortal,
  classNames,
}) => {
  return (
    <PopUp
      usePortal={usePortal}
      title={addressLabel || 'Address'}
      closePopUp={onClose}
      classNames={{
        sectionClassName: classNames?.sectionClassName,
        closeIcon: classNames?.closeIcon,
        header: classNames?.header,
        title: classNames?.title,
      }}
    >
      <PopUpContent className={classNames?.popupContent}>
        <AddressDetails
          address={address}
          addressLabel={addressLabel || 'Address'}
          onSubmit={onSubmit}
          saveButtonClassName={classNames?.saveButton}
        />
      </PopUpContent>
    </PopUp>
  );
};

export default AddressDetailsPopup;
