import { FieldComponent } from 'product_modules/api/Types';
import { StringVisualDataType } from 'product_modules/enums/VisualDataType';
import formatAddressValue from '../utils/valueFormatters/formatAddressValue';

export const addressComponents: FieldComponent[] = [
  {
    id: 'street_number',
    label: 'Street Number',
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'street_name',
    label: 'Street Name',
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'unit_number',
    label: 'Unit Number',
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'city',
    label: 'City',
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'sublocality',
    label: 'Sublocality',
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'state_or_province',
    label: 'State Or Province',
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'zip_or_postal_code',
    label: 'Zip Or Postal Code',
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'country',
    label: 'Country',
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  },
  {
    id: 'full_address',
    label: 'Full Address',
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
    virtual: true,
    transform: (value) => {
      return typeof value === 'object' && !!value ? formatAddressValue(value) : '';
    },
    toFormula: (variable: string) => {
      return `BUILDFULLADDRESS(${variable})`;
    },
  }
];
