import React, { FC } from 'react';
import clsx from 'clsx';
import { AutoSizer, ScrollSync } from 'react-virtualized';
import { TableColumn } from 'product_modules/api/Core/VariablesApi';
import { TableValue } from 'product_modules/api/Types';
import TableHeaderGrid from 'product_modules/components/TableInput/TableHeaderGrid';
import TableInputViewModeGrid from './TableInputViewModeGrid';
import styles from './TableInputViewMode.module.scss';

const MIN_TABLE_CELL_WIDTH = 160;
const EMPTY_TABLE_PLACEHOLDER = 'This table is empty.';

interface TableInputViewModeProps {
  columns: TableColumn[];
  value: TableValue;
  tableHeaderViewModeClassName?: string;
  isExpanded?: boolean;
  isMobile?: boolean;
}

const TableInputViewMode: FC<TableInputViewModeProps> = ({
  columns,
  value,
  isExpanded,
  tableHeaderViewModeClassName,
  isMobile,
}) => {
  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <>
          <ScrollSync>
            {({ onScroll, scrollLeft }) => (
              <>
                <TableHeaderGrid
                  width={width}
                  columns={columns}
                  scrollLeft={scrollLeft}
                  minCellWidth={MIN_TABLE_CELL_WIDTH}
                  tableHeaderViewModeClassName={tableHeaderViewModeClassName}
                  isEmptyTableHeader={!value.length}
                  emptyTablePlaceholder={EMPTY_TABLE_PLACEHOLDER}
                  emptyTableHeaderClassName={clsx(
                    styles.emptyTableContainer,
                    isExpanded && styles.emptyTableContainerExpanded,
                  )}
                />
                <TableInputViewModeGrid
                  columns={columns}
                  value={value}
                  width={width}
                  onScroll={onScroll}
                  isExpanded={isExpanded}
                  minCellWidth={MIN_TABLE_CELL_WIDTH}
                  isMobile={isMobile}
                />
              </>
            )}
          </ScrollSync>
        </>
      )}
    </AutoSizer>
  );
};

export default TableInputViewMode;
