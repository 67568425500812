enum ProductType {
  Custom = 'custom',
  CreditCard = 'creditCard',
  PersonalLoan = 'personalLoan',
  AutoLoanRefinancing = 'autoLoanRefinancing',
  Mortgage = 'mortgage',
  HomeEquityLoan = 'homeEquityLoan',
  HomeImprovementLoan = 'homeImprovementLoan',
  PointOfSaleFinancing = 'pointOfSaleFinancing',
  SmallBusinessLoan = 'smallBusinessLoan',
  CommercialLoan = 'commercialLoan',
  HomeEquityLineOfCredit = 'homeEquityLineOfCredit',
  PersonalLoanAlternative = 'personalLoanAlternative',
  PersonalLoanSecondAlternative = 'personalLoanSecondAlternative',
  CreditCardAlternative = 'creditCardAlternative',
  SolarLoan = 'solarLoan',
}

export default ProductType;
