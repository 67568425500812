import { FC } from 'react';
import formatMonetaryValue from 'product_modules/utils/formatMonetaryValue';
import Tag from 'components/digifi-wrappers/Tag';
import BaseApplicationHeaderWrapper from 'components/BaseApplicationHeaderWrapper';
import OverflowedText from 'components/digifi-wrappers/OverflowedText';
import { useAppSelector } from 'hooks/reduxHooks';
import getApplicationStatusTagColor from 'utils/getApplicationStatusTagColor';
import maskApplicationStatusName from 'utils/maskApplicationStatusName';
import useSelectedApplication from 'hooks/useSelectedApplication';
import styles from './ApplicationHeader.module.scss';

interface IActiveHeaderProps {
  className?: string;
}

const ApplicationHeader: FC<IActiveHeaderProps> = ({
  className,
}) => {
  const application = useSelectedApplication();

  const { currency } = useAppSelector((state) => state.settings.variablesSettings);

  if (!application) {
    return null;
  }

  return (
    <BaseApplicationHeaderWrapper className={className}>
      <div className={styles.leftSideContainer}>
        <OverflowedText className={styles.productName} useTooltip>
          {application.productName}
        </OverflowedText>
        <Tag className={styles.statusTag} color={getApplicationStatusTagColor(application.status)}>
          {maskApplicationStatusName(application.status)}
        </Tag>
      </div>
      {typeof application.loanAmount === 'number' && (
        <p className={styles.loanAmount}>
          {formatMonetaryValue(application.loanAmount, currency)}
        </p>
      )}
    </BaseApplicationHeaderWrapper>
  );
};

export default ApplicationHeader;
