export type RGB = [number, number, number];

const splitHexColorString = (hexColorValue: string) => {
  if (hexColorValue.length === 3) {
    return hexColorValue.split('');
  }
  if (hexColorValue.length === 6) {
    const [, r, g, b] = hexColorValue.match(/^(..)(..)(..)$/)!;
    return [r, g, b];
  }
  throw new TypeError('Hex color string must be 3 or 6 characters long');
};

const parseSingleHexColor = (singleHexColor: string) => {
  const completeHexColor = singleHexColor.length === 1 ? `${singleHexColor}${singleHexColor}` : singleHexColor;
  return parseInt(completeHexColor, 16);
};

const hexColorToRgb = (hexColor: string): RGB => {
  const match = hexColor.toLowerCase().match(/^#?([0-9a-f]+)$/);
  if (!match) {
    throw new TypeError('Invalid hex color string');
  }
  const hexColorValues = splitHexColorString(match[1]);
  return hexColorValues.map(parseSingleHexColor) as RGB;
};

export default hexColorToRgb;
