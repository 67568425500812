import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './ContainerShadows.module.scss';

interface ContainerShadowsProps {
  width: number;
  height: number;
  extremeRowsVisibility: {
    isFirstRowVisible: boolean;
    isLastRowVisible: boolean;
    isFirstColumnVisible: boolean;
    isLastColumnVisible: boolean;
  };
  defaultRowHeight: number;
  bottomShadowClassName?: string;
}

const ContainerShadows: FC<ContainerShadowsProps> = ({
  width,
  height,
  extremeRowsVisibility,
  defaultRowHeight,
  bottomShadowClassName,
}) => {
  return (
    <>
      <div
        className={clsx(
          styles.shadowContainerBottom,
          !extremeRowsVisibility.isLastRowVisible && styles.shadowContainerVisible,
          bottomShadowClassName,
        )}
        style={{ width: `${width}px` }}
      />
      <div
        className={clsx(
          styles.shadowContainerTop,
          !extremeRowsVisibility.isFirstRowVisible && styles.shadowContainerVisible,
        )}
        style={{ width: `${width}px` }}
      />
      <div
        className={clsx(
          styles.shadowContainerLeft,
          !extremeRowsVisibility.isFirstColumnVisible && styles.shadowContainerVisible,
        )}
        style={{ height: `${Math.max(height, defaultRowHeight)}px` }}
      />
      <div
        className={clsx(
          styles.shadowContainerRight,
          !extremeRowsVisibility.isLastColumnVisible && styles.shadowContainerVisible,
        )}
        style={{ height: `${Math.max(height, defaultRowHeight)}px` }}
      />
    </>
  );
};

export default ContainerShadows;
