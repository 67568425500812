import isCorrespondsBoolean from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsBoolean';
import isCorrespondsAddress from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsAddress';
import isCorrespondsNumber from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsNumber';
import isCorrespondsPhoneNumber from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsPhoneNumber';
import isCorrespondsIdentificationType
  from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsIdentificationType';
import isCorrespondsText from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsText';
import isCorrespondsDate from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsDate';
import {
  AddressVisualDataType,
  BooleanVisualDataType,
  DateVisualDataType,
  NumericVisualDataType,
  StringVisualDataType,
  TableVisualDataType,
} from 'product_modules/enums/VisualDataType';
import { CorrespondenceChecker } from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/Types';
import isCorrespondsList from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsList';
import isCorrespondsTable from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsTable';
import { TableValue } from 'product_modules/api/Types';

interface CorrespondenceCheckersByVisualType {
  [AddressVisualDataType.Address]: CorrespondenceChecker<object>,
  [BooleanVisualDataType.Boolean]: CorrespondenceChecker<boolean>,
  [DateVisualDataType.Date]: CorrespondenceChecker<string>,
  [NumericVisualDataType.Number]: CorrespondenceChecker<number>,
  [NumericVisualDataType.Monetary]: CorrespondenceChecker<number>,
  [NumericVisualDataType.Percentage]: CorrespondenceChecker<number>,
  [StringVisualDataType.PhoneNumber]: CorrespondenceChecker<string>,
  [StringVisualDataType.List]: CorrespondenceChecker<string>,
  [StringVisualDataType.Text]: CorrespondenceChecker<string>,
  [StringVisualDataType.LargeText]: CorrespondenceChecker<string>,
  [StringVisualDataType.EmailAddress]: CorrespondenceChecker<string>,
  [StringVisualDataType.IdentificationNumber]: CorrespondenceChecker<string>,
  [TableVisualDataType.Table]: CorrespondenceChecker<TableValue>,
}

const CORRESPONDENCE_CHECKERS_BY_VISUAL_TYPE: CorrespondenceCheckersByVisualType = {
  [AddressVisualDataType.Address]: isCorrespondsAddress,
  [BooleanVisualDataType.Boolean]: isCorrespondsBoolean,
  [NumericVisualDataType.Number]: isCorrespondsNumber,
  [NumericVisualDataType.Monetary]: isCorrespondsNumber,
  [NumericVisualDataType.Percentage]: isCorrespondsNumber,
  [StringVisualDataType.PhoneNumber]: isCorrespondsPhoneNumber,
  [StringVisualDataType.IdentificationNumber]: isCorrespondsIdentificationType,
  [StringVisualDataType.List]: isCorrespondsList,
  [StringVisualDataType.Text]: isCorrespondsText,
  [StringVisualDataType.LargeText]: isCorrespondsText,
  [StringVisualDataType.EmailAddress]: isCorrespondsText,
  [DateVisualDataType.Date]: isCorrespondsDate,
  [TableVisualDataType.Table]: isCorrespondsTable,
};

const getValueVisualTypeCorrespondenceChecker = <Type extends keyof CorrespondenceCheckersByVisualType>(
  visualDataType: Type,
) => CORRESPONDENCE_CHECKERS_BY_VISUAL_TYPE[visualDataType];

export default getValueVisualTypeCorrespondenceChecker;
