import { useCallback } from 'react';
import { VisualDataType } from 'product_modules/enums/VisualDataType';
import getValueCompatibilityChecker from 'product_modules/utils/valueConversionAvailabilityCheckers';

const useValueCompatibilityCheck = (
  visualDataType: VisualDataType,
) => {
  return useCallback(
    getValueCompatibilityChecker(visualDataType),
    [visualDataType],
  );
};

export default useValueCompatibilityCheck;
