import React from 'react';
import clsx from 'clsx';
import { SkeletonInput } from 'product_modules/components/Skeleton';
import styles from './FormSkeleton.module.scss';

const FIELDS_COUNT = 9;

export interface IFormSkeletonProps {
  className?: string;
  fieldsCount?: number;
}

const FormSkeleton = ({ fieldsCount = FIELDS_COUNT, className }: IFormSkeletonProps) => {
  const renderSkeletonFields = (length: number) => {
    return new Array(length).fill(null).map((_, index) => <SkeletonInput key={index} />);
  };

  return <div className={clsx(styles.container, className)}>{renderSkeletonFields(fieldsCount)}</div>;
};

export default FormSkeleton;
