import { isEmptyNumericValue, removeNonDigits } from './utils';

const divideByCommas = (value: string): string => {
  const parsedValue = parseFloat(value);

  if (Number.isNaN(parsedValue) || parsedValue === 0) {
    return value;
  }

  const formattedValue = value
    .split('')
    .reverse()
    .filter((symbol) => symbol !== ',')
    .filter((symbol) => symbol.match(/[0-9]/))
    .map((digit, index) => (index !== 0 && index % 3 === 0 ? `${digit},` : digit))
    .reverse()
    .join('');

  return Number(value) < 0 ? `-${formattedValue}` : formattedValue;
};

const maskNumberValue = (value: string | number): string => {
  if (typeof value === 'string' && isEmptyNumericValue(value)) {
    return value;
  }

  const valueAsString = value.toString();
  const dotIndex = valueAsString.indexOf('.');

  if (dotIndex === -1) {
    return divideByCommas(valueAsString);
  }

  const integerPart = valueAsString.slice(0, dotIndex);
  const decimalPart = valueAsString.slice(dotIndex + 1, valueAsString.length);

  if (!integerPart && decimalPart) {
    return `0.${removeNonDigits(decimalPart)}`;
  }

  return `${divideByCommas(integerPart)}.${removeNonDigits(decimalPart)}`;
};

export default maskNumberValue;
