import React, { FC } from 'react';
import clsx from 'clsx';
import { DocumentExtension } from 'product_modules/api/LoanOriginationSystem/DocumentsApi';
import { CloseImage, DocumentIcon } from 'product_modules/static/images';
import { iconsByExtension } from 'components/common/UploadFile/UploadFile';
import styles from './UploadedDocumentStub.module.scss';

interface IUploadedDocumentStubProps {
  fileName: string;
  onFileChange?: (file: File | null, index?: number) => void;
  index?: number;
  closeIcon?: React.ReactNode;
  containerClassName?: string;
  fileDeleteContainerClassName?: string;
  hideCloseButton?: boolean;
}

export const getFileName = (fileName: string): string =>
  fileName
    .split('.')
    .slice(0, -1)
    .join('.');

export const getFileFormat = (fileName: string): string =>
  fileName
    .split('.')
    .slice(-1)
    .join();

const UploadedDocumentStub: FC<IUploadedDocumentStubProps> = ({
  fileName,
  closeIcon,
  onFileChange,
  index: fileIndex,
  containerClassName,
  fileDeleteContainerClassName,
  hideCloseButton,
}) => {
  const handleDeleteFile = (index?: number) => onFileChange?.(null, index);

  const renderCloseImage = (index?: number) => (
    <div className={clsx(styles.fileDeleteContainer, fileDeleteContainerClassName)} onClick={() => handleDeleteFile(index)}>
      {closeIcon || <CloseImage className={styles.fileDelete} />}
    </div>
  );

  return (
    <div className={clsx(styles.fileInfoContainer, containerClassName)}>
      <div className={styles.fileImage}>
        {iconsByExtension.get(fileName.split('.').pop()?.toLowerCase() as DocumentExtension) ||
          <DocumentIcon/>}
      </div>
      <div className={styles.fileInfo}>
        <span className={styles.fileName}>{getFileName(fileName)}</span>
        <span className={styles.fileFormat}>{getFileFormat(fileName)}</span>
      </div>
      {!hideCloseButton && renderCloseImage(fileIndex)}
    </div>
  );
};

export default UploadedDocumentStub;
