import React, { FC } from 'react';
import clsx from 'clsx';
import { VariableValue } from 'product_modules/api/Types';
import TextElementWrapper from 'components/ApplicationPage/elements/TextElementWrapper';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import { ITextElementConfig } from 'api/digifi/portal-page-elements';
import renderStringTemplateByVariableValue from 'utils/renderStringTemplateByVariableValue';
import styles from './BodyTextElement.module.scss';

interface IBodyTextElementProps {
  config: ITextElementConfig;
  context: Record<string, VariableValue>;
  containerClassName?: string;
}

const DEFAULT_BODY_VALUE = 'Body Text';

const BodyTextElement: FC<IBodyTextElementProps> = ({
  config,
  context,
  containerClassName,
}) => {
  const renderSkeletonContent = (linesCount: number) => (
    <>
      {Array.from({ length: linesCount }).map((_, index) => (
        <SkeletonText key={index} lineHeight="20px" width={index % 2 ? '95%' : '100%'} height="14px" color="primary6" />
      ))}
      <SkeletonText lineHeight="20px" width="80%" height="14px" color="primary6" />
    </>
  );

  const renderSkeleton = () => {
    return (
      <div className={containerClassName}>
        <div className={styles.skeletonDesktop}>
          {renderSkeletonContent(8)}
        </div>
        <div className={styles.skeletonTablet}>
          {renderSkeletonContent(10)}
        </div>
        <div className={styles.skeletonMobile}>
          {renderSkeletonContent(22)}
        </div>
      </div>
    );
  };

  return (
    <TextElementWrapper templateString={config.text} context={context}>
      {(elementContext, showLoader) => (
        showLoader ? renderSkeleton() : (
          <div className={clsx(styles.bodyText, containerClassName)} style={{ textAlign: config.alignment }}>
            {renderStringTemplateByVariableValue(config.text, elementContext) || DEFAULT_BODY_VALUE}
          </div>
        )
      )}
    </TextElementWrapper>
  );
};

export default BodyTextElement;
