import { FC, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import createPathWithBackUrl from 'utils/redirectWithBackUrl';
import useBackUrlQueryParam from 'hooks/useBackUrlQueryParam';
import { AUTH_ROUTES } from 'api/BaseApi';
import useSessionStatus, { SessionStatus } from 'hooks/useSessionStatus';

const RedirectHandler: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const sessionStatus = useSessionStatus();
  const backUrl = useBackUrlQueryParam();

  const redirectWithBackUrl = (path: string) => {
    return navigate(createPathWithBackUrl(path, backUrl));
  };

  const checkSession = () => {
    const isAuthRoute = AUTH_ROUTES.some((path) => matchPath(path, location.pathname));

    if (sessionStatus === SessionStatus.Unauthorized && !matchPath({ path: location.pathname }, '/')) {
      return;
    }

    if (sessionStatus === SessionStatus.AwaitingEmailVerification) {
      return redirectWithBackUrl(AppRoute.ConfirmEmail);
    }

    if (sessionStatus === SessionStatus.AwaitingPhoneVerification) {
      return redirectWithBackUrl(AppRoute.PhoneVerification);
    }

    if (isAuthRoute || matchPath('/', location.pathname)) {
      return navigate(backUrl || AppRoute.Home);
    }
  };

  useEffect(() => {
    checkSession();
  }, [sessionStatus]);

  return null;
};

export default RedirectHandler;
