import { FC } from 'react';
import styles from 'components/PageLayout/Footer/Footer.module.scss';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';
import FooterContentConnector from 'components/PageLayout/Footer/FooterContent/FooterContentConnector';

const Footer: FC = () => {
  return (
    <ContainerStyled className={styles.footerContainer}>
      <FooterContentConnector />
    </ContainerStyled>
  );
};

export default Footer;
