import React, { FC } from 'react';
import { Grid, GridCellProps } from 'react-virtualized';
import clsx from 'clsx';
import Table, { TableHead, TableHeadCell } from 'product_modules/components/Table';
import { TableColumn } from 'product_modules/api/Core/VariablesApi';
import OverflowedText from 'product_modules/components/OverflowedText';
import RequiredSymbol from 'product_modules/components/RequiredSymbol';
import getTableCellWidth from 'product_modules/components/TableInput/utils/getTableCellWidth';
import styles from './TableHeaderGrid.module.scss';

const TABLE_HEADER_HEIGHT = 36;
const TABLE_HEADER_GRID_ROWS_COUNT = 1;

interface TableHeaderGridProps {
  width: number;
  columns: TableColumn[];
  minCellWidth: number;
  scrollLeft: number;
  disabled?: boolean;
  disabledValidation?: boolean;
  tableHeaderViewModeClassName?: string;
  isEmptyTableHeader?: boolean;
  emptyTablePlaceholder?: string;
  emptyTableHeaderClassName?: string;
}

const TableHeaderGrid: FC<TableHeaderGridProps> = ({
  width,
  columns,
  scrollLeft,
  disabled,
  disabledValidation,
  minCellWidth,
  tableHeaderViewModeClassName,
  isEmptyTableHeader,
  emptyTablePlaceholder,
  emptyTableHeaderClassName,
}) => {
  const renderHeaderCell = (columnIndex: number, style?: React.CSSProperties) => (
    <TableHeadCell
      width={width / columns.length}
      className={clsx(styles.headCell, disabled && styles.disabledCell, tableHeaderViewModeClassName)}
      key={columns[columnIndex].id}
      style={{ ...style, minWidth: `${minCellWidth}px` }}
      pixels
    >
      <OverflowedText useTooltip>{columns[columnIndex].name}</OverflowedText>
      {columns[columnIndex].required && !disabledValidation && <RequiredSymbol/>}
    </TableHeadCell>
  );

  const renderHeaderGridCell = (props: GridCellProps) => renderHeaderCell(props.columnIndex, props.style);

  const renderDefaultHeader = () => {
    return (
      <Table className={styles.tableContainer} style={{ width: `${width}px`}}>
        <TableHead noBottomBorder>
          {columns.map((column, index) => renderHeaderCell(index))}
        </TableHead>
        <div className={clsx(styles.emptyTablePlaceholder, emptyTableHeaderClassName)}>
          {emptyTablePlaceholder}
        </div>
      </Table>
    );
  };

  return isEmptyTableHeader ? renderDefaultHeader() : (
    <TableHead style={{ width: `${width}px` }} noBottomBorder>
      <Grid
        rowCount={TABLE_HEADER_GRID_ROWS_COUNT}
        height={TABLE_HEADER_HEIGHT}
        rowHeight={TABLE_HEADER_HEIGHT}
        cellRenderer={renderHeaderGridCell}
        width={width}
        columnCount={columns.length}
        columnWidth={getTableCellWidth(width, columns.length, minCellWidth)}
        scrollLeft={scrollLeft}
        className={styles.tableHeaderGrid}
      />
    </TableHead>
  )
};

export default React.memo(TableHeaderGrid);
