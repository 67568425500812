import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice, EntityState,
} from '@reduxjs/toolkit';
import DocumentsRestApi, {
  IApplicationDocument,
  IBatchUploadDocumentParams,
} from 'api/digifi/DocumentsApi';
import { downloadBlobFile } from 'product_modules/utils/downloadBlobFile';
import { RootState } from 'store';

enum ApplicationDocumentsActionType {
  GetApplicationDocuments = 'applicationDocuments/getApplicationDocuments',
  BatchUploadApplicationDocuments = 'applicationDocuments/batchUploadApplicationDocuments',
  DownloadApplicationDocument = 'applicationDocuments/downloadApplicationDocument',
  DownloadArchiveOfApplicationDocuments = 'applicationDocuments/downloadArchiveOfApplicationDocuments',
}

export interface IApplicationDocumentsState extends EntityState<IApplicationDocument> {
  documentDownloadingState: Record<string, string>;
}

const documentsAdapter = createEntityAdapter<IApplicationDocument>();

const initialState: IApplicationDocumentsState = documentsAdapter.getInitialState({
  documentDownloadingState: {},
});

export const applicationDocumentsApi = new DocumentsRestApi();

export const getApplicationDocuments = createAsyncThunk(
  ApplicationDocumentsActionType.GetApplicationDocuments,
  async({ applicationId }: { applicationId: string }) => {
  const { documents } = await applicationDocumentsApi.getApplicationDocuments(applicationId);

  return documents;
});

export const downloadApplicationDocument = createAsyncThunk(
  ApplicationDocumentsActionType.DownloadApplicationDocument, async (documentId: string) => {
  const response = await applicationDocumentsApi.download(documentId);

  downloadBlobFile(response);
});

interface IDownloadAllDocumentsActionParams {
  applicationId: string;
  id: string;
}

export const downloadAllDocuments = createAsyncThunk(
  ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocuments,
  async (params: IDownloadAllDocumentsActionParams) => {
    const response = await applicationDocumentsApi.downloadAll(params.applicationId);

    downloadBlobFile({
      ...response,
      filename: `Application Documents - ${params.applicationId}`, // Change to DisplayId
    });
  },
);

export const batchUploadApplicationDocuments = createAsyncThunk<void, { applicationId: string; params: IBatchUploadDocumentParams[] }>(
  ApplicationDocumentsActionType.BatchUploadApplicationDocuments,
  async ({ applicationId, params }) => {
    await applicationDocumentsApi.batchUpload(applicationId, params);
  },
);

const applicationDocumentsSlice = createSlice({
  name: 'applicationDocumentsSlice',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getApplicationDocuments.fulfilled, (state, { payload }) => {
        state.documentDownloadingState = {};
        documentsAdapter.setAll(state, payload);
      })
      .addCase(downloadApplicationDocument.pending, (state, action) => {
        state.documentDownloadingState[action.meta.arg] = 'loading';
      })
      .addCase(downloadAllDocuments.pending, (state, action) => {
        state.documentDownloadingState[action.meta.arg.id] = 'loading';
      })
      .addCase(downloadApplicationDocument.fulfilled, (state, action) => {
        state.documentDownloadingState[action.meta.arg] = 'success';
      })
      .addCase(downloadAllDocuments.fulfilled, (state, action) => {
        state.documentDownloadingState[action.meta.arg.id] = 'success';
      })
      .addCase(downloadAllDocuments.rejected, (state, action) => {
        state.documentDownloadingState[action.meta.arg.id] = 'failure';
      })
      .addCase(downloadApplicationDocument.rejected, (state, action) => {
        state.documentDownloadingState[action.meta.arg] = 'failure';
      });
  },
});

export const { selectEntities: selectDocumentEntities } = documentsAdapter.getSelectors((state: RootState) => state.applicationDocuments);

export default applicationDocumentsSlice.reducer;
