import BaseApi from 'api/BaseApi';

export interface IAutomationWorkflow {
  id: string;
}

export interface IAutomationWorkflowApi {
  findById(id: string): Promise<IAutomationWorkflow>;
}

export default class AutomationWorkflowApi extends BaseApi implements IAutomationWorkflowApi {
  public async findById(id: string): Promise<IAutomationWorkflow> {
    return this.fetch('/automation-workflows/' + id);
  }
}
