import BaseApi from 'api/BaseApi';

interface IGetBrandingResponse {
  brandColor: string,
  accentColor: string;
  faviconId: string;
  logoId: string;
  companyName: string;
  country: string;
}

interface IGetPortalConfigurationResponse {
  borrowerLockPeriodDays: number;
}

interface IGetVariablesSettingsResponse {
  phoneNumberFormat: string;
  currency: string;
}

export interface ILegalDocument {
  id: string;
  name: string;
  body: string;
  showInFooter: boolean;
  showOnSubmitApplication: boolean;
  updatedAt: string;
}

export interface ISettingsApi {
  getBranding(): Promise<IGetBrandingResponse | undefined>;
  getLegalDocuments(): Promise<ILegalDocument[]>;
  getPortalConfiguration(): Promise<IGetPortalConfigurationResponse>;
  getVariablesSettings(): Promise<IGetVariablesSettingsResponse>;
}

export default class SettingsRestApi extends BaseApi implements ISettingsApi {
  public getBranding(): Promise<IGetBrandingResponse> {
    return this.fetch<IGetBrandingResponse>('/settings/branding');
  };

  public getLegalDocuments(): Promise<ILegalDocument[]> {
    return this.fetch('/settings/legal-documents');
  }

  public getPortalConfiguration(): Promise<IGetPortalConfigurationResponse> {
    return this.fetch('/settings/portal-configuration');
  }

  public getVariablesSettings(): Promise<IGetVariablesSettingsResponse> {
    return this.fetch('/variables');
  }
}
