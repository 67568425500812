import { FC, PropsWithoutRef } from 'react';
import ActionPopUpItem, { ActionPopUpItemProps } from 'product_modules/components/ActionPopUpItem';
import styled from 'types/AppTheme';

const ACTION_POPUP_ITEM_CLASS_NAME = 'action-popup-item';

const ActionPopUpItemStyled = styled(ActionPopUpItem)`
  &.${ACTION_POPUP_ITEM_CLASS_NAME}:hover {
    background-color: ${({ theme }) => theme.colors.backgroundHoverAccentColor};
    color: ${({ theme }) => theme.colors.accentColor};
  }
`;

const ActionPopUpItemWrapper: FC<PropsWithoutRef<ActionPopUpItemProps>> = (props) => {
  return (
    <ActionPopUpItemStyled {...props} className={ACTION_POPUP_ITEM_CLASS_NAME} />
  );
};

export default ActionPopUpItemWrapper;
