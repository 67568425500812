export const BorrowerVariable = {
  FirstName: 'borrower_first_name',
  LastName: 'borrower_last_name',
  PhoneNumber: 'borrower_phone_number',
  Email: 'borrower_email',
  Password: 'borrower_password',
  CompanyName: 'borrower_company_name',
};

export type BorrowerVariableType = typeof BorrowerVariable;
