import { FC } from 'react';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import useAppInitialization from 'hooks/useAppInitialization';

const AuthorizedPageWrapper: FC = ({ children }) => {
  const isPageLoading = useAppInitialization();

  const renderPageContent = () => {
    if (isPageLoading) {
      return <LoaderOverlay />;
    }

    return children;
  };

  return (
    <>
      {renderPageContent()}
    </>
  );
};

export default AuthorizedPageWrapper;
