import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from 'components/Notifications/NotificationList/customNotifications/AlreadyHaveAnAccountErrorNotification/AlreadyHaveAnAccountErrorNotification.module.scss';
import { AppRoute } from 'enums/AppRoute';

const AlreadyHaveAnAccountErrorNotification: FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(AppRoute.SignIn);
  };

  return (
    <div>It looks like you already have an account. Please
      {' '}<span className={styles.link} onClick={handleClick}>sign in.</span>
    </div>
  );
};

export default AlreadyHaveAnAccountErrorNotification;
