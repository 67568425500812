import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './TimerMessage.module.scss';

export interface ITimerMessageProps {
  className?: string;
}

const TimerMessage: FC<ITimerMessageProps> = ({ children, className }) => {
  return <div className={clsx(styles.container, className)}>{children}</div>;
};

export default TimerMessage;
