import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISubmitPageParams } from 'api/digifi/PortalPageInfoApi';
import { portalPageInfoApi } from 'store/api';

export const submitPortalPageInfo = createAsyncThunk(
  'portalPageInfo/submitPortalPageData',
  async (params: ISubmitPageParams) => {
    return portalPageInfoApi.submitPageData(params);
  },
);
