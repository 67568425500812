import { FC, useCallback } from 'react';
import clsx from 'clsx';
import { keyBy } from 'lodash';
import { SwiperSlide } from 'swiper/react';
import { IOfferSelectionElementConfig } from 'api/digifi/portal-page-elements';
import { TableValue } from 'product_modules/api/Types';
import { TableVisualAttributes, Variable } from 'product_modules/api/Core/VariablesApi';
import OfferNotAvailable from './OfferNotAvailable';
import OfferCardWrapper from './OfferCardWrapper';
import SwiperWrapper from './SwiperWrapper';
import SkeletonOfferSelectionElement from './SkeletonOfferSelectionElement';
import styles from './OfferSelectionElement.module.scss';

interface IOfferSelectionElementProps {
  config: IOfferSelectionElementConfig;
  offersVariableValue: TableValue | null;
  onOfferSelect: (updatedOffers: TableValue) => void;
  offerVariable?: Variable | null;
  containerClassName?: string;
  disabled?: boolean;
}

const OfferSelectionElement: FC<IOfferSelectionElementProps> = ({
  config,
  offersVariableValue,
  onOfferSelect,
  containerClassName,
  disabled,
  offerVariable,
}) => {
  const offerIndexByRowId = (offersVariableValue || []).reduce((accumulator, value, currentIndex) => {
    accumulator[value._id] = currentIndex;

    return accumulator;
  }, {} as Record<string, number>);

  const availableOffers = (offersVariableValue || []).filter((offer) => offer[config.availableColumn]);

  const handleCheckboxValueChange = useCallback((selectedIndex: number) => {
    const selectedAvailableOffer = availableOffers[selectedIndex];
    const updatedOffers = [...offersVariableValue || []]
      .map((offer) => ({ ...offer, [config.selectedColumn]: false }));

    updatedOffers[offerIndexByRowId[selectedAvailableOffer._id]] = {
      ...updatedOffers[offerIndexByRowId[selectedAvailableOffer._id]],
      [config.selectedColumn]: true,
    };

    onOfferSelect(updatedOffers);
  }, [availableOffers, onOfferSelect, offerIndexByRowId]);

  const columnsBySystemId = keyBy(
    (offerVariable?.visualAttributes as TableVisualAttributes)?.columns || [],
    'systemName',
  );

  const displayedColumns = config.displayedColumns.filter((column) => {
    return columnsBySystemId[column];
  });

  if (typeof offerVariable === 'undefined') {
    return <SkeletonOfferSelectionElement />;
  }

  if (
    !config.variable
    || !availableOffers.length
    || !offerVariable
    || !columnsBySystemId[config.availableColumn]
    || !columnsBySystemId[config.selectedColumn]
    || displayedColumns.length === 0
  ) {
    return <OfferNotAvailable containerClassName={containerClassName} />;
  }

  return (
    <div className={clsx(styles.container, containerClassName, disabled && styles.containerDisabled)}>
      <SwiperWrapper itemsCount={availableOffers.length}>
        {availableOffers.map((availableOffer, index) => (
          <SwiperSlide key={availableOffer._id}>
            <OfferCardWrapper
              index={index}
              checked={!!availableOffer[config.selectedColumn]}
              onCheckboxValueChange={handleCheckboxValueChange}
              offer={availableOffers[index]}
              displayedColumns={config.displayedColumns}
              variableName={config.variable}
            />
          </SwiperSlide>
        ))}
      </SwiperWrapper>
    </div>
  );
};

export default OfferSelectionElement;
