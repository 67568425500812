import { createSelector } from 'reselect';
import { get } from 'lodash';
import { IReduxState } from 'types/ReduxState';

export const getLayoutConfigurations = (state: IReduxState) => state.layoutConfigurations;

export const createLayoutConfigurationByReferenceKeySelector = (referenceKey: string) => {
  return createSelector([getLayoutConfigurations], (layoutConfigurations) => {
    return layoutConfigurations.configurationsByReferenceKey[referenceKey] || null;
  });
};

export const getLayoutEntities = (state: IReduxState, path: string) => get(state, path);

export const createLayoutEntitiesByIdsSelector = <EntityType>(path: string, items: {
  id: string,
  position: number
}[]) => {
  return createSelector([(state) => getLayoutEntities(state, path)], (entities) => {
    return items.map((item) => entities.entities[item.id]) as EntityType[];
  });
};

export const createLayoutEntityByIdSelector = <EntityType>(path: string, id: string) => {
  return createSelector([(state) => getLayoutEntities(state, path)], (entities) => {
    return entities.entries?.[id] as EntityType;
  });
};
