import styled from 'types/AppTheme';
import { rgba } from 'polished';
import { IOptionCardProps } from './OptionCard';

const OptionCardContainerStyled = styled.div<IOptionCardProps>`
  background: ${({ isSelected, theme }) => (isSelected 
    ? `linear-gradient(to bottom right, ${rgba(theme.colors.brandColor, 0.05)}, ${rgba(theme.colors.brandColor, 0)})`
    : '#FFFFFF'
  )};
  border: ${({ isSelected, theme }) => (isSelected 
    ? `3px solid ${theme.colors.brandColor}` 
    : '1px solid #CFD3D9'
  )};
`;

export default OptionCardContainerStyled;
