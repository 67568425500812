import { FC, FocusEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useLayoutConfigurationVariables,
} from 'product_modules/loaders/LayoutConfiguration/hooks/useLayoutConfigurationVariables';
import { useVariablesFormValidation } from 'product_modules/components/ConfigurableForm';
import { updateLayoutConfiguration } from 'handlers/layoutConfigurationsSlice';
import {
  useLayoutConfigurationReferenceKeyWithLayoutFetch,
} from 'hooks/useLayoutConfigurationReferenceKeyWithLayoutFetch';
import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';
import { VariableValue } from 'product_modules/api/Types';
import { IPortalPageVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import useProductCalculations from 'product_modules/hooks/useProductCalculations';
import useCalculationsLogic from 'product_modules/hooks/useCalculationsLogic';
import DataInputElement from './DataInputElement';
import useConditionalDisplayLogic from 'product_modules/hooks/useConditionalDisplayLogic';
import useSelectedApplication from 'hooks/useSelectedApplication';

interface IDataInputElementConnectorProps {
  elementId: string;
  productId: string;
  portalPageFormData: Record<string, VariableValue>;
  onPortalPageFieldChange: (field: IPortalPageVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onPortalPageFieldBlur:  (field: IPortalPageVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  containerClassName?: string;
  disabled?: boolean;
}

const storageLayoutEntitiesPaths = {
  groups: 'portalPageGroups',
  cards: 'portalPageCards',
  variablesConfigurations: 'portalPageVariableConfigurations',
};

const DataInputElementConnector: FC<IDataInputElementConnectorProps> = ({
  elementId,
  productId,
  portalPageFormData,
  onPortalPageFieldBlur,
  onPortalPageFieldChange,
  containerClassName,
  disabled,
}) => {
  const dispatch = useDispatch();
  const application = useSelectedApplication();

  const productCalculations = useProductCalculations(productId);

  const referenceKey = useLayoutConfigurationReferenceKeyWithLayoutFetch({
    referenceType: LayoutConfigurationReferenceType.PortalPage,
    reference: elementId,
  });

  const layoutConfigurationVariables = useLayoutConfigurationVariables(
    referenceKey,
    storageLayoutEntitiesPaths.variablesConfigurations,
  );

  const fullPortalPageDataWithCalculations = useCalculationsLogic(productCalculations, {
    ...application?.variables || {},
    ...portalPageFormData,
  });

  const displayFieldsAttributes = useConditionalDisplayLogic(
    layoutConfigurationVariables || null,
    {
      ...application?.variables || {},
      ...fullPortalPageDataWithCalculations || {},
    },
  );

  const isApplicationDetailsDataValid = useVariablesFormValidation(
    layoutConfigurationVariables || null,
    fullPortalPageDataWithCalculations,
    displayFieldsAttributes,
  );

  useEffect(() => {
    dispatch(updateLayoutConfiguration({ id: referenceKey, isValid: isApplicationDetailsDataValid }));
  }, [isApplicationDetailsDataValid]);

  return (
    <DataInputElement
      referenceKey={referenceKey}
      portalPageFormData={fullPortalPageDataWithCalculations || {}}
      storageLayoutEntitiesPaths={storageLayoutEntitiesPaths}
      onPortalPageFieldChange={onPortalPageFieldChange}
      onPortalPageFieldBlur={onPortalPageFieldBlur}
      containerClassName={containerClassName}
      disabled={disabled}
      productCalculations={productCalculations}
      displayFieldsAttributes={displayFieldsAttributes}
    />
  );
};

export default DataInputElementConnector;
