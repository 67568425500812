import { AddressValue, AddressValueKey } from 'product_modules/api/Types';

interface IAddressTemplateComponent {
  key: keyof AddressValue;
  dismissComma?: boolean;
  prefix?: string;
}

const addressTemplateComponents: Array<IAddressTemplateComponent> = [
  { key: AddressValueKey.StreetNumber },
  { key: AddressValueKey.StreetName, dismissComma: true },
  { key: AddressValueKey.UnitNumber, dismissComma: true, prefix: '#' },
  { key: AddressValueKey.Sublocality },
  { key: AddressValueKey.City },
  { key: AddressValueKey.StateOrProvince },
  { key: AddressValueKey.ZipOrPostalCode },
  { key: AddressValueKey.Country },
];

const templateAddressPart = (
  addressTemplateComponent: IAddressTemplateComponent,
  addressPart: string,
  fullAddress: string,
) => {
  return addressTemplateComponent.dismissComma
    ? `${fullAddress} ${addressTemplateComponent.prefix || ''}${addressPart}`
    : `${fullAddress}, ${addressTemplateComponent.prefix || ''}${addressPart}`;
};

export const buildFullAddressString = (address: AddressValue | null | undefined) => {
  if (!address) {
    return '';
  }

  return addressTemplateComponents.reduce((fullAddress, addressTemplateComponent) => {
    const addressPart = address[addressTemplateComponent.key];

    if (!addressPart) {
      return fullAddress;
    }

    return fullAddress
      ? templateAddressPart(addressTemplateComponent, addressPart, fullAddress)
      : `${addressTemplateComponent.prefix || ''}${addressPart}`;
  }, '');
};
