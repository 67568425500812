import { BASE_API_PREFIX, FetchMethodType } from 'api/BaseApi';
import { stringifyRequestBody } from 'utils/fetch';

interface ICreateTokenResponse {
  token: string;
}

export interface IAdminAuthApi {
  createToken(password: string): Promise<string | null>;
}

// We need to avoid using BaseApi here, since there we have logic
// to handle 401/403 with redirections to sign in page

export default class AdminAuthApi implements IAdminAuthApi {
  public async createToken(password: string): Promise<string | null> {
    const response = await fetch(`${BASE_API_PREFIX}/admin-auth/tokens`, {
      method: FetchMethodType.POST,
      body: stringifyRequestBody({
        password,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      return null;
    }

    const responseData: ICreateTokenResponse = await response.json();

    return responseData.token;
  }
}
