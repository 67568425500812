import { FC, useEffect, useState } from 'react';
import heic2any from 'heic2any';
import ImageViewer from 'components/common/ImageViewer';
import { DocumentExtension } from 'api/digifi/DocumentsApi';

interface IImagePreviewProps {
  previewLink: string;
  filename: string;
  fileExtension: string;
  onLoad: () => void;
  onError: () => void;
}

const ImagePreview: FC<IImagePreviewProps> = ({ previewLink, filename, fileExtension, onLoad, onError }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const getHeicImageImageSrc = async (imageUrl: string) => {
    const downloadResponse = await fetch(imageUrl);

    const convertedFile = (await heic2any({
      blob: await downloadResponse.blob(),
    })) as Blob;

    return window.URL.createObjectURL(convertedFile);
  };

  const initializeImage = async (imageUrl: string) => {
    const url = fileExtension === DocumentExtension.Heic ? await getHeicImageImageSrc(imageUrl) : imageUrl;

    setImageSrc(url);
  };

  useEffect(() => {
    initializeImage(previewLink);
  }, [previewLink]);

  return (
    <>
      {imageSrc && (
        <ImageViewer
          src={imageSrc}
          alt={filename}
          onLoad={onLoad}
          onError={onError}
        />
      )}
    </>
  );
};

export default ImagePreview;
