import { RefObject, useEffect } from 'react';

const useOutOfView = (ref: RefObject<HTMLElement>, callback: () => void) => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (!entry.isIntersecting) {
        callback();
      }
    },
    { threshold: 1.0 },
  );

  useEffect(() => {
    observer.observe(ref!.current!);
    return () => {
      observer.disconnect();
    };
  }, []);
};

export default useOutOfView;
