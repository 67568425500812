import { FC } from 'react';
import { SkeletonRectangle, SkeletonRectangleProps } from 'product_modules/components/Skeleton';

const SkeletonRectangleWrapper: FC<SkeletonRectangleProps> = (props) => {
  return (
    <SkeletonRectangle {...props} />
  );
};

export default SkeletonRectangleWrapper;
