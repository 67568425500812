import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './Table.module.scss';

export interface TableProps {
  className?: string;
  style?: React.CSSProperties;
}

const Table = ({ children, className, style }: PropsWithChildren<TableProps>) => {
  return <div className={clsx(styles.table, className)} style={style}>{children}</div>;
};

export default Table;
