import { useState } from 'react';

function useBlockingRequest(): [
  boolean,
  <RequestArgs extends unknown[], ReturnType>(
    request: (...args: RequestArgs) => Promise<ReturnType>,
  ) => (...args: RequestArgs) => Promise<void>,
];

function useBlockingRequest<DefaultRequestArgs extends unknown[], DefaultReturnType>(
  request: (...args: DefaultRequestArgs) => Promise<DefaultReturnType>,
): [
  boolean,
  (...args: DefaultRequestArgs) => Promise<void>,
];

function useBlockingRequest<DefaultRequestArgs extends unknown[], DefaultReturnType>(
  defaultRequest?: (...args: DefaultRequestArgs) => Promise<DefaultReturnType>,
) {
  const [isBlockingRequestInProgress, setIsBlockingRequestInProgress] = useState(false);

  const makeBlockingRequest = async (request: () => Promise<unknown>) => {
    try {
      setIsBlockingRequestInProgress(true);

      await request();
    } finally {
      setIsBlockingRequestInProgress(false);
    }
  };

  const wrapBlockingRequest = <RequestArgs extends unknown[], ReturnType>(
    request: (...args: RequestArgs) => Promise<ReturnType>,
  ) => {
    return (...args: RequestArgs) => {
      return makeBlockingRequest(() => request(...args));
    };
  }

  if (defaultRequest) {
    return [
      isBlockingRequestInProgress,
      wrapBlockingRequest(defaultRequest),
    ] as [boolean, (...args: DefaultRequestArgs) => Promise<void>];
  }

  return [
    isBlockingRequestInProgress,
    wrapBlockingRequest,
  ] as [boolean, typeof wrapBlockingRequest];
}

export default useBlockingRequest;
