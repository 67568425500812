import { useMemo, useRef } from 'react';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import { validateVariable } from './validation';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';
import { BaseConfigurableFormVariableField } from './types';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import useVariablesBySystemNames from 'product_modules/loaders/Variables/hooks/useVariablesBySystemNames';
import { ProductCalculation } from 'product_modules/api/LoanOriginationSystem/ProductCalculationsApi';

const useVariablesFormValidation = (
  fields: BaseConfigurableFormVariableField[] | null,
  data: FormLayoutData | null,
  displayFieldsAttributes?: Record<string, boolean>,
  calculations?: Map<string, ProductCalculation> | null,
) => {
  const cachedValidationResults = useRef<Record<string, [VariableValue, boolean, boolean]>>({});

  const usedVariablesSystemNames = useMemo(() => {
    return fields?.map((field) => field.variable);
  }, [fields]);

  const variables = useVariablesBySystemNames(usedVariablesSystemNames);

  const runValidationForField = (
    field: BaseConfigurableFormVariableField,
    variable: Variable,
    currentValue: VariableValue,
    shouldDisplayField: boolean,
  ) => {
    const cachedResult = cachedValidationResults.current[field.id];
    const [previousValue, previousShouldDisplayField, previousValidationResult] = cachedResult || [];

    if (cachedResult && previousValue === currentValue && previousShouldDisplayField === shouldDisplayField) {
      return previousValidationResult;
    }

    return !shouldDisplayField || validateVariable(variable, currentValue, field.required);
  };

  return useMemo(() => {
    if (!fields || !data || !variables) {
      return false;
    }

    for (const field of fields) {
      const variable = variables[field.variable];

      if (calculations?.has(variable.systemName)) {
        continue;
      }

      const value = data[field.variable];
      const shouldDisplayField = !displayFieldsAttributes
        || displayFieldsAttributes[field.id]
        || !isEmptyVariableValue(value);
      const validationResult = runValidationForField(field, variable, value, shouldDisplayField);

      cachedValidationResults.current[field.id] = [value, shouldDisplayField, validationResult];

      if (!validationResult) {
        return false;
      }
    }

    return true;
  }, [fields, data, displayFieldsAttributes, variables]);
};

export default useVariablesFormValidation;
