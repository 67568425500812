import React, { FC, useState } from 'react';
import clsx from 'clsx';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import styles from './OverflowedText.module.scss';

export interface OverflowedTextProps {
  className?: string;
  useTooltip?: boolean;
  tooltipOnHover?: React.ReactNode;
  shouldAlwaysDisplayTooltipOnHover?: boolean;
  style?: React.CSSProperties;
  tag?: 'div' | 'p' | 'span' | 'h5' | 'h2';
  disableHover?: boolean;
  open?: boolean;
  tooltipClassName?: string;
  twoLines?: boolean;
}

const OverflowedText: FC<OverflowedTextProps> = ({
  className,
  useTooltip,
  tooltipOnHover,
  children,
  tag = 'div',
  style,
  disableHover = false,
  open,
  twoLines,
  tooltipClassName,
  shouldAlwaysDisplayTooltipOnHover = false,
}) => {
  const overflowedTextClassName = clsx(
    styles.overflowedText,
    !useTooltip && !disableHover && styles.overflowedTextWithBreakWords,
    className,
    open && styles.fullText,
    twoLines && styles.twoLines,
  );

  const TagComponent = tag;

  const [shouldDisplayTooltip, setShouldDisplayTooltip] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setShouldDisplayTooltip(
      shouldAlwaysDisplayTooltipOnHover || event.currentTarget.scrollWidth > event.currentTarget.clientWidth,
    );
  };

  const handleMouseLeave = () => {
    setShouldDisplayTooltip(false);
  };

  if (useTooltip) {
    return (
      <WrapperWithTooltip className={tooltipClassName} open={shouldDisplayTooltip} tooltip={tooltipOnHover || children}>
        <TagComponent
          style={style}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={overflowedTextClassName}
        >
          {children}
        </TagComponent>
      </WrapperWithTooltip>
    );
  }

  return (
    <TagComponent style={style} className={overflowedTextClassName}>
      {children}
    </TagComponent>
  );
};

export default OverflowedText;
