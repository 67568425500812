import { FC } from 'react';
import { ShareImageCustomColor } from 'product_modules/static/images';
import formatDate from 'utils/formatDate';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import InfoSection from 'components/common/InfoSection';
import { SkeletonApplicationOverview } from 'components/ApplicationDetails/ApplicationOverview/index';
import { getBorrowerFullName, getCoborrowerFullNames } from 'utils/borrowerNameHelper';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';
import Button from 'components/digifi-wrappers/Button';
import { AppRoute } from 'enums/AppRoute';
import styles from './ApplicationOverview.module.scss';

interface IApplicationOverviewProps {
  application: IApplicationViewModel | null;
  isLoading?: boolean;
}

const ApplicationOverview: FC<IApplicationOverviewProps> = ({ application, isLoading }) => {
  const [
    borrowerConfig,
    applicationDateConfig,
    coBorrowerConfig,
    lastUpdatedConfig,
  ] = [
    {
      id: 'borrower',
      name: 'Applicant',
      value: application ? getBorrowerFullName(application.variables, application.borrowerType) : '',
    },
    {
      id: 'application-date',
      name: 'Application Date',
      value: application?.createdAt ? formatDate(application.createdAt) : '',
    },
    {
      id: 'co-borrower',
      name: 'Co-Applicant(s)',
      value: application ? getCoborrowerFullNames(application.variables, application.coborrowerTypes) : '',
    },
    {
      id: 'last-updated',
      name: 'Last Updated',
      value: application?.updatedAt ? formatDate(application.updatedAt) : '',
    },
  ];

  const desktopOverviewCellsMap = [
    borrowerConfig,
    applicationDateConfig,
    coBorrowerConfig,
    lastUpdatedConfig,
  ];

  const mobileOverviewCellsMap = [
    borrowerConfig,
    coBorrowerConfig,
    applicationDateConfig,
    lastUpdatedConfig,
  ];

  return (
    <>
      {isLoading || !application ? <SkeletonApplicationOverview /> : (
        <ContainerStyled className={styles.overviewContainer}>
          <div className={styles.overviewTitle}>
            Application Overview
            <div className={styles.displayId}>ID: #{application.displayId}</div>
          </div>
          <div className={styles.infoSectionDesktop}>
            <InfoSection
              sectionContent={desktopOverviewCellsMap}
              containerClassName={styles.overviewContent}
              fieldTitleClassName={styles.fieldTitle}
              fieldValueClassName={styles.fieldValue}
              fieldClassName={styles.field}
              rows={2}
            />
          </div>
          <div className={styles.infoSectionMobile}>
            <InfoSection
              sectionContent={mobileOverviewCellsMap}
              containerClassName={styles.overviewContent}
              fieldTitleClassName={styles.fieldTitle}
              fieldValueClassName={styles.fieldValue}
              fieldClassName={styles.field}
            />
          </div>
          <Button
            kind='primary'
            onClick={() => window.open(`${AppRoute.Application}/${application.displayId}`, '_blank')}
            className={styles.viewDetailsButton}
          >
            <ShareImageCustomColor />
            View Details
          </Button>
        </ContainerStyled>
      )}
    </>
  );
};

export default ApplicationOverview;
