import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import Button from 'product_modules/components/Button/Button';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import Label from 'product_modules/components/Label';
import styles from './ButtonToggleGroup.module.scss';

export interface IButtonToggleGroupOption {
  value: string;
  label: string;
}

export interface IButtonToggleGroupProps {
  options: string[] | IButtonToggleGroupOption[];
  selectedValue: string;
  onSelect: (value: string) => void;
  className?: string;
  buttonClassName?: string;
  buttonsContainerClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  required?: boolean;
  labelTitle?: string;
  buttonWrapperClassName?: string;
  isOptionDisabled?: (option: string) => boolean;
  renderOptionTooltip?: (option: string) => ReactNode;
  selectedButtonClassName?: string;
}

const ButtonToggleGroup: FC<IButtonToggleGroupProps> = ({
  options,
  selectedValue,
  className,
  buttonClassName,
  buttonsContainerClassName,
  labelClassName,
  disabled,
  isOptionDisabled,
  renderOptionTooltip,
  onSelect,
  labelTitle,
  required,
  buttonWrapperClassName,
  selectedButtonClassName,
}) => {
  const handleButtonClick = (value: string) => () => {
    if (value !== selectedValue) {
      onSelect(value);
    }
  };

  return (
    <div className={clsx(styles.buttonToggleGroup, className)}>
      {labelTitle && <Label required={required} className={clsx(styles.label, labelClassName)}>
        {labelTitle}
      </Label>}
      <div className={clsx(styles.buttonsContainer, buttonsContainerClassName)}>
        {(options as []).map((option: string | IButtonToggleGroupOption) => {
          const optionValue = typeof option === 'string' ? option : option.value;

          const optionDisabled = isOptionDisabled?.(optionValue);

          return (
            <WrapperWithTooltip key={optionValue} tooltip={renderOptionTooltip?.(optionValue)}>
              <span className={clsx(styles.buttonWrapper, buttonWrapperClassName)}>
                <Button
                  className={clsx(
                    styles.button,
                    (disabled || optionDisabled) && styles.disabled,
                    selectedValue === optionValue && styles.selected,
                    buttonClassName,
                    selectedValue === optionValue && selectedButtonClassName,
                  )}
                  onClick={handleButtonClick(optionValue)}
                  disabled={disabled || optionDisabled}
                >
                  {typeof option === 'string' ? option : option.label}
                </Button>
              </span>
            </WrapperWithTooltip>
          );
        })}
      </div>
    </div>
  );
};

export default ButtonToggleGroup;
