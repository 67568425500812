import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './RequiredSymbol.module.scss';

interface RequiredSymbolProps {
  className?: string;
}

const RequiredSymbol: FC<RequiredSymbolProps> = ({
  className,
}) => {
  return <span className={clsx(styles.requiredSymbol, className)}>*</span>
};

export default RequiredSymbol;
