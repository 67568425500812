import React, { forwardRef, useEffect } from 'react';
import { InputProps } from 'product_modules/components/InputWithValidation/InputWithValidation';
import useCombinedRefs from 'product_modules/hooks/useCombinedRefs';

interface CustomBlurInputProps extends InputProps {
  onCustomBlur: (target: EventTarget | null) => void;
}

const CustomBlurInput = forwardRef<HTMLInputElement, CustomBlurInputProps>(({ onCustomBlur, ...props }, ref) => {
  const defaultRef = React.useRef(null);
  const combinedRef = useCombinedRefs<HTMLInputElement>(ref, defaultRef);

  /**
   * The most obvious way to handle closing the dropdown after the input loses focus
   * seems to be delegating onBlur to the input element. That doesn't work as expected,
   * cause "blur" happens prior to "change" of an option input. That basically means,
   * options disappear before one can be selected.
   * I addressed this issue by a combination of workarounds:
   * 1. "fake" "blur" is emitted when another input receives focus
   * 2. selecting an option also triggers onBlur()
   * 3. onBlur() is triggered on any click outside the component
   */
  useEffect(() => {
    const handleFocus = ({ target }: FocusEvent) => {
      if (combinedRef.current !== target) {
        onCustomBlur(target);
      }
    };

    document.addEventListener('focus', handleFocus, true);

    return () => {
      document.removeEventListener('focus', handleFocus, true);
    };
  }, []);

  return <input autoComplete="off" ref={combinedRef} type="text" {...props} />;
});

export default CustomBlurInput;
