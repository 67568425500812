import React, { FC, useState, useMemo } from 'react';
import { AddressValue, FieldComponent, FormLayoutData } from 'product_modules/api/Types';
import TextInput from 'product_modules/components/TextInput';
import ButtonWrapper from 'components/digifi-wrappers/Button';
import Header from 'product_modules/components/Header';
import { isConfigurableFormDataChanged } from 'product_modules/components/ConfigurableForm/utils';
import formatAddressValue from 'product_modules/utils/valueFormatters/formatAddressValue';
import { addressComponents } from 'product_modules/config';
import styles from './AddressDetails.module.scss';

export interface AddressDetailsProps {
  addressLabel?: string;
  address: AddressValue | null | undefined;
  onSubmit: (address: AddressValue) => void;
  saveButtonClassName?: string;
}

const AddressDetails: FC<AddressDetailsProps> = ({
  address,
  onSubmit,
  addressLabel,
  saveButtonClassName,
}) => {
  const [addressDetails, setAddressDetails] = useState(address || {});

  const handleAddressDetailsInputChange = (value: string, addressDetailsComponent: FieldComponent) => {
    setAddressDetails((previousAddressDetails) => ({ ...previousAddressDetails, [addressDetailsComponent.id ]: value }));
  };

  const createAddressDetailsInputChangeHandler = (addressDetailsComponent: FieldComponent) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      handleAddressDetailsInputChange(event.target.value, addressDetailsComponent);
    };
  };

  const renderAddressDetailsComponent = (addressDetailsComponent: FieldComponent) => (
    <TextInput
      key={addressDetailsComponent.id}
      labelTitle={addressDetailsComponent.label}
      placeholder={addressDetailsComponent.label}
      // @ts-ignore
      value={addressDetails[addressDetailsComponent.id] || ''}
      onChange={createAddressDetailsInputChangeHandler(addressDetailsComponent)}
    />
  );

  const isFormDataChanged = useMemo(() => {
    return isConfigurableFormDataChanged((address || {}) as FormLayoutData, addressDetails as FormLayoutData);
  }, [addressDetails, address]);

  const handleSaveAddressButtonClick = () => {
    onSubmit(addressDetails);
  };

  const componentsForInput = useMemo(() => {
    return addressComponents.filter((component) => !component.virtual);
  }, []);


  return (
    <div>
      <TextInput
        placeholder={addressLabel}
        labelTitle={addressLabel}
        value={formatAddressValue(addressDetails)}
        disabled
      />
      <Header className={styles.componentsHeader} type="h5">Components</Header>
      <div className={styles.components}>
        {componentsForInput.map(renderAddressDetailsComponent)}
      </div>
      <ButtonWrapper
        onClick={handleSaveAddressButtonClick}
        disabled={!isFormDataChanged}
        kind="primary"
        size="form"
        className={saveButtonClassName}
      >
        Save Address
      </ButtonWrapper>
    </div>
  );
};

export default AddressDetails;
