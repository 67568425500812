import { FC } from 'react';
import Button from 'components/digifi-wrappers/Button';
import { SessionEndedImage } from 'static/images';
import styles from './ESignatureElement.module.scss';

interface ISessionTimeoutProps {
  onClick: () => void;
}

const SessionTimeout: FC<ISessionTimeoutProps> = ({ onClick }) => {
  return (
    <div className={styles.errorContainer}>
      <SessionEndedImage />
      <div className={styles.textContainer}>
        <p className={styles.title}>
          Session Ended
        </p>
        <p className={styles.errorDescription}>
          Click the button to return to your session<br/>
          and continue.
        </p>
      </div>
      <Button kind="secondary" onClick={onClick}>
        Restart
      </Button>
    </div>
  );
};

export default SessionTimeout;
