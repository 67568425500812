import React from 'react';
import CountryProvider from 'product_modules/providers/CountryProvider';
import { useSelector } from 'react-redux';
import { IReduxState } from 'types/ReduxState';

const SettingsCountryProvider: React.FC = ({ children }) => {
  const settings = useSelector((state: IReduxState) => state.settings);

  return (
    <CountryProvider value={settings.branding.country}>
      {children}
    </CountryProvider>
  );
};

export default SettingsCountryProvider;
