import { FC } from 'react';
import Label, { LabelProps } from 'product_modules/components/Label';

const LabelWrapper: FC<LabelProps> = (props) => {
  return (
    <Label {...props} />
  );
};

export default LabelWrapper;
