export enum DocumentsTableColumnSizes {
  Name = 49,
  Type = 11,
  Size = 10,
  Uploaded = 30,
}

export enum DocumentsTableColumnSizesMobile {
  Name = 64,
  Size = 15,
  Uploaded = 19,
}
