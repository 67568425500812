import React, { FC, DetailedHTMLProps, HTMLAttributes } from 'react';
import TagCore from './TagCore';
import blendColors from 'product_modules/utils/blendColors';
import addAlphaToColor from 'product_modules/utils/addAlphaToColor';
import { CloseSmallImage } from 'product_modules/static/images';
import styles from './Tag.module.scss';
import clsx from 'clsx';

type HTMLDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

interface ApplicationTagProps extends HTMLDivProps {
  color?: string;
  medium?: boolean;
  onClose?: () => void;
}

const DEFAULT_COLOR = '#f1f2f4';

const ApplicationTag: FC<ApplicationTagProps> = ({
  medium,
  color = DEFAULT_COLOR,
  onClose,
  children,
  className,
  ...tagProps
}) => {
  return (
    <TagCore
      style={{
        color,
        backgroundColor: `rgb(${blendColors(addAlphaToColor(color, 0.1)).join(',')})`,
      }}
      medium={medium}
      className={clsx(className, onClose && styles.tagWithCloseButton)}
      {...tagProps}
    >
      {children}
      {onClose && <CloseSmallImage onClick={onClose} />}
    </TagCore>
  );
};

export default ApplicationTag;
