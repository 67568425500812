import React, { useCallback, useState, FocusEvent } from 'react';
import BorrowerProfileForm from 'components/ApplicationForm/forms/BorrowerProfileForm';
import { VariableValue } from 'product_modules/api/Types';
import { IBaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import isTrimmableString from 'utils/isTrimmableString';
import styles from './BorrowerInformationStep.module.scss';
import StepWrapper, { IStepNavigationProps } from 'components/common/StepWrapper';

interface IBorrowerInformationStepProps extends IStepNavigationProps {
  borrowerFormData: Record<string, VariableValue>;
  borrowerType: BorrowerType;
  onSetBorrowerFormData: (variable: Variable, value: VariableValue) => void;
  coBorrowersFormData: Array<Record<string, VariableValue>>;
  availableBorrowerTypes: BorrowerType[];
  isLoading: boolean;
}

const BorrowerInformationStep = ({
  borrowerFormData,
  borrowerType,
  onSetBorrowerFormData,
  coBorrowersFormData,
  availableBorrowerTypes,
  ...restProps
}: IBorrowerInformationStepProps) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const handleBorrowerProfileFieldChange = useCallback(
    (_variableConfiguration: IBaseVariableConfiguration, variable: Variable, value: VariableValue) => {
      onSetBorrowerFormData(variable, value);
    },
    [],
  );

  const handleBorrowerProfileFieldBlur = useCallback(
    (_variableConfiguration: IBaseVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => {
      const visualDataType = variable.visualDataType;

      if (!isTrimmableString(visualDataType)) {
        return;
      }

      onSetBorrowerFormData(variable, (event?.target.value as string)?.trim());
    },
    [],
  );

  return (
    <StepWrapper
      contentClassName={styles.content}
      buttonsContainerClassName={styles.buttonsContainer}
      disableContinue={!isFormValid}
      titleClassName={styles.title}
      subtitleClassName={styles.subtitle}
      title='Applicant Information'
      subtitle='Please provide a few details to help us process your request.'
      hideBack
      {...restProps}
    >
      <BorrowerProfileForm
        borrowerFormData={borrowerFormData}
        coBorrowersFormData={coBorrowersFormData}
        onFieldChange={handleBorrowerProfileFieldChange}
        onFieldBlur={handleBorrowerProfileFieldBlur}
        availableBorrowerTypes={availableBorrowerTypes}
        selectedBorrowerType={borrowerType}
        onFormValidationChange={setIsFormValid}
        isBorrowerForm
      />
    </StepWrapper>
  );
};

export default BorrowerInformationStep;
