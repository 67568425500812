import { FC, FocusEvent, useRef } from 'react';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import { PortalPageElementType } from 'api/digifi/portal-page-elements';
import PageElement from 'components/ApplicationPage/PageElement';
import { TableValue, VariableValue } from 'product_modules/api/Types';
import { IPortalPageVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';

interface IApplicationPage {
  className?: string;
  pageElementsIds: string[];
  application: IApplicationViewModel;
  isElementLoadingByType: Record<PortalPageElementType, boolean>;
  portalPageFormData: Record<string, VariableValue>;
  onPortalPageFieldChange: (field: IPortalPageVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onPortalPageFieldBlur: (field: IPortalPageVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onSubmitPageData: () => Promise<void>;
  onOfferSelect: (updatedOffers: TableValue) => void;
  offersVariableValue: TableValue | null;
  offersSelectionElementId: string | null;
}

const ApplicationPage: FC<IApplicationPage> = ({
  pageElementsIds,
  application,
  isElementLoadingByType,
  className,
  portalPageFormData,
  onPortalPageFieldChange,
  onPortalPageFieldBlur,
  onSubmitPageData,
  offersVariableValue,
  onOfferSelect,
  offersSelectionElementId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={className} ref={containerRef}>
      {pageElementsIds.map((id) => (
        <PageElement
          key={id}
          elementId={id}
          context={application.variables}
          productId={application.productId}
          applicationDisplayId={application.displayId}
          isElementLoadingByType={isElementLoadingByType}
          portalPageFormData={portalPageFormData}
          onPortalPageFieldChange={onPortalPageFieldChange}
          onPortalPageFieldBlur={onPortalPageFieldBlur}
          onSubmitPageData={onSubmitPageData}
          offersVariableValue={offersVariableValue}
          onOfferSelect={onOfferSelect}
          offersSelectionElementId={offersSelectionElementId}
          containerRef={containerRef}
        />
      ))}
    </div>
  );
};

export default ApplicationPage;
