import { Portal } from '@material-ui/core';
import { FC } from 'react';

export enum PortalLayer {
  Bottom = 'bottom-layer-popoups'
}

interface IPortalWithLayerProps {
  layer: PortalLayer;
}

const PortalWithLayer: FC<IPortalWithLayerProps> = ({
  layer,
  children,
}) => {
  return (
    <Portal container={document.getElementById(layer)}>
      {children}
    </Portal>
  );
};

export default PortalWithLayer;
