import { ApplicationFormPageType, IProduct } from 'api/digifi/ProductsApi';

const coborrowerOptions = new Set([
  ApplicationFormPageType.CoBorrower,
  ApplicationFormPageType.CoBorrower2,
  ApplicationFormPageType.CoBorrower3,
]);

export const hasProductCoborrowerOptions = (selectedProduct: IProduct | null): boolean => {
  return Object.keys(selectedProduct?.applicationFormPages || {})
    .some((key) => coborrowerOptions.has(key as ApplicationFormPageType));
};
