import { DocuSignLogo } from 'static/images';
import styles from './ESignatureElement.module.scss';

interface IEmptyESignatureElementProps {
  title: string;
  description: string;
}

const EmptyESignatureElement = ({ title, description }: IEmptyESignatureElementProps) => {
  return (
    <div className={styles.noDocumentsContainer}>
      <DocuSignLogo/>
      <div className={styles.separator}/>
      <div className={styles.noDocumentsContent}>
        <p className={styles.title}>
          {title}
        </p>
        <p className={styles.description}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default EmptyESignatureElement;
