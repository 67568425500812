import {
  createAsyncThunk,
  createSlice,
  createAction,
} from '@reduxjs/toolkit';
import { layoutConfigurationApi } from 'store/api';
import { groupItemIdsByParentId } from './layout-configurations/Utils';
import {
  IBaseLayout,
  IFindLayoutConfigurationParams,
} from 'api/digifi/layout/LayoutConfigurationApi';

export enum LayoutConfigurationActionType {
  GetLayoutConfiguration = 'layoutConfigurations/getLayoutConfiguration',
  UpdateLayoutConfiguration = 'layoutConfigurations/updateLayoutConfiguration',
}

export interface IItemData {
  id: string;
  position: number;
}

export interface ILayoutConfiguration {
  sortedGroups: Array<IItemData>;
  sortedCardsByGroup: Record<string, Array<IItemData>>;
  sortedVariableConfigurationsByCard: Record<string, Array<IItemData>>;
  isValid?: boolean;
}

export interface ILayoutConfigurationState {
  configurationsByReferenceKey: Record<string, ILayoutConfiguration>;
  isValidConfiguration: Record<string, boolean>;
}

export const initialState: ILayoutConfigurationState = {
  configurationsByReferenceKey: {},
  isValidConfiguration: {},
};

export const updateLayoutConfiguration = createAction<{ id: string, isValid: boolean }>(LayoutConfigurationActionType.UpdateLayoutConfiguration);

export const getLayoutConfiguration = createAsyncThunk<
  IBaseLayout,
  IFindLayoutConfigurationParams,
  { fulfilledMeta: { referenceKey: string } }
>(
  LayoutConfigurationActionType.GetLayoutConfiguration,
  async (params, thunkAPI) => {
    return thunkAPI.fulfillWithValue(await layoutConfigurationApi.find(params), {
      referenceKey: params.referenceType + (params.reference ? `-${params.reference}` : ''),
    });
  },
);

const layoutConfigurationsSlice = createSlice({
  name: 'layoutConfigurationsSlice',
  initialState,
  reducers: {
    removeLayoutConfigurations(state) {
      state.configurationsByReferenceKey = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateLayoutConfiguration, (state, { payload }) => {
      const { id, isValid } = payload;

      state.isValidConfiguration = {
        ...state.isValidConfiguration,
        [id]: isValid,
      };
    });

    builder.addCase(getLayoutConfiguration.fulfilled, (state, action) => {
      state.configurationsByReferenceKey = {
        ...state.configurationsByReferenceKey,
        [action.meta.referenceKey]: {
          sortedGroups: action.payload.groups.map((group) => ({ id: group.id, position: group.position })),
          sortedCardsByGroup: groupItemIdsByParentId(action.payload.cards, 'group'),
          sortedVariableConfigurationsByCard: groupItemIdsByParentId(
            action.payload.variables,
            'cardId',
          ),
        },
      };
    });
  },
});

export const { removeLayoutConfigurations } = layoutConfigurationsSlice.actions;

export default layoutConfigurationsSlice.reducer;
