import React, { FocusEvent, forwardRef, KeyboardEvent, useMemo } from 'react';
import clsx from 'clsx';
import { CountryCode } from 'libphonenumber-js';
import TextInputWrapper, { TextInputWrapperProps } from 'product_modules/components/TextInputWrapper/TextInputWrapper';
import { useTrimmedPhoneNumber, focusInputElement } from './utils';
import LoaderWithState, { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import countryCodeStyles from 'product_modules/components/CountryCodeSelect/CountryCodeSelect.module.scss';
import PhoneInput, { flags } from 'product_modules/components/PhoneInput';
import TextInput from 'product_modules/components/TextInput';
import getPhoneNumberPlaceholder from 'product_modules/components/PhoneNumberInput/getPhoneNumberPlaceholder';
import styles from './PhoneNumberInput.module.scss';

export interface PhoneNumberInputProps extends Omit<TextInputWrapperProps, 'children'> {
  value: string;
  country: CountryCode | null | undefined;
  onChange: (value: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  containerClassName?: string;
  withFlag?: boolean;
  inputIcon?: React.ReactNode;
  raw?: boolean;
  required?: boolean;
  name?: string;
  autoFocus?: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  labelTooltipClassName?: string;
}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(({
  value,
  country,
  onChange,
  onFocus,
  onBlur,
  readOnly,
  disabled,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  withFlag = true,
  inputIcon,
  autoFocus,
  raw = false,
  required,
  name,
  onKeyDown,
  autoComplete,
  titleHint,
  ...wrapperProps
}, ref) => {
  const placeholder = useMemo(() => getPhoneNumberPlaceholder(country || null), [country]);
  const [trimmedValue, handleChange] = useTrimmedPhoneNumber(value, onChange, country);

  if (raw) {
    return (
      <TextInput
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        tabIndex={tabIndex}
        inputIcon={inputIcon}
        ref={ref}
        type="tel"
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        titleHint={titleHint}
        {...wrapperProps}
      />
    )
  }

  const renderFlag = () => {
    if (!country || !flags[country]) {
      return;
    }

    const Flag = flags[country];
    return <Flag className={countryCodeStyles.flag} />;
  };

  return (
    <TextInputWrapper required={required} {...wrapperProps} disabled={disabled} titleHint={titleHint}>
      <div className={clsx(styles.container, disabled && styles.container__disabled)} onClick={focusInputElement}>
        {withFlag && renderFlag()}
        <PhoneInput
          className={styles.phoneNumberInput}
          value={trimmedValue}
          placeholder={placeholder}
          country={country}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
          tabIndex={tabIndex}
          name={name}
          autoFocus={autoFocus}
          type="tel"
          ref={ref}
          onKeyDown={onKeyDown}
          autoComplete={autoComplete}
        />
        {showLoader && <LoaderWithState state={loaderState} onStateReset={onLoaderStateReset} />}
        {inputIcon && <div className={styles.inputIconContainer}>{inputIcon}</div>}
      </div>
    </TextInputWrapper>
  );
});

export default PhoneNumberInput;
