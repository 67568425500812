import { selectAllBorrowers } from 'handlers/borrowersSlice';
import { useAppSelector } from './reduxHooks';

const useCurrentBorrower = () => {
  const [borrower] = useAppSelector(selectAllBorrowers);

  return borrower ?? null;
};

export default useCurrentBorrower;
