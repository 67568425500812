import { FC } from 'react';
import { TableBody, TableBodyProps } from 'product_modules/components/Table';

const TableBodyWrapper: FC<TableBodyProps> = (props) => {
  return (
    <TableBody {...props} />
  );
};

export default TableBodyWrapper;
