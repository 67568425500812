import useQueryParam from 'product_modules/hooks/useQueryParam';
import { AUTH_ROUTES, BACK_URL_QUERY_PARAM } from 'api/BaseApi';

const useBackUrlQueryParam = () => {
  const backUrlFromPath = useQueryParam(BACK_URL_QUERY_PARAM);

  return backUrlFromPath && AUTH_ROUTES.includes(backUrlFromPath)
    ? null
    : backUrlFromPath;
};

export default useBackUrlQueryParam;
