import clsx from 'clsx';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';
import styles from './NumberIcon.module.scss';

interface INumberIconProps {
  number: number;
  className?: string;
}

const OUTER_CONTAINER_OPACITY = 0.2;

const NumberIcon = ({
  number,
  className,
}: INumberIconProps) => {
  return (
    <ContainerStyled opacity={OUTER_CONTAINER_OPACITY} className={clsx(styles.container, className)}>
      <ContainerStyled className={styles.content}>
        {number}
      </ContainerStyled>
    </ContainerStyled>
  );
};

export default NumberIcon;
