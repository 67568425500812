import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'handlers';
import { apiSlice } from 'slices/apiSlice';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
