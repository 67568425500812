import { createAction, createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { portalPageElementsApi } from 'store/api';
import {
  IFindPortalPageElementsParams,
  IGenerateImageUrlsParams,
  IPortalPageElement,
} from 'api/digifi/PortalPageElementsApi';

enum PortalPageElementsActionType {
  FindPortalPageElements = 'portalPageElements/findPortalPageElements',
  GenerateImageUrls = 'portalPageElements/generateImageUrls',
  ResetPortalPageElements = 'portalPageElements/resetPortalPageElements',
}

const portalPageElementsAdapter = createEntityAdapter<IPortalPageElement>();

export interface IPortalPageElementsState extends EntityState<IPortalPageElement> {
  imageUrls: Record<string, string>;
  isLoaded: boolean;
}

const initialState: IPortalPageElementsState = portalPageElementsAdapter.getInitialState({
  imageUrls: {},
  isLoaded: false,
});

export const getPortalPageElements = createAsyncThunk(
  PortalPageElementsActionType.FindPortalPageElements,
  async (params: IFindPortalPageElementsParams) => {
    return portalPageElementsApi.find(params);
  },
);

export const generateImageUrls = createAsyncThunk(
  PortalPageElementsActionType.GenerateImageUrls,
  async (params: IGenerateImageUrlsParams) => {
    return portalPageElementsApi.generateImageUrls(params);
  },
);

export const resetPortalPageElements = createAction(PortalPageElementsActionType.ResetPortalPageElements);

const portalPageElementsSlice = createSlice({
  name: 'portalPageElementsSlice',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getPortalPageElements.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(getPortalPageElements.fulfilled, (state, { payload }) => {
        portalPageElementsAdapter.setAll(state, payload);
        state.isLoaded = true;
      })
      .addCase(generateImageUrls.fulfilled, (state, { payload }) => {
        state.imageUrls = payload;
      })
      .addCase(resetPortalPageElements, (state) => {
        portalPageElementsAdapter.removeAll(state);
      });
  },
});

export default portalPageElementsSlice.reducer;
