import { NonNullishVariableValue } from 'product_modules/utils/valueFormatters/base/Types';
import isCorrespondsBoolean from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/isCorrespondsBoolean';
import { convertStringToBoolean } from 'product_modules/utils/coerceUtils';

const isCompatibleWithBooleanType = (value: NonNullishVariableValue): value is boolean | string => {
  return isCorrespondsBoolean(value)
    || (typeof value === 'string' && convertStringToBoolean(value) !== null);
};

export default isCompatibleWithBooleanType;
