import React, { createContext, useCallback, useContext, useLayoutEffect } from 'react';
import { EventEmitter } from 'events';

const DynamicSizeContext = createContext<EventEmitter | null>(null);

const EVENT_RESIZE = 'resize';

export const useDynamicResize = (onResize: () => void) => {
  const eventEmitter = useContext(DynamicSizeContext);

  const handleResize = useCallback(() => {
    onResize();
  }, []);

  useLayoutEffect(() => {
    eventEmitter?.addListener(EVENT_RESIZE, handleResize);

    return () => {
      eventEmitter?.removeListener(EVENT_RESIZE, onResize);
    };
  }, [eventEmitter]);
};
