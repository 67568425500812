import { VariableDataType } from 'product_modules/api/Core/VariablesApi';
import {
  AddressVisualDataType,
  BooleanVisualDataType,
  DateVisualDataType,
  NumericVisualDataType,
  StringVisualDataType,
  TableVisualDataType,
  VisualDataType,
} from 'product_modules/enums/VisualDataType';
import { unhandledCase } from 'product_modules/utils/unhandledCase';

export const getDataTypeByVisualDataType = (visualDataType: VisualDataType): VariableDataType => {
  switch (visualDataType) {
    case StringVisualDataType.IdentificationNumber:
    case StringVisualDataType.Text:
    case StringVisualDataType.LargeText:
    case StringVisualDataType.PhoneNumber:
    case StringVisualDataType.EmailAddress:
    case StringVisualDataType.List: {
      return VariableDataType.String;
    }
    case DateVisualDataType.Date: {
      return VariableDataType.Date;
    }
    case NumericVisualDataType.Number:
    case NumericVisualDataType.Monetary:
    case NumericVisualDataType.Percentage: {
      return VariableDataType.Number;
    }
    case BooleanVisualDataType.Boolean: {
      return VariableDataType.Boolean;
    }
    case AddressVisualDataType.Address: {
      return VariableDataType.Address;
    }
    case TableVisualDataType.Table: {
      return VariableDataType.Table;
    }
    default: {
      return unhandledCase(visualDataType);
    }
  }
};
