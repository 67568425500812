import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';
import { IPortalPageGroup } from 'api/digifi/layout/GroupsApi';
import { LayoutConfigurationEntityName } from 'enums/LayoutConfigurationEntityName';
import { createLayoutEntitiesSlice } from './createLayoutEntitiesSlice';

const {
  reducer,
} = createLayoutEntitiesSlice<
  IPortalPageGroup
>({
  referenceType: LayoutConfigurationReferenceType.PortalPage,
  layoutConfigurationEntityName: LayoutConfigurationEntityName.Group,
});

export default reducer;
