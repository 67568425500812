import { forwardRef } from 'react';
import EmailInput, { EmailInputProps } from 'product_modules/components/EmailInput';

const EmailInputWrapper = forwardRef<HTMLInputElement, EmailInputProps>((props, ref) => {
  return (
    <EmailInput {...props} ref={ref} />
  );
});

export default EmailInputWrapper;
