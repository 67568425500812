import React, { FC } from 'react';
import { keyframes } from 'styled-components';
import { rgba } from 'polished';
import styled from 'types/AppTheme';
import ProgressBarElement, { IProgressBarElementProps } from './ProgressBarElement';

const PROGRESS_STEP_ACTIVE_CLASS_NAME = 'PROGRESS_STEP_ACTIVE_CLASS_NAME';
const PROGRESS_STEP_DONE_CLASS_NAME = 'PROGRESS_STEP_DONE_CLASS_NAME';
const CHECK_MARK_CLASS_NAME = 'CHECK_MARK_CLASS_NAME';
const SEPARATOR_DONE_CLASS_NAME = 'SEPARATOR_DONE_CLASS_NAME';

const pulse = (color: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${rgba(color, 0.4)};
  }

  70% {
    box-shadow: 0 0 0 10px ${rgba(color, 0)};
  }

  100% {
    box-shadow: 0 0 0 0 ${rgba(color, 0)};
  }
`;

const ProgressBarElementStyled = styled(ProgressBarElement)<IProgressBarElementProps>`
  .${PROGRESS_STEP_ACTIVE_CLASS_NAME} {
    background: ${({ theme }) => theme.colors.brandColor};
    border-color: ${({ theme }) => theme.colors.brandColor};
    animation: ${({ theme }) => pulse(theme.colors.brandColor)} 2s infinite;
  }
  
  .${PROGRESS_STEP_DONE_CLASS_NAME} {
    border-color: ${({ theme }) => theme.colors.brandColor};
  }
  
  .${CHECK_MARK_CLASS_NAME} {
    path {
      stroke: ${({ theme }) => theme.colors.brandColor};
    }
  }
  
  .${SEPARATOR_DONE_CLASS_NAME} {
    background: ${({ theme }) => theme.colors.brandColor};
  }
`;

const ProgressBarElementWrapper: FC<IProgressBarElementProps> = (props) => {
  return (
    <ProgressBarElementStyled
      {...props}
      progressStepActiveClassName={PROGRESS_STEP_ACTIVE_CLASS_NAME}
      progressStepDoneClassName={PROGRESS_STEP_DONE_CLASS_NAME}
      checkMarkClassName={CHECK_MARK_CLASS_NAME}
      separatorDoneClassName={SEPARATOR_DONE_CLASS_NAME}
    />
  );
};

export default ProgressBarElementWrapper;
