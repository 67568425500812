import React from 'react';
import { SkeletonRectangle } from 'product_modules/components/Skeleton';
import styles from './SkeletonInput.module.scss';

const SkeletonInput = () => (
  <div className={styles.field}>
    <div className={styles.label}>
      <SkeletonRectangle width="70px" height="16px" color="primary6" />
    </div>
    <SkeletonRectangle width="100%" color="primary6" height="52px" />
  </div>
);

export default SkeletonInput;
