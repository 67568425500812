import { FC } from 'react';
import ContainerWithGradientStyled from 'components/BrandingHelpers/ContainerWithGradientStyled';

interface IContainerWithGradientProps {
  useAccentColor?: boolean;
  className?: string;
}

const ContainerWithGradient: FC<IContainerWithGradientProps> = ({ useAccentColor, className, children }) => {
  return (
    <ContainerWithGradientStyled
      useAccentColor={useAccentColor}
      className={className}
    >
      {children}
    </ContainerWithGradientStyled>
  );
};

export default ContainerWithGradient;
