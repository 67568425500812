import React from 'react';
import AutoCompletionSync, { AutoCompletionSyncProps } from './AutoCompletionSync';
import AutoCompletionAsync, { AutoCompletionAsyncProps } from './AutoCompletionAsync';

type AutoCompletionProps = AutoCompletionSyncProps | AutoCompletionAsyncProps;

const isAutoCompletionSyncProps = (props: AutoCompletionProps): props is AutoCompletionSyncProps =>
  'options' in props && !('fetchOptions' in props);

const AutoCompletion = (props: AutoCompletionProps) => {
  return isAutoCompletionSyncProps(props) ? (
    <AutoCompletionSync {...props} ref={null} />
  ) : (
    <AutoCompletionAsync {...props} ref={null} />
  );
};

export default AutoCompletion;
