import formatDateValue from 'product_modules/utils/valueFormatters/formatDateValue';
import formatPhoneNumberValue from 'product_modules/utils/valueFormatters/formatPhoneNumberValue';
import formatNumberValue from 'product_modules/utils/valueFormatters/formatNumberValue';
import formatBooleanValue, { BooleanFormattingParams } from 'product_modules/utils/valueFormatters/formatBooleanValue';
import formatMonetaryValue, { MonetaryFormattingParams } from 'product_modules/utils/valueFormatters/formatMonetaryValue';
import formatPercentageValue from 'product_modules/utils/valueFormatters/formatPercentageValue';
import formatAddressValue from 'product_modules/utils/valueFormatters/formatAddressValue';
import formatIdentificationNumberValue from 'product_modules/utils/valueFormatters/formatIdentificationNumberValue';
import formatTextValue from 'product_modules/utils/valueFormatters/formatTextValue';
import formatTableValue from 'product_modules/utils/valueFormatters/formatTableValue';
import {
  AddressVisualDataType,
  BooleanVisualDataType,
  DateVisualDataType,
  NumericVisualDataType,
  StringVisualDataType, TableVisualDataType,
  VisualDataType,
} from 'product_modules/enums/VisualDataType';
import ValueFormatter from 'product_modules/utils/valueFormatters/base/Types';
import {
  DateVisualAttributes,
  IdentificationTypeVisualAttributes,
  TableVisualAttributes
} from 'product_modules/api/Core/VariablesApi';

interface TextFormatters {
  [DateVisualDataType.Date]: typeof formatDateValue,
  [StringVisualDataType.PhoneNumber]: typeof formatTextValue,
  [StringVisualDataType.List]: typeof formatTextValue,
  [StringVisualDataType.Text]: typeof formatTextValue,
  [StringVisualDataType.LargeText]: typeof formatTextValue,
  [StringVisualDataType.EmailAddress]: typeof formatTextValue,
  [StringVisualDataType.IdentificationNumber]: typeof formatIdentificationNumberValue,
}

interface NumericFormatters {
  [NumericVisualDataType.Number]: typeof formatNumberValue,
  [NumericVisualDataType.Monetary]: typeof formatMonetaryValue,
  [NumericVisualDataType.Percentage]: typeof formatNumberValue,
}

interface BooleanFormatters {
  [BooleanVisualDataType.Boolean]: typeof formatBooleanValue,
}

interface ObjectFormatters {
  [AddressVisualDataType.Address]: typeof formatAddressValue,
  [TableVisualDataType.Table]: typeof formatTableValue,
}

interface FormattersByVisualType extends TextFormatters, NumericFormatters, ObjectFormatters, BooleanFormatters {}

const STRING_VALUE_FORMATTERS = {
  [DateVisualDataType.Date]: formatDateValue,
  [StringVisualDataType.PhoneNumber]: formatPhoneNumberValue,
  [StringVisualDataType.Text]: formatTextValue,
  [StringVisualDataType.List]: formatTextValue,
  [StringVisualDataType.LargeText]: formatTextValue,
  [StringVisualDataType.EmailAddress]: formatTextValue,
  [StringVisualDataType.IdentificationNumber]: formatIdentificationNumberValue,
};

const NUMERIC_VALUE_FORMATTERS = {
  [NumericVisualDataType.Number]: formatNumberValue,
  [NumericVisualDataType.Monetary]: formatMonetaryValue,
  [NumericVisualDataType.Percentage]: formatPercentageValue,
};

const BOOLEAN_VALUE_FORMATTERS = {
  [BooleanVisualDataType.Boolean]: formatBooleanValue,
};

const OBJECT_VALUE_FORMATTERS = {
  [AddressVisualDataType.Address]: formatAddressValue,
  [TableVisualDataType.Table]: formatTableValue,
};

const FORMATTERS_MAP: { [Type in keyof FormattersByVisualType]: FormattersByVisualType[Type] } = {
  ...STRING_VALUE_FORMATTERS,
  ...NUMERIC_VALUE_FORMATTERS,
  ...BOOLEAN_VALUE_FORMATTERS,
  ...OBJECT_VALUE_FORMATTERS,
};

export type FormattingParamsByVisualType<Type extends VisualDataType> =
  Type extends BooleanVisualDataType.Boolean ? BooleanFormattingParams :
    Type extends NumericVisualDataType.Monetary ? MonetaryFormattingParams :
      Type extends StringVisualDataType.IdentificationNumber ? IdentificationTypeVisualAttributes :
        Type extends DateVisualDataType.Date ? DateVisualAttributes :
          Type extends TableVisualDataType.Table ? TableVisualAttributes :
            never;

const getValueFormatter = <
  VisualType extends keyof FormattersByVisualType,
>(visualType: VisualType): ValueFormatter<FormattingParamsByVisualType<VisualType>> =>
  FORMATTERS_MAP[visualType] as ValueFormatter<FormattingParamsByVisualType<VisualType>>;

export default getValueFormatter;
