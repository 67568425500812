import { jwtDecode } from 'jwt-decode';

interface ISetTokensParams {
  accessToken?: string;
  refreshToken?: string;
  passwordValidationToken?: string;
}

class TokenUtils {
  public static getAccessToken = () => {
    return localStorage.getItem('accessToken') || null;
  };

  public static getRefreshToken = () => {
    return localStorage.getItem('refreshToken') || null;
  };

  public static getPasswordValidationToken = () => {
    return localStorage.getItem('passwordValidationToken') || null;
  };

  public static setTokens = ({ accessToken, refreshToken, passwordValidationToken }: ISetTokensParams) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    }

    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }

    if (passwordValidationToken) {
      localStorage.setItem('passwordValidationToken', passwordValidationToken);
    }
  };

  public static removeTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('passwordValidationToken');
  };

  public static isPasswordValidationTokenValid = () => {
    const token = localStorage.getItem('passwordValidationToken');

    if (!token) {
      return false;
    }

    const parsedToken: { exp: number, iat: number } = jwtDecode(token);
    return (Date.now() / 1000) < parsedToken.exp;
  };
}

export default TokenUtils;
