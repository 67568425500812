import { FC } from 'react';
import Separator, { ISeparatorProps } from 'product_modules/components/Separator';

const SeparatorWrapper: FC<ISeparatorProps> = ((props) => {
  return (
    <Separator {...props} />
  );
});

export default SeparatorWrapper;
