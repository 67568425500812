import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { BorrowerTypeCompanyIcon, BorrowerTypePersonIcon } from 'static/images';
import OptionCard from 'components/Auth/Onboarding/components/OptionCard/OptionCard';
import styles from './BorrowerTypeStep.module.scss';
import StepWrapper, { IStepNavigationProps } from 'components/common/StepWrapper';

interface IBorrowerTypeStepProps extends IStepNavigationProps {
  borrowerType: BorrowerType | null;
  onSetBorrowerType: (borrowerType: BorrowerType) => void;
}

const BorrowerTypeStep = ({
  borrowerType,
  onSetBorrowerType,
  ...restProps
}: IBorrowerTypeStepProps) => {
  const renderBorrowerTypeCard = (type: BorrowerType) => {
    const isSelected = type === borrowerType;
    const isPerson = type === BorrowerType.Person;

    return (
      <OptionCard
        key={type}
        icon={isPerson ? <BorrowerTypePersonIcon /> : <BorrowerTypeCompanyIcon />}
        text={isPerson ? 'Person' : 'Company'}
        isSelected={isSelected}
        onClick={() => onSetBorrowerType(type)}
        className={styles.cardContent}
      />
    );
  };

  return (
    <StepWrapper
      title='Are you applying as a person or a company?'
      subtitle='Please select your applicant type.'
      disableContinue={!borrowerType}
      {...restProps}
    >
      {Object.values(BorrowerType).map(renderBorrowerTypeCard)}
    </StepWrapper>
  );
};

export default BorrowerTypeStep;
