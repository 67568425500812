import { FC } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Header from 'components/PageLayout/Header';
import FormFooterLink from 'components/PageLayout/FormFooterLink';
import { AppRoute } from 'enums/AppRoute';
import MainLayout from 'layout/MainLayout';
import { logout } from 'handlers/authSlice';
import { useAppDispatch } from 'hooks/reduxHooks';
import styles from './UnauthorizedLayout.module.scss';

interface IUnauthorizedLayoutProps {
  footerText?: string;
  footerLinkText?: string;
  footerLink?: AppRoute;
  logoutOnLinkClick?: true;
  hideSignInButton?: boolean;
  innerContentClassName?: string;
}

const UnauthorizedLayout: FC<IUnauthorizedLayoutProps> = ({
  footerText,
  footerLinkText,
  footerLink,
  logoutOnLinkClick,
  hideSignInButton,
  innerContentClassName,
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate(footerLink || AppRoute.SignIn);
  };

  return (
    <MainLayout
      contentClassName={styles.mainLayoutContent}
      innerContentClassName={clsx(styles.innerContent, innerContentClassName)}
      renderElementBeforeContent={() => <Header hideSignInButton={hideSignInButton} />}
    >
      <div className={styles.contentContainer}>
        {children}
        {footerLinkText && footerLink && (
          <div className={styles.footerLinkContainer}>
            <FormFooterLink
              text={footerText}
              linkText={footerLinkText}
              to={footerLink}
              onLinkClick={logoutOnLinkClick && handleLogout}
              className={styles.footerLink}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};
export default UnauthorizedLayout;
