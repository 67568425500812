import React, { FC } from 'react';
import { Menu, MenuProps, PopoverOrigin } from '@material-ui/core';

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = { vertical: 'top', horizontal: 'right' };
const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = { vertical: 'top', horizontal: 'right' };
const MenuListProps = { disablePadding: true };

const ContextualPopUp: FC<MenuProps> = React.memo((props) => {
  const { anchorOrigin = DEFAULT_ANCHOR_ORIGIN, transformOrigin = DEFAULT_TRANSFORM_ORIGIN, ...restProps } = props;

  return (
    <Menu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...restProps}
      getContentAnchorEl={null} // needs to be set as null in order to make vertical positioning work
      MenuListProps={MenuListProps}
    />
  );
});

export default ContextualPopUp;
