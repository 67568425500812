import { useCallback } from 'react';
import useScript from 'hooks/useScript';

interface IUseDocuSignWidgetProps {
  bundleUrl: string;
  onSessionEnd: (event: unknown) => void;
  onError: () => void;
  mountContainerId: string;
}

const CLEAR_AFTER = 60000; // show error after 1 minute of loading

const useDocuSignWidget = ({
  bundleUrl,
  onSessionEnd,
  onError,
  mountContainerId,
}: IUseDocuSignWidgetProps) => {
  const isScriptLoaded = useScript(bundleUrl);

  let timeout: NodeJS.Timeout;

  const handleSessionReady = () => {
    clearTimeout(timeout);
  };

  const handleTimeout = useCallback(() => {
    document.getElementsByTagName('iframe')?.[0]?.remove();
    onError();
  }, [onError]);

  return useCallback(async (clientId: string, signingUrl: string) => {
    if (!isScriptLoaded) {
      return;
    }

    try {
      const docusign = await window.DocuSign.loadDocuSign(clientId);

      timeout = setTimeout(handleTimeout, CLEAR_AFTER);

      const signing = docusign.signing({
        url: signingUrl,
        displayFormat: 'focused',
        style: {
          signingNavigationButton: {
            finishText: 'Finish',
          },
        },
      });

      signing.on('ready', handleSessionReady);
      signing.on('sessionEnd', onSessionEnd);

      signing.mount(`#${mountContainerId}`);
    } catch (error) {
      onError();
    }
  }, [onSessionEnd, isScriptLoaded]);
};

export default useDocuSignWidget;
