import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';
import { LayoutConfigurationEntityName } from 'enums/LayoutConfigurationEntityName';
import { IBorrowerVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import { createLayoutEntitiesSlice } from './createLayoutEntitiesSlice';

const {
  reducer,
  removeLayoutEntities: removeBorrowerProfileVariables,
} = createLayoutEntitiesSlice<
  IBorrowerVariableConfiguration
>({
  referenceType: LayoutConfigurationReferenceType.BorrowerProfile,
  layoutConfigurationEntityName: LayoutConfigurationEntityName.Variable,
});

export {
  removeBorrowerProfileVariables,
};

export default reducer;
