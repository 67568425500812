import { useContext } from 'react';
import createUseEntitiesByHook from 'product_modules/hooks/createUseEntitiesByHook';
import { VariablesBySystemNameLoaderContext } from 'product_modules/loaders/Variables/VariablesBySystemNameLoader';
import { RootState } from 'store';

const useVariablesBySystemNames = createUseEntitiesByHook({
  mapSelector: (state: RootState) => state.variables.variablesBySystemName,
  useLoadEntities: () => {
    return useContext(VariablesBySystemNameLoaderContext);
  },
});

export default useVariablesBySystemNames;
