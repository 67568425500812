import { useCallback } from 'react';
import { resetPortalPageElements } from 'handlers/portalPageElementsSlice';
import { resetSelectedApplication, setApplicationData } from 'handlers/applicationDataSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import usePolling from 'hooks/usePolling';
import useSelectedApplication from 'hooks/useSelectedApplication';
import { findApplicationByDisplayId } from 'handlers/applicationsSlice';

const useApplicationStatusUpdatePolling = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();

  const application = useSelectedApplication()!;

  const { previousApplicationStatusId, isSubmitInProgress } = useAppSelector(
    (state) => state.applicationData,
  );

  const handleFinishPolling = useCallback((success?: boolean) => {
    dispatch(setApplicationData({ isSubmitInProgress: false }));

    if (success) {
      dispatch(resetPortalPageElements());
    } else {
      dispatch(resetSelectedApplication());
    }
  }, []);

  const fetchApplication = async () => {
    if (application.status.id !== previousApplicationStatusId) {
      return;
    }

    const updatedApplication = await dispatchWithUnwrap(findApplicationByDisplayId(application.displayId));

    if (updatedApplication?.status.id !== previousApplicationStatusId) {
      handleFinishPolling(true);
    }
  };

  usePolling({
    callback: fetchApplication,
    onAbort: handleFinishPolling,
    condition: isSubmitInProgress,
  });
};

export default useApplicationStatusUpdatePolling;
