import React, { FC, MouseEvent, useRef, useState } from 'react';
import { PopoverOrigin } from '@material-ui/core';
import clsx from 'clsx';
import styles from './RowActions.module.scss';
import { MoreImage } from 'product_modules/static/images';
import ContextualPopUp from 'product_modules/components/ContextualPopUp';
import ActionPopUp from 'product_modules/components/ActionPopUp';
import ActionPopUpItem from 'product_modules/components/ActionPopUpItem';
import Separator from 'product_modules/components/Separator';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import Spinner from 'product_modules/components/Spinner';

export interface RowAction {
  title: string;
  handler: (id?: string) => void;
  danger?: boolean;
  separatorRequired?: boolean;
  separatorBeforeRequired?: boolean;
  disabled?: boolean;
  tooltip?: string;
  disabledClassName?: string;
}

export interface RowActionsProps {
  id?: string;
  actions: RowAction[];
  handleDeleteRule?: () => void;
  className?: string;
  btnClassName?: string;
  popupClassName?: string;
  alwaysVisible?: boolean;
  isLoading?: boolean;
  popupTitle?: string;
  btnTooltip?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  style?: React.CSSProperties;
}

const RowActions: FC<RowActionsProps> = React.memo(({
  id,
  actions,
  className,
  btnClassName,
  popupClassName,
  popupTitle,
  btnTooltip,
  alwaysVisible,
  isLoading,
  anchorOrigin,
  transformOrigin,
  style,
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);

  const stopPropagationAndPreventDefault = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault(); // this is required to prevent default behavior of TableRow link
  };

  const handleOpenPopUp = (event: MouseEvent): void => {
    stopPropagationAndPreventDefault(event);

    setIsPopUpOpen(true);
  };

  const handleClosePopUp = (actionCallback?: RowAction['handler']): void => {
    setIsPopUpOpen(false);
    if (actionCallback) {
      actionCallback(id);
    }
  };

  const popupHandlerRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={clsx(styles.buttonContainer, className)} onClick={stopPropagationAndPreventDefault} style={style}>
      <WrapperWithTooltip tooltip={btnTooltip}>
        <button
          type="button"
          className={clsx(
            styles.actionsButton,
            btnClassName,
            (alwaysVisible || isLoading) && styles.alwaysVisible,
            isLoading && styles.loading,
          )}
          onClick={handleOpenPopUp}
          ref={popupHandlerRef}
          disabled={isLoading}
        >
          {isLoading ? <Spinner className={styles.spinner} size={24} /> : <MoreImage />}
        </button>
      </WrapperWithTooltip>
      <ContextualPopUp
        open={isPopUpOpen}
        anchorEl={popupHandlerRef.current}
        onClose={() => handleClosePopUp()}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <ActionPopUp className={popupClassName} onClose={() => handleClosePopUp()} title={popupTitle}>
          {actions.map(
            ({
               title,
               danger,
               handler,
               separatorRequired,
               separatorBeforeRequired,
               disabled,
               disabledClassName,
               tooltip,
             }) => (
              <WrapperWithTooltip tooltip={tooltip} key={title}>
                <div className={styles.actionItemContainer}>
                  {separatorBeforeRequired && <Separator />}
                  <ActionPopUpItem
                    disabled={disabled}
                    danger={danger}
                    onClick={() => handleClosePopUp(handler)}
                    className={clsx(disabled && styles.disabledAction, disabled && disabledClassName)}
                  >
                    {title}
                  </ActionPopUpItem>
                  {separatorRequired && <Separator />}
                </div>
              </WrapperWithTooltip>
            ),
          )}
        </ActionPopUp>
      </ContextualPopUp>
    </div>
  );
});

export default React.memo(RowActions) as typeof RowActions;
