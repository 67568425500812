import { forwardRef } from 'react';
import PasswordInput, { PasswordInputProps } from 'product_modules/components/PasswordInput';

const PasswordInputWrapper = forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
  return (
    <PasswordInput {...props} ref={ref} />
  );
});

export default PasswordInputWrapper;
