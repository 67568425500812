import React, { ChangeEvent, FocusEvent, forwardRef, KeyboardEvent } from 'react';
import TextInput from 'product_modules/components/TextInput';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import styles from './EmailInput.module.scss';

export interface EmailInputProps {
  value: string;
  labelTitle: string;
  onChange: (value: string) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  errorMessage: string;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  inputIcon?: React.ReactNode;
  required?: boolean;
  name?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  titleHint?: string;
  labelTooltipClassName?: string;
}

const PLACEHOLDER = 'email@domain.com';

const EmailInput = forwardRef<HTMLInputElement, EmailInputProps>(({
  value,
  labelTitle,
  onChange,
  onBlur,
  onFocus,
  readOnly,
  disabled,
  errorMessage,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  inputIcon,
  required,
  name,
  onKeyDown,
  autoComplete,
  useSimplifiedInput,
  titleHint,
  labelTooltipClassName,
  style
}, ref) => {
  return (
    <div className={styles.container}>
      <TextInput
        value={value}
        labelTitle={labelTitle}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value)}
        placeholder={PLACEHOLDER}
        onFocus={onFocus}
        onBlur={onBlur}
        errorMessage={errorMessage}
        readOnly={readOnly}
        disabled={disabled}
        showLoader={showLoader}
        loaderState={loaderState}
        onLoaderStateReset={onLoaderStateReset}
        tabIndex={tabIndex}
        inputIcon={inputIcon}
        required={required}
        name={name}
        type="email"
        autoComplete={autoComplete}
        onKeyDown={onKeyDown}
        inputRef={ref}
        useSimplifiedInput={useSimplifiedInput}
        style={style}
        titleHint={titleHint}
        labelTooltipClassName={labelTooltipClassName}
      />
    </div>
  );
});

export default EmailInput;
