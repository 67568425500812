import { useMemo } from 'react';

export interface IICreateUseEntityByHookParams<Entity, Field extends string | number> {
  useEntitiesById: (fields: Field[] | null | undefined) => Record<Field, Entity> | null;
}

const createUseEntityByHook = <Entity, Field extends string | number>({
  useEntitiesById,
}: IICreateUseEntityByHookParams<Entity, Field>) => {
  return (fetchField: Field | null | undefined) => {
    const fields = useMemo(() => {
      return fetchField ? [fetchField] : null;
    }, [fetchField]);

    const entities = useEntitiesById(fields);

    if (!fetchField) {
      return null;
    }

    return entities ? (entities[fetchField] ?? null) : undefined;
  };
};

export default createUseEntityByHook;
