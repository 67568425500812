import React, { FC } from 'react';
import SkeletonRectangle, { SkeletonRectangleProps } from 'product_modules/components/Skeleton/SkeletonRectangle';
import clsx from 'clsx';
import styles from './SkeletonText.module.scss';

export interface SkeletonTextProps extends SkeletonRectangleProps {
  lineHeight: string;
  lineWidth?: string;
}

const SkeletonText: FC<SkeletonTextProps> = ({
  lineHeight,
  lineWidth,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  className,
  ...restSkeletonRectangleProps
}) => {
  return (
    <div
      className={clsx(styles.container, className)}
      style={{ height: lineHeight, width: lineWidth, marginRight, marginLeft, marginBottom, marginTop }}
    >
      <SkeletonRectangle {...restSkeletonRectangleProps} />
    </div>
  );
};

export default SkeletonText;
