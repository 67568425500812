import BaseApi from 'api/BaseApi';
import { BorrowerType } from 'product_modules/enums/BorrowerType';

interface IBorrowerTypesApi {
  getBorrowerTypes(): Promise<BorrowerType[]>;
}

export default class BorrowerTypesApi extends BaseApi implements IBorrowerTypesApi {
  public getBorrowerTypes(): Promise<BorrowerType[]> {
    return this.fetch('/borrower-types');
  }
}
