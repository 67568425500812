import React, { FC } from 'react';
import clsx from 'clsx';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import ButtonToggleGroup, { IButtonToggleGroupOption, IButtonToggleGroupProps } from 'product_modules/components/ButtonToggleGroup/ButtonToggleGroup';
import styles from './BorrowerTypeToggleGroup.module.scss';

export interface IBorrowerTypeToggleGroupProps extends Omit<
  IButtonToggleGroupProps, 'options' | 'onSelect' | 'selectedValue'
> {
  className?: string;
  buttonClassName?: string;
  selectedBorrowerType: BorrowerType | null;
  onChange: (type: BorrowerType) => void;
  availableBorrowerTypes: BorrowerType[];
  optionTextOverwrite?: Record<BorrowerType, string>;
}

const BorrowerTypeToggleGroup: FC<IBorrowerTypeToggleGroupProps> = ({
  className,
  selectedBorrowerType,
  onChange,
  buttonClassName,
  availableBorrowerTypes,
  ...restProps
}) => {
  const transformedOptions = Object.values(BorrowerType).map<IButtonToggleGroupOption>((type) => {
    return {
      value: type,
      label: restProps.optionTextOverwrite?.[type] || type,
    };
  });

  return (
    <ButtonToggleGroup
      {...restProps}
      className={clsx(styles.buttonToggleGroup, className)}
      buttonClassName={clsx(styles.buttonFromToggleGroup, buttonClassName)}
      options={transformedOptions}
      selectedValue={selectedBorrowerType || ''}
      onSelect={(type) => onChange(type as BorrowerType)}
      isOptionDisabled={(type) => !availableBorrowerTypes.includes(type as BorrowerType)}
    />
  );
}

export default BorrowerTypeToggleGroup;
