import Header from 'product_modules/components/Header';
import NumberIcon from 'components/GuestHome/StepsInfoBlock/NumberIcon';
import styles from './StepsInfoBlock.module.scss';

interface IInfoSectionStep {
  title: string;
  content: string;
}

const STEPS: IInfoSectionStep[] = [
  {
    title: 'Online Process',
    content: 'Our online origination experience is built to be fast and efficient.',
  },
  {
    title: 'Mobile Friendly',
    content: 'Complete the process from any phone, tablet or computer.',
  },
  {
    title: 'Safe & Secure',
    content: 'Your data is protected with the highest levels of encryption.',
  },
];

const StepsInfoBlock = () => {
  const renderStep = (item: IInfoSectionStep, index: number) => {
    return (
      <div className={styles.stepContainer} key={index}>
        <NumberIcon number={index + 1} className={styles.icon} />
        <div>
          <Header type='h6' className={styles.title}>{item.title}</Header>
          <p className={styles.text}>{item.content}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {STEPS.map(renderStep)}
    </div>
  );
};

export default StepsInfoBlock;
