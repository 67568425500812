import { FC } from 'react';
import clsx from 'clsx';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import Tag from 'components/digifi-wrappers/Tag';
import OverflowedText from 'components/digifi-wrappers/OverflowedText';
import formatDisplayId from 'product_modules/utils/userIdFormat';
import formatMonetaryValue from 'product_modules/utils/formatMonetaryValue';
import { ApplicationsTableColumnSizes } from 'components/ApplicationsList/ApplicationsTableRow/ApplicationsTableColumnSized';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import formatDate from 'utils/formatDate';
import getApplicationStatusTagColor from 'utils/getApplicationStatusTagColor';
import maskApplicationStatusName from 'utils/maskApplicationStatusName';
import styles from './ApplicationsTableRow.module.scss';

interface IApplicationsTableRowProps {
  application: IApplicationViewModel;
  currency: string;
}

const ApplicationsTableRow: FC<IApplicationsTableRowProps> = ({ application, currency }) => {
  const renderDesktopRow = () => {
    return (
      <>
        <TableBodyCell width={ApplicationsTableColumnSizes.ProductName} overflowed className={clsx(styles.tableCellDesktop, styles.productName)}>
          {application.productName}
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes.Id} overflowed className={styles.tableCellDesktop}>
          <div className={styles.displayId}>
            ID: {formatDisplayId(application.displayId)}
          </div>
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes.LoanAmount} overflowed className={styles.tableCellDesktop}>
          {application.loanAmount !== undefined && application.loanAmount !== null && formatMonetaryValue(application.loanAmount, currency)}
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes.Status} className={styles.tableCellDesktop}>
          <Tag color={getApplicationStatusTagColor((application.status))} className={styles.statusTag}>
            <OverflowedText>
              {maskApplicationStatusName(application.status)}
            </OverflowedText>
          </Tag>
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes.ApplicationDate} overflowed className={styles.tableCellDesktop}>
          {formatDate(application.createdAt)}
        </TableBodyCell>
      </>
    );
  };

  const formattedLoanAmount = (application.loanAmount !== undefined && application.loanAmount !== null)
    ? formatMonetaryValue(application.loanAmount, currency)
    : '—';

  const renderMobileRow = () => {
    return (
      <>
        <TableBodyCell className={styles.tableCellMobile}>
          <div className={clsx(styles.tableCellSection, styles.productNameSection)}>
            <div className={styles.mobileSectionContainer}>
              <div className={styles.mobileProductNameTitle}>
                {application.productName}
              </div>
              <Tag color={getApplicationStatusTagColor((application.status))} className={styles.statusTag}>
                {maskApplicationStatusName(application.status)}
              </Tag>
            </div>
            <div className={clsx(styles.mobileSectionContainer, styles.mobileLoanAmountContainer)}>
              <p className={styles.loanAmount}>
                {formattedLoanAmount}
              </p>
              <div className={styles.displayId}>
                ID: {formatDisplayId(application.displayId)}
              </div>
            </div>
          </div>
          <div className={styles.tableCellContent}>
            <div className={styles.tableCellSection}>
              {formatDate(application.createdAt)}
            </div>
          </div>
        </TableBodyCell>
      </>
    );
  };

  return (
    <>
      {renderDesktopRow()}
      {renderMobileRow()}
    </>
  );
};

export default ApplicationsTableRow;
